import React, { useRef, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { createEraserCircle, updateEraserCircle, removeEraserCircle } from './drawFnc';
import './DrawNormal.scss';

import { useSelector, useDispatch } from 'react-redux';
import fnc from "../mymod/commFunction";
import { setUndo, setRedo, setClear } from '../reduxToolkit/redux';
import indexDb from '../indexedDB/query_autogen';


const DRAW_MODE = 'draw';
const ERASE_MODE = 'erase';
let scaleLet = 1;

const getMidPoint = (point1, point2) => ({
  x: (point1.x + point2.x) / 2,
  y: (point1.y + point2.y) / 2,
});


const DrawNormal = forwardRef(
  (
    {
    
      imgUrlQue,
      testCode, munCode, no,
      setAllowTouchMove,
      height,
      imgSize,
      imgWidth,
   
    }, ref ) => {

      const currentColor = useSelector(state => state.reduxStore.currentColor);
      const highlightMode = useSelector(state => state.reduxStore.highlightMode);
      const list_pdf = useSelector(state => state.reduxStore.list_pdf_marking);
      const rowIdx_pdf = useSelector(state => state.reduxStore.rowIdx_pdf);

      const mode = useSelector(state => state.reduxStore.mode);
      const undo = useSelector(state => state.reduxStore.undo);
      const redo = useSelector(state => state.reduxStore.redo);
      const clear = useSelector(state => state.reduxStore.clear);


    const stCode = fnc.getCookie('stCode');
    const svgRefs = useRef([]);
    const svg = svgRefs.current;
    const points = useRef([]);
    const colorRef = useRef("black");
    const highlightModeRef = useRef(false);
    const drawnPaths = useRef([]); // 그려진 경로를 저장할 배열
    const removedPaths = useRef([]); // 지워진 경로를 저장하기 위한 배열

    // const [imgWidth, setImgWidth] = useState('90%');
    const [viewBoxHeight, setViewBoxHeight] = useState(1500);
    const [scale, setScale] = useState(1*1);//여기 조절하면 기존에 저장한 것 축소 정확히 된다.(나중에 듀얼로 할 것)
    const scaleRef = useRef(1);
    const [device, setDevice] = useState('');
    const [animationClass, setAnimationClass] = useState('');
    const dispatch = useDispatch();
    const slideHandlePrevNextInt = useSelector(state => state.reduxStore.slideHandlePrevNextInt);
    const info = useSelector(state => state.reduxStore.info);
    const kind = useSelector(state => state.reduxStore.kind);//kind를 redux로 변경 24.05.08
    const svrUrl = process.env.REACT_APP_SERVER_URL;
    const isPenUsed = useRef(false);//svg저장용도
    const [loadedSvgData, setLoadedSvgData] = useState('');
  
    let currentPath;
   
    useEffect(() => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      // setDevice(userAgent);
      if (/android/i.test(userAgent)) {
        setDevice('Android');
      } else if (/iPad|iPhone|iPod|Mac/.test(userAgent) && !window.MSStream) {
        setDevice('iPad');
      } else {
        setDevice('Unknown');
      }
    }, []);

    useEffect(() => {//ref로 해야 color가 먹힌다.
      colorRef.current = currentColor;
      highlightModeRef.current = highlightMode;
    }, [currentColor]);
    //---------------------------------------------------------

    const deletePath = (x, y) => {
      const svg = svgRefs.current;
      const paths = svg.getElementsByTagName("path");

      for (const path of paths) {
        const pathData = path.getAttribute("d");
        const pathDataArray = pathData.split(" ");

        for (let i = 1; i < pathDataArray.length - 1; i++) {
          const lineData = pathDataArray[i].split(",");
          const lineX = parseFloat(lineData[0]);
          const lineY = parseFloat(lineData[1]);

          if (Math.abs(lineX - x) < 15 && Math.abs(lineY - y) < 15) {
            removedPaths.current.push(path); // 지운 path를 removedPaths 배열에 추가
            svg.removeChild(path);
            return; // Return here if you want to remove only one path
          }
        }
      }
    };


    const handleDrawing = (clientX, clientY, type) => {
    
      const svg = svgRefs.current;
      const pt = svg.createSVGPoint();
    
      pt.x = clientX;
      pt.y = clientY;
      const svgP = pt.matrixTransform(svg.getScreenCTM().inverse());

      // if (device === 'Android') {
      //   scaleLet = 1; 
      // }else {
      //   scaleLet = scaleRef.current.textContent; 
      // }
     
      scaleLet = 1; 
      const { x: x2, y: y2 } = svgP;
      const x = x2 / scaleLet;
      const y = y2 / scaleLet;

      if (mode === ERASE_MODE) {
        updateEraserCircle(x, y); // 원 위치 업데이트
        deletePath(x, y);
        if (type === "start") {
          createEraserCircle(x, y, svg);
        }
        return;
      }

      if (type === "start") {

        points.current = [{ x, y }];
  
        currentPath = document.createElementNS("http://www.w3.org/2000/svg","path");
        let strokeWidth = 2;
        let strokeOpacity = 1;
        if (highlightModeRef.current) {
          strokeWidth = 15;
          strokeOpacity = 0.5;
        }
        currentPath.setAttribute("stroke-width", strokeWidth);
        currentPath.setAttribute("stroke-opacity", strokeOpacity);
        currentPath.setAttribute("stroke", colorRef.current);
        currentPath.setAttribute("fill", "none");
        currentPath.setAttribute("d", `M ${x},${y}`);
        currentPath.setAttribute("stroke-linecap", "round"); // 선의 끝이 둥글게
        // currentPath.setAttribute("stroke-linecap", "square"); // 선의 끝이 넘어가

        // 블렌드 모드 적용
        if (
          colorRef.current === "yellow" ||
          colorRef.current === "greenyellow"
        ) {
          currentPath.style.mixBlendMode = "multiply";
        }

        svg.appendChild(currentPath);
        drawnPaths.current.push(currentPath); // 그려진 경로를 drawnPaths 배열에 저장

      } else if (type === "move") {
         points.current.push({ x, y });
        if (points.current.length < 3) {
          return;
        }

        const [point1, point2, point3] = points.current.slice(-3);
        const midPoint1Raw = getMidPoint(point1, point2);
        const midPoint2Raw = getMidPoint(point2, point3);
        
        const midPoint1 = {
          x: midPoint1Raw.x,
          y: midPoint1Raw.y,
        };

        const midPoint2 = {
          x: midPoint2Raw.x,
          y: midPoint2Raw.y,
        };

        const d = currentPath.getAttribute("d") || ""; // 'd' 속성이 null일 경우 대비
        const curve = ` C ${midPoint1.x},${midPoint1.y} ${point2.x},${point2.y} ${midPoint2.x},${midPoint2.y}`;
        currentPath.setAttribute("d", `${d} ${curve}`);

      }
    };


    useEffect(() => {
      const svg = svgRefs.current;
      const touchStart = (e) => {
        const svg = svgRefs.current;//down도 해본다.
        removeEraserCircle(svg); // 원 제거 이거 해야 원이 안 생긴다. mouseup만 하면 됨 24.05.11
 
        if (
          !e.touches ||
          e.touches.length === 0 ||
          e.touches[0].radiusX !== 0
        ) {
          //손가락
          // setAllowTouchMove(true);
          if (e.touches.length === 1) {
            setAllowTouchMove(true);
            isPenUsed.current = false;
          }else {
            setAllowTouchMove(false);
            isPenUsed.current = true;
          }

          return; // Ignore if it's not a pen
        }

        if (e.touches[0].radiusX === 0) {
          //펜일 때  
            setAllowTouchMove(false);
            isPenUsed.current = true;
          e.preventDefault();
          const { clientX, clientY } = e.touches[0];
          handleDrawing(clientX, clientY, "start");
        }
      };

      const touchMove = (e) => {
        if (!e.touches || e.touches.length === 0 || e.touches[0].radiusX !== 0 ) {
          return; // Ignore if it's not a pen
        }

        if (e.touches[0].radiusX === 0) {
          e.preventDefault();
          const { clientX, clientY } = e.touches[0];
          handleDrawing(clientX, clientY, "move");
        }
      };

      const touchEnd = () => {
        points.current = [];
        const svg = svgRefs.current;
        removeEraserCircle(svg); // 원 제거

        if ( isPenUsed.current ) {
          fncSetSvgRedux();//펜 사용일 때만
          //kind 상태변화 추가 새로 que, sol 가져오기 위해
        }
        isPenUsed.current = false;
      };

      svg.addEventListener("touchstart", touchStart);
      svg.addEventListener("touchmove", touchMove);
      svg.addEventListener("touchend", touchEnd);

      return () => {
        svg.removeEventListener("touchstart", touchStart);
        svg.removeEventListener("touchmove", touchMove);
        svg.removeEventListener("touchend", touchEnd);
      };

    }, [mode, kind]);

    //---------------------------마우스로 그리기 (개발자용)//크롬 pc용을 해야 그려진다. (검사 닫아)
    useEffect(() => {
      const svg = svgRefs.current;
    
      let isDrawing = false;
    
      const mouseDown = (e) => {
        isDrawing = true;
        const svg = svgRefs.current;//down도 해본다.
        removeEraserCircle(svg); // 원 제거 이거 해야 원이 안 생긴다. mouseup만 하면 됨 24.05.11
 
        handleDrawing(e.clientX, e.clientY, "start");
      };
    
      const mouseMove = (e) => {
        if (!isDrawing) return;
        handleDrawing(e.clientX, e.clientY, "move");
      };
    
      const mouseUp = () => {
        isDrawing = false;
        points.current = [];

        const svg = svgRefs.current;
        removeEraserCircle(svg); // 원 제거 이거 해야 원이 안 생긴다. mouseup만 하면 됨 24.05.11
 
        fncSetSvgRedux()

      };

   
      svg.addEventListener("mousedown", mouseDown);
      svg.addEventListener("mousemove", mouseMove);
      svg.addEventListener("mouseup", mouseUp);
    
      return () => {
        svg.removeEventListener("mousedown", mouseDown);
        svg.removeEventListener("mousemove", mouseMove);
        svg.removeEventListener("mouseup", mouseUp);
      };
    
    }, [mode, kind]); // 여기에 kind 추가 (문 풀 변경 시 que, sol 새로 가져와야.. 또는 kind를 없애고 ref.current를 이용하던지 : 개고생)
    

    // Assume saveSVG is a function that returns the current SVG data
    const saveSVG = () => {
      const svgElement = svgRefs.current;
      if (!svgElement) return null;
      return svgElement.innerHTML;
    };
  
  function fncUndo() {
    const svg = svgRefs.current;
    const paths = svg.querySelectorAll("path");
    if (paths.length > 0) {
      const latestPath = paths[paths.length - 1];
      removedPaths.current.push(latestPath);
      svg.removeChild(latestPath);
    }
  }

  function fncRedo() {
    const svg = svgRefs.current;
      if (!svg) return; // svg가 없다면 함수를 빠져나옵니다.
      if (removedPaths.current.length > 0) {
        const latestRemovedPath = removedPaths.current.pop();
        svg.appendChild(latestRemovedPath);
      }
  }

  function fncClear() {
    const rtn = window.confirm(
      "메모 전체를 지우시겠습니까? \n(배경 내용은 유지됩니다.)"
    );
    if (!rtn) return;
    const svg = svgRefs.current;
    if (!svg) return;
    const paths = svg.getElementsByTagName("path");
    while (paths.length > 0) {
      svg.removeChild(paths[0]);
    }
  }


  if(undo){
    fncUndo();
    dispatch(setUndo(false));
  }

  if(redo){
    fncRedo();
    dispatch(setRedo(false));
  }
  if(clear){
    fncDeleteAllSvg() 
    dispatch(setClear(false));
  }

  
function fncRestoreSvg() {
  const svg = svgRefs.current;
  // let testCode, munCode, no;
  // testCode = list_pdf[rowIdx_pdf]["source"];
  // munCode = list_pdf[rowIdx_pdf]["pdfFileName"];
  // no = list_pdf[rowIdx_pdf]["page"];
  indexDb.selectSvg({ stCode, testCode, munCode, kind }, (err, res) => {
    if (res && res.length > 0 && res[0].svgData) {
      svg.innerHTML = res[0].svgData;
    } else {
      fncMakeSvg();
    }
  });
}



function fncSetSvgRedux() { //메모리에 저장하기  
  //중요: ref.current는 항상 최신값을 랜더링 없이 갱신, 직접변수 kind는 랜더링을 시켜야 최신값으로 변경 됨
  const svgData = saveSVG();
  if ((svgData  && stCode && kind)) {
      indexDb.upsertSvg({testCode, stCode, munCode, kind, svgData, no });
  }
}


function fncDeleteAllSvg() {//전체 삭제 clear
  if (!munCode || !testCode || !stCode) return;//공통지문의 경우 코드 없을 때는 검색 생략
  indexDb.deleteSvg({stCode, testCode, munCode, kind}, (response, err) => {
    if (response.result > 0) {//{result: 1}
      fncClear();      
    } else {     
      console.error("Error in callback:", err);
    }
  });
}



function fncMakeSvg() {
   const svg = svgRefs.current;
  // svg.innerHTML = "";//초기화(이거하면 안돼)
  // alert(imgSize)

  if (imgSize) {//viewBox 높이 설정
    const imgSizeX = imgSize.split(',')[0];
    const imgSizeY = imgSize.split(',')[1];

    if (imgSizeY) {
      setViewBoxHeight(imgSizeY * imgWidth / imgSizeX + 1000);
    }
  } else {
    setViewBoxHeight('2000');//이게이미지 사이즈 기본값임
  }
  

  // 저장된 이미지가 있으면 여기는 콘솔 안 찍혀 (이곳은 이미지 새로 넣는 곳)
  if (!imgUrlQue || !imgUrlQue.imgPathQue) return; // imgUrlQue 또는 imgUrlQue.imgUrlQue가 없다면 리턴
  const imgUrlPath = imgUrlQue.imgPathQue;
  const img = document.createElementNS("http://www.w3.org/2000/svg","image");

  img.setAttributeNS(null, "x", "0");
  img.setAttributeNS(null, "y", "20");//이미지 top
  // img.setAttributeNS(null, "width", `${imgWidth}`);
  img.setAttributeNS(null, "width", `${imgWidth}`);
  img.setAttributeNS("http://www.w3.org/1999/xlink", "xlink:href", imgUrlPath );
 // 여긴 풀이로 변경 시 loadedSvgData가 변경되므로 imgUrlPath도 변경된다. (지우지 말 것)
  svg.appendChild(img);
}


useEffect(() => {
  fncRestoreSvg();
}, [imgWidth]);



    //#######################################################################################

    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );
    const maxScale = vw / imgWidth;

    const style = {
      height: height || 'auto', // 기본값 설정 가능
    };

    const style2 = {//bounce 실패
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch'
    };

    function handleClick() {

    }

    //================================================

    return (
      <div className="DrawNormal">
      
          <div className={`main_inner ${animationClass}`}>
                <div className="box-svg" style={style2}>
    
                  <svg
                    onClick={handleClick}
                    style={{
                      position: 'absolute',
                      left: '50%',
                      transform: 'translateX(-50%) scale(' + scale + ')',
                      transformOrigin: '50% 0',
                      width: imgWidth,
                      height: viewBoxHeight,
                    }}
                    ref={(el) => {
                      svgRefs.current = el; 
                    }      
                  }
                    viewBox={`0 0 ${imgWidth} ${viewBoxHeight}`}
                  ></svg>
                </div>

          </div>
          
      </div>
    );
  }
);

export default React.memo(DrawNormal);

