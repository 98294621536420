import './SignUp.scss';
import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import TermsAgreement from './TermsAgreement'; // 이용약관 컴포넌트 임포트
import AddressDaum from './AddressDaum';
import fnc from '../mymod/commFunction';
import * as S from '../scss/theme';

function SignUp() {
  const svrUrl = process.env.REACT_APP_SERVER_URL;
  const navigate = useNavigate();

  const [userInput, setUserInput] = useState({
    username: '',
    userid: '',
    email: '',
    phone: '',
    phone_mom: '',
    password: '',
    confirmPassword: '',
    address: '',
    detailAddress: '',
    zipcode: '',
    kind: 0,
  });

  const [errorMessages, setErrorMessages] = useState({});
  const [isCheckingUserid, setIsCheckingUserid] = useState(false);
  const [useridAvailable, setUseridAvailable] = useState(true);
  const [agreedTerms, setAgreedTerms] = useState(false);
  const [agreedPrivacy, setAgreedPrivacy] = useState(false);
  const [isModal, setIsModal] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInput({
      ...userInput,
      [name]: value,
    });
  };

  useEffect(() => {
    console.log(userInput, ' userInputList');
  }, [userInput]);

  const validateForm = () => {

    if (userInput.kind == 0) {
      alert('회원유형을 선택해 주세요.');
      return false;
    }

    if (!userInput.userid.trim()) {
      alert('아이디를 입력해 주세요.');
      return false;
    }

    if (!userInput.username.trim()) {
      alert('이름을 입력해 주세요.');
      return false;
    }

    if (!userInput.email) {
      alert('이메일을 입력해 주세요.');
      return false;
    } else if (!/\S+@\S+\.\S+/.test(userInput.email)) {
      alert('유효한 이메일 주소를 입력해 주세요.');
      return false;
    }


    const phonePattern = /^[0-9]{10,11}$/;
    if (!userInput.phone) {
      alert( '휴대전화 번호를 입력해 주세요.');
      return false;
    } else if (!phonePattern.test(userInput.phone)) {
      alert('유효한 휴대전화 번호를 입력해 주세요.');
      return false;
    }

    if (!userInput.phone_mom && userInput.kind == 1) {
      alert( '부모님 휴대전화 번호를 입력해 주세요.');
      return false;
    } else if (!phonePattern.test(userInput.phone_mom)  && userInput.kind == 1) {
      alert('유효한 부모님 휴대전화 번호를 입력해 주세요.');
      return false;
    }

    if (!userInput.address) {
      alert('주소를 입력해 주세요.');
      return false;
    }

    if (!userInput.detailAddress) {
      alert('상세주소를 입력해 주세요.');
      return false;
    }

    
    if (!userInput.password) {
      alert('비밀번호를 입력해 주세요.');
      return false;
    } else if (userInput.password.length < 6) {
      alert('비밀번호는 최소 6자 이상이어야 합니다.');
      return false;
    }

    if (userInput.password !== userInput.confirmPassword) {
      alert( '비밀번호가 일치하지 않습니다.');
      return false;
    }

    
    if (!agreedTerms) {
      alert('이용약관에 동의해야 합니다.');
      return false;
    }

    if (!agreedPrivacy) {
      alert('개인정보 수집에 동의해야 합니다.');
      return false;
    }

    return true;
  };

  const handleCheckUsernameClick = () => {
    setIsCheckingUserid(true);
    fncExistId(userInput.userid);
  };

  function onClose() {
    navigate('/login');
  }

  function fncExistId(id) {
    if (id.length < 4) {
      alert('아이디를 입력하세요.');
      return;
    }

    fnc.sendAxios('/exist_id', { id }, (res) => {
      if (res) {
        if (res[0].cnt > 0) {
          setUseridAvailable(false);
          alert('이미 사용 중인 아이디입니다.');
        } else {
          setUseridAvailable(true);
          alert('사용 가능한 아이디입니다.');
        }
      } else {
        console.log("속성이 obj 내에 없습니다.");
      }
    });
  }

  function fncInsertUser() {


    if (!validateForm()) return;


    fnc.sendAxios('/save/join/user', {
      acaCode: '1000',
      id: userInput.userid,
      pw: userInput.password,
      userName: userInput.username,
      hp: userInput.phone,
      kind: userInput.kind, 
      zipCode: userInput.zipcode, 
      adress1: userInput.address,
      adress2: userInput.detailAddress,
      serviceTerms: agreedTerms ? '동의함' : '동의안함',
      smsTerms: agreedPrivacy ? '동의함' : '동의안함',
      hp_parent: userInput.phone_mom,
      email: userInput.email,
    }, (res) => {
      if (res) {
        if (res.success === 'exist') {
          alert('이미 존재하는 아이디입니다.');
        } else if (res.success === true) {
          alert('회원가입이 완료되었습니다. \n 로그인 하세요!');
          navigate('/login');
        }
      } else {
        alert('가입에 실패했습니다.');
        console.log("속성이 obj 내에 없습니다.");
      }
    });
  }

  const handleAddressChange = (address, zipcode) => {
    setUserInput({
      ...userInput,
      address: address,
      zipcode: zipcode,
    });
  };

  const onAgreeTermsChange = (isAgreed) => {
    setAgreedTerms(isAgreed);
  };

  const onAgreePrivacyChange = (isAgreed) => {
    setAgreedPrivacy(isAgreed);
  };

  return (
    <S.Section2>
    <div className='SignUp'>
    <div className='wrap-main'>
      <section className='section-singnup'>
      <p className='title'>회원가입</p>
          <div className='box_signUp'>
            <div className='inner_singUp'>
              <article className='article-kind'>
                <label className="main_lbl">*회원유형</label>
                <select
                  className="cmbKind"
                  name='kind'
                  value={userInput.kind}
                  onChange={handleChange}
                >
                  <option value="0">유형을 선택하세요!</option>
                  <option value="1">학생</option>
                  <option value="2">선생님</option>
                  <option value="3">관리자</option>
                </select>
        
              </article>

              <div>
                <label htmlFor="userid">*아이디</label>
                <input
                  type="text"
                  id="userid"
                  name="userid"
                  value={userInput.userid}
                  onChange={handleChange}
                  required
                  placeholder='아이디를 입력해 주세요.'
                />
    
              </div>

              <div>
                <label htmlFor="username">*이름</label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  value={userInput.username}
                  onChange={handleChange}
                  required
                  placeholder='이름을 입력해 주세요.'
                />
 
              </div>

              <div>
                <label htmlFor="email">*이메일</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={userInput.email}
                  onChange={handleChange}
                  required
                  placeholder='이메일을 입력해 주세요.'
                />
          
              </div>

              <div>
                <label htmlFor="phone">*휴대전화</label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  value={userInput.phone}
                  onChange={handleChange}
                  required
                  placeholder='- 없이 입력해주세요.'
                />
   
              </div>

             { userInput.kind == 1 && <div>
                <label htmlFor="phone_mom">*부모님 휴대전화</label>
                <input
                  type="text"
                  id="phone_mom"
                  name="phone_mom"
                  value={userInput.phone_mom}
                  onChange={handleChange}
                  required
                  placeholder='- 없이 입력해주세요.'
                />
  
              </div>}


              <div className='box-adress'>
              <label htmlFor="address">*주소 {userInput.zipcode ? `(${userInput.zipcode})` : ''}</label>
                <input
                  type="text"
                  id="address"
                  name="address"
                  value={userInput.address}
                  readOnly
                  placeholder='주소를 검색해 주세요.'
                />
                <Button className='btnAdressSearch'  color="error" onClick={() => setIsModal(true)} >
                  주소검색
                </Button>
 
                 <input
                  type="text"
                  id="detailAddress"
                  name="detailAddress"
                  value={userInput.detailAddress}
                  onChange={handleChange}
                  placeholder='상세주소를 입력해 주세요.'
                />
            </div>


              <div>
                <label htmlFor="password">*비밀번호</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={userInput.password}
                  onChange={handleChange}
                  required
                  placeholder='비밀번호를 입력해 주세요.'
                />
              </div>

              <div>
                <input
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  value={userInput.confirmPassword}
                  onChange={handleChange}
                  required
                  placeholder='비밀번호를 한번 더 입력해 주세요.'
                />
               
              </div>
            </div>

            <Button
              className='btnCheckUserId'
              onClick={handleCheckUsernameClick}
              color="error"
            >
              중복확인
            </Button>
          </div>

          <div className='box_termsAgreement'>
            <TermsAgreement
              agreedTerms={agreedTerms}
              agreedPrivacy={agreedPrivacy}
              onAgreeTermsChange={onAgreeTermsChange}
              onAgreePrivacyChange={onAgreePrivacyChange}
            />
            {errorMessages.agreedTerms && <p className="errorMessage">{errorMessages.agreedTerms}</p>}
            {errorMessages.agreedPrivacy && <p className="errorMessage">{errorMessages.agreedPrivacy}</p>}
          </div>

          <div className='box_menu'>
            <Button className='btnClose h-btn-gray' onClick={onClose}>취소</Button>
            <Button  className='btnSave h-btn-red' onClick={fncInsertUser} >가입하기</Button>
          </div>
  
      </section>
    
      {isModal && <div className='box_modal'>
        <AddressDaum setIsModal={setIsModal} handleAddressChange={handleAddressChange} />
      </div> }   
    </div>
    </div>
    </S.Section2>
  );
}

export default SignUp;
