import "./BasicSetting.scss";
import * as S from "../scss/theme";
import HeaderTitle from "../comm/HeaderTitle";
import React, { useState, useEffect } from "react";
import Combobox from '../comm/Combobox';
// import GangsaTable from './GangsaMain';
import GangsaMain from './GangsaMain';
import GroupMain from './GroupMain';
import ClassMain from './ClassMain';
import { useDispatch } from 'react-redux';
import { setHeaderInfo, setSelectedUnit_pdf } from '../reduxToolkit/redux';
import { Button } from '@mui/material';
import fnc from "../mymod/commFunction";


const BasicSetting = () => {
    const dispatch = useDispatch();
    const [isClickGangsa, setIsClickGangsa] = useState(true);
    const [isClickClass, setIsClickClass] = useState(false);
    const [isClickGroup, setIsClickGroup] = useState(false);
    const [optionsUniTable, setOptionsUniTable] = useState([]);

    useEffect(() => {   
        const userName = fnc.getCookie('userName');
        dispatch(setHeaderInfo({title: `강사등록`, direction: -1}));
      }, []);


  
    function fncSelectGangsaCombo(acaCode) {
        fnc.sendAxios("/select/class/gangsaList", { acaCode }, (res) => {
          if (res) {     
            // setOptionsByIndex1(res);
       
          } else {
            console.log(res, "속성이 obj 내에 없습니다.");
          }
        });
      }

     
    

     
      //--------------------------------------------------------------2. groupList

  
   
    


  
//-------------------------------------------------3. classList
function fncSelectClassTable(acaCode, groupCode) {
  fnc.sendAxios("/select/class/classList", { acaCode, groupCode }, (res) => {
    if (res) {     
      setOptionsUniTable(res); 
    } else {
      console.log(res, "속성이 obj 내에 없습니다.");
    }
  });
}

function fncSelectGangsa() {
  setIsClickGangsa(true);
  setIsClickClass(false);
  setIsClickGroup(false);
  dispatch(setHeaderInfo({title: `강사등록`, direction: -1}));
}


function fncSelectGroup() {
  setIsClickGangsa(false);
  setIsClickClass(false);
  setIsClickGroup(true);
  dispatch(setHeaderInfo({title: `과정등록`, direction: -1}));
}


function fncSelectClass() {
  setIsClickClass(true);
  setIsClickGangsa(false);
  setIsClickGroup(false);
  dispatch(setHeaderInfo({title: `최종 강좌생성`, direction: -1}));
}

  //===========================================================
  return (
    <>
      <S.Section>
        <HeaderTitle />
        <div className="BasicSetting">
          <div className="wrap-book">
            <section className="section-btn">
              <article className="article-btn">
                <Button className="btn" onClick={fncSelectGangsa}>강사등록</Button>
                <Button className="btn" onClick={fncSelectGroup}>과정등록</Button>
                <Button className="btn"  onClick={fncSelectClass}>최종강좌생성</Button>
              </article>
            </section>
          </div>


          {isClickGangsa && 
            <section className="section-bookMain">
                {isClickGangsa && <GangsaMain/> }
            </section>
          }

          {isClickGroup && 
            <section className="section-bookMain">
                <GroupMain/> 
            </section>
          }

          {isClickClass && 
            <section className="section-bookMain">
                <ClassMain/> 
            </section>
          }

        </div>
      </S.Section>
    </>
  );
};

export default BasicSetting;
