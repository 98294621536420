import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateMarkingList } from '../reduxToolkit/redux';
import './BookPdf_marking_omr.scss';

const BookPdf_marking_omr = () => { 
    const dispatch = useDispatch();
    const markingList = useSelector(state => state.reduxStore.markingList);
    const list = useSelector(state => state.reduxStore.list_pdf_marking);
    const [selectedValues, setSelectedValues] = useState({});

    useEffect(() => {
        if (markingList) {
            setSelectedValues(markingList);
        }
    }, [markingList]);

    const handleRadioChange = (idx, value) => {
        const updatedValues = {
            ...selectedValues,
            [idx + 1]: value
        };
        setSelectedValues(updatedValues);
        dispatch(updateMarkingList({ no: idx + 1, value }));
    };

    return (
        <div className='BookPdf_marking_omr'>
            <table className="warp-marking-omr">
                <tbody>
                    {list && list.map((item, index) => (
                        item.kind === '객관식' || !item.kind ? (//Exam에는 kind가 없음
                            <tr className="tr_queMenu_omr" key={index}>
                                <td>
                       
                                    <span className="NoOmr">{index + 1}</span>
                                    {[1, 2, 3, 4, 5].map((value) => (
                                        <label
                                            key={value}
                                            className={`custom-radio ${selectedValues[index + 1] == value ? 'selected' : ''}`}
                                            data-number={value}
                                        >
                                            <input
                                                type="radio"
                                                name={`question${index + 1}`}
                                                value={value}
                                                checked={selectedValues[index + 1] == value}
                                                onChange={() => handleRadioChange(index, value)}
                                            />
                                        </label>
                                    ))}
                                </td>
                            </tr>
                        ) : item.kind === '주관식' || item.kind === '개념' ? (
                            <tr className="tr_queMenu_omr" key={index}>
                                <td>
                                    <span className="NoOmr">{index + 1}</span>
                                    <input
                                        className="markingValue_omr"
                                        // id={`text-input-omr-${index}`}
                                        type="text"
                                        spellCheck="false"
                                        autoComplete="off"
                                        value={selectedValues[index + 1] || ''}
                                        onChange={(e) => handleRadioChange(index, e.target.value)}
                                    />
                                </td>
                            </tr>
                        ) : null
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default BookPdf_marking_omr;
