import "./CourseReg.scss";
import * as S from "../scss/theme";
import HeaderTitle from "../comm/HeaderTitle";
import React, { useState, useEffect } from "react";
import Combobox from '../comm/Combobox';
import UnitTable from './CourseTable';
import CourseMain from './CourseMain';
import { useDispatch } from 'react-redux';
import { setHeaderInfo, setSelectedUnit_pdf } from '../reduxToolkit/redux';
import { Button } from '@mui/material';
import fnc from "../mymod/commFunction";


const CourseReg = () => {
    const dispatch = useDispatch();
    const [options1, setOptions1] = useState([]);
    const [options2, setOptions2] = useState([]);
    const [optionsUniTable, setOptionsUniTable] = useState([]);
    const [selectedUnitItem, setSelectedUnitItem] = useState({});

    const [initialComboValue, setInitialComboValue] = useState('');
    const acaCode = fnc.getCookie('acaCode');

    useEffect(() => {
       
        fncSelectGangsaCombo(acaCode);
        const userName = fnc.getCookie('userName');
        dispatch(setHeaderInfo({title: `${userName}의 수강반`, direction: -1}));
      }, []);

    const handleComboboxChange1 = (selectedOption) => {
        const gangsaCode = selectedOption.value;
        fncSelectGroupCombo(acaCode, gangsaCode)
        setOptionsUniTable([]);//강좌 초기화
    };
  
    function fncSelectGangsaCombo(acaCode) {
        fnc.sendAxios("/select/class/gangsaList", { acaCode }, (res) => {
          if (res) {     
            setOptionsByIndex1(res);
       
          } else {
            console.log(res, "속성이 obj 내에 없습니다.");
          }
        });
      }

     
    

      const setOptionsByIndex1 = (data) => {
        const newOptions = [
          { value: "-1", label: "강의 선생님 선택", isDisabled: false },
        ].concat(
          data.map((item) => ({
            value: item.gangsaCode,
            label: item.name   
          }))
        );
    
        let selectedValue;
        setOptions1(newOptions);
        // selectedValue = fnc.getCookie_pure("combo_selected_key1");
        // setInitialComboValue(selectedValue);
        setInitialComboValue("-1");
      };

      //--------------------------------------------------------------2. groupList

      function fncSelectGroupCombo(acaCode, gangsaCode) {
        fnc.sendAxios("/select/class/groupList", { acaCode, gangsaCode }, (res) => {
          if (res) {     
            // console.log(res, 'groupList');
            setOptionsByIndex2(res);
          } else {
            console.log(res, "속성이 obj 내에 없습니다.");
          }
        });
      }

      
      const setOptionsByIndex2 = (data) => {
        const newOptions = [
          { value: "-1", label: "과정선택", isDisabled: false },
        ].concat(
          data.map((item) => ({
            value: item.groupCode,
            label: item.groupName   
          }))
        );
    
        let selectedValue;
        setOptions2(newOptions);
        // selectedValue = fnc.getCookie_pure("combo_selected_key2");
        // setInitialComboValue(selectedValue);
        setInitialComboValue("-1");
      };
    
    
  
    const handleComboboxChange2 = (selectedOption) => {
      const groupCode = selectedOption.value;
      fncSelectClassTable(acaCode, groupCode)

  };

  
//-------------------------------------------------3. classList
function fncSelectClassTable(acaCode, groupCode) {
  fnc.sendAxios("/select/class/classList", { acaCode, groupCode }, (res) => {
    if (res) {     
      setOptionsUniTable(res); 
    } else {
      console.log(res, "속성이 obj 내에 없습니다.");
    }
  });
}


const handleUnitTable = (selectedOption) => {//단원선택 rowclick
  const value = selectedOption.value;
  const label = selectedOption.label;
  setSelectedUnitItem(selectedOption);

};


      // const options = [
      //     { value: '', label: '항목을 선택하세요.' },
      //     { value: 'chocolate', label: 'Chocolate1' },
      //   ];

  //===========================================================
  return (
    <>
      <S.Section>
        <HeaderTitle />
        <div className="CourseReg">
          <div className="wrap-book">
            <section className="section-cmb">
              <article className="article-cmb">
                <Combobox
                  options={options1}
                  initialComboValue={initialComboValue}
                  handleChange={handleComboboxChange1}
                />
              </article>

              <article className="article-cmb">
                <Combobox
                  options={options2}
                  initialComboValue={initialComboValue}
                  handleChange={handleComboboxChange2}
                />
              </article>
              

            </section>

            <section className="section-unitTable">
              <UnitTable
                options={optionsUniTable}
                handleChange={handleUnitTable}
              />
            </section>

          </div>

          <section className="section-bookMain">
              {selectedUnitItem && <CourseMain selectedItem={selectedUnitItem} />}
          </section>

        </div>
      </S.Section>
    </>
  );
};

export default CourseReg;
