import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateMarkingList } from '../reduxToolkit/redux';
import './Detail_omrResult.scss';
import Button from '@mui/material/Button';
import fnc from '../mymod/commFunction';

const Detail_omrResult = () => { 
    const dispatch = useDispatch();
    const [list, setList] = useState([]);
    const lmsListCode = useSelector(state => state.reduxStore.lmsListCode);

    const [score, setScore] = useState(null);
    const [avg, setAvg] = useState(null);
    const [cnt, setCnt] = useState(null);

    useEffect(() => {
        fncSelect_list();
        fncSelect_avg();
      }, []);

     
     
  function fncSelect_list() {
    const acaCode = fnc.getCookie('acaCode');
    const stCode = fnc.getCookie('stCode');
    
    fnc.sendAxios('/select/lms/omr/result', { acaCode, stCode, parentCode: lmsListCode }, (res) => {
      if (res && Array.isArray(res)) { 
        // console.log(res, '==============res');
        setList(getRecords(res));
      } else {
        console.log(res,"select/lms/course/omrGetDap");
      }
    });
  }


  
  function fncSelect_avg() {//평균가져오기
    const acaCode = fnc.getCookie('acaCode');
    const stCode = fnc.getCookie('stCode');
    
    fnc.sendAxios('/select/lms/omr/result/avg', { acaCode, stCode, parentCode: lmsListCode }, (res) => {
      if (res) { 
        // console.log(res, '==============avg');
        setScore(res[0].score);
        setAvg(res[0].avg);
        setCnt(res[0].cnt);

      } else {
        console.log(res,"select/lms/omr/result/avg");
      }
    });
  }

  function getRecords(res) {
    let records = [];
    for (let i = 0; i < res.length; i++) {      
        const sno= res[i]["sno"];
        const OX= res[i]["OX"];
        const dap = res[i]["dap"];
        const markingValue = res[i]["markingValue"];
        const point = res[i]["point"];//배점
      
        records.push({ sno, dap, markingValue, OX, point });
    }
    return records;
  }




  //============================================================================
    return (
        <div className='Detail_omrResult'>
            <div className="title">점수: {score} &nbsp;&nbsp; 평균: {avg} &nbsp;&nbsp; 응시: {cnt}</div>
            <div className='wrap-main'>
             <section className="section-main">
                <table className="table-hong table-main">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>정답</th>
                      <th>마킹</th>
                      <th>OX</th>
                      <th>배점</th>
                    </tr>
                  </thead>

                  <tbody>
                      {list && list.map((item, index) => (
      
                          <tr key={index}>  
                            <td>{index+1}</td>
                            <td>{item.dap}</td>
                            <td>{item.markingValue}</td>
                            <td>{item.OX}</td>
                            <td>{item.point}</td>
                          </tr>
                      ))}
                
                    </tbody>
                </table>
          </section>
        </div>

     
        </div>
    );
};

export default Detail_omrResult;
