// import './DrawNormal';
import "./DrawMenu.scss";
import { Button } from "@mui/material";
import React, { useEffect, useState, useRef, useCallback } from "react";
import redux, { setCurrentColor, setHighlightMode, setMode, setUndo, setRedo, setClear, 
                setPrev, setNext, } from "../reduxToolkit/redux";
import { useDispatch, useSelector } from "react-redux";


const DrawMenu = ({ }) => {
  const DRAW_MODE = "draw";
  const ERASE_MODE = "erase";
  const [activeButton, setActiveButton] = useState("black");
  const dispatch = useDispatch();

  const handleButtonPress = useCallback((color, buttonId) => {
    setActiveButton(buttonId);
    dispatch(setCurrentColor(color));
    dispatch(setMode(DRAW_MODE));
    if (color === "yellow" || color === "greenyellow") {
      dispatch(setHighlightMode(true));
    } else {
      dispatch(setHighlightMode(false));
    }
  }, []);

  function handleEraseClick() {
    dispatch(setMode(ERASE_MODE));
    setActiveButton("eraser-active"); // activeButton을 'eraser-active'로 설정
  }

  function onUndo() {
    dispatch(setUndo(true));
  }

  function onRedo() {
    dispatch(setRedo(true));
  }

  function onClear() {
    dispatch(setClear(true));
  }

  function onNext() {
    dispatch(setNext(true));
  }

  function onPrev() {
    dispatch(setPrev(true));
  }

  

  //=============================================

  return (
    <div className="DrawMenu">
      <section className="section-menu-draw">
        <input
          type="button" //btnActive로 하면 배경 엑티브 색이 나온다.
          className={`btnColor btnBlack ${
            activeButton === "btnBlack" ? "btnActive2" : ""
          }`}
          onClick={() => handleButtonPress("black", "btnBlack")}
        />

        <input
          type="button"
          className={`btnColor btnRed ${
            activeButton === "btnRed" ? "btnActive2" : ""
          }`}
          onClick={() => handleButtonPress("red", "btnRed")}
        />

        <input
          className={`btnColor btnBlue ${
            activeButton === "btnBlue" ? "btnActive2" : ""
          }`}
          type="button"
          onClick={() => handleButtonPress("blue", "btnBlue")}
        />

        <input
          className={`btnColor btnGreen ${
            activeButton === "btnGreen" ? "btnActive2" : ""
          }`}
          type="button"
          onClick={() => {
            handleButtonPress("green", "btnGreen");
          }}
        />

        <input
          className={`btnColor btnYellow ${
            activeButton === "btnYellow" ? "btnYellow-active" : ""
          }`}
          type="button"
          onClick={() => {
            handleButtonPress("yellow", "btnYellow");
          }}
        />

        <input
          className={`btnColor btnGreenyellow ${
            activeButton === "Greenyellow" ? "btnGreenyellow-active" : ""
          }`}
          type="button"
          onClick={() => {
            handleButtonPress("greenyellow", "Greenyellow");
          }}
        />
        <input
          type="button"
          className={`btnEraser ${
            activeButton === "eraser-active" ? "btnEraser-active" : "btnEraser"
          }`}
          onClick={() => handleEraseClick()}
        />
        <input type="button" className="btnUndo" onClick={onUndo} />
        <input type="button" className="btnRedo" onClick={onRedo} />
        <input type="button" className="btnClear" onClick={onClear} />
        {/* <input type="button" className="btnPrev" value={'이전'} onClick={onPrev} /> */}
        {/* <input type="button" className="btnNext" value={'다음'} onClick={onNext} /> */}
        <Button className="btnPrev" onClick={onPrev}>이전</Button>
        <Button className="btnNext" onClick={onNext}>다음</Button>

        {/* <input
          type="button"
          className="btnZoomIn"
          onClick={zoomIn}
          value="Zoom In"
        />
        <input
          type="button"
          className="btnZoomOut"
          onClick={zoomOut}
          value="Zoom Out"
        /> */}
      </section>
    </div>
  );
};

export default DrawMenu;
