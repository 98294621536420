import './CourseTable.scss';
import React, { useEffect, useState } from "react";
import styled from 'styled-components';
// import fnc from '../mymod/commFunction';
import Select from 'react-select';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import fnc from '../mymod/commFunction';
import { useDispatch, useSelector } from 'react-redux';
import { setIsRefresh_studentCourseList } from '../reduxToolkit/redux';


const CourseTable = ({options, handleChange}) => {
  const dispatch = useDispatch();
  const isRefresh_studentCourseList = useSelector(state => state.reduxStore.isRefresh_studentCourseList);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [selectedRowIndices, setSelectedRowIndices] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const handleRowClick = (index, option) => {
    handleChange(option);
    setSelectedRowIndex(index);
  };

  useEffect(()=>{
    setSelectedRowIndices([]);
    setSelectAll(false);
  }, [options]);

const handleHeaderCheckboxChange = (e) => {
    if (e.target.checked) {
        setSelectedRowIndices(options.map((_, index) => index));
        setSelectAll(true);
    } else {
        setSelectedRowIndices([]);
        setSelectAll(false);
    }
};

const handleRowCheckboxChange = (index) => {
    setSelectedRowIndices(prevIndices =>
        prevIndices.includes(index)
            ? prevIndices.filter(i => i !== index)
            : [...prevIndices, index]
    );
};

const handleButtonClick = () => {
    const selectedOptions = selectedRowIndices.map(index => options[index]);

    if(selectedOptions.length === 0){
        alert('강좌를 선택하세요!');
        return null;
      }
      const rtn = window.confirm(selectedOptions.length +'개의 강좌를 신청하시겠습니까?');
      if(!rtn) return null;

   
    for (let i = 0; i < selectedOptions.length; i++) {
        const classCode = selectedOptions[i].classCode;
        const className = selectedOptions[i].className;

        let isLast = false;
        if(i===selectedOptions.length-1){
            isLast=true;
        }
        fncInsert_course(classCode, className, isLast);
      }

  };


  function fncInsert_course(classCode, className, isLast) {
    const acaCode = fnc.getCookie('acaCode');
    const stCode = fnc.getCookie('stCode');
    const sDate = fnc.fncDate();
    
    fnc.sendAxios('/insert/class/cl_course', { acaCode, stCode, classCode, className, sDate }, (res) => {
      if (res) { 
        if (isLast) {
            dispatch(setIsRefresh_studentCourseList(!isRefresh_studentCourseList));
          }
      } else {
        console.log(res,"insert/class/cl_course");
      }
    });
  }

  //-------------------------------------------
  return (//자식 컨포넌트
  <div className="CourseTable">
  <table className="table-hong table-unit">
      <thead>
          <tr>      
              <th>no</th>
              <th>강좌</th>
              <th>
                  <Checkbox checked={selectAll} onChange={handleHeaderCheckboxChange} />
              </th>
          </tr>
      </thead>
      <tbody>
          {options.map((option, index) => (
           
              <tr
                  className={selectedRowIndex === index ? 'active' : ''}
                  key={index}
                  onClick={() => handleRowClick(index, option)}
              >
               
                  <td>{index+1}</td>
                  <td>{option.className}</td>
                  <td>
                      <Checkbox
                          checked={selectedRowIndices.includes(index)}
                          onChange={() => handleRowCheckboxChange(index)}
                      />
                  </td>
              </tr>
              
          ))}
      </tbody>
  </table>

  <div className='box-menu'>
    <Button className='btnInsertClass' onClick={handleButtonClick}>수강신청</Button>
  </div>
   
</div>
  );
};

export default CourseTable;

//===================================================================
  
// StyledSelect 컴포넌트 생성
const StyledSelect = styled(Select)`
width: 100%;  // 원하는 너비 설정
text-align: left;
font-size: 0.9rem;
`;


const customStyles = {
    
}