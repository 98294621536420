import './ComboboxMui.scss';
import React from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

const ComboboxMui = ({ label, value, onChange, options }) => {
  return (
    <FormControl variant="outlined" fullWidth className="custom-form-control">
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        onChange={onChange}
        label={label}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default ComboboxMui;
