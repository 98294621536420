import './Book_chartDetailTec.scss';
import * as S from '../scss/theme';
import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import fnc from '../mymod/commFunction';
import Button from '@mui/material/Button';
import HeaderTitle from "../comm/HeaderTitle";
import ChartSt from './BookChartSt';
import Book_chartDetail from './Book_chartDetail';
import imgReport from '../images/svg/report.svg';

import { useDispatch, useSelector } from 'react-redux';
import { setList_pdf, setRowIdx_pdf, setKind, setList_pdf_marking, setList_pdf_complete, 
          setSelectedPage_pdf, setHeaderInfo, setSelectedBookDetailCode, setIsBookStudy } from '../reduxToolkit/redux';


const Book_chartDetailTec = ({source, page, setIsModalTec}) => {//selectedItem이건 bookCode: 1345임 value: 1345가 아니고
    const location = useLocation();
    const imgUrl = process.env.REACT_APP_imgUrl;
    const dispatch = useDispatch();

    const selectedUnit_pdf = useSelector(state => state.reduxStore.selectedUnit_pdf);
    const isRefresh_bookMain = useSelector(state => state.reduxStore.isRefresh_bookMain);
    const userKind = useSelector(state => state.reduxStore.userInfo.kind);
    const selectedBookDetailCode = useSelector(state => state.reduxStore.selectedBookDetailCode);
    const isBookStudy = useSelector(state => state.reduxStore.isBookStudy);
    
    const [list, setList] = useState([]);
    const [isModal, setIsModal] = useState(false);
    const [isModal_memo, setIsModal_memo] = useState(false);
    const [isModal_marking, setIsModal_marking] = useState(false);
    const [isModal_complete, setIsModal_complete] = useState(false);
    // const [selectedListItem, setSelectedListItem] = useState({}); 
    const [selectedItems, setSelectedItems] = useState([]);//체크박스
    const [selectAll, setSelectAll] = useState(false);//체크박스

    const [title, setTitle] = useState("");
    const [isModal_chart, setIsModal_chart] = useState(false);
    const [stCode, setStCode] = useState(null);


    useEffect(() => {
      fncSelectList();

    }, [source, page]);

    
  
  function fncSelectList() {
    const acaCode = fnc.getCookie('acaCode');
    const stCode = fnc.getCookie('stCode');


    fnc.sendAxios('/select/book/report/detailTec', {source, page }, (res) => {
      if (res && Array.isArray(res)) { //단원선택 클릭 시 결과 (오른쪽 페이지 list)
        // console.log(res, ' bookCode list');

        const newList = getRecords_list(res);
        setList(newList);

      } else {
        console.log(res,"select/book/pdfList");
      }
    });
  }
  
  function getRecords_list(res) {
    let records = [];
    for (let i = 0; i < res.length; i++) {      
        const sno= res[i]["sno"];
        const testCodeMun = res[i]["testCodeMun"];
        const munCnt = res[i]["munCnt"];
        const stNm = res[i]["name"];
        const stCode = res[i]["stCode"];
        let hp = res[i]["hp"];
        hp=fnc.formatPhoneNumber(hp);
        let score = res[i]["score"];
        score = score.toFixed(1);
        const Xcnt = res[i]["Xcnt"];
        const Ocnt = res[i]["Ocnt"];
        const eDate = res[i]["eDate"];
        let avg = res[i]["avg"];
        avg = avg.toFixed(1);
        const rank = res[i]["rank"];

        records.push({ sno, testCodeMun,  munCnt, stNm, stCode, hp,
          score, Ocnt, Xcnt, eDate: fnc.fncDate(eDate), avg, rank });
    }
    return records;
  }


 

    //=========================================
    return (
      //자식 컨포넌트
      <div className="Book_chartDetailTec">
         {/* <HeaderTitle  title={title} direction={1} onBackClick={() => setIsModalTec(false)} /> */}

        <Button className='btnClose-me' onClick={() => setIsModalTec(false)} >
            <i class="fa-solid fa-xmark"></i>
        </Button> 

        <div className="wrap-book-main">
          <section className="section-book-main">
            <table className="table-hong table-chartDetailTec">
              <thead>
                <tr>
                  <th>no</th>      
                  <th>성명</th>
                  <th>휴대전화</th>
                  <th>문항수</th>
                  <th>맞음(개)</th>
                  <th>틀림(개)</th>
                  <th>점수</th>
                  <th>평균</th>
                  <th>석차</th>
                  <th>제출일</th>
                  <th>상세</th>
              
                </tr>
              </thead>

              <tbody>

               { list.map((item, index) =>  {

                  return(
                      <tr key={item.sno}>
                        <td>{index + 1}</td>                 
                        <td>{item.stNm}</td>
                        <td>{item.hp}</td>
                        <td>{item.munCnt}</td>
                        <td>{item.Ocnt}</td>
                        <td>{item.Xcnt}</td>
                        <td>{item.score}</td>
                        <td>{item.avg}</td>
                        <td>{item.rank}</td>
                        <td>{item.eDate}</td>
                        <td>
                            <Button className="btnReport"
                                onClick={() => onChartSt(item)}
                                startIcon={<img src={imgReport} alt="Report" />}>
                            </Button>
                        </td>


                        
                      </tr>
                  );

                })}
                
              </tbody>

            </table>
          </section>

        </div>

        {isModal_chart && (
                <S.Modal>
                     <HeaderTitle  title={title} direction={1} onBackClick={() => setIsModal_chart(false)} />
                    <div style={{display:'flex', flexDirection:'row', gap: '1rem'}}>
                      
                        <section className="section-chart-detail">
                            <Book_chartDetail stCode={stCode} source={source} page={page} />
                        </section>

                        <section className="section-chart">
                            <ChartSt stCode={stCode} source={source} page={page} />
                        </section>

                        </div>
                    
                </S.Modal>
            )}
     
      </div>
    );

    //===============================================================
    function onChartSt(item) {

      setStCode(item.stCode);
      setIsModal_chart(true);
    }
}

export default Book_chartDetailTec;