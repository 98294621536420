import './LmsTecResultAllimjang_report_sendMenu.scss';
import React from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { useDispatch, useSelector } from 'react-redux';

const LmsTecResultAllimjang_report_sendMenu = ({optValueTarget, setOptValueTarget, 
  optValueSchedule, setOptValueSchedule, sendDate, setSendDate, sendTime, setSendTime}) => {

    const dispatch = useDispatch();
    const isModal = useSelector((state) => state.reduxStore.isModal);
    const classCode = useSelector((state) => state.reduxStore.classCode);

    //------------------------라디오 그룹
    const handleChangeRadioGroup = (event) => {
      setOptValueTarget(event.target.value); // 라디오 버튼 선택 시 상태 업데이트
      console.log(event.target.value); // 선택된 값을 콘솔에 출력
    };

    // 새로운 라디오 그룹 핸들러 (즉시 예약)
    const handleChangeScheduleGroup = (event) => {
      setOptValueSchedule(event.target.value); // 새로운 상태 업데이트
      console.log(event.target.value); // 선택된 값을 콘솔에 출력
    };

    //=========================================
    return (
      <div className="LmsTecResultAllimjang_report_sendMenu">
        <div className="wrap-main">
        
          <article className='article-option'>
              <div className='option-target'>
                  <RadioGroup row value={optValueTarget} onChange={handleChangeRadioGroup} >
                    <FormControlLabel value="all" control={<Radio />} label="모두" />
                    <FormControlLabel value="student" control={<Radio />} label="학생" />
                    <FormControlLabel value="parent" control={<Radio />} label="부모" />
                  </RadioGroup>
              </div>

              {/* 즉시 예약 라디오 그룹 */}
              <div className='option-schedule'>
                  <RadioGroup row value={optValueSchedule} onChange={handleChangeScheduleGroup}>
                    <FormControlLabel value="now" control={<Radio />} label="즉시" />
                    <FormControlLabel value="reserve" control={<Radio />} label="예약" />
                  </RadioGroup>
              </div>

            </article>

            {/* DatePicker 추가 (예약 날짜 선택) */}
            <div className='date-picker'>
                <DatePicker
                  selected={sendDate}
                  onChange={(date) => setSendDate(date)}
                  dateFormat="yyyy-MM-dd"
                  className="datePicker"
                  enableTabLoop={false} // 클릭시 오른쪽 밀림 방지
                  popperClassName="custom-datepicker-popper" // 커스텀 클래스 추가
                  popperPlacement="bottom" // 팝업 위치 설정 (필요에 따라 변경 가능)
                  portalId="root-portal" // 팝업을 렌더링할 DOM 요소 설정
                />

                <DatePicker
                      selected={sendTime} // 선택된 시간 (sendTime)
                      onChange={(date) => setSendTime(date)} // 시간이 변경될 때 setSendTime으로 업데이트
                      showTimeSelect // 시간 선택 기능 활성화
                      showTimeSelectOnly // 시간을 선택하는 옵션만 활성화
                      timeIntervals={15} // 15분 단위로 시간 선택
                      timeCaption="시간" // 시간 선택 상자에 표시되는 텍스트
                      dateFormat="h:mm aa" // 시간 형식 (12시간 형식, AM/PM)
                      className="timePicker" // 입력 필드 스타일링 클래스
                      popperClassName="custom-datepicker-popper" // 팝업 스타일링 클래스
                      popperPlacement="bottom" // 팝업 위치 설정'
                      portalId="root-portal" // 부모 위에 뜨게
                  />

            </div>

           
        </div>
      </div>
    );
}

export default LmsTecResultAllimjang_report_sendMenu;
