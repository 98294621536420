import './ClassMain.scss';
import * as S from '../scss/theme';
import React, { useState, useEffect } from "react";
import fnc from '../mymod/commFunction';
import Button from '@mui/material/Button';
import Combobox from '../comm/Combobox_noBMK';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { setList_pdf, setHeaderInfo,setIsRefresh_studentCourseList } from '../reduxToolkit/redux';



const ClassMain = () => {

    const dispatch = useDispatch();
   
    const [list, setList] = useState([]);
  
    const [selectedIdx, setSelectedIdx] = useState(0);
    const [isRefresh, setIsRefresh] = useState(false);
    const [options1, setOptions1] = useState([]);//강사
    const [options2, setOptions2] = useState([]);//과목
  
    const [selectedOption1, setSelectedOption1] = useState(null);
    const [selectedOption2, setSelectedOption2] = useState(null);

    const [className, setClassName] = useState(null);
    const [classCode, setClassCode] = useState(null);
    const [groupCode, setGroupCode] = useState(null);//Row클릭시 콤보에 표시용
    const [gangsaCode, setGangsaCode] = useState(null);//Row클릭시 콤보에 표시용

    useEffect(() => {
      fncSelect_list();
      fncSelect_cmbGroupList();
      fncSelect_cmbGangsaList();      
    }, [isRefresh]);

    
  function fncSelect_list() {
    const acaCode = fnc.getCookie('acaCode');
    
    fnc.sendAxios('/select/basic/class/classList', { acaCode }, (res) => {
      if (res && Array.isArray(res)) { 

        const listClass = getRecords(res);
        setList(listClass);

      } else {
        console.log(res,"select/basic/class/classList");
      }
    });
  }

  
  function getRecords(res) {
    let records = [];
    for (let i = 0; i < res.length; i++) {      
        const sno= res[i]["sno"];
        const clClassCode = res[i]["clClassCode"];
        const clGangsaCode = res[i]["clGangsaCode"];
        const clGroupCode = res[i]["clGroupCode"];
        const clKwamokCode = res[i]["clKwamokCode"];
        const clClassName = res[i]["clClassName"];
        const clGangsaName = res[i]["clGangsaName"];
        const clGroupName = res[i]["clGroupName"];
        const clKwamokName = res[i]["clKwamokName"];
        const sDate = res[i]["sDate"];
      
        records.push({ sno, clClassCode, clGangsaCode, clGroupCode, clKwamokCode, 
          clClassName, clGangsaName, clGroupName, clKwamokName, sDate });
    }
    return records;
  }

  //--------------------------------------------------강사리스트

  function fncSelect_cmbGroupList() {
    const acaCode = fnc.getCookie('acaCode');
    
    fnc.sendAxios('/select/basic/class/group', { acaCode }, (res) => {
      if (res && Array.isArray(res)) { 

        const cmbListGroup = getRecords_group(res);
        setOptions1(cmbListGroup);

      } else {
        console.log(res,"select/basic/class/group");
      }
    });
  }

  function getRecords_group(res) {
    let records = [];
   
    for (let i = 0; i < res.length; i++) {      
          const clGroupCode = res[i]["clGroupCode"];
          const clGroupName = res[i]["clGroupName"];
          records.push({ value: clGroupCode, label: clGroupName });
    }
    return records;
  }

  //-----------------------------------------------과목
  function fncSelect_cmbGangsaList() {
    const acaCode = fnc.getCookie('acaCode');
    
    fnc.sendAxios('/select/basic/class/gangsa', { acaCode }, (res) => {
      if (res) { 
        const cmbListGangsa = getRecords_gangsa(res);
        setOptions2(cmbListGangsa);
      } else {
        console.log(res,"select/basic/kwamokList");
      }
    });
  }

  function getRecords_gangsa(res) {
    let records = [];
    
    for (let i = 0; i < res.length; i++) {      
          const gangsaCode = res[i]["gangsaCode"];
          const name = res[i]["name"];
          records.push({ value: gangsaCode, label: name });
    }
    return records;
  }



  //----------------------------------------------------------------------------------------------

  function onRefresh() {
    setIsRefresh(!isRefresh);
    // setSelectedOption1(null);//이거하면 수정시 공백으로 되는 콤보박스가 있음
    // setSelectedOption2(null);
  }

  function onInsert() {
    const acaCode = fnc.getCookie('acaCode');
    const clGroupCode = selectedOption1?.value ?? '';
    const clGroupName = selectedOption1?.label ?? '';
    const clGangsaCode = selectedOption2?.value ?? '';

    const sDate = fnc.fncDate();
    const clClassCode = fnc.fncSirialNum('c');
    const clClassName = className ?? '';

    setClassCode(clClassCode);//삭제용

    if(!clGroupName) {
      alert('과정을 선택하세요!');
      return null;
    }

    // const exist = list.some(item => item.gangsaCode === gangsaCode);
    // if(exist) {
    //   alert(`${gangsaName}은 이미 등록되어있습니다.`);
    //   return null;
    // }

    const rtn = window.confirm(`${clClassName}을 등록하시겠습니까?`);
    if(!rtn) return;

    fnc.sendAxios('/insert/basic/class/class', { acaCode, clGroupCode, clGangsaCode, clClassCode, clClassName, sDate }, (res) => {
      if (res) {     
        onRefresh(); 
      } else {
        console.log(res,"insert/basic/class/class");
      }
    });


  }

  function onUpdate() {

    const acaCode = fnc.getCookie('acaCode');
    const clGroupCode = selectedOption1?.value ?? '';
    const clGroupName = selectedOption1?.label ?? '';
    const clGangsaCode = selectedOption2?.value ?? '';
    const eDate = fnc.fncDate();
    const clClassCode = classCode;
    const clClassName = className ?? '';

    if(!clClassName) {
      alert('대상을 선택하세요!');
      return null;
    }
    const rtn = window.confirm(`${clClassName}으로 수정하시겠습니까?`);
    if(!rtn) return;

    fnc.sendAxios('/update/basic/class/class', { acaCode, clGroupCode, clClassCode, clClassName, clGangsaCode, eDate }, (res) => {
      if (res) {     
        onRefresh(); 
      } else {
        console.log(res,"update/basic/class/class");
      }
    });
  }

  function onDelete() {
      const acaCode = fnc.getCookie('acaCode');
      const clClassCode = classCode;

      if(!clClassCode) {
        alert('반을 선택하세요!');
        return null;
      }

      const rtn = window.confirm(`${className}반을 삭제하시겠습니까?`);
      if(!rtn) return;

      fnc.sendAxios('/delete/basic/class/class', { acaCode, clClassCode }, (res) => {
        if (res) { 
            setIsRefresh(!isRefresh);
        } else {
          console.log(res,"delete/basic/class/class");
        }
      });
    
  }

  function onClickTr( item, index) {
    setSelectedIdx(index);//값넣기는 일단 실패
    setClassCode(item.clClassCode);
    setClassName(item.clClassName);
    setGroupCode(item.clGroupCode);
    setGangsaCode(item.clGangsaCode);
  }

  
    //=========================================
    return (

      <div className="ClassMain">
        <div className="wrap-course-main">
          <section className="section-book-main">
            <table className="table-hong table-gangsa-main">
              <thead>
                <tr>
                  <th>No</th>
                  <th>과정</th>  
                  <th>담임</th>
                  <th>반명</th>
                  <th>날짜</th>
                </tr>
              </thead>

              <tbody>
                  {list && list.map((item, index) => (
  
                      <tr key={index} onClick={() => onClickTr(item, index)}>  
                        <td>{index+1}</td>
                        <td>{item.clGroupName}</td>
                        <td>{item.clGangsaName}</td>
                        <td>{item.clClassName}</td>
                        <td>{item.sDate}</td>
                      </tr>
                  ))}
            
                </tbody>

            </table>
          </section>
        </div>

        <section className='section-input'>
          
          <div className='box-cmb'>
            <span className='lbl-cmb'>*과정을 선택하세요!</span>
            <div className='cmb'>
                <Combobox options={options1}  handleChange={(item) => setSelectedOption1(item)}
                 initialComboValue={groupCode}  
                 />
            </div>  
          </div>

          <div className='box-cmb'>
            <span className='lbl-cmb'>*담임을 선택하세요!</span>
            <div className='cmb'>
              <Combobox options={options2} handleChange={(item) => setSelectedOption2(item)}
                initialComboValue={gangsaCode} 
               />
            </div>  
          </div>

          <div className='box-cmb'>
            <span className='lbl-cmb'>*강좌명을 입력하고 추가하세요!</span>
            <div className='cmb'>
      
               <input type='text' className='txtGroupName' 
                value={className}
                spellCheck='false'
                onChange={(e) => setClassName(e.target.value)}>
                </input>
            </div>  
          </div>

         
            
                    
        </section>

        <section className='section-btn'>
          <Button className='btn btnRefresh' onClick={onRefresh}>새로고침</Button> 
          <Button className='btn btnInsert' onClick={onInsert}>추가</Button> 
          <Button className='btn btnUpdate' onClick={onUpdate}>수정</Button> 
          <Button className='btn btnDelete' onClick={onDelete}>삭제</Button>  
        </section> 
        <div className='guide'>
            (1) 과정명을 선택하세요 (만약 없다면 과정 등록에서 생성하세요!) <br/>
            (2) 담임을 선택하세요(본인명 선택)  <br/>
            (3) 강좌명을 입력하고 추가 하세요! 완료되면 LMS 생성으로 이동하여 학습을 입력하시면 됩니다.

          </div>       
      </div>
    );
}

export default ClassMain;