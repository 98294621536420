import 'rc-tree/assets/index.css';
import './MyTreeView.scss';
import React, { useEffect, useState } from 'react';
import Tree, { TreeNode } from 'rc-tree';
import fnc from '../mymod/commFunction';
import { useSelector, useDispatch } from 'react-redux';
import { setClassCode, setIsModal, setHeaderInfo } from "../reduxToolkit/redux";
import imgFolder from '../images/svg/folder2.svg';
import imgFolderOpen from '../images/svg/folder-open.svg';
import imgFile from '../images/svg/page.svg';

const MyTreeView = ({setSelectedNodeItem}) => {
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);

  const [treeData, setTreeData] = useState([]);
  const userName = fnc.getCookie('userName');
  const dispatch = useDispatch();

  useEffect(() => {
    fncSelectGangsaClassList();
  }, []);

  const onExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
  };


  function fncSelectGangsaClassList() {
    const acaCode = fnc.getCookie('acaCode');
    const stCode = fnc.getCookie('stCode');
    
    fnc.sendAxios('/select/lms/treeview', { acaCode, stCode }, (res) => {
      if (res && Array.isArray(res)) { 
        const transformedData = transformDataToTree(res);
        setTreeData(transformedData);
      } else {
        console.log(res, "select/lms/treeview");
      }
    });
  }

  
  function transformDataToTree(data) {
    const treeData = [];
    let currentGroup = null;

    data.forEach(row => {
      if (!currentGroup || currentGroup.id !== `group${row.clGroupCode}`) {
        currentGroup = {
          id: `group${row.clGroupCode}`,
          title: row.groupName,
          key: `group${row.clGroupCode}`,
          children: [],
        };
        treeData.push(currentGroup);
      }
      currentGroup.children.push({
        id: row.clClassCode,
        title: row.clClassName,
        key: row.clClassCode,
      });
    });

    return treeData;
  }

 

  const onSelect = (selectedKeys, selectedItem) => {

    // // // 그룹일 경우 펼침/닫힘 토글
    if (selectedItem.node.children) {
      setExpandedKeys(keys => {
        if (keys.includes(selectedItem.node.key)) {
          return keys.filter(key => key !== selectedItem.node.key); // 이미 펼쳐진 경우 닫기
        } else {
          return [...keys, selectedItem.node.key]; // 펼치기
        }
      });
    }
    
    setSelectedKeys(selectedKeys); // active상태 유지
    
    setSelectedNodeItem(selectedItem);
    dispatch(setClassCode(selectedItem.node.key));
    dispatch(setIsModal(false));

    dispatch(setHeaderInfo({title: selectedItem.node.title, direction: -1}));

  };

  //======================================================================
  return (
    <div className='MyTreeView'>

      <div className='box-tv'>
        <div className='box-title'>
          {userName} 학생
        </div>
        <Tree
          expandedKeys={expandedKeys}
          selectedKeys={selectedKeys}
          onExpand={onExpand}
          onSelect={onSelect}
          treeData={treeData}
          // switcherIcon={switcherIcon}//작동 하지만 미흡하다.
          // showIcon={false}
        />
      </div>

    </div>
  );
};

//-----------------------------------------
const switcherIcon = (extension) => {
  const isDirectory = extension.data.children;
  let icon = '';

  if (isDirectory) {
    icon = extension.expanded ? imgFolderOpen : imgFolder;
  } else {
    icon = imgFile;
  }

  return <img src={icon} alt="icon" className="icon-style" />;
};



export default MyTreeView;
