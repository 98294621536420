import './GangsaMain.scss';
import * as S from '../scss/theme';
import React, { useState, useEffect } from "react";
import fnc from '../mymod/commFunction';
import Button from '@mui/material/Button';
import Combobox from '../comm/Combobox_noBMK';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { setList_pdf, setHeaderInfo,setIsRefresh_studentCourseList } from '../reduxToolkit/redux';



const GangsaMain = ({selectedItem, handleChange}) => {


    const dispatch = useDispatch();
   

    const [list, setList] = useState([]);
    const [selectedIdx, setSelectedIdx] = useState(0);
    const [isRefresh, setIsRefresh] = useState(false);
    const [options1, setOptions1] = useState([]);//강사
    const [options2, setOptions2] = useState([]);//과목
    const [options3, setOptions3] = useState([]);//분류
    const [options4, setOptions4] = useState([]);//권한
   
    const [selectedOption1, setSelectedOption1] = useState(null);
    const [selectedOption2, setSelectedOption2] = useState(null);
    const [selectedOption3, setSelectedOption3] = useState(null);
    const [selectedOption4, setSelectedOption4] = useState(null);


    const [gangsaCode, setGangsaCode] = useState(null);
    const [kwamokCode, setKwamokCode] = useState(null);
    const [type, setType] = useState(null);
    const [accessLevel, setAccessLevel] = useState(null);

   
    useEffect(() => {
      fncSelect_list();
      fncSelect_hakjukGangsaList();
      fncSelect_kwamokList();
      setOptions3(getRecords_kind());
      setOptions4(getRecords_accessLevel());
      
    }, [isRefresh]);

    
  function fncSelect_list() {
    const acaCode = fnc.getCookie('acaCode');
    const stCode = fnc.getCookie('stCode');
    
    fnc.sendAxios('/select/basic/gangsaList', { acaCode }, (res) => {
      if (res && Array.isArray(res)) { 

        const listGangsa = getRecords(res);
        setList(listGangsa);

        const cmbListGangsa = getRecords_gangsa(res);
        setOptions1(cmbListGangsa);

      } else {
        console.log(res,"select/book/pdfList");
      }
    });
  }

  
  function getRecords(res) {
    let records = [];
    for (let i = 0; i < res.length; i++) {      
        const sno= res[i]["sno"];
        const gangsaCode = res[i]["gangsaCode"];
        const name = res[i]["name"];//BookPdf에서는 page, source를 사용한다.
        const hp = res[i]["hp"];
        const kwamok = res[i]["kwamok"];
        const kwamokCode = res[i]["kwamokCode"];
        const accessLevel = res[i]["accessLevel"];
        const type = res[i]["type"];
        const sDate = res[i]["sDate"];
        const eDate = res[i]["eDate"];
      
        records.push({ sno, gangsaCode, name, hp, kwamok, kwamokCode, accessLevel, type, sDate, eDate });
    }
    return records;
  }

  //--------------------------------------------------강사리스트

  function fncSelect_hakjukGangsaList() {
    const acaCode = fnc.getCookie('acaCode');
    const stCode = fnc.getCookie('stCode');
    
    fnc.sendAxios('/select/basic/hakjukGangsaList', { acaCode }, (res) => {
      if (res && Array.isArray(res)) { 

        const cmbListGangsa = getRecords_gangsa(res);
        setOptions1(cmbListGangsa);

      } else {
        console.log(res,"select/book/pdfList");
      }
    });
  }

  function getRecords_gangsa(res) {
    let records = [];
   
    for (let i = 0; i < res.length; i++) {      
          const gangsaCode = res[i]["gangsaCode"];
          const name = res[i]["name"];
          records.push({ value: gangsaCode, label: name });
    }
    return records;
  }

  //-----------------------------------------------과목
  function fncSelect_kwamokList() {
    const acaCode = fnc.getCookie('acaCode');
    const stCode = fnc.getCookie('stCode');
    
    fnc.sendAxios('/select/basic/kwamokList', { acaCode }, (res) => {
      if (res) { 
        const cmbListKwamok = getRecords_kwamok(res);
        setOptions2(cmbListKwamok);
      } else {
        console.log(res,"select/basic/kwamokList");
      }
    });
  }

  function getRecords_kwamok(res) {
    let records = [];
    
    for (let i = 0; i < res.length; i++) {      
          const gangsaCode = res[i]["kwamokCode"];
          const name = res[i]["kwamok"];
          records.push({ value: gangsaCode, label: name });
    }
    return records;
  }


  //-----------------------------------------------분류
  function getRecords_kind() {
    let records = [];
    records.push({ value: '강사', label: '강사' });
    records.push({ value: '관리', label: '관리' });
    return records;
  }


  //-----------------------------------------------권한
  function getRecords_accessLevel() {
    let records = [];
    for (var i = 1; i <= 9; i++) {
      records.push({ value: i , label: i });
    }
    return records;
  }


  //----------------------------------------------------------------------------------------------

  function onRefresh() {
    setIsRefresh(!isRefresh);
    // setSelectedOption1(null);
    // setSelectedOption2(null);
    // setSelectedOption3(null);
    // setSelectedOption4(null);
  }

  function onInsert() {
    const acaCode = fnc.getCookie('acaCode');
    const gangsaCode = selectedOption1?.value ?? '';
    const gangsaName = selectedOption1?.label ?? '';
    const kwamokCode = selectedOption2?.value ?? '';
    const kwamok = selectedOption2?.label ?? '';
    const type = selectedOption3?.value ?? '';
    const accessLevel = selectedOption4?.value ?? '';
    const sDate = fnc.fncDate();

    if(!gangsaCode) {
      alert('강사를 선택하세요!');
      return null;
    }

    const exist = list.some(item => item.gangsaCode === gangsaCode);
    if(exist) {
      alert(`${gangsaName}은 이미 등록되어있습니다.`);
      return null;
    }

    const rtn = window.confirm(`${gangsaName}을 등록하시겠습니까?`);
    if(!rtn) return;

    fnc.sendAxios('/insert/basic/gangsa', { acaCode, gangsaCode, kwamokCode, accessLevel, type, sDate }, (res) => {
      if (res) {     
        onRefresh(); 
      } else {
        console.log(res,"insert/basic/gangsa");
      }
    });


  }

  function onUpdate() {
    const acaCode = fnc.getCookie('acaCode');
    const gangsaCode = selectedOption1?.value ?? '';
    const gangsaName = selectedOption1?.label ?? '';
    const kwamokCode = selectedOption2?.value ?? '';
    const kwamok = selectedOption2?.label ?? '';
    const type = selectedOption3?.value ?? '';
    const accessLevel = selectedOption4?.value ?? '';
    const sDate = fnc.fncDate();

    if(!gangsaCode) {
      alert('강사를 선택하세요!');
      return null;
    }
    const rtn = window.confirm(`${gangsaName}을 수정하시겠습니까?`);
    if(!rtn) return;

    fnc.sendAxios('/update/basic/gangsa', { acaCode, gangsaCode, kwamokCode, accessLevel, type, sDate }, (res) => {
      if (res) {     
        onRefresh(); 
      } else {
        console.log(res,"update/basic/gangsa");
      }
    });
  }

  function onDelete() {
      const acaCode = fnc.getCookie('acaCode');
      const stCode = fnc.getCookie('stCode');
      const gangsaCode = selectedOption1?.value ?? '';
      const gangsaName = selectedOption1?.label ?? '';

      if(!gangsaCode) {
        alert('강사를 선택하세요!');
        return null;
      }

      const rtn = window.confirm(`${gangsaName}을 삭제하시겠습니까?`);
      if(!rtn) return;

      fnc.sendAxios('/delete/basic/gangsa', { acaCode, gangsaCode }, (res) => {
        if (res) { 
            setIsRefresh(!isRefresh);
        } else {
          console.log(res,"delete/basic/gangsa");
        }
      });
    
  }

  function onClickTr( item, index) {
    setSelectedIdx(index);//값넣기는 일단 실패
   
    setGangsaCode(item.gangsaCode);
    setKwamokCode(item.kwamokCode);
    setType(item.type);
    setAccessLevel(item.accessLevel);
  }

  
    //=========================================
    return (

      <div className="GangsaMain">
        <div className="wrap-course-main">
          <section className="section-book-main">
            <table className="table-hong table-gangsa-main">
              <thead>
                <tr>
                  <th>No</th>
                  <th>성명</th>
                  <th>핸드폰</th>
                  <th>담당과목</th>
                  <th>분류</th>
                  <th>권한</th>
                </tr>
              </thead>

              <tbody>
                  {list && list.map((item, index) => (
  
                      <tr key={index} onClick={() => onClickTr(item, index)}>  
                        <td>{index+1}</td>
                        <td>{item.name}</td>
                        <td>{item.hp}</td>
                        <td>{item.kwamok}</td>
                        <td>{item.type}</td>
                        <td>{item.accessLevel}</td>
                      </tr>
                  ))}
            
                </tbody>

            </table>
          </section>
        </div>

        <section className='section-input'>
          
          <div className='box-cmb'>
            <span className='lbl-cmb'>*강사를 선택하세요!</span>
            <div className='cmb'>
                <Combobox options={options1}  handleChange={(item) => setSelectedOption1(item)}
                initialComboValue={gangsaCode} 
                //  initialComboValue={list[selectedIdx].gangsaCode}  
                 />
            </div>  
          </div>

          <div className='box-cmb'>
            <span className='lbl-cmb'>*담당과목을 선택하세요!</span>
            <div className='cmb'>
              <Combobox options={options2} handleChange={(item) => setSelectedOption2(item)}
              initialComboValue={kwamokCode} 
              // initialComboValue={list[selectedIdx].kwamokCode} 
               />
            </div>  
          </div>

          <div className='box-cmb'>
            <span className='lbl-cmb'>*분류를 선택하세요!</span>
            <div className='cmb'>
            <Combobox options={options3} handleChange={(item) => setSelectedOption3(item)} 
              initialComboValue={type} 
              // initialComboValue={list[selectedIdx].type} 
              />
            </div>  
          </div>

          <div className='box-cmb'>
            <span className='lbl-cmb'>*권한을 선택하세요!</span>
            <div className='cmb'>
              <Combobox options={options4}  handleChange={(item) => setSelectedOption4(item)} 
              initialComboValue={accessLevel} 
              // initialComboValue={list[selectedIdx].accessLevel} 
              />
            </div>  
          </div>
            
                    
        </section>

        <section className='section-btn'>
          <Button className='btn btnRefresh' onClick={onRefresh}>새로고침</Button> 
          <Button className='btn btnInsert' onClick={onInsert}>추가</Button> 
          <Button className='btn btnUpdate' onClick={onUpdate}>수정</Button> 
          <Button className='btn btnDelete' onClick={onDelete}>삭제</Button>  
        </section>        
      </div>
    );
}

export default GangsaMain;