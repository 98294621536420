import React, { useEffect } from "react";
// import fnc from '../mymod/commFunction';
import * as S from '../scss/theme';

const Home = () => {

    useEffect(() => {
        // fnc.setCookie('stCode', '100227');
    }, []);

    //=========================================
    return(
        <div className="Home">
            <S.Section>
            <h1>메인 페이지입니다.</h1>
            <p>메인 페이지입니다.</p>
            </S.Section>
        </div>
    );
}

export default Home;