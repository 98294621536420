
import React, { useState } from 'react';
import DaumPostcode from 'react-daum-postcode';
import Button from '@mui/material/Button';

const AddressDaum = ({setIsModal, handleAddressChange }) => {
    const [address, setAddress] = useState({
        zonecode: '',
        fullAddress: '',
        roadAddress: '',
        jibunAddress: '',
        detailAddress: ''
    });

    const handleComplete = (data) => {
        let fullAddress = data.address;
        let extraAddress = '';

        if (data.addressType === 'R') {
            if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '' && data.apartment === 'Y') {
                extraAddress += (extraAddress !== '' ? ', ' + data.buildingName : data.buildingName);
            }
            fullAddress += (extraAddress !== '' ? ' (' + extraAddress + ')' : '');
        }


        handleAddressChange(fullAddress, data.zonecode);
        setIsModal(false);

        setAddress({
            ...address,
            zonecode: data.zonecode,
            fullAddress: fullAddress,
            roadAddress: data.roadAddress,
            jibunAddress: data.jibunAddress
        });
    };

    const handleDetailAddressChange = (e) => {
        setAddress({
            ...address,
            detailAddress: e.target.value
        });
    };

    return (
        <div>
            <h1>주소 검색</h1>
            <DaumPostcode
                onComplete={handleComplete}
                autoClose={false}
                width={600}
                height={450}
                style={{ border: '1px solid #000' }}
            />
            <div>
                {/* <input
                    type="text"
                    placeholder="우편번호"
                    value={address.zonecode}
                    readOnly
                /> */}

                {/* <input
                    type="text"
                    placeholder="주소"
                    value={address.fullAddress}
                    readOnly
                /> */}

                {/* <input
                    type="text"
                    placeholder="상세주소"
                    value={address.detailAddress}
                    onChange={handleDetailAddressChange}
                /> */}
                
            </div>

            <section className='section-menu-adress'>
                <Button onClick={()=> setIsModal(false)}>닫기</Button>
            </section>
        </div>
    );
};

export default AddressDaum;
