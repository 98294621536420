import './BookChartSt.scss';
import React, { useState, useEffect } from "react";
import HeaderTitle from "../comm/HeaderTitle";
import fnc from "../mymod/commFunction";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";
import { useDispatch, useSelector } from "react-redux";
import { setHeader_dev, } from "../reduxToolkit/redux";


const BookChartSt = ({ source, page, stCode }) => {
 
  const dispatch = useDispatch();
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    dispatch(setHeader_dev("Exam2Main"));
  }, []);

  useEffect(() => {
    fncSelect_exam_report({stCode, source, page});
  }, []);

  const fncSelect_exam_report = ({ stCode, source, page }) => {
    fnc.sendAxios("/select/book/report", { stCode, source, page }, (res) => {
      if (res) {
       const data = getRecords_dataList(res);
        setDataList(data);
      } else {
        console.log(res, "fncSelect_exam_report");
      }
    });
  };

  function getRecords_dataList(res) {
    let records = [];
    for (let i = 0; i < res.length; i++) {
        let name = res[i].name;
        let score =  parseFloat(res[i].score).toFixed(1);
        let avg =  parseFloat(res[i].avgScore).toFixed(1);
        let sd = res[i]['stdDeviation'];
       // 모든 소수점 이하 자리가 0인 경우 정수로 변환
       score = (parseFloat(score) % 1 === 0) ? parseInt(score) : parseFloat(score);
       avg = (parseFloat(avg) % 1 === 0) ? parseInt(avg) : parseFloat(avg);
            
        records.push({
            name, score, avg , sd
        });
    }
    return records;
}

  
const CustomLabel = (props) => {

    const { x, y, width, value } = props;
    return (
      <text x={x + width / 2} y={y - 10} fill="#000" textAnchor="middle" dominantBaseline="middle">
        {value}
      </text>
    );
  };

  //==================================================================================
  return (
    <div className="BookChartSt">

    <div className="box-chart">
     <div style={{ width: "100%", height: '80%', marginTop: '0rem' }}>
        {dataList.length > 0 && 
          <>
          <h1 className='chartTitle'>{dataList[0].name}의 점수</h1>
          <span>내점수 {dataList[0].score}</span> &ensp;
          <span>평균 {dataList[0].avg}</span>
          </>
        }
        <ResponsiveContainer width="80%" height="90%">
        <BarChart data={dataList} margin={{ top: 30, right: 10, left: 70, bottom: 100 }} barCategoryGap="30%">
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" tick={false}>
            <Label value="" offset={-5} position="insideBottom" />
          </XAxis>
          <YAxis>
            <Label value="점수" angle={-90} position="insideLeft" style={{ textAnchor: 'middle' }} />
          </YAxis>
          {/* <Tooltip /> */}
          <Legend verticalAlign="bottom" align="center" wrapperStyle={{ transform: 'translateX(2rem)' }} />

          <Bar dataKey="score" fill="#8884d8" name="내 점수"  label={<CustomLabel />} />
          <Bar dataKey="avg" fill="#82ca9d" name="평균"  label={<CustomLabel />} />
        </BarChart>
      </ResponsiveContainer>
      </div> 

      </div>
    </div>
  );
};

export default BookChartSt;
