import "./LmsTecResultApp.scss";
import * as S from "../scss/theme";
import HeaderTitle from "../comm/HeaderTitle";
import React, { useState, useEffect } from "react";
import { setHeaderInfo } from '../reduxToolkit/redux';
import { useDispatch } from 'react-redux';
import MyTreeView from "./MyTreeViewTec";
import LmsTecResultAttendByClass from "./LmsTecResultAttendByClass";
import LmsTecResultAttendByStudent from "./LmsTecResultAttendByStudent";
import LmsTecResultHomeworkByClass from "./LmsTecResultHomeworkByClass";
import LmsTecResultHomeworkByStudent from "./LmsTecResultHomeworkByStudent";
import LmsTecResultOmrByClass from './LmsTecResultOmrByClass';
import LmsTecResultOmrByStudent from './LmsTecResultOmrByStudent';
import LmsTecResultAllimjang from "./LmsTecResultAllimjang";
import fnc from "../mymod/commFunction";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const LmsTecResultApp = () => {
    const dispatch = useDispatch();
    const [selectedNodeItem, setSelectedNodeItem] = useState({});
    const [isClickGangsa, setIsClickGangsa] = useState(true);
    const [isClickClass, setIsClickClass] = useState(false);

    useEffect(() => {
        const userName = fnc.getCookie('userName');
        dispatch(setHeaderInfo({ title: `${userName} 선생님의 LMS`, direction: -1 }));
    }, [dispatch]);

    useEffect(() => {
        if (selectedNodeItem.node) {

            if (selectedNodeItem.node.key.slice(0, 1) === 'c'){
                setIsClickClass(true);
            }else {
                setIsClickClass(false);
            }
        }
    }, [selectedNodeItem]);

    return (
        <>
            <S.Section>
                <HeaderTitle />
                <div className="LmsTecResultApp">
                    <div className="wrap-book">
                        <section className="section-btn">
                            <MyTreeView setSelectedNodeItem={setSelectedNodeItem} />
                        </section>
                    </div>

                    {isClickGangsa &&
                        <section className="section-bookMain">
                            <Tabs>
                                <TabList className='tabList1'>
                                    <Tab>강좌별</Tab>
                                    <Tab>학생별</Tab>
                                    <Tab>알림장</Tab>
                                </TabList>

                                {/* 강좌별 */}
                                <TabPanel>
                                    <Tabs>
                                        <TabList className='tabList2'>
                                            <Tab>출결</Tab>
                                            <Tab>보충</Tab>
                                            <Tab>진도</Tab>
                                            <Tab>과제</Tab>
                                            <Tab>시험</Tab>
                                            <Tab>임시시험</Tab>
                                        </TabList>

                                        <TabPanel>
                                            {!isClickClass && <h2 className="tabTitle">강좌를 클릭하세요!</h2>}
                                            {isClickGangsa && isClickClass && <LmsTecResultAttendByClass classCode={selectedNodeItem.node.key} kind='출석' />}
                                        </TabPanel>

                                        <TabPanel>
                                            {!isClickClass && <h2 className="tabTitle">강좌를 클릭하세요!</h2>}
                                            {isClickGangsa && isClickClass && <LmsTecResultAttendByClass classCode={selectedNodeItem.node.key} kind='보충' />}
                                        </TabPanel>

                                        <TabPanel>
                                            {!isClickClass && <h2 className="tabTitle">강좌를 클릭하세요!</h2>}
                                            {isClickGangsa && isClickClass && <LmsTecResultHomeworkByClass classCode={selectedNodeItem.node.key} kind='진도' />}
                                        </TabPanel>

                                        <TabPanel>
                                            {!isClickClass && <h2 className="tabTitle">강좌를 클릭하세요!</h2>}
                                            {isClickGangsa && isClickClass && <LmsTecResultHomeworkByClass classCode={selectedNodeItem.node.key} kind='과제' />}
                                        </TabPanel>

                                        <TabPanel>
                                            {!isClickClass && <h2 className="tabTitle">강좌를 클릭하세요!</h2>}
                                            {isClickGangsa && isClickClass && <LmsTecResultHomeworkByClass classCode={selectedNodeItem.node.key} kind='시험' />}
                                        </TabPanel>

                                        <TabPanel>
                                            {!isClickClass && <h2 className="tabTitle">강좌를 클릭하세요!</h2>}
                                            {isClickGangsa && isClickClass && <LmsTecResultOmrByClass classCode={selectedNodeItem.node.key} kind='lesson' />}
                                        </TabPanel>
                                    </Tabs>
                                    
                                </TabPanel>

                                {/* 학생별 */}
                                <TabPanel>
                                    <Tabs>
                                        <TabList className='tabList3'>
                                            <Tab>출결</Tab>
                                            <Tab>보충</Tab>
                                            <Tab>진도</Tab>
                                            <Tab>과제</Tab>
                                            <Tab>시험</Tab>
                                            <Tab>임시시험</Tab>
                                        </TabList>

                                        <TabPanel>
                                            {!isClickClass && <h2 className="tabTitle">강좌를 클릭하세요!</h2>}
                                            {isClickGangsa && isClickClass && <LmsTecResultAttendByStudent classCode={selectedNodeItem.node.key} kind='출석' />}
                                        </TabPanel>

                                        <TabPanel>
                                            {!isClickClass && <h2 className="tabTitle">강좌를 클릭하세요!</h2>}
                                            {isClickGangsa && isClickClass && <LmsTecResultAttendByStudent classCode={selectedNodeItem.node.key} kind='보충' />}
                                        </TabPanel>

                                        <TabPanel>
                                            {!isClickClass && <h2 className="tabTitle">강좌를 클릭하세요!진도</h2>}
                                            {isClickGangsa && isClickClass && <LmsTecResultHomeworkByStudent classCode={selectedNodeItem.node.key} kind='진도' />}
                                        </TabPanel>

                                        <TabPanel>
                                            {!isClickClass && <h2 className="tabTitle">강좌를 클릭하세요!과제</h2>}
                                            {isClickGangsa && isClickClass && <LmsTecResultHomeworkByStudent classCode={selectedNodeItem.node.key} kind='과제' />}
                                        </TabPanel>
                                        <TabPanel>
                                            {!isClickClass && <h2 className="tabTitle">강좌를 클릭하세요!시험</h2>}
                                            {isClickGangsa && isClickClass && <LmsTecResultHomeworkByStudent classCode={selectedNodeItem.node.key} kind='시험' />}
                                        </TabPanel>

                                        <TabPanel>
                                            {!isClickClass && <h2 className="tabTitle">강좌를 클릭하세요!과제</h2>}
                                            {isClickGangsa && isClickClass && <LmsTecResultOmrByStudent classCode={selectedNodeItem.node.key} kind='lesson' />}
                                        </TabPanel>

                                    </Tabs>
                                </TabPanel>

                                {/* 알림장 */}
                                <TabPanel>
                                            {!isClickClass && <h2 className="tabTitle">강좌를 클릭하세요!</h2>}
                                            {isClickGangsa && isClickClass && <LmsTecResultAllimjang kind='출석' />}
                                </TabPanel>

                            </Tabs>
                        </section>
                    }
                </div>
            </S.Section>
        </>
    );
};

export default LmsTecResultApp;
