import './LmsTecResultAttendByStudent.scss';
import * as S from '../scss/theme';
import React, { useState, useEffect } from "react";
import fnc from '../mymod/commFunction';
import Button from '@mui/material/Button';

import { useDispatch, useSelector } from 'react-redux';
import { setLessonDate, setIsModal, setLmsListCode } from '../reduxToolkit/redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ClassStList from './ClassStList';


const LmsTecResultAttendByStudent = ({kind}) => {
    //kind는 사용 안 함 (학생 선택 포함 되어있음)
    const selectedItemSt_result = useSelector((state) => state.reduxStore.selectedItemSt_result);

    const [list, setList] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0));
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [monthInfo, setMonthInfo] = useState('');
    const [isModal_classSt, setIsModal_classSt] = useState(false);
    const [selectedItemSt, setSelectedItemSt] = useState(null);
   
    useEffect(() => {
      if (selectedItemSt_result) {
        fncSelect_list();
      }

    }, [selectedItemSt_result]);

    
  function fncSelect_list() {
    const acaCode = fnc.getCookie('acaCode');
    // const stCode = selectedItemSt.stCode || '';
    // const classCode = selectedItemSt.classCode;

    const stCode = selectedItemSt_result.stCode || '';
    const classCode = selectedItemSt_result.classCode;


    fnc.sendAxios('/select/lmsTecResult/attendByStudent', 
          { acaCode, stCode, classCode, kind }, (res) => {
      if (res && Array.isArray(res)) { 
        console.log(res, 'res');
        setList(getRecords(res));

      } else {
        console.log(res,"select/book/pdfList");
      }
    });
  }

  
  function getRecords(res) {
    let records = [];
    for (let i = 0; i < res.length; i++) {      
        const snoSt= res[i]["snoSt"];
        const lessonDate= res[i]["lessonDate"];//list 코드 parent Code로 사용
        const name = res[i]["name"];
        let hp = res[i]["hp"];
        hp=fnc.formatPhoneNumber(hp);
        const classCode = res[i]["classCode"];
        const className = res[i]["className"];
        const kind = res[i]["kind"];
        let checkedynSt = res[i]["checkedynSt"];
        checkedynSt = fncCheckedynSt(checkedynSt);
        let eDate = res[i]["eDate"];
        const confirmed = res[i]["confirmed"];
        const weekDay = fnc.getWeekDay(lessonDate);

        records.push({ snoSt, lessonDate, weekDay, name, hp, classCode, className, kind, checkedynSt, eDate, confirmed });
    }
    return records;
  }

  function fncCheckedynSt(checkedynSt) {
    switch(checkedynSt) {

      case 'Y':
        return '출석';
        break;  
    
      case 'L':
        return '지각';
        break;

      default:
        return '결석';
        break;
  
    }
  }
  
  
  function onRefresh() {
    if (isButtonDisabled) return; // 버튼이 비활성화되어 있는 경우 아무것도 하지 않음
    fncSelect_list();
    // 버튼 비활성화 및 일정 시간 후 재활성화
    setIsButtonDisabled(true);
    setTimeout(() => setIsButtonDisabled(false), 2000); // 2초 후 버튼 재활성화
  }

  const onMoveMonth = (currDate, monthCnt) => {
    const newStartDate = new Date(currDate.getFullYear(), currDate.getMonth() + monthCnt, 1);
    const newEndDate = new Date(newStartDate.getFullYear(), newStartDate.getMonth() + 1, 0);
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

    //=========================================
    return (

      <div className="LmsTecResultAttendByStudent">
        <div className="wrap-main">
          <section className="section-main">
            <table className="table-hong table-main">
              <thead>
                <tr>
                  <th>No</th>
                  <th>수업일</th>
                  <th>성명</th>
                  <th>HP</th>
                  <th>출결</th>
                  <th>확정</th>
                  <th>수정일</th>       
                </tr>
              </thead>

              <tbody>
                  {list && list.map((item, index) => (
  
                      <tr key={index}>  
                        <td>{index+1}</td>
                        <td>{item.lessonDate} ({item.weekDay})</td>
                        <td>{item.name}</td>
                        <td>{item.hp}</td>
                        <td className={
                          item.checkedynSt == '결석' ? 'attendRed': 
                          item.checkedynSt == '지각' ? 'attendBlue':''} 
                         >
                            {item.checkedynSt}
                        </td>
                        <td>{item.confirmed}</td>
                        <td>{item.eDate}</td>
                        
                      </tr>
                  ))}
            
                </tbody>
            </table>
          </section>
      
        </div>

        <section className='section-menu'>
          <span className='lblStName'>{selectedItemSt_result.stName}</span>
          <Button className='btnSt' onClick={() => setIsModal_classSt(true)} disabled={isButtonDisabled}> 
            <i class="fa fa-user"></i> &thinsp; 학생검색
          </Button>
        </section>

         {isModal_classSt && <S.Modal_fullScreen>
          <ClassStList  setIsModal_classSt={setIsModal_classSt} setSelectedItemSt={setSelectedItemSt}/>
          </S.Modal_fullScreen>
          }

      </div>
    );
}

export default LmsTecResultAttendByStudent;