import 'rc-tree/assets/index.css';
import './MyTreeViewTec.scss';
import React, { useEffect, useState } from 'react';
import Tree, { TreeNode } from 'rc-tree';
import fnc from '../mymod/commFunction';
import { useSelector, useDispatch } from 'react-redux';
import { setClassCode, setClassName, setIsModal, setHeaderInfo } from "../reduxToolkit/redux";

const MyTreeViewTec = ({setSelectedNodeItem}) => {
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);

  const [treeData, setTreeData] = useState([]);
  const userName = fnc.getCookie('userName');
  const dispatch = useDispatch();

  useEffect(() => {
    fncSelectGangsaClassList();
  }, []);

  const onExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
  };


  function fncSelectGangsaClassList() {
    const acaCode = fnc.getCookie('acaCode');
    const stCode = fnc.getCookie('stCode');
    
    fnc.sendAxios('/select/lmsTec/treeview', { acaCode, gangsaCode: stCode }, (res) => {
      if (res && Array.isArray(res)) { 
        const transformedData = transformDataToTree(res);
        setTreeData(transformedData);
      } else {
        console.log(res, "select/lms/treeview");
      }
    });
  }

  
  function transformDataToTree(data) {
    const treeData = [];
    let currentGroup = null;

    data.forEach(row => {
      if (!currentGroup || currentGroup.id !== `group${row.clGroupCode}`) {
        currentGroup = {
          id: `group${row.clGroupCode}`,
          title: row.groupName,
          key: `group${row.clGroupCode}`,
          children: []
        };
        treeData.push(currentGroup);
      }
      currentGroup.children.push({
        id: row.clClassCode,
        title: row.clClassName,
        key: row.clClassCode
      });
    });

    return treeData;
  }


  const onSelect = (selectedKeys, selectedItem) => {
    // // // 그룹일 경우 펼침/닫힘 토글
    if (selectedItem.node.children) {
      setExpandedKeys(keys => {
        if (keys.includes(selectedItem.node.key)) {
          return keys.filter(key => key !== selectedItem.node.key); // 이미 펼쳐진 경우 닫기
        } else {
          return [...keys, selectedItem.node.key]; // 펼치기
        }
      });
    }

    setSelectedKeys(selectedKeys); // Update selected keys

    setSelectedNodeItem(selectedItem);
    dispatch(setClassCode(selectedItem.node.key));
    dispatch(setClassName(selectedItem.node.title));
    dispatch(setIsModal(false));
    dispatch(setHeaderInfo({title: selectedItem.node.title, direction: -1}));

  };

  //======================================================================
  return (
    <div className='MyTreeViewTec'>

      <div className='box-tv'>
        <div className='box-title'>
          {userName} 선생님
        </div>
        
        <Tree
          expandedKeys={expandedKeys}
          selectedKeys={selectedKeys}
          onExpand={onExpand}
          onSelect={onSelect}
          treeData={treeData}
        />
      </div>

    </div>
  );
};

export default MyTreeViewTec;
