import { createSlice } from "@reduxjs/toolkit";

const reduxAca = createSlice({
    name: 'redux_aca',//변경해도 의미 없네 (내부적으로 식별 용도인 듯)
    initialState: {
    
        selectedClassList: {},
        isAdmin: false,
        isLoggedIn: false,
        isSubmenuVisible: false,
        userInfo:{},
    },
    
    reducers: {

        setSelectedClassList: (state, action) => {
            state.selectedClassList = action.payload;
        },
        setIsAdmin: (state, action) => {
            state.isAdmin = action.payload;
        },
        setIsLoggedIn: (state, action) => {
            state.isLoggedIn = action.payload;
        },
        setIsSubmenuVisible: (state, action) => {
            state.isSubmenuVisible = action.payload;
        },

        setUserInfo: (state, action) => {
            state.userInfo = action.payload;
        },

    }


});



export const { 
 
    setSelectedClassList,
    setIsAdmin,
    setIsLoggedIn,
    setIsSubmenuVisible,
    setUserInfo,
} = reduxAca.actions;

export default reduxAca.reducer;

