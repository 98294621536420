import './LmsTecResultOmrByClass.scss';
import * as S from '../scss/theme';
import React, { useState, useEffect } from "react";
import fnc from '../mymod/commFunction';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { setLessonDate, setIsModal, setLmsListCode } from '../reduxToolkit/redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const LmsTecResultOmrByClass = ({kind}) => {

    const dispatch = useDispatch();
    const isModal = useSelector((state) => state.reduxStore.isModal);
    const classCode = useSelector((state) => state.reduxStore.classCode);
    const isRefresh_lmsTecList = useSelector((state) => state.reduxStore.isRefresh_lmsTecList);

    const [list, setList] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [monthInfo, setMonthInfo] = useState('');
      
    const monthInterval = 3;

   
    useEffect(() => {
      onMoveMonth(fnc.getFirstDayOfMonth(new Date(),+1), - monthInterval)
      fncSelect_list();
    }, [classCode, isRefresh_lmsTecList]);

    useEffect(() => {
      fncSelect_list();
      const today = fnc.fncDate();
      const weekday = fnc.getWeekDay(today);
      setMonthInfo(`${today} (${weekday})`);
    }, [startDate, endDate]);
  
    
  function fncSelect_list() {
    const acaCode = fnc.getCookie('acaCode');
    const stCode = fnc.getCookie('stCode');
    
    fnc.sendAxios('/select/lmsTecResult/omrByClass', 
          { acaCode, classCode, kind, startDate, endDate }, (res) => {
      if (res && Array.isArray(res)) { 
        console.log(res, 'res');
        setList(getRecords(res));

      } else {
        console.log(res,"sselect/lmsTecResult/omrByClass");
      }
    });
  }

  
  function getRecords(res) {
    let records = [];
    for (let i = 0; i < res.length; i++) {      
        const classCode= res[i]["classCode"];
        let lessonDate= res[i]["lessonDate"];
        const weekDay = fnc.getWeekDay(lessonDate);
        const stName = res[i]["stName"];
        let hp = res[i]["hp"];
        hp=fnc.formatPhoneNumber(hp);
    
        let score = res[i]["score"];
        const markingDate = res[i]["markingDate"];
        let avgScore = res[i]["avgScore"];

        if (score !== '') {
          score = parseFloat(score).toFixed(1);
        }

        if (avgScore !== '') {
          avgScore = parseFloat(avgScore).toFixed(1);
        }

        const rank = res[i]["rank"];
        
        records.push({ classCode, lessonDate, weekDay, stName, hp,  score, avgScore, rank, markingDate });
    }
    return records;
  }

  
  
  function onRefresh() {
    if (isButtonDisabled) return; // 버튼이 비활성화되어 있는 경우 아무것도 하지 않음
    fncSelect_list();
    // 버튼 비활성화 및 일정 시간 후 재활성화
    setIsButtonDisabled(true);
    setTimeout(() => setIsButtonDisabled(false), 2000); // 2초 후 버튼 재활성화
  }

//------------------------------------------


  const onMoveMonth = (currDate, monthCnt) => {//dtp
    
    const newStartDate = fnc.getFirstDayOfMonth(currDate, monthCnt);
    const newEndDate = fnc.getLastDayOfMonth(currDate, monthCnt + (Math.abs(monthCnt) - 1) );

    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };


    //=========================================
    return (

      <div className="LmsTecResultOmrByClass">
        <div className="wrap-main">
          <section className="section-main">
            <table className="table-hong table-main">
              <thead>
                <tr>
                  <th>No</th>
                  <th>수업일</th>
                  <th>성명</th>
                  <th>HP</th>
                  <th>점수</th>
                  <th>평균</th>
                  <th>석차</th>
                  <th>제출일</th>       
                </tr>
              </thead>

              <tbody>
                  {list && list.map((item, index) => (
  
                      <tr key={index}>  
                        <td>{index+1}</td>
                        <td>{item.lessonDate} ({item.weekDay})</td>
                        <td>{item.stName}</td>
                        <td>{item.hp}</td>
                        <td>{item.score}</td>    
                        <td>{item.avgScore}</td>    
                        <td>{item.rank}</td>          
                        <td>{item.markingDate}</td>
                        
                      </tr>
                  ))}
            
                </tbody>
            </table>
          </section>
      
        </div>

        <section className='section-menu'>

        

          <p className='monthInfo'>{monthInfo}</p>

          <Button className='btnNextPrev' onClick={() => onMoveMonth(startDate, - monthInterval)}>
            <i className="fas fa-backward"></i> 
          </Button>

          <DatePicker
              selected={startDate}
              onChange={(date) => {setStartDate(date)}}
              dateFormat="yyyy-MM-dd"
              className="datePicker"
              enableTabLoop={false}//클릭시 오른쪽 밀림 방지
            />

          <p>~</p>
          
          <DatePicker
            selected={endDate}
            onChange={(date) => {setEndDate(date)}}
            dateFormat="yyyy-MM-dd"
            className="datePicker"
            enableTabLoop={false}//클릭시 오른쪽 밀림 방지
          />

          <Button className='btnNextPrev' onClick={() => onMoveMonth(fnc.getFirstDayOfMonth(new Date(), 1), - monthInterval)}>
            <i className="fas fa-pause"></i>
          </Button>

          <Button className='btnNextPrev' onClick={() => onMoveMonth(startDate, monthInterval)}>
            <i className="fas fa-forward"></i>
          </Button>

          <Button className='btnRefresh' onClick={onRefresh} disabled={isButtonDisabled}> 
            <i className="fas fas fa-sync"></i> 
            새로고침
          </Button>

          </section>

      </div>
    );
}

export default LmsTecResultOmrByClass;