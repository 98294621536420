// CustomSpinner.js
import React from 'react';
import './CustomSpinner.scss';

function CustomSpinner() {
  return (
    <div className="custom-spinner-container">
      <div className="custom-spinner-wrapper">
        <div className="custom-spinner"></div>
      </div>
    </div>
  );
}

export default CustomSpinner;
