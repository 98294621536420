import './VideoReact.scss';
import HeaderTitle from '../comm/HeaderTitle';
import fnc from '../mymod/commFunction';
import Button from '@mui/material/Button';
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setHeader_dev, setSelectedVideoList } from '../reduxToolkit/redux';
// import useWarnBeforeLeaving from '../comm/useWarnBeforeLeaving'; //페이지 이동시 라이프사이클

import { Player, ControlBar, ReplayControl, ForwardControl, CurrentTimeDisplay, TimeDivider,
  PlaybackRateMenuButton, VolumeMenuButton } from 'video-react';
import "video-react/dist/video-react.css"; // Import css

const VideoReact = ({ fullUrl, setIsModal }) => {
  const videoUrl = process.env.REACT_APP_VIDEO_URL;
  // const {setIsAccorddionExpend} = useSelector.reduxStore_video;
  const selectedVideoList = useSelector(state => state.reduxStore.selectedVideoList);
  
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [fullUrl, setFullUrl] = useState('');
  const playerRef = useRef(null);
  const [watchedSeconds, setWatchedSeconds] = useState(new Set());
  const [duration, setDuration] = useState(0);
  const [learningRate, setLearningRate] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isActive, setIsActive] = useState(false);
  
  
  const [title, setTitle] = useState('');
  // const [videoCode, setVideoCode] = useState(null);

    // useWarnBeforeLeaving(true);//이건 사이트를 새로고침하거나 X로 닫기전에 메세지 띄움

// console.log(selectedVideoList,'---------------selectedVideoList-------------');


useEffect(() => {
  dispatch(setHeader_dev('VideoReact'));

}, []);

  useEffect(() => {
    // // const fileName = selectedVideoList.fileName;
    // const fileName = `0000/020103180101_10081.mp4`
    // // alert(`${videoUrl}/${fileName}`)
    // setFullUrl(`${videoUrl}/${fileName}`);
    // // setVideoCode(selectedVideoList.code);
  }, [selectedVideoList, videoUrl]);

  useEffect(() => {//play버튼 재생 시 안 보이게
    const handleStateChange = (state) => {
      setIsPlaying(!state.paused);
      if (!state.paused) {
        playerRef.current.manager.rootElement.classList.add("playing");
      } else {
        playerRef.current.manager.rootElement.classList.remove("playing");
      }
    };
  
    const player = playerRef.current;
    if (player && player.subscribeToStateChange) {
      player.subscribeToStateChange(handleStateChange);
    }
  
    return () => {
      if (player && player.unsubscribeFromStateChange) {
        player.unsubscribeFromStateChange(handleStateChange);
      }
    };
  }, []);//play버튼 재생 시 안 보이게
  

  useEffect(() => {
    const handleStateChange = (state) => {
      if (state.duration && state.duration !== duration) {
        setDuration(state.duration);
      }

      const currentSecond = Math.floor(state.currentTime);
      setWatchedSeconds(prevSeconds => {
        const newSeconds = new Set(prevSeconds);
        newSeconds.add(currentSecond);
        return newSeconds;
      });
    };

    const player = playerRef.current;
    if (player && player.subscribeToStateChange) {
      player.subscribeToStateChange(handleStateChange);
    }

    // Cleanup on unmount
    return () => {
      if (player && player.unsubscribeFromStateChange) {
        player.unsubscribeFromStateChange(handleStateChange);
      }
    };
  }, [duration]);

  useEffect(() => {
    if (duration > 0) {
      let rate = (watchedSeconds.size / duration) * 100;
      rate = rate > 100 ? 100 : rate;
      setLearningRate(rate);
    }
  }, [watchedSeconds, duration]);

  const handleVideoEnd = () => {
    setLearningRate(100);
  };

  function onClose() {

    const rtn = window.confirm('시청을 종료하시겠습니까?');
    if(!rtn) return;

    if (playerRef.current) {
      playerRef.current.pause(); // 비디오 재생 멈춤
      playerRef.current.seek(0); // 비디오 시간을 0으로 초기화
    }

   setIsModal(false);

  }

  function onPre() {
    const listenTime = watchedSeconds.size;

    if(listenTime > 60) {
      fncInsert_videoListenSt();

      const rtn = window.confirm('시청을 종료하시겠습니까?');
      if(!rtn) return;
    }else {
      const rtn = window.confirm('시청을 종료하시겠습니까?');
      if(!rtn) return;
      // alert(`1분 미만은 기록하지 않습니다.`);
    }
  
    if (playerRef.current) {
      playerRef.current.pause(); // 비디오 재생 멈춤
      playerRef.current.seek(0); // 비디오 시간을 0으로 초기화
    }
    
    // navigate("/videoList")
  }

  function fncInsert_videoListenSt() {//필요하면 나중에 연결할 것

   const parentCode= selectedVideoList.code;
   const code= fnc.fncSirialNum('L');
   const acaCode = fnc.getCookie('acaCode');
   const stCode = fnc.getCookie('stCode');
   const listenPercent=learningRate.toFixed(0);
   const totalTime = duration;
   const listenTime = watchedSeconds.size;
   const parentName= selectedVideoList.fileName;

    fnc.sendAxios('/insert_videoListenSt', {parentCode, code, acaCode, stCode, 
      listenPercent, totalTime, listenTime, parentName }, (res) => {
      if (res) {   
        console.log(res);
      } else {
        console.log("속성이 obj 내에 없습니다.");
      }
    });

  }

  //==================================================
  return (
    <div className="VideoReact">
        {/* <HeaderTitle  title={title} direction={1} onBackClick={onClose} /> */}
        <Button className='btnClose-me' onClick={() => setIsModal(false)} >
              <i class="fa-solid fa-xmark"></i>
        </Button> 
      
      <div className="wrap_player">
          <Player
  
            ref={playerRef}
            // poster={thumbnail}
            // autoPlay   
            muted={false}
            playsInline
            onEnded={handleVideoEnd}
            type="video/mp4"
            loop={false}
            fluid={false}           
          >
           {fullUrl && <source src={fullUrl} /> }
            <ControlBar>
              <ReplayControl seconds={10} order={1.1} />
              <ForwardControl seconds={10} order={1.2} />
              <CurrentTimeDisplay order={4.1} />
              <TimeDivider order={4.2} />
              <PlaybackRateMenuButton rates={[2, 1.5, 1, 0.5]} order={7.1} />
              <VolumeMenuButton />
            </ControlBar>
          </Player>
        
     
      {/* 일단 죽여놓는다. */}
    
      {false && (
        <div className="box_learningRate">
          <div className="learningRate">학습률: {learningRate.toFixed(2)}%</div>
        </div>
      )}


      </div>
    </div>
  );
};

export default VideoReact;
