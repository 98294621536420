import './Detail_homework.scss';
import * as S from '../scss/theme';
import React, { useState, useEffect } from "react";
import fnc from '../mymod/commFunction';
import Button from '@mui/material/Button';
import Combobox from '../comm/Combobox_noBMK';
import Select from 'react-select';
import HeaderTitle from "../comm/HeaderTitle";
import { useDispatch, useSelector } from 'react-redux';
import { setList_pdf, setHeaderInfo,setIsRefresh_studentCourseList } from '../reduxToolkit/redux';



const Detail_homework = () => {


    const dispatch = useDispatch();
   
    const classCode = useSelector(state => state.reduxStore.classCode);
    const lessonDate = useSelector(state => state.reduxStore.lessonDate);
    

    const [list, setList] = useState([]);
    const [selectedIdx, setSelectedIdx] = useState(0);
    const [isRefresh, setIsRefresh] = useState(false);



    useEffect(() => {
      fncSelect_list();
    }, [isRefresh]);

    
  function fncSelect_list() {
    const acaCode = fnc.getCookie('acaCode');
    const stCode = fnc.getCookie('stCode');
    
    fnc.sendAxios('/select/lms/course/studyStaus', { stCode, classCode, lessonDate }, (res) => {
      if (res && Array.isArray(res)) { 

        // console.log(res);
        setList(getRecords(res));

      } else {
        console.log(res,"select/book/pdfList");
      }
    });
  }

  
  function getRecords(res) {
    let records = [];
    for (let i = 0; i < res.length; i++) {      
        const kind= res[i]["kind"];
        const lessonDate = res[i]["lessonDate"];
        const lessonDateSt = res[i]["lessonDateSt"];
        const classCode = res[i]["classCode"];
        const parentCode = res[i]["code"];
        const sno = res[i]["snoStu"];
        const confirmed = res[i]["confirmed"];
             
        records.push({ kind, lessonDate, lessonDateSt, classCode, parentCode, sno, confirmed });
    }
    return records;
  }

  
  function onClickTr( item, index) {
    setSelectedIdx(index);//값넣기는 일단 실패
   
  }

  function onComplete(item) {

    const acaCode = fnc.getCookie('acaCode');
    const stCode = fnc.getCookie('stCode');
    const parentCode = item.parentCode;
    const classCode = item.classCode;
    const lessonDate = item.lessonDate;
    const checkedyn = 'Y';

    const confirmed = item.confirmed;

    if (confirmed == 'Y') {
      alert('확정된 항목은 수정할 수 없습니다.');
      return;
    }

    fnc.sendAxios('/select/lms/course/studyStaus/complete', { acaCode, stCode, parentCode, classCode, lessonDate, checkedyn }, (res) => {
      if (res) {  
        setIsRefresh(!isRefresh);
      } else {
        console.log(res,"select/book/pdfList");
      }
    });

  }

  
  function onDelete(item) {
    const sno = item.sno;
    const kind = item.kind;
    const confirmed = item.confirmed;

    if (confirmed == 'Y') {
      alert('확정된 항목은 수정할 수 없습니다.');
      return;
    }

    const rtn = window.confirm(`${kind}을 삭제하시겠습니까?`);
    if (!rtn) return;

    fnc.sendAxios('/select/lms/course/studyStaus/delete', { sno }, (res) => {
      if (res) { 
        setIsRefresh(!isRefresh);
      } else {
        console.log(res,"select/book/pdfList");
      }
    });

  }
  
    //=========================================
    return (

      <div className="Detail_homework">
         <div className='title'>학습상태</div>
      <div className="wrap-main">
          <section className="section-main">
          <table className="table-hong table-main">
              <thead>
                <tr>
                  <th>항목</th>
                  <th>수업일</th>
                  <th>제출일</th>
                  <th>확정</th>
                  <th>삭제</th>
                  
                  <th>제출</th>
                </tr>
              </thead>

              <tbody>
                  {list && list.map((item, index) => (
  
                      <tr key={index} onClick={() => onClickTr(item, index)}>  
                        <td>{item.kind}</td>
                        <td>{item.lessonDate}</td>
                        <td>{item.lessonDateSt}</td>
                        <td>{item.confirmed}</td>
                        <td><Button className='btnDelete' onClick={() => onDelete(item)}><i className="fas fa-trash"></i></Button></td>
                     
                        <td><Button className='btnComplete' onClick={() => onComplete(item)}>완료</Button></td>
                      </tr>
                  ))}
            
                </tbody>
            </table>

         
          </section>
      </div>

      </div>
    );
}

export default Detail_homework;