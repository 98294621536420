
function getSmsErrMsg(resultCode) {
  switch (resultCode) {
      case '0':
        return '성공';
      case '32':
        return '메세지초과';
      case '100'://100도 발신번호오류 출력
      case '101':
        return '발신번호오류';
      case '110':
        return '수신시간 만료';
      case '201':
        return '통신사기타오류';
      case '504':
        return '파일 포멧오류';
      default:
        return '파일 포멧오류';
    }    
}

function getSmsStatus(status) {
  switch (status) {
      case '0':           
          return '발송대기';
      case '2':           
          return '전송완료';
      case '3':           
          return '결과수신완료';    
      default:
          return '';
  }
}

const fnc2 = { 
  getSmsErrMsg, getSmsStatus,
 }
export default fnc2;
// module.exports = fnc;

