import AWS from 'aws-sdk';

const accessKeyId = process.env.REACT_APP_S3_ID;
const secretAccessKey = process.env.REACT_APP_S3_KEY;

const S3_BUCKET = 'kuesb';
    const REGION = 'ap-northeast-2';

    AWS.config.update({
      region: REGION,
      accessKeyId,
      secretAccessKey,
    });
  
  const s3 = new AWS.S3();//config 뒤에 있어야 한다. (자격증명)
    
  const getS3Path = (key, sec=60000) => {
    const params = {
      Bucket: S3_BUCKET,
      Key: key, // 첫 번째 이미지 키 사용
      Expires: sec, // URL 유효 기간을 60초로 설정
  
    };
    try {
      const url = s3.getSignedUrl('getObject', params);
      return url;
    } catch (error) {
      console.error(`Error fetching the image ${key} from S3:`, error);
    }
  };

   
  const getS3PathDownLoad = (key, fileName, sec=600) => {
    const params = {
      Bucket: S3_BUCKET,
      Key: key, // 첫 번째 이미지 키 사용
      Expires: sec, // URL 유효 기간을 60초로 설정
      ResponseContentDisposition: `attachment; filename="${encodeURIComponent(fileName)}"`, // 다운로드 강제
    };
    try {
      const url = s3.getSignedUrl('getObject', params);
      return url;
    } catch (error) {
      console.error(`Error fetching the image ${key} from S3:`, error);
    }
  };

  const getS3PathLong = (key, fileName, sec=60000) => {
    const params = {
      Bucket: S3_BUCKET,
      Key: key, // 첫 번째 이미지 키 사용
      Expires: sec // URL 유효 기간을 60초로 설정
    };
    try {
      const url = s3.getSignedUrl('getObject', params);
      return url;
    } catch (error) {
      console.error(`Error fetching the image ${key} from S3:`, error);
    }
  };

  const getS3PathShort = (key, sec=1) => {
    const params = {
      Bucket: S3_BUCKET,
      Key: key, // 첫 번째 이미지 키 사용
      Expires: sec // URL 유효 기간을 60초로 설정
    };
    try {
      const url = s3.getSignedUrl('getObject', params);
      return url;
    } catch (error) {
      console.error(`Error fetching the image ${key} from S3:`, error);
    }
  };

  const getS3PathVideo = (key) => {
    const params = {
      Bucket: S3_BUCKET,
      Key: key, // 첫 번째 이미지 키 사용
      Expires: 60000 // sec
    };
    try {
      const url = s3.getSignedUrl('getObject', params);
      return url;
    } catch (error) {
      console.error(`Error fetching the image ${key} from S3:`, error);
    }
  };
  
const fncS3 = { 
  getS3Path, getS3PathLong, getS3PathShort, getS3PathVideo, getS3PathDownLoad,
 }

export default fncS3;
// module.exports = fnc;
// 
