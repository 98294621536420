import './BookPdf_complete.scss';
import fnc from '../mymod/commFunction';
import fncS3 from '../mymod/commFunction3';
// import * as S from '../scss/theme';
import React, { useState, useEffect } from "react";
import HeaderTitle from '../comm/HeaderTitle';
import BookPdf_marking_menu from './BookPdf_marking_menu';
import BookPdf_marking_omr from './BookPdf_marking_omr';
import imgO  from '../images/svg/O2.png';
import imgX  from '../images/svg/X2.png';
import { useSelector, useDispatch } from 'react-redux';
import { setHeader_dev, initializeMarkingList, setList_que, setRowIdx_que, setKind } from '../reduxToolkit/redux';
import { Button } from '@mui/material';

const BookPdf_complete = ({ setIsModal }) => {
    const dispatch = useDispatch();

    // const imgUrl = process.env.REACT_APP_imgUrl;
    const imgUrl = process.env.REACT_APP_imgUrl_S3;
    const headerInfo = useSelector(state => state.reduxStore.headerInfo);
    // const list = useSelector(state => state.reduxStore.list_pdf_marking);
    const list_pdf = useSelector(state => state.reduxStore.list_pdf);
    const list = useSelector(state => state.reduxStore.list_pdf_complete);
    const rowIdx_pdf = useSelector(state => state.reduxStore.rowIdx_pdf);

    const markingList = useSelector(state => state.reduxStore.markingList);

    const [imagePath, setImagePath] = useState('');
    const [title, setTitle] = useState('');
    const [selectedListItem, setSelectedListItem] = useState({});
    const [record_que, setRecord_que] = useState([]);
    const [OX, setOX] = useState("O");

    useEffect(() => {
      dispatch(setHeader_dev('BookPdf_complete'));
      console.log(list_pdf, ' complete');
    }, []);

    useEffect(() => {

        const listQue = getRecords_pdf_detail_que(list);
        setRecord_que(listQue);
        dispatch(initializeMarkingList(listQue.length));
        dispatch(setKind('sol'));
        // setSelectedListItem(list_pdf[rowIdx_pdf]);
        setSelectedListItem(setList_que[rowIdx_pdf]);
        dispatch(setList_que(listQue));
        
     }, [list, dispatch]);

    

    function getRecords_pdf_detail_que(userJsonObj) {
        let records = [];
        const acaCode = fnc.getCookie('acaCode');

        for (let i = 0; i < userJsonObj.length; i++) {
            let source = userJsonObj[i]['source'];
            let folder = userJsonObj[i]['folder'].replaceAll('\\', '/');
            let imgFileName = userJsonObj[i]['imgFileName'];
            let kind = userJsonObj[i]['kind'];
            let page = userJsonObj[i]['page'];
            let pdfFileName = userJsonObj[i]['pdfFileName'];
            let queNo = userJsonObj[i]['queNo'];
            let realNo = userJsonObj[i]['realNo'];
            let dap = userJsonObj[i]['dap'];
            let imgCol = userJsonObj[i]['imgCol'];
            let ImgSize = userJsonObj[i]['ImgSize'];
            let ImgSizeSol = userJsonObj[i]['ImgSizeSol'];
            let qrCode = userJsonObj[i]['qrCode'];

            let OX = userJsonObj[i]['OX'];
            let marking = userJsonObj[i]['marking'];


            // let rnd = Math.floor(Math.random() * 900) + 100;
            // let imagePath = `${imgUrl}/${folder}/${imgFileName}?${rnd}`;
            // let imagePath_sol = `${imgUrl}/${folder}/풀이_${imgFileName}?${rnd}`;

            
            let imagePath = fncS3.getS3Path( `${imgUrl}/${acaCode}/${folder}/${imgFileName}`);
            let imagePath_sol = fncS3.getS3PathLong( `${imgUrl}/${acaCode}/${folder}/풀이_${imgFileName}`);


            records.push({
                source, imagePath, folder, imgFileName, kind, page, pdfFileName, queNo, realNo, dap, imgCol,
                ImgSize, ImgSizeSol, qrCode, OX, marking, imagePath_sol
            });
        }
        return records;
    }

  
    //---------------------------------------------------------------------------
    return (
      <div className="BookPdf_complete">
        {/* <HeaderTitle title={title} direction={1} onBackClick={() => setIsModal(false)} /> */}
        <Button className='btnClose-me' onClick={() => setIsModal(false)} >
                <i class="fa-solid fa-xmark"></i>
        </Button> 

        <div className="wrap-que">
          <section className="section-que">
            {record_que.map((item, index) => (
              <article className="article-que" key={index}>
               
                <span className="No">
                  {index + 1}
                  <img  className="img-OX"  src={item.OX === 'O' ? imgO : imgX} alt="imgOX"/>
                </span>
                <div className="box-imgQue">
                  <img
                    className={`imgQue ${item.imgCol}`}
                    src={item.imagePath}
                    alt={`${item.pdfFileName}`}
                  />
                </div>
                <p className="menu">
                  <BookPdf_marking_menu
                    list_que={record_que}
                    item={item}
                    no={index + 1}
                    markingListValue={markingList[index + 1]}
                    isComplete={true}
                  />
                </p>
              </article>
            ))}
    
          </section>

          <section className="section-omr">
            {/* <BookPdf_marking_omr /> */}
          </section>

       

        </div>
      </div>
    );
}

export default BookPdf_complete;
