import './LmsTecResultHomeworkByStudent.scss';
import * as S from '../scss/theme';
import React, { useState, useEffect } from "react";
import fnc from '../mymod/commFunction';
import Button from '@mui/material/Button';
// import LmsMainDetail from './LmsMainDetail';
// import BoardWriteText from './BoardWriteText';
import { useDispatch, useSelector } from 'react-redux';
// import { setLessonDate, setIsModal, setLmsListCode } from '../reduxToolkit/redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ClassStList from './ClassStList';


const LmsTecResultHomeworkByStudent = ({kind}) => {

    const dispatch = useDispatch();
    const isModal = useSelector((state) => state.reduxStore.isModal);
    const classCode = useSelector((state) => state.reduxStore.classCode);
    const selectedItemSt_result = useSelector((state) => state.reduxStore.selectedItemSt_result);

    const [list, setList] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const [isModal_classSt, setIsModal_classSt] = useState(false);
    const [selectedItemSt, setSelectedItemSt] = useState(null);
      

    useEffect(() => {
      if (selectedItemSt_result) {
        fncSelect_list();
      }

    }, [selectedItemSt_result]);

  
    
    
  function fncSelect_list() {
    const acaCode = fnc.getCookie('acaCode');
    const stCode = selectedItemSt_result.stCode || '';
    const classCode = selectedItemSt_result.classCode;
    
    fnc.sendAxios('/select/lmsTecResult/jindo_homework_exam_byStudent', 
          { acaCode, stCode, classCode, kind }, (res) => {
            
      if (res && Array.isArray(res)) { 
        setList(getRecords(res));
      } else {
        console.log(res,"select/lmsTecResult/jindo_homework_exam_byStudent");
      }
    });
  }

  
  function getRecords(res) {
    let records = [];
    for (let i = 0; i < res.length; i++) {      
        const snoSt= res[i]["snoSt"];
        const lessonDate= res[i]["lessonDate"];//list 코드 parent Code로 사용
        const name = res[i]["name"];
        let hp = res[i]["hp"];
        hp=fnc.formatPhoneNumber(hp);
        const classCode = res[i]["classCode"];
        const className = res[i]["className"];
        const kind = res[i]["kind"];
        let checkedynSt = res[i]["checkedynSt"];
        checkedynSt = fncCheckedynSt(checkedynSt);
        let eDate = res[i]["eDate"];
        const confirmed = res[i]["confirmed"];
        const weekDay = fnc.getWeekDay(lessonDate);
        const jindo = res[i]["jindo"];
        const homework = res[i]["homework"];
        const bookCode = res[i]["bookCode"];
        const bookName = res[i]["bookName"];
        let score = res[i]["score"];
        let avgScore = res[i]["avgScore"];
        const rank = res[i]["rank"];

        if (score !== '') {
          score = parseFloat(score).toFixed(1);
        }

        if (avgScore !== '') {
          avgScore = parseFloat(avgScore).toFixed(1);
        }

        records.push({ snoSt, lessonDate, weekDay, name, hp, classCode, className, kind, checkedynSt, 
          eDate, confirmed, jindo, homework, bookCode, bookName, score, avgScore, rank });
    }
    return records;
  }

  function fncCheckedynSt(checkedynSt) {
    switch(checkedynSt) {

      case 'Y':
        return 'O';
        break;  
    
      case 'N':
        return 'X';
        break;

      default:
        return '';
        break;
  
    }
  }
  
  
    //=========================================
    return (

      <div className="LmsTecResultHomeworkByStudent">
        <div className="wrap-main">
          <section className="section-main">
            <table className="table-hong table-main">
              <thead>
                <tr>
                  <th>No</th>
                  <th>수업일</th>
                  <th>{kind}</th>
                  <th>완료</th>
                  <th>확정</th>
                 
                  <th>점수</th>   
                  <th>평균</th>
                  <th>석차</th>
                  <th>수정일</th>    
                </tr>
              </thead>

              <tbody>
                  {list && list.map((item, index) => (
  
                      <tr key={index}>  
                        <td>{index+1}</td>
                        <td>{item.lessonDate} ({item.weekDay})<br />
                        {/* <span className='name_hp'> {item.name} ( {item.hp.slice(-4)} )</span>  */}
                        </td>
                        <td>
                           { kind == '진도' && <span className='jindo'>{item.jindo}</span> }
                           { kind == '과제' && <span className='homework'>{item.homework}</span> }
                           { kind == '시험' && <span className='bookName'>{item.bookName}</span> }      
                        </td>

        
                        <td className={
                          item.checkedynSt == 'X' ? 'attendRed': 
                          item.checkedynSt == '' ? 'attendBlue':''} 
                         >
                            {item.checkedynSt}
                        </td>
                        <td>{item.confirmed}</td>
                       
                        <td>{item.score}</td>
                        <td>{item.avgScore}</td>    
                        <td>{item.rank}</td>         
                        <td>{item.eDate}</td>
                      </tr>
                      
                  ))}
            
                </tbody>
            </table>
          </section>
      
        </div>


        <section className='section-menu'>
          <span className='lblStName'>{selectedItemSt_result.stName}</span>
          <Button className='btnSt' onClick={() => setIsModal_classSt(true)} disabled={isButtonDisabled}> 
            <i class="fa fa-user"></i> &thinsp; 학생검색
          </Button>
        </section>

         {isModal_classSt && <S.Modal_fullScreen>
          <ClassStList  setIsModal_classSt={setIsModal_classSt} setSelectedItemSt={setSelectedItemSt}/>
          </S.Modal_fullScreen>
          }

         
      </div>
    );
}

export default LmsTecResultHomeworkByStudent;