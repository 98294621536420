import './CreatPw.scss';
// import * as S from '../theme';
// import styled from 'styled-components'; 
import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import TermsAgreement from './TermsAgreement'; // 이용약관 컴포넌트 임포트
import fnc from '../mymod/commFunction';

function CreatPw() {
  const svrUrl = process.env.REACT_APP_SERVER_URL;
  const navigate = useNavigate();
  const [userName , setUserName] = useState('');

  const [userInput, setUserInput] = useState({
    userBirthday: '',
    userid: '',
    password: '',
    confirmPassword: '',
  });


  // 오류 메시지를 관리할 상태 추가
  const [errorMessages, setErrorMessages] = useState({});
  const [isCheckingUserid, setIsCheckingUserid] = useState(false);
  const [useridAvailable, setUseridAvailable] = useState(true);

  const [agreedTerms, setAgreedTerms] = useState(false);
  const [agreedPrivacy, setAgreedPrivacy] = useState(false);

  // 입력 필드가 변경될 때마다 호출될 함수
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInput({
      ...userInput,
      [name]: value,
    });
  };

  // 이용약관 및 개인정보 수집 동의 핸들러
  const onAgreeTermsChange = (isAgreed) => {
    setAgreedTerms(isAgreed);
  };

  const onAgreePrivacyChange = (isAgreed) => {
    setAgreedPrivacy(isAgreed);
  };
  // 유효성 검사 함수
  const validateForm = () => {
    let errors = {};
    let formIsValid = true;

    // 아이디 검사
    if (!userInput.userid.trim()) {
      errors.userid = '학생고유번호를 입력해 주세요.';
      formIsValid = false;
    }

     // 이름 검사
     if (!userInput.userBirthday.trim()) {
        errors.userBirthday = '생년월일을 입력해 주세요.';
        formIsValid = false;
      }


    // 비밀번호 검사
    if (!userInput.password) {
      errors.password = '비밀번호를 입력해 주세요.';
      formIsValid = false;
    } else if (userInput.password.length < 6) {
      errors.password = '비밀번호는 최소 6자 이상이어야 합니다.';
      formIsValid = false;
    }

    // 비밀번호 확인 검사
    if (userInput.password !== userInput.confirmPassword) {
      errors.confirmPassword = '비밀번호가 일치하지 않습니다.';
      formIsValid = false;
    }


    setErrorMessages(errors);
    return formIsValid;
  };

  // 폼 제출 시 호출될 함수
  const handleSubmit = (e) => {
    e.preventDefault(); // 폼 제출 기본 이벤트 방지

    if (validateForm()) {

      // if(!isCheckingUserid) {
      //   alert('아이디 중복확인을 먼저하세요');
      //   return;
      // }

      // if(!useridAvailable) {
      //   alert('중복된 아이디입니다.');
      //   return;
      // }

      if (!agreedTerms) {
        alert('이용약관에 동의해야 회원가입을 진행할 수 있습니다.');
        return;
      }

      if (!agreedPrivacy) {
        alert('개인정보 수집에 동의해야 회원가입을 진행할 수 있습니다.');
        return;
      }

      const rtn = window.confirm('비밀번호 생성을 하시겠습니까?');
      if(rtn) {

        const param = {
          basicid: userInput.userid, 
          userBirthday: userInput.userBirthday,
          password: userInput.password  };

          fncExistId(param.basicid, param.userBirthday, param.password);

      }
   
    } else {
      console.log('Validation Failed:', errorMessages);
      // 유효성 검사 실패 처리 로직 추가 (예: 오류 메시지 표시)
    }
  };


  function onClose() {
    navigate('/login'); 
  }
//---------------------------------------------------axios
function fncExistId(id, birthday, pw) {   
  
    fnc.sendAxios('/aca/selectId', {id, birthday }, (res) => {
      if (res) {     
       console.log(res);
       const name = res[0].name;
       const firstPw = res[0].pw;

      if(firstPw.length > 20){
        alert('이미 패쓰워드가 존재합니다.');
        return false;
      }else {
        fncInsertUser(name, id, pw);
      }
      
           
      } else {
        console.log("속성이 obj 내에 없습니다.");
      }
    });
  }


function fncInsertUser(userName, stCode, pw) {   
  const rtn = window.confirm(`[${userName}]의 \n비밀번호를 생성하시겠습니까?`);
  if (!rtn) return;


    fnc.sendAxios('/aca/updatePw', {stCode, pw }, (res) => {
      if (res) {      
        console.log(res);

        if (res.success === true) {
          fncInsertUserAgreements(stCode);
          alert('회원가입이 완료되었습니다. \n 로그인 하세요!');
          navigate('/login'); 
        }else if (res.success === true) {
            alert('실패했습니다!');

        }
       
      } else {
        alert('가입에 실패했습니다.');
        console.log("속성이 obj 내에 없습니다.");
      }
    });
  }


  
function fncInsertUserAgreements(stCode) {   
    fnc.sendAxios('/aca/insertUserAgreements', {stCode}, (res) => {
      if (res) {      
     
      } else {
        console.log("속성이 obj 내에 없습니다.");
      }
    });
  }



//==================================================
  return (
    <div className='CreatPw'>

      <form onSubmit={handleSubmit}>
      <p className='title'>비밀번호 생성</p>
      <div className='box_signUp'>         
        
          <div className='inner_singUp'>
                  {/* 아이디 입력 필드 */}
                  <div>
                  <label htmlFor="userid">*학생고유번호 (선생님께 문의)</label>
                  <input
                    type="text"
                    id="userid"
                    name="userid"
                    value={userInput.userid}
                    onChange={handleChange}
                    required
                    placeholder='학생고유번호를 입력해 주세요.'
                  />
                  {errorMessages.userid && <p className="errorMessage">{errorMessages.userid}</p>}
                </div>

                {/* 이름 입력 필드 */}
                <div>
                  <label htmlFor="userBirthday">*생년월일</label>
                  <input
                    type="text"
                    id="userBirthday"
                    name="userBirthday"
                    value={userInput.userBirthday}
                    onChange={handleChange}
                    required
                    placeholder='숫자만입력해 주세요.'
          
                  />
                  {errorMessages.userBirthday && <p className="errorMessage">{errorMessages.userBirthday}</p>}
                </div>
              
                {/* 비밀번호 입력 필드 */}
                <div>
                  <label htmlFor="password">*비밀번호</label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    value={userInput.password}
                    onChange={handleChange}
                    required
                    placeholder='비밀번호를 입력해 주세요.'
                    
                  />
                  {errorMessages.password && <p className="errorMessage">{errorMessages.password}</p>}
                </div>
                {/* 비밀번호 확인 입력 필드 */}
                <div>
                  <input
                    type="password"
                    id="confirmPassword"
                    name="confirmPassword"
                    value={userInput.confirmPassword}
                    onChange={handleChange}
                    required
                    placeholder='비밀번호를 한번 더 입력해 주세요.'
                  />
                  {errorMessages.confirmPassword && <p className="errorMessage"> {errorMessages.confirmPassword}</p>}
                </div>
              </div>

              
            {/* 이용약관 컴포넌트 추가 */}
            <div className='box_termsAgreement'>
            <TermsAgreement 
                agreedTerms={agreedTerms}
                agreedPrivacy={agreedPrivacy}
                onAgreeTermsChange={onAgreeTermsChange}
                onAgreePrivacyChange={onAgreePrivacyChange}
              />
            </div>

            {/* 메뉴버튼 */}
            <div className='box_menu'>
                <Button  className='btnClose' variant="contained" onClick={onClose} >취소</Button>
                <Button type='submit' className='btnSave' variant="contained" >확인</Button>
            </div>


            </div>



    </form>
  
    </div>
  );
}

export default CreatPw;
