import './LmsTecResultAttendByClass.scss';
import * as S from '../scss/theme';
import React, { useState, useEffect } from "react";
import fnc from '../mymod/commFunction';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { setLessonDate, setIsModal, setLmsListCode } from '../reduxToolkit/redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const LmsTecResultAttendByClass = ({kind}) => {

    const dispatch = useDispatch();
    const isModal = useSelector((state) => state.reduxStore.isModal);
    const classCode = useSelector((state) => state.reduxStore.classCode);
    const isRefresh_lmsTecList = useSelector((state) => state.reduxStore.isRefresh_lmsTecList);

    const [list, setList] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [monthInfo, setMonthInfo] = useState('');
      
    const monthInterval = 3;

   
    useEffect(() => {
      onMoveMonth(fnc.getFirstDayOfMonth(new Date(),+1), - monthInterval)
      fncSelect_list();
    }, [classCode]);

    useEffect(() => {
      fncSelect_list();
      const today = fnc.fncDate();
      const weekday = fnc.getWeekDay(today);
      setMonthInfo(`${today} (${weekday})`);
  
    }, [startDate, endDate]);
  
    
  function fncSelect_list() {
    const acaCode = fnc.getCookie('acaCode');
    const stCode = fnc.getCookie('stCode');
    
    fnc.sendAxios('/select/lmsTecResult/attendByClass', 
          { acaCode, classCode, kind, startDate, endDate }, (res) => {
      if (res && Array.isArray(res)) { 
        setList(getRecords(res));

      } else {
        console.log(res,"select/book/pdfList");
      }
    });
  }

  
  function getRecords(res) {
    let records = [];
    for (let i = 0; i < res.length; i++) {      
        const snoSt= res[i]["snoSt"];
        const lessonDate= res[i]["lessonDate"];//list 코드 parent Code로 사용
        const name = res[i]["name"];
        let hp = res[i]["hp"];
        hp=fnc.formatPhoneNumber(hp);
        const classCode = res[i]["classCode"];
        const className = res[i]["className"];
        const kind = res[i]["kind"];
        let checkedynSt = res[i]["checkedynSt"];
        checkedynSt = fncCheckedynSt(checkedynSt);
        let eDate = res[i]["eDate"];
        const confirmed = res[i]["confirmed"];
        const weekDay = fnc.getWeekDay(lessonDate);

        records.push({ snoSt, lessonDate, weekDay, name, hp, classCode, className, kind, checkedynSt, eDate, confirmed });
    }
    return records;
  }

  function fncCheckedynSt(checkedynSt) {
    switch(checkedynSt) {

      case 'Y':
        return '출석';
        break;  
    
      case 'L':
        return '지각';
        break;

      default:
        return '결석';
        break;
  
    }
  }
  
  
  function onRefresh() {
    if (isButtonDisabled) return; // 버튼이 비활성화되어 있는 경우 아무것도 하지 않음
    fncSelect_list();
    // 버튼 비활성화 및 일정 시간 후 재활성화
    setIsButtonDisabled(true);
    setTimeout(() => setIsButtonDisabled(false), 2000); // 2초 후 버튼 재활성화
  }

//------------------------------------------

  function onClickCheckedynSt(item, index) {//출결 변경
    const nameSt = item.name;
    if (item.confirmed == 'Y') {
      const rtn = window.confirm(`확정된 학생입니다. \n${nameSt}을 수정하시겠습니까?`);
      if (!rtn) return;
    }

    const newList = [...list];
    newList[index].checkedynSt = newList[index].checkedynSt || '결석'; // 기본 값 설정

    switch(newList[index].checkedynSt) {
      case '출석':
        newList[index].checkedynSt = '지각';
        fncUpdate_attend(item.snoSt, '지각');
        break;
      case '지각':
        newList[index].checkedynSt = '결석';
        fncUpdate_attend(item.snoSt, '결석');
        break;
      case '결석':
        newList[index].checkedynSt = '출석';
        fncUpdate_attend(item.snoSt, '출석');
        break;
      default:
        console.log(`Unknown status: ${newList[index].checkedynSt}`);
    }
    setList(newList);
  }

  function onClickConfirmed(item, index) {//확정 변경
    const newList = [...list];
    newList[index].confirmed = newList[index].confirmed || ''; // 기본 값 설정
    switch (newList[index].confirmed) {
      case '':
        newList[index].confirmed = 'Y';
        fncUpdate_confirmed(item.snoSt, 'Y')
        break;
      case 'Y':
        newList[index].confirmed = '';
        fncUpdate_confirmed(item.snoSt, '')
        break;
      default:
        console.log(`Unknown status: ${newList[index].confirmed}`);
    }
    
    setList(newList);
  }


  
  function fncUpdate_attend(snoSt, checkedynSt) {
    switch (checkedynSt) {
      case '출석':
        checkedynSt = 'Y';
        break;
      case '결석':
        checkedynSt = 'N';
        break;
      case '지각':
        checkedynSt = 'L';
        break;
    }

    fnc.sendAxios('/update/lmsTecResult/learningSituation_st_attend', { snoSt, checkedynSt }, (res) => {
      if (res) { 
        console.log(res, 'res');
      } else {
        console.log(res,"update/lmsTecResult/learningSituation_st_attend");
      }
    });
  }

  
  function fncUpdate_confirmed(snoSt, confirmed) {//출결 확정

    fnc.sendAxios('/update/lmsTecResult/learningSituation_st_comfirmed', { snoSt, confirmed }, (res) => {
      if (res) { 
        console.log(res, 'res');
      } else {
        console.log(res,"update/lmsTecResult/learningSituation_st_comfirmed");
      }
    });
  }


  const onMoveMonth = (currDate, monthCnt) => {//dtp
    
    const newStartDate = fnc.getFirstDayOfMonth(currDate, monthCnt);
    const newEndDate = fnc.getLastDayOfMonth(currDate, monthCnt + (Math.abs(monthCnt) - 1) );

    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };


    //=========================================
    return (

      <div className="LmsTecResultAttendByClass">
        <div className="wrap-main">
          <section className="section-main">
            <table className="table-hong table-main">
              <thead>
                <tr>
                  <th>No</th>
                  <th>수업일</th>
                  <th>성명</th>
                  <th>HP</th>
                  <th>출결</th>
                  <th>확정</th>
                  <th>수정일</th>       
                </tr>
              </thead>

              <tbody>
                  {list && list.map((item, index) => (
  
                      <tr key={index}>  
                        <td>{index+1}</td>
                        <td>{item.lessonDate} ({item.weekDay})</td>
                        <td>{item.name}</td>
                        <td>{item.hp}</td>
                        <td className={
                          item.checkedynSt == '결석' ? 'attendRed': 
                          item.checkedynSt == '지각' ? 'attendBlue':''} 
                          onClick={() => onClickCheckedynSt(item, index)}>
                            {item.checkedynSt}
                        </td>
                        <td onClick={() => onClickConfirmed(item, index)}>{item.confirmed}</td>
                        <td>{item.eDate}</td>
                        
                      </tr>
                  ))}
            
                </tbody>
            </table>
          </section>
      
        </div>

        <section className='section-menu'>

          <p className='monthInfo'>{monthInfo}</p>

          <Button className='btnNextPrev' onClick={() => onMoveMonth(startDate, - monthInterval)}>
            <i className="fas fa-backward"></i> 
          </Button>

          <DatePicker
              selected={startDate}
              onChange={(date) => {setStartDate(date)}}
              dateFormat="yyyy-MM-dd"
              className="datePicker"
              enableTabLoop={false}//클릭시 오른쪽 밀림 방지
            />

          <p>~</p>
          
          <DatePicker
            selected={endDate}
            onChange={(date) => {setEndDate(date)}}
            dateFormat="yyyy-MM-dd"
            className="datePicker"
            enableTabLoop={false}//클릭시 오른쪽 밀림 방지
          />

          <Button className='btnNextPrev' onClick={() => onMoveMonth(fnc.getFirstDayOfMonth(new Date(), 1), - monthInterval)}>
            <i className="fas fa-pause"></i>
          </Button>

          <Button className='btnNextPrev' onClick={() => onMoveMonth(startDate, monthInterval)}>
            <i className="fas fa-forward"></i>
          </Button>

          <Button className='btnRefresh' onClick={onRefresh} disabled={isButtonDisabled}> 
            <i className="fas fas fa-sync"></i> 
            새로고침
          </Button>

          </section>

      </div>
    );

    
}

export default LmsTecResultAttendByClass;