import './Exam3Marking.scss';
import fnc from '../mymod/commFunction';
import fncS3 from '../mymod/commFunction3';
import React, { useState, useEffect } from "react";
import HeaderTitle from '../comm/HeaderTitle';
import BookPdf_marking_menu from './Exam4Marking_menu';
import BookPdf_marking_omr from '../page_book/BookPdf_marking_omr';

import { useSelector, useDispatch } from 'react-redux';
import { setHeader_dev, initializeMarkingList, setList_que, setRowIdx_que, setIsRefresh_examMain } from '../reduxToolkit/redux';
import { Button } from '@mui/material';


const Exam3Marking = ({ setIsModal }) => {
   
    const dispatch = useDispatch();
    const imgUrl = process.env.REACT_APP_imgUrl_S3;//web/kuesbImageLms
    const headerInfo = useSelector(state => state.reduxStore.headerInfo);
    const list = useSelector(state => state.reduxStore.list_pdf_marking);
    // const list_pdf = useSelector(state => state.reduxStore.list_pdf);
    const rowIdx_pdf = useSelector(state => state.reduxStore.rowIdx_pdf);
    const markingList = useSelector(state => state.reduxStore.markingList);
    const isRefresh_examMain = useSelector(state => state.reduxStore.isRefresh_examMain);
    const userKind = useSelector(state => state.reduxStore.userInfo.kind);

    const [imagePath, setImagePath] = useState('');
    const [title, setTitle] = useState('');
    const [selectedListItem, setSelectedListItem] = useState({});
    const [record_que, setRecord_que] = useState([]);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    useEffect(() => {
      dispatch(setHeader_dev('Exam3Marking'));
    }, []);

    useEffect(() => {
        const records = getRecords_exam_detail_que(list);

        // console.log(records, '---------------- records');
        setRecord_que(records);
        dispatch(initializeMarkingList(records.length));
        setSelectedListItem(records[rowIdx_pdf]);
        dispatch(setList_que(records));       
     }, [list, dispatch]);


    function getRecords_exam_detail_que(userJsonObj) {
        let records = [];
        // const acaCode = fnc.getCookie('acaCode');
        const acaCode = fnc.getCookie('s3_acaCode');
        // console.log(acaCode, 's3_acaCode');
                
        for (let i = 0; i < userJsonObj.length; i++) {
            let source = userJsonObj[i]['source'];
            let testCode = userJsonObj[i]['testCode'];
            let testCodeMun = userJsonObj[i]['testCodeMun'];
            let folder = userJsonObj[i]['folder'].replaceAll('\\', '/');
            let commQue = userJsonObj[i]['commQue'];//공통지문
            let queNo = userJsonObj[i]['queNo'];//일련번호 문항코드
            let imgFileName = `${queNo}.gif`;
            let dap = userJsonObj[i]['dap'];
            let point = userJsonObj[i]['point'];
            let imgCol = userJsonObj[i]['imgCol'];
            let ImgSize = userJsonObj[i]['ImgSize'];
            let ImgSizeSol = userJsonObj[i]['ImgSizeSol'];
            let kind = userJsonObj[i]['kind']; // let kind = '객관식'

            // console.log( `${imgUrl}/${acaCode}/${folder}/${imgFileName}`,'aaaaaa');
            let imagePath = fncS3.getS3Path( `${imgUrl}/${acaCode}/${folder}/${imgFileName}`);
            let imagePath_sol = fncS3.getS3PathLong( `${imgUrl}/${acaCode}/${folder}/풀이_${imgFileName}`);
            let imagePath_comm = '';
            if (commQue) {
                imagePath_comm = fncS3.getS3PathLong( `${imgUrl}/${acaCode}/${folder}/${commQue}.gif`);
            } else {
                imagePath_comm ='';
            }
           
            // let imagePath = fncS3.getS3Path( `${imgUrl}/${folder}/${imgFileName}`);
            // let imagePath_sol = fncS3.getS3Path( `${imgUrl}/${folder}/풀이_${imgFileName}`);

            
            records.push({
                source, testCode, testCodeMun , imagePath, folder, imgFileName, queNo, dap, 
                imgCol, ImgSize, ImgSizeSol, imagePath_sol, imagePath_comm, commQue, kind, point
            });
        }
        return records;
    }

    function onSubmit() {
      let isMarkingComplete = fncIsMarkingComplete();
      if (!isMarkingComplete) {
          alert('마킹을 완료하세요!');
          return false;
      }
      let rtn = window.confirm('제출하시겠습니까?');
      if (!rtn) return false;
      setIsButtonDisabled(true);
      // fncChejum(); 사용안함(2번 중복: 확인하고 나중에 삭제할 것)
      fncChejum_point();

    }
    const fncIsMarkingComplete = () => {
      for (let key in markingList) {
          if (markingList[key] === '') {
              return false;
          }
      }
      return true;
  };
  
  function fncChejum() {
    // 사용안함
    let oxBar = null;
    let dapBar = null;
    let markingBar = null;
    let oCnt = 0;
    let xCnt = 0;
     
    let source, testCode, testCodeMun, dap, page, realNo, queNo, markingValue, OX, kind;
    for (let i = 0; i < record_que.length; i++) {
        source = record_que[i].source;
        testCode = record_que[i].testCode;
        testCodeMun = record_que[i].testCodeMun;
        dap = record_que[i].dap;
        page = record_que[i].page;
        realNo = record_que[i].realNo;
        queNo = record_que[i].queNo;
        kind = record_que[i].kind;
        markingValue = markingList[i + 1]; // Redux에서 마킹 값 가져오기

          
        if (dap == markingValue) {//== 두 개로 해야 함 (나중에 띄어쓰기 처리 해야 함)
            OX = 'O';
            oCnt++;
        } else {
            OX = 'X';
            xCnt++;
        }

        // console.log(dap, markingValue, OX);
        dapBar = dapBar ? dapBar + '|' + dap : dap;
        oxBar = oxBar ? oxBar + '|' + OX : OX;
        markingBar = markingBar ? markingBar + '|' + markingValue : markingValue;
    }

    const munCnt = oCnt + xCnt;
    const achievement_rate = (oCnt / munCnt) * 100;
    const truncatedTitle = headerInfo.title;
    const score = Math.round(achievement_rate * 10) / 10; // 소수 둘째 자리에서 반올림
    const msg = `\n${truncatedTitle} 학습\n맞음: ${oCnt}/${munCnt} 성취도: ${score}\n입니다`;


    fncInsert_exam_te_marking_DB({testCode, score, munCnt, oCnt, xCnt, dapBar, markingBar, oxBar})

    alert(msg);

  };

  function fncChejum_point() {
    let oxBar = null;
    let dapBar = null;
    let markingBar = null;
    let oCnt = 0; // 맞춘 문항 수
    let xCnt = 0; // 틀린 문항 수
    let totalPoints = 0; // 총 배점
    let obtainedPoints = 0; // 얻은 점수

    let source, testCode, testCodeMun, dap, page, realNo, queNo, markingValue, OX, kind, point;
    for (let i = 0; i < record_que.length; i++) {
        source = record_que[i].source;
        testCode = record_que[i].testCode;
        testCodeMun = record_que[i].testCodeMun;
        dap = record_que[i].dap;
        // point = record_que[i].point; // 각 문항의 배점
        point = record_que[i].point ? record_que[i].point : 1; //배점이 없는 경우(24-10-15)

        page = record_que[i].page;
        realNo = record_que[i].realNo;
        queNo = record_que[i].queNo;
        kind = record_que[i].kind;
        markingValue = markingList[i + 1]; // Redux에서 마킹 값 가져오기

        // 배점을 합산 (배점이 문자열일 수 있으므로 숫자로 변환)
        totalPoints += parseFloat(point);

        if (dap == markingValue) { // == 두 개로 해야 함 (나중에 띄어쓰기 처리 해야 함)
            OX = 'O';
            oCnt++;
            obtainedPoints += parseFloat(point); // 맞춘 문항의 배점을 합산
        } else {
            OX = 'X';
            xCnt++;
        }

        dapBar = dapBar ? dapBar + '|' + dap : dap;
        oxBar = oxBar ? oxBar + '|' + OX : OX;
        markingBar = markingBar ? markingBar + '|' + markingValue : markingValue;
    }

    const munCnt = oCnt + xCnt; // 총 문항 수

    // 성취도는 얻은 점수 / 총 배점으로 계산
    const achievement_rate = (obtainedPoints / totalPoints) * 100;
    const truncatedTitle = headerInfo.title;
    const score = Math.round(achievement_rate * 10) / 10; // 소수 둘째 자리에서 반올림

    const msg = `\n${truncatedTitle} 학습\n맞음: ${oCnt}/${munCnt} 성취도: ${score}\n입니다`;


    // DB에 마킹 결과와 점수 저장
    fncInsert_exam_te_marking_DB({testCode, score, munCnt, oCnt, xCnt, dapBar, markingBar, oxBar})

    // 결과를 사용자에게 알림
    alert(msg);
};


  function fncInsert_exam_te_marking_DB({testCode, score, munCnt, oCnt, xCnt, dapBar, markingBar, oxBar}) {    
      const sDate = fnc.fncDate();
      const acaCode = fnc.getCookie('acaCode');
      const stCode = fnc.getCookie('stCode');

      fnc.sendAxios('/insert/exam/te_marking_DB', {stCode, testCode, score, munCnt, oCnt, xCnt, dapBar, 
                        markingBar, oxBar, sDate }, (res) => {       
            if (res) {  
              if (res.success) {//res.success===true
                fncInsert_exam_te_marking_mun_DB({testCode, oxBar});
              }
                                     
            } else {
                console.log('te_marking_mun_st_pdf');
            }
      });

  }

  function fncInsert_exam_te_marking_mun_DB({testCode, oxBar}) {    
    const acaCode = fnc.getCookie('acaCode');
    const stCode = fnc.getCookie('stCode');

    fnc.sendAxios('/insert/exam/te_marking_mun_DB', {acaCode, stCode, testCode, oxBar }, (res) => {       
          if (res) {  
                    dispatch(setIsRefresh_examMain(!isRefresh_examMain));
                    setIsModal(false)
          } else {
              console.log('te_marking_mun_st_pdf');
          }
    });

}

    //========================================================================================
    return (
      <div className="Exam3Marking">
        {/* <HeaderTitle  title={title}  direction={1} onBackClick={() => setIsModal(false)} /> */}

      <Button className='btnClose-me' onClick={() => setIsModal(false)} >
        <i class="fa-solid fa-xmark"></i>
      </Button> 

        <div className="wrap-que">
          <section className="section-que">
            {record_que.map((item, index) => (
              <article className="article-que" key={index}>
                <span className="No">{index + 1}</span>
                <div className="box-imgQue">
                  <img
                    className={`imgQue ${item.imgCol}`}
                    src={item.imagePath}
                    alt={`${item.pdfFileName}`}
                  />
                </div>
                <p className="menu">
                  <BookPdf_marking_menu
                    list_que={record_que}
                    item={item}
                    no={index + 1}
                    markingListValue={markingList[index + 1]}
                  />
                </p>
              </article>
            ))}
            <div className="box-submit">
             {<Button className="btnSubmit" disabled={isButtonDisabled} onClick={onSubmit}>제출</Button> }
            </div>
          </section>

          <section className="section-omr">
            <BookPdf_marking_omr />
          </section>

       

        </div>
      </div>
    );
}

export default Exam3Marking;
