import './Combobox.scss';
import React, { useState, useRef, useEffect } from 'react';

const Combobox = ({ options, handleChange, initialComboValue }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(initialComboValue || (options.length ? options[0].value : ''));
  const comboboxRef = useRef(null); // 컴포넌트 전체를 위한 ref

  // 드롭다운 메뉴 외부 클릭 감지를 위한 이벤트 핸들러 설정
  useEffect(() => {

    console.log(options, '----------options');
    const handleClickOutside = (event) => {
      if (comboboxRef.current && !comboboxRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    // 문서 전체에 클릭 이벤트 리스너 추가
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  
  useEffect(() => {//이게 있어야 북마크가 된다.
    if (options && options.length > 0) {
      setSelectedValue(initialComboValue);
    }
  }, [options]);

  // useEffect(() => {//이게 있어야 북마크가 된다.
  //   if (options && options.length > 0) {
  //     setSelectedValue(initialComboValue);
      
  //   }
  // }, [options, initialComboValue]);

  // useEffect(() => {
  //   if (options && options.length > 0) {
  //     setSelectedValue(initialComboValue);
  //     const selectedOption = options.find(opt => opt.value === initialComboValue);
  //     if (selectedOption) {
  //       handleChange({ value: selectedOption.value, label: selectedOption.label, index: options.indexOf(selectedOption) });
  //     }
  //   }
  // }, [initialComboValue]);


  const handleSelectChange = (value, label, index) => { 
    if (value !== selectedValue) { // 선택된 값이 이전에 선택된 값과 같다면 함수 실행을 중단
      setSelectedValue(value);
      handleChange({ value, label, index });
    }
    setIsOpen(false); // 옵션 선택시 메뉴 닫기
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };


  useEffect(() => {//--------강사등록에서 추가한 건데 문제있으면 삭제하도록(일단 보류)
    // if (initialComboValue !== selectedValue) {
    //   setSelectedValue(initialComboValue);
    // }
  }, [initialComboValue]);

  //=====================================================
  return (
    <div className='Combobox' ref={comboboxRef}>

      <div className="custom-select">
        <div className="custom-select-trigger" onClick={toggleDropdown}>
          {options.find(opt => opt.value === selectedValue)?.label || ' '}
        </div>
        {isOpen && (
          <ul className="custom-options" >
            {options.map((opt, index) => (
              <li key={opt.value} 
                className={opt.value === selectedValue ? 'selected' : ''}
                onClick={() => handleSelectChange(opt.value, opt.label, index)}>
                {opt.label}
              </li>
            ))}
          </ul>
        )}
      </div>

    </div>
  );
};

export default Combobox;
