import React, { useEffect } from 'react';
import { Routes, BrowserRouter as Router, Route, useLocation  } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import './index.css';
import Footer from './Footer';
import Navbar from './Navbar'; // Navbar 컴포넌트 임포트
import './scss/font.scss';
import EvaluationTec from './autogenAca/EvaluationTec';
import GradeView from './autogenAca/GradeView';
import Login from './login/BasicLogin';
import SignUp from './login/SignUp';
import CreatPw from './login/CreatePw';
import UpdatePw from './login/UpdatePw';
import UpdateApp from './login/UpdateApp';
import Home from './page_main/Home';
import Book from './page_book/Book';
import Digital from './page_digital/Digital';
import Exam from './page_exam/Exam';
import CourseReg from './page_courseReg/CourseReg';
import CourseRegTec from './page_courseRegTec/CourseRegTec';
import BasicSetting from './page_basicSetting/BasicSetting';
import Lms from './page_lms/Lms';
import LmsTec from './page_lmsTec/LmsTec';

import Combobox from './comm/Combobox';
import DrawMenu from './drawSvg/DrawMenu';

import Download_newWindow from './page_lms/Download_newWindow';
import LmsTecResultApp from './page_lmsTec_result/LmsTecResultApp';

import { useSelector, useDispatch } from 'react-redux';
import {Container} from './scss/theme';
import { setVersion, setIsLoginPage } from './reduxToolkit/redux';


function App() {

  const dispatch = useDispatch();
  const location = useLocation(); // 현재 경로를 얻기 위해 useLocation 훅 사용
  
  window.AppVersion_ios = (version) => {
    if (version){
      dispatch(setVersion(version));
    }
  };

  
  window.AppVersion = (version) => {
    if (version){
      dispatch(setVersion(version));
    }
  };

  
  //-------------------------------------------------------------최신 업데이트
  useEffect(() => {//잘 안 되면 build 폴더를 지우고 추가해봐(앞으로 빼서 그런지 되긴 한다.)

    const currentVersion = localStorage.getItem('updateVersion');
    const newVersion = '1.0.25';

    if (currentVersion !== newVersion) {
      localStorage.setItem('updateVersion', newVersion); 
      window.location.reload(true); // 강제 새로고침, 사파리는 어떤지 확인 필요
    }
  }, []);

  useEffect(() => {
    // 현재 경로가 로그인 관련 경로인지 확인
    if (location.pathname === '/' || location.pathname === '/login') {
      dispatch(setIsLoginPage(true));
    } else {
      dispatch(setIsLoginPage(false));
    }
  }, [location.pathname, dispatch]);

    

  //==============================================
  return (
    <div className="App">
      <Navbar />
      <div className="box_routes">
        <Container>
            <Routes>

              <Route path="/evaluationTec" element={<EvaluationTec />} />
              <Route path="/gradeView" element={<GradeView />} />

              <Route path="/creatpw" element={<CreatPw />} />
              <Route path="/updatepw" element={<UpdatePw />} />
              <Route path="/updateapp" element={<UpdateApp />} />

              <Route path="/" element={<Login />} />
          
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<SignUp />} />

              <Route path="/main" element={<Home />} />
              <Route path="/digital" element={<Digital />} />
              <Route path="/book" element={<Book />} />
              <Route path="/exam" element={<Exam />} />
              <Route path="/courseRegTec" element={<CourseRegTec />} />
              <Route path="/courseReg" element={<CourseReg />} />
              <Route path="/BasicSetting" element={<BasicSetting />} />
              <Route path="/LmsTec" element={<LmsTec />} />
              <Route path="/Lms" element={<Lms />} />
              <Route path="/LmsTecResultApp" element={<LmsTecResultApp />} />

              <Route path="/combobox" element={<Combobox />} />
              <Route path="/drawMenu" element={<DrawMenu />} />

              <Route path="/downloadNewWindow" element={<Download_newWindow />} />
              

              {/* <Route path="/*" element={<Login />} /> */}
            </Routes>
            
        </Container>

   
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default App;
