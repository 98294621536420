import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const DialogComponent = ({ open, onClose, title, children }) => {
  return (
    <div className='DialogComponent'>
    <Dialog
      open={open}
      onClose={onClose}
      
      PaperProps={{
        style: {
          marginTop: '4rem', // 화면 상단으로부터의 마진을 줍니다.
          width: '90%',
          maxHeight: '80vh',
          border: '0px solid red',
          padding: '0rem',
          backgroundColor: '#fff'
        },

      }}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      {/* <DialogTitle id="dialog-title">{title}</DialogTitle> */}
      <DialogTitle id="dialog-title" 
          style={{ backgroundColor: '#1976d2', color: 'white', margin:'0px', 
          padding: '0.5rem', textAlign: 'center', fontSize:'1.1rem' }}>
          {title}
      </DialogTitle>

      <DialogContent>
        {/* white-space(엔터+공백제거) 적용 */}
        <div style={{ whiteSpace: 'pre-line', 
        color: '#333', 
        border: '0px solid blue',
        margin: '0px',
        marginTop:'1rem',
        backgroundColor: '#fff',
        width:'100%'
        }}>
          {children}
        </div>
      </DialogContent>
      <DialogActions>
        <Button  onClick={onClose}>닫기</Button>
      </DialogActions>
    </Dialog>

    </div>
  );
};

export default DialogComponent;
