import './UnitTable.scss';
import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { setIsBookStudy } from '../reduxToolkit/redux';

const UnitTable = ({options, handleChange}) => {


  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const dispatch = useDispatch();
  
  const handleRowClick = (index, option) => {

    handleChange(option);
    setSelectedRowIndex(index);
    dispatch(setIsBookStudy(false));//체크박스 유무(학생이 lms로 들어온 경우)
    
  };


  //-------------------------------------------
  return (//자식 컨포넌트
    <div className="UnitTable">
        <table className="table-hong table-unit">
            <thead>
                <tr>
                  <th>no</th>
                  <th>단원선택</th>
                </tr>
            </thead>

            <tbody>
                {options.map((option, index) => (
                    index > 0 && (//항목을 선택하세요 제외
                    <tr 
                      className={selectedRowIndex === index ? 'active': ''}
                      key={index} 
                      onClick={() => handleRowClick(index, option)}>
                      <td>{index}</td>
                      <td>{option.label}</td>
                    </tr>
                    )
                ))}
            </tbody>
      </table>
    </div>
  );
};

export default UnitTable;

//===================================================================
  
// StyledSelect 컴포넌트 생성
const StyledSelect = styled(Select)`
width: 100%;  // 원하는 너비 설정
text-align: left;
font-size: 0.9rem;
`;


const customStyles = {
    
}