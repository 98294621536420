import './HeaderTitle.scss';
import React, { useEffect } from "react";
import redux from '../reduxToolkit/redux';
import { useSelector } from 'react-redux';

const HeaderTitle = ({ onBackClick, title, direction, style}) => {//driection은 redux또는 props 두가지 사용가능

const headerInfo = useSelector((state) => state.reduxStore.headerInfo);
const header_dev = useSelector((state) => state.reduxStore.header_dev);
//=======================================================
  return (
    <div className="HeaderTitle" style={style}>
        <section className="section-title">
          {headerInfo.direction == 0 || direction == 0 && <article className="back" onClick={onBackClick}></article>}
          {title && <span>{title}</span> }
          {!title && <span>{headerInfo.title}</span> }
          {headerInfo.direction == 1 || direction == 1 &&  <article className="close" onClick={onBackClick} ></article> }
        </section>
        {/* <span className='header_dev'>{header_dev}</span> */}
    </div>
  );
};

export default HeaderTitle;

