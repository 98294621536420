import "./CourseRegTec.scss";
import * as S from "../scss/theme";
import HeaderTitle from "../comm/HeaderTitle";
import React, { useState, useEffect } from "react";
import Combobox from '../comm/Combobox';
import UnitTableTec from './CourseTableTec';
import CourseMainTec from './CourseMainTec';
import { useDispatch } from 'react-redux';
import { setHeaderInfo, setSelectedGangsaCode } from '../reduxToolkit/redux';
import { Button } from '@mui/material';
import fnc from "../mymod/commFunction";


const CourseRegTec = () => {
    const dispatch = useDispatch();
    const [options1, setOptions1] = useState([]);
    const [options2, setOptions2] = useState([]);
    const [optionsUniTable, setOptionsUniTable] = useState([]);
    const [selectedUnitItem, setSelectedUnitItem] = useState({});

    const [initialComboValue, setInitialComboValue] = useState();
    const acaCode = fnc.getCookie('acaCode');
    const userName = fnc.getCookie('userName');
    const gangsaCode = fnc.getCookie('stCode');

    useEffect(() => {
       
        // fncSelectGangsaCombo(acaCode);//일단 지우지 말 것 나중에 콤보로 선택 필요하면 사용하기 위해서

        fncSelectGrouCombo_main(gangsaCode);
        dispatch(setHeaderInfo({title: `수강반 명단`, direction: -1}));

      }, []);

      
    const fncSelectGrouCombo_main = (gangsaCode) => {
      fncSelectGroupCombo(acaCode, gangsaCode)
      setOptionsUniTable([]);//강좌 초기화
      dispatch(setSelectedGangsaCode(gangsaCode));
    };

   
    function fncSelectGangsaCombo(acaCode) {//사용 안 함 (강사 지정으로) 나중에 필요할 수도
        fnc.sendAxios("/select/class/gangsaList", { acaCode }, (res) => {
          if (res) {     
            setOptionsByIndex1(res); 
          } else {
            console.log(res, "속성이 obj 내에 없습니다.");
          }
        });
      }

      const handleComboboxChange1 = (selectedOption) => {//사용 안 함 (강사 지정으로)
        const gangsaCode = selectedOption.value;
        fncSelectGroupCombo(acaCode, gangsaCode)
        setOptionsUniTable([]);//강좌 초기화
        dispatch(setSelectedGangsaCode(gangsaCode));
    };

    

      const setOptionsByIndex1 = (data) => {
        const newOptions = [
          { value: "-1", label: " ", isDisabled: false },
        ].concat(
          data.map((item) => ({
            value: item.gangsaCode,
            label: item.name   
          }))
        );
    
        let selectedValue;
        setOptions1(newOptions);
        selectedValue = fnc.getCookie_pure("combo_selected_key1");
        setInitialComboValue(selectedValue);
      };

      //--------------------------------------------------------------2. groupList

      function fncSelectGroupCombo(acaCode, gangsaCode) {
        fnc.sendAxios("/select/class/groupList", { acaCode, gangsaCode }, (res) => {
          if (res) {     
            // console.log(res, 'groupList');
            setOptionsByIndex2(res);
          } else {
            console.log(res, "속성이 obj 내에 없습니다.");
          }
        });
      }

      
      const setOptionsByIndex2 = (data) => {
        const newOptions = [
          { value: "-1", label: " ", isDisabled: false },
        ].concat(
          data.map((item) => ({
            value: item.groupCode,
            label: item.groupName   
          }))
        );
    
        let selectedValue;
        setOptions2(newOptions);
        selectedValue = fnc.getCookie_pure("combo_selected_key2");
        setInitialComboValue(selectedValue);
      };
    
    
  
    const handleComboboxChange2 = (selectedOption) => {
      const groupCode = selectedOption.value;
      fncSelectClassTable(acaCode, groupCode)

  };

  
//-------------------------------------------------3. classList
function fncSelectClassTable(acaCode, groupCode) {
  fnc.sendAxios("/select/class/classList_gangsa", { acaCode, groupCode, gangsaCode }, (res) => {
    if (res) {     
      setOptionsUniTable(res); 
  
    } else {
      console.log(res, "속성이 obj 내에 없습니다.");
    }
  });
}


const handleUnitTable = (selectedOption) => {//단원선택 rowclick
  const value = selectedOption.classCode;
  const label = selectedOption.className;


  console.log(selectedOption, ' ----------option');

  setSelectedUnitItem(selectedOption);

};


      // const options = [
      //     { value: '', label: '항목을 선택하세요.' },
      //     { value: 'chocolate', label: 'Chocolate1' },
      //   ];

  //===========================================================
  return (
    <>
      <S.Section>
        <HeaderTitle />
        <div className="CourseRegTec">
          <div className="wrap-book">
            <section className="section-cmb">
             { false && <article className="article-cmb">
                <Combobox
                  options={options1}
                  initialComboValue={initialComboValue}
                  handleChange={handleComboboxChange1}
                />
              </article> }

              <article className="article-userName">
                <div>{userName} 선생님</div>
              </article>

              <article className="article-cmb">
                <Combobox
                  options={options2}
                  initialComboValue={initialComboValue}
                  handleChange={handleComboboxChange2}
                />
              </article>
              

            </section>

            <section className="section-unitTable">
              <UnitTableTec
                options={optionsUniTable}
                handleChange={handleUnitTable}
              />
            </section>

          </div>

          <section className="section-bookMain">
              {selectedUnitItem && <CourseMainTec selectedItem={selectedUnitItem} />}
          </section>

        </div>
      </S.Section>
    </>
  );
};

export default CourseRegTec;
