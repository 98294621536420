import './UpdateApp.scss';
import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import * as S from '../scss/theme';
import UpdatePw from './UpdatePw';
import UpdateInfo from './UpdateInfo';


const UpdateApp = () => {

    return(

        <S.Section2>
        <div className='UpdateApp'>
            <div className='wrap-main'>

        
            <section className='section-tap1'>
                <Tabs>
                    <TabList className='tabList1'>
                        <Tab>비밀번호 변경</Tab>
                        <Tab>전화번호 변경</Tab>
                    </TabList>

                    <TabPanel>
                        <UpdatePw />
                    </TabPanel>

                    <TabPanel>
                        <UpdateInfo />
                    </TabPanel>
                </Tabs>
            </section>
            </div>
        </div>
        </S.Section2>
    );
}

export default UpdateApp;
