import './ClassStList.scss';
import * as S from '../scss/theme';
import React, { useState, useEffect } from "react";
import fnc from '../mymod/commFunction';
import Button from '@mui/material/Button';

import { useDispatch, useSelector } from 'react-redux';
import { setLessonDate, setIsModal, setLmsListCode, setSelectedItemSt_result } from '../reduxToolkit/redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import HeaderTitle from '../comm/HeaderTitle';

const ClassStList = ({setIsModal_classSt, setSelectedItemSt}) => {

    const dispatch = useDispatch();
    const isModal = useSelector((state) => state.reduxStore.isModal);
    const classCode = useSelector((state) => state.reduxStore.classCode);
    const isRefresh_lmsTecList = useSelector((state) => state.reduxStore.isRefresh_lmsTecList);

    const [list, setList] = useState([]);

    useEffect(() => {
      fncSelect_list();
    }, [classCode, isRefresh_lmsTecList]);


  
    
  function fncSelect_list() {
    const acaCode = fnc.getCookie('acaCode');
    const stCode = fnc.getCookie('stCode');
    
    fnc.sendAxios('/select/lmsTecResult/classStList', 
          { acaCode, classCode }, (res) => {
      if (res && Array.isArray(res)) { 
        // console.log(res, 'res');
        setList(getRecords(res));

      } else {
        console.log(res,"select/book/pdfList");
      }
    });
  }

  
  function getRecords(res) {
    let records = [];
    for (let i = 0; i < res.length; i++) {      
        const sno= res[i]["sno"];
        const stCode = res[i]["stCode"];
        const stName = res[i]["stName"];
        let hp = res[i]["hp"];
        hp=fnc.formatPhoneNumber(hp);
        const classCode = res[i]["classCode"];
        const className = res[i]["className"];
        records.push({ sno, stCode, stName, hp, classCode, className });
    }
    return records;
  }

  function onClickTr(item) {
    // console.log(item);
    // setSelectedItemSt(item);
    dispatch(setSelectedItemSt_result(item));
    onclose();
  }

  function onclose() {
    setIsModal_classSt(false);
  }

    //=========================================
    return (

      <div className="ClassStList">
   
        <div className="wrap-main">

          <HeaderTitle onBackClick={onclose} direction={1}/>
          
          <section className="section-main">
            <table className="table-hong table-main">
              <thead>
                <tr>
                  <th>{list.length}</th>
                  <th>성명</th>
                  <th>HP</th>
                </tr>
              </thead>

              <tbody>
                  {list && list.map((item, index) => (
  
                      <tr key={item.sno} onClick={() => onClickTr(item)}>  
                        <td>{index+1}</td>
                        <td>{item.stName}</td>
                        <td>{item.hp}</td>
                      </tr>
                  ))}
            
                </tbody>
            </table>

           

          </section>
      
       

        </div>

      

      </div>
    );
}

export default ClassStList;