import './CourseMainTec.scss';
import * as S from '../scss/theme';
import React, { useState, useEffect } from "react";
import fnc from '../mymod/commFunction';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';

import { useDispatch, useSelector } from 'react-redux';
import { setList_pdf, setHeaderInfo,setIsRefresh_studentCourseList, setIsWaitStSearch } from '../reduxToolkit/redux';


const CourseMainTec = ({selectedItem, handleChange}) => {

    const imgUrl = process.env.REACT_APP_imgUrl;
    const dispatch = useDispatch();
    const isRefresh_studentCourseList = useSelector(state => state.reduxStore.isRefresh_studentCourseList);
    const selectedGangsaCode = useSelector(state => state.reduxStore.selectedGangsaCode);
    const isWaitStSearch = useSelector(state => state.reduxStore.isWaitStSearch);
    const [list, setList] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    // const [isWaitStSearch, setIsWaitStSearch] = useState(false);
    // console.log(selectedGangsaCode, '  -------  selectedGangsaCode');


    useEffect(() => {
      if(!isWaitStSearch){
        fncSelect_studentCourseList(selectedItem);
      }else {
        fncSelect_studentCourseList_wait();
      }
     
    }, [selectedItem, isRefresh_studentCourseList, isWaitStSearch]);

    
  function fncSelect_studentCourseList(selectedItem) {
    const acaCode = fnc.getCookie('acaCode');
    const stCode = fnc.getCookie('stCode');
    const classCode = selectedItem.classCode;
    
    fnc.sendAxios('/select/class/studentCourseListTec', { acaCode, classCode }, (res) => {
      if (res && Array.isArray(res)) { //단원선택 클릭 시 결과 (오른쪽 페이지 list)

        setList(res);
        setSelectAll(false);//체크박스 해제
        setSelectedItems([]);//체크박스 해제
        const listPdf = getRecords_studentCourseList(res);
        dispatch(setList_pdf(listPdf));
      } else {
        console.log(res,"select/book/pdfList");
      }
    });
  }


  
  function fncSelect_studentCourseList_wait() {//대기학생 검색
    const acaCode = fnc.getCookie('acaCode');
    const stCode = fnc.getCookie('stCode');
    const gangsaCode = selectedGangsaCode;
    
    fnc.sendAxios('/select/class/studentCourseListTec_wait', { acaCode, gangsaCode }, (res) => {
      if (res && Array.isArray(res)) { //단원선택 클릭 시 결과 (오른쪽 페이지 list)
        setList(res);
        setSelectAll(false);//체크박스 해제
        setSelectedItems([]);//체크박스 해제
        const listPdf = getRecords_studentCourseList(res);
        dispatch(setList_pdf(listPdf));
      } else {
        console.log(res,"select/class/studentCourseListTec_wait");
      }
    });
  }
  
  function getRecords_studentCourseList(res) {
    let records = [];
    for (let i = 0; i < res.length; i++) {      
        const pdfFileName= res[i]["pdfFileName"];
        const source = res[i]["source"];
        const page = res[i]["page"];//BookPdf에서는 page, source를 사용한다.

        const testCode = source;
        const munCode = pdfFileName;
        const no = page;
        let rnd = Math.floor(Math.random() * 900) + 100;
        let imagePath = `${imgUrl}/pdf/${testCode}/${pdfFileName}?${rnd}`;

        records.push({ imagePath, testCode, munCode, no, source, page, pdfFileName });
    }
    return records;
  }


  
  function onClickTr( item, index) {
   
  }

  function handleSelectAll() {
    if (selectAll) {
      setSelectedItems([]);
    } else {
      setSelectedItems(list.map((item, index) => index));
    }
    setSelectAll(!selectAll);
  }

  function handleSelectItem(index) {
    const isSelected = selectedItems.includes(index);
    if (isSelected) {
      setSelectedItems(selectedItems.filter(item => item !== index));
    } else {
      setSelectedItems([...selectedItems, index]);
    }
  }

  function handleDeleteSelected() {
    const selectedListItems = list.filter((item, index) => selectedItems.includes(index));

    if(selectedListItems.length === 0){
      alert('대상을 선택하세요!');
      return null;
    }
    const rtn = window.confirm(selectedListItems.length +'개의 강좌를 취소하시겠습니까?');
    if(!rtn) return null;

    for (let i = 0; i < selectedListItems.length; i++) {
      const sno = selectedListItems[i].sno;
      let isLast = false;
      if(i === selectedListItems.length-1) {
        isLast = true;
      }
      fncDelete_student_course(sno, isLast);
    }
  }

  function fncDelete_student_course(sno, isLast) {
    const acaCode = fnc.getCookie('acaCode');
    const stCode = fnc.getCookie('stCode');
    
    fnc.sendAxios('/delete/class/student_course', { acaCode, sno }, (res) => {
      if (res) { 
        if (isLast) {
          dispatch(setIsRefresh_studentCourseList(!isRefresh_studentCourseList));   
        }
      } else {
        console.log(res,"select/book/pdfList");
      }
    });
  }


  //-----------------------------------------------------수정(대기, 수강)
  function handleUpdateStatusSelected(statusValue) {
    const selectedListItems = list.filter((item, index) => selectedItems.includes(index));
    
    const statusName = statusValue == 'Y' ? '수강': '대기';
   if(selectedListItems.length === 0){
      alert('대상을 선택하세요!');
      return null;
    }
    const rtn = window.confirm(`${selectedListItems.length}개의 대상을 수정(${statusName})하시겠습니까?`);
    if(!rtn) return null;

    for (let i = 0; i < selectedListItems.length; i++) {
      const sno = selectedListItems[i].sno;
      let isLast = false;
      if(i === selectedListItems.length-1) {
        isLast = true;
      }
      fncUpdate_student_course_status(sno, isLast, statusValue);
    }
  }


  function fncUpdate_student_course_status(sno, isLast, statusValue) {
    const acaCode = fnc.getCookie('acaCode');
    const stCode = fnc.getCookie('stCode');
    // const statusValue = 'Y';
    fnc.sendAxios('/update/class/studentCourse_status', { acaCode, sno, statusValue }, (res) => {
      if (res) { 
        if (isLast) {
          dispatch(setIsRefresh_studentCourseList(!isRefresh_studentCourseList)); 
        }
      } else {
        console.log(res,"update/class/studentCourse_status");
      }
    });
  }

  //---------------------강사코드별 대기학생만 검색

  function handleSearchSelected() {

    fncSelect_studentCourseList_wait();
    dispatch(setIsWaitStSearch(true));

  }
  
    //=========================================
    return (
      //자식 컨포넌트
      <div className="CourseMainTec">
        <div className="wrap-course-main">
          <section className="section-book-main">
            <table className="table-hong table-course-main">
              <thead>
                <tr>
                  <th>No</th>
                  {/* <th>과정</th> */}
                  <th>강좌</th>
                  <th>성명</th>
                  <th>휴대전화</th>
                  <th>수강일</th>
                  <th>상태</th>
                  <th>
                   <Checkbox checked={selectAll} onChange={handleSelectAll} />
                  </th>
                
              
                </tr>
              </thead>

              <tbody>
                  {list && list.map((item, index) => (
  
                      <tr key={index} onClick={() => onClickTr(item, index)}>
                       
                       <td>{index+1}</td>
                        {/* <td>{item.groupName}</td> */}
                        <td>{item.className}</td>
                        <td>{item.stName}</td>
                        <td>{fnc.formatPhoneNumber(item.hp)}</td>
                        <td>{item.sDate}</td>
                        <td className={item.status === 'Y' ? 'attending' : 'waiting'}>
                          {item.status == 'Y' ? '수강' : '대기'}
                        </td>
                        <td>
                          <Checkbox checked={selectedItems.includes(index)} onChange={() => handleSelectItem(index)} />
                        </td>
                      </tr>
                  ))}
            
                </tbody>

            </table>
          </section>
        </div>
        
        <div className='box-menu'>
          <Button className='btnSearch' onClick={handleSearchSelected}>강사별 대기학생 전체 검색</Button> 
          <Button className='btnDelete' onClick={handleDeleteSelected}>삭제</Button>   
          <Button className='btnUpdateN' onClick={() => handleUpdateStatusSelected('N')}>대기</Button> 
          <Button className='btnUpdateY' onClick={() => handleUpdateStatusSelected('Y')}>수강</Button>   
        </div>
                
      </div>
    );
}

export default CourseMainTec;