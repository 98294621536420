import { createSlice } from "@reduxjs/toolkit";
import axios from 'axios';

const svrUrl = process.env.REACT_APP_SERVER_URL;

export const reduxSlice = createSlice({
    name: 'name_reduxSlice',//변경해도 의미 없네 (내부적으로 식별 용도인 듯)
    initialState: {
        value1: 1,
        list: [],
        selectedList: {},
        boardTitle:{},
        boardKind: '',
        initialPage: 0,
        isFileListSelect: false,
        isLoggedIn: false,
        userInfo: {},
        isRefreshMun: false,
        testNm: null,

    },
    
    reducers: {

        setList: (state, action) => {
            state.list = action.payload;
        },

        setSelectedList: (state, action) => {
            state.selectedList = action.payload;
        },
      
        setBoardTitle: (state, action) => {
            state.boardTitle = action.payload;
        },
        setBoardKind: (state, action) => {
            state.boardKind = action.payload;
        },
        setInitialPage: (state, action) => {
            state.initialPage = action.payload;
        },
        setIsFileListSelect: (state, action) => {
            state.isFileListSelect = action.payload;
        },
        setIsLoggedIn: (state, action) => {
            state.isLoggedIn = action.payload;
        },
        setUserInfo: (state, action) => {
            state.userInfo = action.payload;
        },
        setIsRefreshMun: (state, action) => {
            state.isRefreshMun = action.payload;
        },

        setTestNm: (state, action) => {
            state.testNm = action.payload;
        },

        listUpdate: (state, action) => {
            const index = state.list.findIndex(item => item.sno === action.payload.sno);
            if (index !== -1) {
              state.list[index] = {...state.list[index], ...action.payload};
            }
        },

    }


});



export const { 
    increment, 
    setList, setList2,
    setBoardTitle, 
    setBoardKind,
    setSelectedList,
    setInitialPage,
    setIsFileListSelect,
    setIsLoggedIn,
    setUserInfo,
    setIsRefreshMun,
    listUpdate,
    setTestNm,
} = reduxSlice.actions;

export default reduxSlice.reducer;

