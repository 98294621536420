import './TermsAgreement.scss';
import React, { useState, useEffect } from 'react';

import {Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import DialogComponent from './DialogComponent'; 


import customIcon from '../images/hong/check-mark-gray.png';
import customCheckedIcon from '../images/hong/check-mark-green.png';


const TermsAgreement = ({
  agreedTerms,
  agreedPrivacy,
  onAgreeTermsChange,
  onAgreePrivacyChange, 
}) => {


  const [openTermsDialog, setOpenTermsDialog] = useState(false);
  const [openPrivacyDialog, setOpenPrivacyDialog] = useState(false);
  const [termsContent, setTermsContent] = useState('');
  const [privacyContent, setPrivacyContent] = useState('');
  
  useEffect(() => {
    // 이용약관 내용 로드
    fetch('/terms-of-service.txt')
      .then(response => response.text())
      .then(text => setTermsContent(text))
      .catch(error => console.error('Error loading the terms of service:', error));

    // 개인정보 수집 약관 내용 로드
    fetch('/privacy-policy.txt')
      .then(response => response.text())
      .then(text => setPrivacyContent(text))
      .catch(error => console.error('Error loading the privacy policy:', error));
  }, []);

  const handleOpenTermsDialog = () => {
    setOpenTermsDialog(true);
  };

  const handleOpenPrivacyDialog = () => {
    setOpenPrivacyDialog(true);
  };

  const handleCloseTermsDialog = () => {
    setOpenTermsDialog(false);
  };

  const handleClosePrivacyDialog = () => {
    setOpenPrivacyDialog(false);
  };

//============================================================
  return (
    <div className='TermsAgreement'>
      <div className='inner-termsAgreement'>

      
      {/* <p>동의 항목</p> */}
      <div className='box_chk'>
      
      <FormControlLabel
       className="formControlLabel" // CSS 클래스 적용
        control={
          <Checkbox
          className='chk01'
            checked={agreedTerms && agreedPrivacy}
            onChange={(e) => {
              onAgreeTermsChange(e.target.checked);
              onAgreePrivacyChange(e.target.checked);
            }}
            icon={<img src={customIcon} alt="Unchecked Icon" />}
            checkedIcon={<img src={customCheckedIcon} alt="Checked Icon" />}
          />
          }
          label={
            <Typography className='item01' variant="body1" component="span" sx={{ fontWeight: 'bold'}}>
              이용약관, 개인정보 수집 및 이용에 모두 동의합니다.
            </Typography>
          }
        />
      </div>

      <div className='box_chk'>
        <FormControlLabel
         control={
          <Checkbox
            checked={agreedTerms}
            onChange={(e) => onAgreeTermsChange(e.target.checked)}
            icon={<img src={customIcon} alt="Unchecked Icon" />}
            checkedIcon={<img src={customCheckedIcon} alt="Checked Icon" />}
          />
          }
          label="이용약관 동의 (필수)"
        />
        <Button onClick={handleOpenTermsDialog} sx={{ ml: 'auto', mt: '10px' }}>약관 보기</Button>
      </div>
      
      <div className='box_chk'>
        <FormControlLabel
        control={
         <Checkbox
           checked={agreedPrivacy}
           onChange={(e) => onAgreePrivacyChange(e.target.checked)}
           icon={<img src={customIcon} alt="Unchecked Icon" />}
            checkedIcon={<img src={customCheckedIcon} alt="Checked Icon" />}
         />
         }
          label="개인정보 수집 동의 (필수)"
        />
   
        <Button onClick={handleOpenPrivacyDialog} sx={{ ml: 'auto', mt: '10px' }}>약관 보기</Button>
      </div>
  
      {/* 이용약관 대화상자 */}
      <DialogComponent
        open={openTermsDialog}
        onClose={handleCloseTermsDialog}
        title="이용약관"
      >
        {termsContent}
      </DialogComponent>
      
      {/* 개인정보 수집 약관 대화상자 */}
      <DialogComponent
        open={openPrivacyDialog}
        onClose={handleClosePrivacyDialog}
        title="개인정보 수집 약관"
      >
        {privacyContent}
      </DialogComponent>
      </div>
    </div>
  );
};

export default TermsAgreement;
