import './Exam_gradeCut.scss';
import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import fnc from '../mymod/commFunction';

const Exam_gradeCut = ({testCodeMun}) => {
       
    const [list, setList] = useState([]);
   
    useEffect(() => {
      fncSelectList();
     
    }, [testCodeMun]);

    
  
  function fncSelectList() {
     
    fnc.sendAxios('/select/exam/examList/gradeCut', {testCodeMun}, (res) => {
      if (res && Array.isArray(res)) { //단원선택 클릭 시 결과 (오른쪽 페이지 list)

        const newList = getRecords_list(res);
        setList(newList);

      } else {
        console.log(res,"select/exam/examList/gradeCut");
      }
    });
  }
  

  
  function getRecords_list(res) {
    let records = [];
    for (let i = 0; i < res.length; i++) {      
        const testCode= res[i]["testCode"];
        const difficulty = res[i]["difficulty"];
        const cutLine = res[i]["cutLine"];
        const no = res[i]["no"];
        const maxScore = res[i]["maxScore"];
        const minScore = res[i]["minScore"];
        const content = res[i]["content"];

        records.push({ testCode, difficulty, cutLine, no, maxScore, minScore, content });
    }
    return records;
  }



    //=========================================
    return (
      //자식 컨포넌트
      <div className="Exam_gradeCut">
        <div className="wrap-book-main">
          <section className="section-book-main">

           

            <table className="table-hong table-book-main">
              <thead>
                <tr>
                  <th>등급</th>
                  <th>점수</th>               
                </tr>
              </thead>

              <tbody>

               { list.map((item) =>  {

                  return(
                      <tr key={item.testCode }>
                        <td>{item.content }</td>
                        <td>{item.maxScore}점 ~ {item.minScore}점</td>
                        {/* <td>{item.cutLine }</td> */}
                      
                      </tr>
                  );

                })}
                
              </tbody>

            </table>

            {list.length > 0 && 
            <article className='article-cutLine'>
              <p>난이도: {list[0].difficulty} </p>
              <p>커트라인: {list[0].cutLine} </p>
            </article>}
            
          </section>




        </div>
     
      </div>
    );
}

export default Exam_gradeCut;