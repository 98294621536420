import './UpdatePw.scss';
// import * as S from '../scss/theme.js';
import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import TermsAgreement from './TermsAgreement'; // 이용약관 컴포넌트 임포트
import fnc from '../mymod/commFunction';
import * as S from '../scss/theme';

function UpdatePw() {
  const svrUrl = process.env.REACT_APP_SERVER_URL;
  const navigate = useNavigate();

  const id = fnc.getCookie('id');
  const stCode = fnc.getCookie('stCode');
  const userName = fnc.getCookie('userName');


  const [userInput, setUserInput] = useState({
    currPassword: '',
    password: '',
    confirmPassword: '',
  });


  // 오류 메시지를 관리할 상태 추가
  const [errorMessages, setErrorMessages] = useState({});
  const [isCheckingUserid, setIsCheckingUserid] = useState(false);
  const [useridAvailable, setUseridAvailable] = useState(true);

  const [agreedTerms, setAgreedTerms] = useState(false);
  const [agreedPrivacy, setAgreedPrivacy] = useState(false);

  // 입력 필드가 변경될 때마다 호출될 함수
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInput({
      ...userInput,
      [name]: value,
    });
  };

  // 이용약관 및 개인정보 수집 동의 핸들러
  const onAgreeTermsChange = (isAgreed) => {
    setAgreedTerms(isAgreed);
  };

  const onAgreePrivacyChange = (isAgreed) => {
    setAgreedPrivacy(isAgreed);
  };
  // 유효성 검사 함수(아래 빨간 텍스트 나오는 경고)
  const validateForm = () => {
    let errors = {};
    let formIsValid = true;
   
    // 새 비밀번호 검사
    if (!userInput.password) {
      errors.password = '새 비밀번호를 입력해 주세요.';
      formIsValid = false;
    } else if (userInput.password.length < 6) {
      errors.password = '새 비밀번호는 최소 6자 이상이어야 합니다.';
      formIsValid = false;
    }

    // 비밀번호 확인 검사
    if (userInput.password !== userInput.confirmPassword) {
      errors.confirmPassword = '새 비밀번호가 서로 일치하지 않습니다.';
      formIsValid = false;
    }


    setErrorMessages(errors);
    return formIsValid;
  };

  // 폼 제출 시 호출될 함수
  const handleSubmit = (e) => {
    e.preventDefault(); // 폼 제출 기본 이벤트 방지

    if (validateForm()) {

      // if(!isCheckingUserid) {
      //   alert('아이디 중복확인을 먼저하세요');
      //   return;
      // }

      // if(!useridAvailable) {
      //   alert('중복된 아이디입니다.');
      //   return;
      // }

      // if (!agreedTerms) {
      //   alert('이용약관에 동의해야 회원가입을 진행할 수 있습니다.');
      //   return;
      // }

      // if (!agreedPrivacy) {
      //   alert('개인정보 수집에 동의해야 회원가입을 진행할 수 있습니다.');
      //   return;
      // }

      const rtn = window.confirm('비밀번호를 수정하시겠습니까?');
      if(rtn) {
     
          fncBasicLogin(id, stCode, userInput.currPassword, userInput.confirmPassword);
      }
   
    } else {
      console.log('Validation Failed:', errorMessages);
      // 유효성 검사 실패 처리 로직 추가 (예: 오류 메시지 표시)
    }
  };


  function onClose() {
    navigate(-1); 
    // navigate('/login'); 
  }
//---------------------------------------------------axios
  
function fncBasicLogin(id, stCode, pw, newPw) {
  fnc.sendAxios('/kuesbLogin', { id, pw }, (res) => {
    if (res.length > 0) {
      fncUpdatePw(stCode, newPw);
    } else {
      alert('정보가 올바르지 않습니다.')

    }
  });
}


function fncUpdatePw(stCode, newPw) {   

    fnc.sendAxios('/kuesb/updatePw', {stCode, pw: newPw }, (res) => {
      if (res) {      

        if (res.success === true) {
          // fncInsertUserAgreements(stCode);
          alert('비밀번호 수정이 완료되었습니다. \n 로그인 하세요!');
          navigate('/login'); 
        }else if (res.success === true) {
            alert('실패했습니다!');

        }
       
      } else {
        alert('가입에 실패했습니다.');
        console.log("속성이 obj 내에 없습니다.");
      }
    });
  }


  
function fncInsertUserAgreements(stCode) {   
    fnc.sendAxios('/aca/insertUserAgreements', {stCode}, (res) => {
      if (res) {      
     
      } else {
        console.log("속성이 obj 내에 없습니다.");
      }
    });
  }



//==================================================
  return (
    <S.Section_n>
    <div className='UpdatePw'>
      <div className='wrap-main'>

    
      <form onSubmit={handleSubmit}>
      <p className='title'>비밀번호 변경</p>
      <div className='box_signUp'>           
          <div className='inner_singUp'>           
               {/* 성명 */}
                <div>
                <label>*성명</label>
                <p className='userName'>{userName}</p>
                </div>
                
                 {/* 현재 비밀번호 입력 필드 */}
                 <div>
                  <label htmlFor="currPassword">*현재 비밀번호</label>
                  <input
                    type="password"
                    id="currPassword"
                    name="currPassword"
                    value={userInput.currPassword}
                    onChange={handleChange}
                    required
                    placeholder='현재 비밀번호를 입력해 주세요.'
                    
                  />
                  {errorMessages.currPassword && <p className="errorMessage">{errorMessages.currPassword}</p>}
                </div>

              
                {/* 새 비밀번호 입력 필드 */}
                <div>
                  <label htmlFor="password">*새 비밀번호</label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    value={userInput.password}
                    onChange={handleChange}
                    required
                    placeholder='새 비밀번호를 입력해 주세요.'
                    
                  />
                  {errorMessages.password && <p className="errorMessage">{errorMessages.password}</p>}
                </div>
                {/* 비밀번호 확인 입력 필드 */}
                <div>
                  <input
                    type="password"
                    id="confirmPassword"
                    name="confirmPassword"
                    value={userInput.confirmPassword}
                    onChange={handleChange}
                    required
                    placeholder='새 비밀번호를 한번 더 입력해 주세요.'
                  />
                  {errorMessages.confirmPassword && <p className="errorMessage"> {errorMessages.confirmPassword}</p>}
                </div>
              </div>

              
            {/* 이용약관 컴포넌트 추가  일단 수집 안 하는 걸로*/}
         { false &&   <div className='box_termsAgreement'> 
            <TermsAgreement 
                agreedTerms={agreedTerms}
                agreedPrivacy={agreedPrivacy}
                onAgreeTermsChange={onAgreeTermsChange}
                onAgreePrivacyChange={onAgreePrivacyChange}
              />
            </div> }

            {/* 메뉴버튼 */}
            <div className='box_menu'>
                <Button  className='btnClose h-btn-gray' onClick={onClose} >취소</Button>
                <Button type='submit' className='btnSave h-btn-red' >확인</Button>
            </div>


            </div>



    </form>
    </div>
    </div>
    </S.Section_n>
  );
}

export default UpdatePw;
