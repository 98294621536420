import './Detail_test.scss';
import * as S from '../scss/theme';
import React, { useState, useEffect } from "react";
import fnc from '../mymod/commFunction';
import Button from '@mui/material/Button';
import Combobox from '../comm/Combobox_noBMK';
import Select from 'react-select';
import HeaderTitle from "../comm/HeaderTitle";
import { useDispatch, useSelector } from 'react-redux';
import { setIsModalTestLms, setSelectedBookCode, setBookTestKind, 
  setSelectedBookName,setSelectedBookDetailCode, setIsBookStudy } from '../reduxToolkit/redux';
import Exam2Main from '../page_exam/Exam2Main';
import BookMain from '../page_book/BookMain';
import { Value } from 'sass';

const Detail_test = ({}) => {


    const dispatch = useDispatch();
   
    const classCode = useSelector(state => state.reduxStore.classCode);
    const lessonDate = useSelector(state => state.reduxStore.lessonDate);
    // const isModalTest = useSelector(state => state.reduxStore.isModalTest);
    

    const [list, setList] = useState([]);
    const [selectedIdx, setSelectedIdx] = useState(0);
    const [isRefresh, setIsRefresh] = useState(false);
    const [selectedUnitItem, setSelectedUnitItem] = useState({});
    const [isModalTest, setIsModalTest] = useState(false);
    const [bookName, setBookName] = useState(null);
   
    useEffect(() => {
      fncSelect_list();
    }, [isRefresh]);

    
  function fncSelect_list() {
    const acaCode = fnc.getCookie('acaCode');
    const stCode = fnc.getCookie('stCode');
    
    fnc.sendAxios('/select/lms/course/studyTest', { acaCode, classCode, lessonDate }, (res) => {
      if (res && Array.isArray(res)) { 
        setList(getRecords(res));
        // console.log(res, '----------- res');
      } else {
        console.log(res,"select/lms/course/studyTest");
      }
    });
  }

  
  function getRecords(res) {
    let records = [];
    let kind = null;
    for (let i = 0; i < res.length; i++) {      
        const bookCode= res[i]["bookCode"];
        const bookName = res[i]["bookName"];
        const classCode = res[i]["classCode"];
        const className = res[i]["className"];
        const code = res[i]["code"];
        const kindOrgin = res[i]["kind"];
        const kind2 = res[i]["kind2"];
        const sno = res[i]["sno"];

        console.log(kindOrgin, 'kindOrgin');
        if (kindOrgin === '출판물') {
          kind = '학습';
      } else if (kindOrgin === 'TEST') {
          kind = '시험';

      } else if (kindOrgin === '디지털') {
          kind = '디지털';
      } else {
          kind = '';
      }

      const detailCode = res[i]["detailCode"];
      const detailName = res[i]["detailName"];
      const detailCnt = res[i]["detailCnt"];
             
        records.push({ bookCode, bookName, classCode, className, code, kindOrgin, kind, kind2, sno, detailCode, detailName, detailCnt });
    }
    return records;
  }

  
  function onClickTr( item, index) {
    setSelectedIdx(index);//값넣기는 일단 실패
    // setIsModalTest(true);
   
  }

  function onComplete(item) {
    let rtn = window.confirm(`${item.kindOrgin}을 학습하시겠습니까? \n(${item.bookName})`);
    if (!rtn) return;
// console.log(item, 'item.kindOrgin');
    dispatch(setIsModalTestLms(true));
    dispatch(setSelectedBookCode(item.bookCode));
    dispatch(setBookTestKind(item.kindOrgin));
    dispatch(setSelectedBookName(item.bookName));
    dispatch(setSelectedBookDetailCode(JSON.parse(item.detailCode)));//배열로 변환해서 전달해야 함
    dispatch(setIsBookStudy(true));
  
  }

  
  
    //=================================================
    return (
      <div className="Detail_test">
        <div className="title">학습 및 진단평가</div>
        <div className="wrap-main">
          <section className="section-main">
            <table className="table-hong table-main">
              <thead>
                <tr>
                  <th>항목</th>
                  <th>단원</th>
                  <th>개수</th>
                  <th>구분</th>
                  <th>학습</th>
                </tr>
              </thead>

              <tbody>
                {list &&
                  list.map((item, index) => (
                    <tr key={index} onClick={() => onClickTr(item, index)}>
                      <td>{item.kindOrgin}</td>
                      <td>{item.bookName}</td>
                      <td>{item.detailCnt}</td>
                      <td>{item.kind2}</td>
                      <td>
                        <Button
                          className="btnComplete"
                          onClick={() => onComplete(item)}
                        >
                          {item.kind}
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>


          
         
         
          </section>

          {/* {isModalTest && (
               <section className='section-exam'>
                <div className="box_modal_test">
              
                  <HeaderTitle onBackClick={()=>setIsModalTest(false)} title={bookName} direction={1}/>
                    {bookTestKind === 'TEST' ? (
                    <Exam2Main selectedItem={selectedUnitItem} />
                    ) : (
                    <BookMain selectedItem={selectedUnitItem} />
                    )}     
                </div>
              </section>
            )} */}
{/* 
          {isModalTest && (
               
            <>
            <S.Modal2>
              
                  <HeaderTitle onBackClick={()=>setIsModalTest(false)} title={bookName} direction={1}/>
                    {bookTestKind === 'TEST' ? (
                    <Exam2Main selectedItem={selectedUnitItem} />
                    ) : (
                    <BookMain selectedItem={selectedUnitItem} />
                    )}     
              </S.Modal2> 
               </> 
            )} */}

          
        </div>
      </div>
    );
}

export default Detail_test;