import './SmsResult.scss';
import React, { useEffect, useState } from 'react';
import HeaderTitle from '../comm/HeaderTitle';
import * as S from '../scss/theme';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Button } from '@mui/material';
import fnc from '../mymod/commFunction'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import SmsResult_mms_wait from './SmsResult_mms_wait';
import SmsResult_mms_complete from './SmsResult_mms_complete';


const SmsResult = ({setIsModal}) => {

    const [year, setYear] = useState(fnc.getYear());
    const [month, setMonth] = useState(fnc.getMonth(2));
    const [selectedTab, setSelectedTab] = useState(0); // 탭 상태 관리

    function onclose() {
        setIsModal(false);
      }

        //------------------------라디오 그룹
    const handleChangeRadioGroup = (event) => {
        setMonth(event.target.value); 
    };

    const handleChangeTab = (index) => {
        setSelectedTab(index);
    };

    //==============================
    return(
        <S.Section2>
        <div className='SmsResult'>
            <div className='wrap-main'>

                <HeaderTitle onBackClick={onclose} title={'문자내역'} direction={1}/>
          
                <section className={`section-menu1 ${selectedTab === 0 ? 'h-disabled': ''} `}>
                    <article className='article-year'>
                            <Button className='btnNextPrev' onClick={() =>setYear(pre => pre - 1)}> 
                            <i class="fa-solid fa-circle-chevron-left"></i>
                            </Button>
                            <span className='year'>{year}년</span>

                            <Button className='btnNextPrev' onClick={() => setYear(fnc.getYear())}> 
                            <i className="fas fa-pause"></i>
                            </Button>

                            <Button className='btnNextPrev' onClick={() => setYear(pre => pre + 1)}> 
                            <i class="fa-solid fa-circle-chevron-right"></i>
                            </Button>
                    </article>
                   

                    <article className='article-radio'>
                        <RadioGroup row value={month} onChange={handleChangeRadioGroup}>
                            <FormControlLabel value="01" control={<Radio />} label="1월" />
                            <FormControlLabel value="02" control={<Radio />} label="2월" />
                            <FormControlLabel value="03" control={<Radio />} label="3월" />
                            <FormControlLabel value="04" control={<Radio />} label="4월" />
                            <FormControlLabel value="05" control={<Radio />} label="5월" />
                            <FormControlLabel value="06" control={<Radio />} label="6월" />
                            <FormControlLabel value="07" control={<Radio />} label="7월" />
                            <FormControlLabel value="08" control={<Radio />} label="8월" />
                            <FormControlLabel value="09" control={<Radio />} label="9월" />
                            <FormControlLabel value="10" control={<Radio />} label="10월" />
                            <FormControlLabel value="11" control={<Radio />} label="11월" />
                            <FormControlLabel value="12" control={<Radio />} label="12월" />
                        </RadioGroup>

                    </article>
                </section>


                <section className='section-tab'>
                    <Tabs selectedIndex={selectedTab} onSelect={handleChangeTab}>
                        <TabList className='tabList'>
                            <Tab>발송대기(MMS)</Tab>
                            <Tab>발송완료(MMS)</Tab>
                        </TabList>

                        <TabPanel>
                           <SmsResult_mms_wait />        
                        </TabPanel>

                        <TabPanel>
                           {month && <SmsResult_mms_complete year={year} month={month} /> }
                        </TabPanel>

                    </Tabs>

                   

                </section>



            </div>
        </div>
        </S.Section2>
    );
}


export default SmsResult;