import { ClassNames } from "@emotion/react";
import { createSlice } from "@reduxjs/toolkit";
import { select } from "slate";

const redux = createSlice({
    name: 'redux',//변경해도 의미 없네 (내부적으로 식별 용도인 듯)
    initialState: {
        list_pdf: [],
        rowIdx_pdf: null,
        list_que: [],
        rowIdx_que: null,
        selectedClassList: {},
        isAdmin: false,
        isLoggedIn: false,
        isSubmenuVisible: false,
        userInfo:{},
        boardTitle: {},
        headerInfo: {title:'제목', direction: -1},
        currentColor: 'black',
        highlightMode: false,
        isShowList: false,
        mode: '',
        undo: false, redo: false, clear: false, prev: false, next: false,
        kind: '',
        list_pdf_marking: [], list_pdf_complete: [],
        markingList: {}, // 추가된 상태
        selectedUnit_pdf: null, selectedTestNm: null,
        selectedPage_pdf: null,
        header_dev: null,
        isRefresh_bookMain: false,  isRefresh_examMain: false,
        selectedVideoList: {},
        isRefresh_studentCourseList: false,
        classCode: null, lessonDate: null, isModal: false, className: null,
        isModalTest: false, bookCode: null,
        lmsListCode: null,
        isRefresh_lmsTecList: false,
        isModalTestLms: false,
        bookTestKind: null,
        selectedBookCode: null,
        selectedBookName: null,
        selectedBookDetailCode: [],
        isBookStudy: false,
        selectedGangsaCode: null,
        isWaitStSearch: false,//대기학생 검색여부
        version: null,
        isLoginPage: false,
        selectedStCode: null, selectedStName: null, selectedUserKind: 0, //1학생, 2선생님
        selectedItemSt_result: {},
        acaName: 'KUESB-LMS',
        choJoongGo: null,
    },
    
    reducers: {

        setList_pdf: (state, action) => {
            state.list_pdf = action.payload;
        },
        setRowIdx_pdf: (state, action) => {
            state.rowIdx_pdf = action.payload;
        },
        setList_que: (state, action) => {
            state.list_que = action.payload;
        },
        setRowIdx_que: (state, action) => {
            state.rowIdx_que = action.payload;
        },
        setSelectedClassList: (state, action) => {
            state.selectedClassList = action.payload;
        },
        setIsAdmin: (state, action) => {
            state.isAdmin = action.payload;
        },
        setIsLoggedIn: (state, action) => {
            state.isLoggedIn = action.payload;
        },
        setIsSubmenuVisible: (state, action) => {
            state.isSubmenuVisible = action.payload;
        },

        setUserInfo: (state, action) => {
            state.userInfo = action.payload;
        },
        setBoardTitle: (state, action) => {
            state.boardTitle = action.payload;
        },
        //dispatch(setHeaderInfo({title: label, direction: -1})); 한개만 해도 된다.
        setHeaderInfo: (state, action) => {
            const updates = action.payload;
            for (const key in updates) {
                if (key in state.headerInfo) {
                    state.headerInfo[key] = updates[key];
                }
            }
        },

        setCurrentColor: (state, action) => {
            state.currentColor = action.payload;
        },
        setHighlightMode: (state, action) => {
            state.highlightMode = action.payload;
        },
        setMode: (state, action) => {
            state.mode = action.payload;
        },
        setIsShowList: (state, action) => {
            state.isShowList = action.payload;
        },
        setUndo: (state, action) => {
            state.undo = action.payload;
        },
        setRedo: (state, action) => {
            state.redo = action.payload;
        },
        setClear: (state, action) => {
            state.clear = action.payload;
        },
        setPrev: (state, action) => {
            state.prev = action.payload;
        },
        setNext: (state, action) => {
            state.next = action.payload;
        },
        setKind: (state, action) => {
            state.kind = action.payload;
        },
        setList_pdf_marking: (state, action) => {
            state.list_pdf_marking = action.payload;
        },
        setList_pdf_complete: (state, action) => {
            state.list_pdf_complete = action.payload;
        },
          // 추가된 리듀서
        initializeMarkingList: (state, action) => {
            const numQuestions = action.payload;
            const newMarkingList = {};
            for (let i = 1; i <= numQuestions; i++) {
                newMarkingList[i] = '';
            }
            state.markingList = newMarkingList;
        },
        updateMarkingList: (state, action) => {
            const { no, value } = action.payload;
            state.markingList[no] = value;
        },
        setSelectedUnit_pdf: (state, action) => {
            state.selectedUnit_pdf = action.payload;
        },
        setSelectedPage_pdf: (state, action) => {
            state.selectedPage_pdf = action.payload;
        },
        setHeader_dev: (state, action) => {
            state.header_dev = action.payload;
        },
        setIsRefresh_bookMain: (state, action) => {
            state.isRefresh_bookMain = action.payload;
        },
        setIsRefresh_studentCourseList: (state, action) => {
            state.isRefresh_studentCourseList = action.payload;
        },
        
        setIsRefresh_examMain: (state, action) => {
            state.isRefresh_examMain = action.payload;
        },

        
        setSelectedVideoList: (state, action) => {
            state.selectedVideoList = action.payload;
        },
        //-------------------------------------------------exam study
        setSelectedTestNm: (state, action) => {
            state.selectedTestNm = action.payload;
        },
        setClassCode: (state, action) => {
            state.classCode = action.payload;
        },
        setClassName: (state, action) => {
            state.className = action.payload;
        },
        setLessonDate: (state, action) => {
            state.lessonDate = action.payload;
        },
        setIsModal: (state, action) => {
            state.isModal = action.payload;
        },

        setIsModalTest: (state, action) => {
            state.isModalTest = action.payload;
        },
        
        setBookCode: (state, action) => {
            state.bookCode = action.payload;
        },

        setLmsListCode: (state, action) => {
            state.lmsListCode = action.payload;
        },

        setIsRefresh_lmsTecList: (state, action) => {
            state.isRefresh_lmsTecList = action.payload;
        },
        setIsModalTestLms: (state, action) => {
            state.isModalTestLms = action.payload;
        },
        setBookTestKind: (state, action) => {
            state.bookTestKind = action.payload;
        },
        setSelectedBookCode: (state, action) => {
            state.selectedBookCode = action.payload;
        },
        setSelectedBookName: (state, action) => {
            state.selectedBookName = action.payload;
        },

        setSelectedBookDetailCode: (state, action) => {
            state.selectedBookDetailCode = action.payload;
        },
        setIsBookStudy: (state, action) => {
            state.isBookStudy = action.payload;
        },
        setSelectedGangsaCode: (state, action) => {
            state.selectedGangsaCode = action.payload;
        },
        setIsWaitStSearch: (state, action) => {
            state.isWaitStSearch = action.payload;
        },
        setVersion: (state, action) => {
            state.version = action.payload;
        },
        setIsLoginPage(state, action) {
            state.isLoginPage = action.payload;
        },
        setSelectedStCode(state, action) {
            state.selectedStCode = action.payload;
        },
        setSelectedStName(state, action) {
            state.selectedStName = action.payload;
        },
        setSelectedUserKind(state, action) {
            state.selectedUserKind = action.payload;
        },
        setSelectedItemSt_result(state, action) {
            state.selectedItemSt_result = action.payload;
        },
        setAcaName: (state, action) => {
            state.acaName = action.payload;
        },
        setChoJoongGo: (state, action) => {
            state.choJoongGo = action.payload;
        },
        
    }

    
});



export const { 
    setList_pdf, 
    setRowIdx_pdf,
    setList_que, 
    setRowIdx_que,
    setSelectedClassList,
    setIsAdmin,
    setIsLoggedIn,
    setIsSubmenuVisible,
    setUserInfo,
    setBoardTitle,
    setHeaderInfo,
    setCurrentColor, setHighlightMode,setMode, setIsShowList, setUndo,setRedo,setClear,
    setPrev, setNext,
    setKind,
    setList_pdf_marking, setList_pdf_complete,
    initializeMarkingList, // 추가된 액션
    updateMarkingList, // 추가된 액션
    setSelectedUnit_pdf, setSelectedPage_pdf,
    setHeader_dev,
    setIsRefresh_bookMain, setIsRefresh_examMain,
    setSelectedVideoList,
    setSelectedTestNm,
    setIsRefresh_studentCourseList,
    setClassCode, setLessonDate, setIsModal,setClassName,
    setIsModalTest, setBookCode,
    setLmsListCode,
    setIsRefresh_lmsTecList,
    setIsModalTestLms, setSelectedBookCode, setBookTestKind, setSelectedBookName, setSelectedBookDetailCode, setIsBookStudy,
    setSelectedGangsaCode, setIsWaitStSearch,
    setVersion, setIsLoginPage,
    setSelectedStCode, setSelectedStName, setSelectedUserKind, 
    setSelectedItemSt_result,
    setAcaName, setChoJoongGo,

} = redux.actions;

export default redux.reducer;

