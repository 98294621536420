
import styled from  "styled-components";


export const theme ={
    bgColor:"#010306",
    dashBg:"#E9EDF8",
    textColor:"#f4f6f5",
    accentColor:"0,59,115",
    accentColor01:"31,59,113",
    accentColor02:"241,220,124",
    accentColor03:"96,131,92",
    itemColor1:"0,151,151",
    itemColor2:"0,115,181",
    itemColor3:"77,94,154",
    opacity: 0.05,
    opacity1: 10,
    opacity2: 0.2,
    opacity4: 0.4,

    //** radius */
    column5:"0.3125rem",
    column10:"0.625rem",
    column15:"0.9375rem",
    column20:"1.25rem",
    column25:"1.5625rem",
    column30:"1.875rem",
    column40:"2.5rem",
}

//Container는 App()에 포함됨
export const Container = styled.div`
    position: relative;
    max-width: 1680px;
    margin: 0 auto;
    /* width: 80rem; */
    /* height: 100vh; */
   /* border: 1px solid red; */
   /* overflow: auto; */
`

//Container = styled.div`가 Navbar에  감싸고 있다.

export const Section = styled.div`
    margin-top: 4.5rem;
    height: calc(100vh - 8rem); 
    position: relative;
    padding: 0;
    width: 100vw;
    max-width: 80rem;
    /* z-index: 100000; */
    
    /* max-width: 1680px; */
`;


export const Section2 = styled.div`
    margin-top: 5rem;
    height: calc(100vh - 5rem); 
    position: relative;
    padding: 0;
    width: 100vw;
    max-width: 80rem;

`;


export const Section_n = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: relative;
    padding: 0;
    width: 100vw;
    max-width: 80rem;
    overflow: hidden;
`;


export const Modal = styled.div`
    position:absolute;
    top: 0rem;
    left: 0px;
    width: 100%;//스크롤 생기면 부모가 왼쪽으로 이동한다.
    height: calc(100vh - 7.0rem); 
    z-index: 10000;
    background-color: white;
    /* border: 1px solid green */
    
`;


export const Modal2 = styled.div`
    position:absolute;
    top: 0rem;
    left: 0px;
    width: 100%;//스크롤 생기면 부모가 왼쪽으로 이동한다.
    height: calc(100vh - 7.0rem); 
    z-index: 0;
    background-color: white;
    overflow-x: hidden;
    overflow-y: hidden;
    
/* background-color: red; */
 
    
`;


export const Modal3 = styled.div`
    position:absolute;
    top: 0rem;
    left: 0px;
    width: 100%;//스크롤 생기면 부모가 왼쪽으로 이동한다.
    height: calc(100vh - 7.0rem); 
    z-index: 10;
    background-color: white;
    
`;



export const Modal_fullScreen = styled.div`
    position:fixed;
    top: 0rem;
    left: 0px;
    width: 100%;//스크롤 생기면 부모가 왼쪽으로 이동한다.
    height: calc(100vh - 0rem); 
    z-index: 10;
    background-color: ${(props) => props.backgroundColor || 'rgba(1, 1, 1, 0.1)'};
    display:flex;
    justify-content:center;
    /* props 사용법 */
    /* <S.Modal_fullScreen backgroundColor='white'> */
    
`;



export const SubVisual = styled.div`
    position: relative;
    margin: 0 auto;
    height: max(450px, 2vh, 450px);
    >div{
        max-width: 98.75rem;
        padding: 0 2rem;
        margin:0 auto;
        display: flex;
        align-items: center;
        height:100%;
        h1{

            color:white;
            font-weight: 800;
            font-size: 3.75rem;
            text-align: left;
        }
    }
`


// export default theme