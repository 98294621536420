
import Dexie from 'dexie';
import dbAutogen from './CreatDb'; 
import fnc from "../mymod/commFunction";

const selectSvg = ({stCode, testCode,  munCode, kind}, callback) => {
    let acaCode, svgData, no;
    acaCode = fnc.getCookie('acaCode');
    // kind = 'que'
    no = '1'
  
    dbAutogen.memo_que
    .where('[acaCode+testCode+stCode+munCode+kind]')
    .equals([acaCode, testCode, stCode, munCode, kind])
    .toArray()
    .then(results => {
      console.log("검색 결과:", results);
      // 검색 결과를 콜백 함수로 넘깁니다.
      if (callback) {
        callback(null, results);
      }
    })
    .catch(err => {
      console.error("검색 중 오류 발생:", err);
      // 오류를 콜백 함수로 넘깁니다.
      if (callback) {
        callback(err, null);
      }
    });
}


const upsertSvg = ({stCode, testCode, munCode, kind, svgData, no }) => {//kind=que or sol
    const acaCode = fnc.getCookie('acaCode');
    dbAutogen.table('memo_que') 
      .where({ acaCode, testCode, stCode, munCode, kind })
      .first() // 첫 번째 일치하는 항목을 찾습니다.
      .then(found => {
        if (found) {
          return dbAutogen.table('memo_que').update(found.id, { svgData});
        } else {
          return dbAutogen.table('memo_que').add({ acaCode, testCode, stCode, munCode, kind, svgData, no });
        }
      })
      .then(result => {
        if (typeof result === 'number') {
          console.log("Data added or updated with id", result);
        } else {
          console.log("Data updated");
        }
      })
      .catch(err => {
        console.error("Error:", err);
      });

  }

  
  const deleteSvg = ({stCode, testCode, munCode, kind}, callback) => {
    const acaCode = fnc.getCookie('acaCode'); 
    dbAutogen.memo_que
        .where('[acaCode+testCode+stCode+munCode+kind]')
        .equals([acaCode, testCode, stCode, munCode, kind])
        .delete()
        .then(deleteCount => {
            // 삭제된 항목 수를 콜백 함수로 전달
            if (callback) {
                // callback(null, `Deleted ${deleteCount} items`);
                callback({result: deleteCount}, null);
            }
        })
        .catch(err => {
            console.error("Error during deletion:", err);
            // 오류를 콜백 함수로 전달
            if (callback) {
                // callback(err, null);
                callback(null, err)
            }
        });
}



const indexDb = { selectSvg, upsertSvg, deleteSvg }
export default indexDb;