import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import './CircularProgressWithLabel.scss'; // 스타일 추가

// CircularProgressWithLabel Component
const CircularProgressWithLabel = ({ value }) => {
  return (
     <Box className="progress-container">
      {/* 회색 원 (바깥쪽) */}
      <div className="outer-circle"></div>

      {/* 녹색 원 (안쪽, 진행률 표시) */}
      <CircularProgress variant="determinate" value={value} className="progress-bar" />

      {/* 퍼센트 텍스트 */}
      <Box className="progress-label">
        <Typography variant="caption" component="div" className="progress-text">
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};


export default CircularProgressWithLabel;
