import './LmsMain.scss';
import * as S from '../scss/theme';
import React, { useState, useEffect } from "react";
import fnc from '../mymod/commFunction';
import Button from '@mui/material/Button';
import Combobox from '../comm/Combobox_noBMK';
import Select from 'react-select';
import LmsMainDetail from './LmsMainDetail';
import { useDispatch, useSelector } from 'react-redux';
import { setLessonDate, setIsModal, setLmsListCode } from '../reduxToolkit/redux';



const LmsMain = ({classCode, kind}) => {


    const dispatch = useDispatch();
   
    const isModal = useSelector((state) => state.reduxStore.isModal);
    const [list, setList] = useState([]);
    const [selectedIdx, setSelectedIdx] = useState(0);
    const [isRefresh, setIsRefresh] = useState(false);
    const [isDetail, setIsDetail] = useState(false);
    // const [isModal, setIsModal] = useState(false);

    const [sno, setSno] = useState(null);
   
    useEffect(() => {
      fncSelect_list();
   
    }, [classCode, isRefresh]);

    
  function fncSelect_list() {
    const acaCode = fnc.getCookie('acaCode');
    const stCode = fnc.getCookie('stCode');
    
    fnc.sendAxios('/select/lms/course/lessonSt', { classCode, kind }, (res) => {
      if (res && Array.isArray(res)) { 
        // console.log(res, 'LmsMain res');
        setList(getRecords(res));

      } else {
        console.log(res,"select/book/pdfList");
      }
    });
  }

  
  function getRecords(res) {
    let records = [];
    for (let i = 0; i < res.length; i++) {      
        const sno= res[i]["sno"];
        const code= res[i]["code"];//list 코드 parent Code로 사용
        const content = res[i]["content"];
        const fileName = res[i]["fileName"];
        const fileUrl = res[i]["fileUrl"];
        const sDate = res[i]["sDate"];
        const title = res[i]["title"];
        const writer = res[i]["writer"];
             
        records.push({ sno, code, content, fileName, fileUrl, sDate, title, writer });
    }
    return records;
  }

  
  function onClickTr( item, index) {

    setSno(item.sno);
    dispatch(setLessonDate(item.sDate));
    dispatch(setIsModal(true));
    dispatch(setLmsListCode(item.code));
  }
  
  function isNew(date) {
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    return new Date(date) > oneWeekAgo;
  }
  
    //=========================================
    return (

      <div className="LmsMain">
        <div className="wrap-course-main">
          <section className="section-book-main">
            <table className="table-hong table-gangsa-main">
              <thead>
                <tr>
                  <th>No</th>
                  <th>날짜</th>
                  <th>제목</th>
                  <th>작성자</th>
                </tr>
              </thead>

              <tbody>
                  {list && list.map((item, index) => (
  
                      <tr key={index} onClick={() => onClickTr(item, index)}>  
                        <td>{index+1}</td>
                        <td>{item.sDate}</td>
                        <td>{item.title}
                            {isNew(item.sDate) && <span className="new-label">new</span>}
                        </td>
                        <td>{item.writer}</td>
                      </tr>
                  ))}
            
                </tbody>
            </table>
          </section>


        </div>

        {isModal && <div className='box_modal'>
                  <LmsMainDetail sno={sno} setIsModal={setIsModal}/>
        </div> }    


      </div>
    );
}

export default LmsMain;