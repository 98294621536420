
import React from 'react';

export const eraserCircleRef = React.createRef();

export const createEraserCircle = (x, y, svg) => {
  const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
  circle.setAttribute('cx', x);
  circle.setAttribute('cy', y);
  circle.setAttribute('r', 15);  // 반경 크기
  circle.setAttribute('stroke', 'gray');
  circle.setAttribute('stroke-width', 1);
  circle.setAttribute('fill', 'transparent');
  svg.appendChild(circle);
  eraserCircleRef.current = circle;
};

export const updateEraserCircle = (x, y) => {
  if (eraserCircleRef.current) {
    eraserCircleRef.current.setAttribute('cx', x);
    eraserCircleRef.current.setAttribute('cy', y);
  }
};

export const removeEraserCircle = (svg) => {
  if (eraserCircleRef.current) {
    svg.removeChild(eraserCircleRef.current);
    eraserCircleRef.current = null;
  }
};
