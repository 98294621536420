import './CourseTableTec.scss';
import React, { useEffect, useState } from "react";
import styled from 'styled-components';
// import fnc from '../mymod/commFunction';
import Select from 'react-select';
import Button from '@mui/material/Button';
import fnc from '../mymod/commFunction';
import { useDispatch, useSelector } from 'react-redux';
import { setIsWaitStSearch } from '../reduxToolkit/redux';


const CourseTableTec = ({options, handleChange}) => {
  const dispatch = useDispatch();
  const isRefresh_studentCourseList = useSelector(state => state.reduxStore.isRefresh_studentCourseList);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [selectedRowIndices, setSelectedRowIndices] = useState([]);

  const handleRowClick = (index, option) => {
 
    handleChange(option);
    setSelectedRowIndex(index);
    dispatch(setIsWaitStSearch(false));
  };

  // useEffect(()=>{
  //   setSelectedRowIndices([]);

  // }, options);

  useEffect(() => {
    setSelectedRowIndices([]);
  }, [options.length]); // options의 길이가 변경될 때만 효과를 실행
  

  //-------------------------------------------
  return (//자식 컨포넌트
  <div className="CourseTableTec">
  <table className="table-hong table-unit">
      <thead>
          <tr>      
              <th>no</th>
              <th>강좌</th>
          </tr>
      </thead>
      <tbody>
          {options.map((option, index) => (
           
              <tr
                  className={selectedRowIndex === index ? 'active' : ''}
                  key={index}
                  onClick={() => handleRowClick(index, option)}
              >
               
                  <td>{index+1}</td>
                  <td>{option.className}</td>
              </tr>
              
          ))}
      </tbody>
  </table>
   
</div>
  );
};

export default CourseTableTec;

//===================================================================
  
// StyledSelect 컴포넌트 생성
const StyledSelect = styled(Select)`
width: 100%;  // 원하는 너비 설정
text-align: left;
font-size: 0.9rem;
`;


const customStyles = {
    
}