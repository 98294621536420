import React from 'react';
import './NavbarSub.css';
import { Link } from 'react-router-dom';

function NavbarSub({ hideSubmenu , isMenuHovered, handleMenuLeave }) {

   

    return (

      <>
    
       <nav className={`navbar-sub ${isMenuHovered ? 'navbar-sub-hovered' : handleMenuLeave}`} onMouseLeave={handleMenuLeave}>
       <div className='submenu'>
          <div className="submenu-table">
          
          </div>

          <div className="submenu-table">
           
           
          </div>

          <div className="submenu-table">
            {/* <Link to="/notice">공지사항</Link>
            <Link to="/board">자유게시판</Link>
            <Link to="/faq">자주묻는 질문</Link>        
            <Link to="/gallery">포토갤러리</Link>
            <Link to="/video">동영상게시판</Link>
            <Link to="/libray">자료실</Link> */}
          </div>

           <div className="submenu-table">
           <Link to="/"></Link>
          </div>
          
           <div className="submenu-table">
           <Link to="/"></Link>
          </div>
      </div>

        

        </nav>
        
       
       </>
    );
}

export default NavbarSub;