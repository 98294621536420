import './SmsResult_mms_wait.scss';
import React, { useEffect, useState } from 'react';
import HeaderTitle from '../comm/HeaderTitle';
import * as S from '../scss/theme';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Button } from '@mui/material';
import fnc from '../mymod/commFunction'
import Checkbox from '@mui/material/Checkbox';
import CustomSpinner from '../comm/CustomSpinner';


const SmsResult_mms_wait = ({}) => {

    const [isSelectAll, setIsSelectAll] = useState(false);//체크박스
    const [list, setList] = useState([]);
    const [msg, setMsg] = useState('');
    const [isRefresh, setIsRefresh] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fncSelect_list();
    }, [isRefresh]);


    function fncSelect_list() {
        setIsLoading(true);
        const acaCode = fnc.getCookie('acaCode');
        const bill_id = process.env.REACT_APP_BILL_ID;
  
        fnc.sendAxios('/select/sms/mms_wait', { acaCode, bill_id }, (res) => {
          if (res && Array.isArray(res)) { 
            setList(getRecords(res));
            setIsLoading(false);
          } else {
            console.log(res,"kuesbSmsRoutes/select/sms/mms_wait");
          }
        });
      }
    
      
      function getRecords(res) {
        let records = [];
        setIsSelectAll(false);
        for (let i = 0; i < res.length; i++) {      
            const writeDate= res[i]["작성일"];
            const arrivalDate= res[i]["도착일"];
            const mode = res[i]["모드"];
            let stName = res[i]["성명"];
            const phone = res[i]["전화번호"];
            const category = res[i]["항목"];
            const msg = res[i]["내용"];
            let sendUserName = res[i]["발송인"];
            let msgKey = res[i]["연번"];
            const fileCnt = res[i]["FILE_CNT"];

            const isChecked = false;

            records.push({ writeDate, arrivalDate, mode, stName, phone, category,
                msg, sendUserName, msgKey, fileCnt, isChecked
             });
        }
        return records;
      }
    
      function delete_reserve() {

        const checkedCnt = list.filter(item => item.isChecked).length; // 체크된 항목 필터링
   
        if (checkedCnt === 0) {
          alert ('대상을 체크하세요!');
          return; 
        }
    
        const rtn = window.confirm(`${checkedCnt}명의 예약을 삭제하시겠습니까?`);
        if (!rtn) return;

        let cnt = 0;

        for (let i = 0; i < list.length; i++) {
            const isChecked = list[i].isChecked;

            if (isChecked) {
                const msgKey = list[i].msgKey;
                fncDelete_mmsReserve(msgKey, () => {
                    cnt++;

                    if(checkedCnt === cnt) {
                        alert('완료되었습니다.');
                        setIsRefresh(!isRefresh);            
                    }
                })    
            } 
        }

      }

      function fncDelete_mmsReserve(msgKey, callback) {
      
        fnc.sendAxios('/delete/sms/mms_reserve', { msgKey }, (res) => {
          if (res) { 
            callback();
          } else {
            console.log(res,"kuesbSmsRoutes/delete/sms/mms_reserve");
          }
        });
      }
    

      const handleRefreshClick = () => {
        if (isDisabled) return; // 버튼이 비활성화되어 있는 경우 아무것도 하지 않음
        setIsRefresh(!isRefresh);
        setIsDisabled(true); // 버튼을 일시적으로 비활성화
        // 2초 후 버튼을 다시 활성화
        setTimeout(() => {
          setIsDisabled(false);
        }, 2000);
      };

    //======================================
    return(

        <div className='SmsResult_mms_wait'>
            <div className='wrap-main'>
                <section className='section-main'>
                    <table className="table-hong table-main">
                        <thead>
                            <tr>
                            <th>No</th>
                            <th>작성일</th>
                            <th>도착일</th>
                            <th>모드</th>
                            <th>성명</th>
                            <th>전화번호</th>
                            <th>내용</th> 
                            <th>발송인</th> 
                            <th>FILE_CNT</th> 
                            <th>
                                <Checkbox checked={isSelectAll} onChange={handleSelectAllChange} />
                            </th>    
                            </tr>
                        </thead>

                        <tbody>
                            {list && list.map((item, index) => (

                                <tr key={index} onClick={() => setMsg(item.msg)}>  
                                    <td>{index+1}</td>
                                    <td>{item.writeDate}</td>
                                    <td>{item.arrivalDate}</td>
                                    <td>{item.mode}</td>                    
                                    <td>{item.stName}</td>
                                    <td>{item.phone}</td>
                                    <td>{item.msg}</td>
                                    <td>{item.sendUserName}</td>
                                    <td>{item.fileCnt}</td>

                                    <td>
                                        <Checkbox
                                            checked={item.isChecked}
                                            onChange={(event) => handleCheckboxChange(event, index)}
                                        />
                                    </td>
                                    
                                </tr>
                            ))}
                        
                        </tbody>
                    </table>

                </section>

                <section className='section-msg'>
                        {msg}
                </section>

                <section className='section-menu2'>
                        <Button className='btnDelete_reserve h-btn-red' onClick={delete_reserve}> SMS 예약취소</Button>
                        <Button className='btnRefresh h-btn-green' onClick={handleRefreshClick} disabled={isDisabled}> 새로고침</Button>
                </section>

            </div>
        </div>

    );

    //========================================================================
     //---------------------------checkbox
  function handleCheckboxChange(event, index) {
    const isChecked = event.target.checked;
    const newList = [...list];
    newList[index].isChecked = isChecked;
    setList(newList);

 

  // 모두 체크되었는지 확인하여 헤더 체크박스 상태 업데이트
  const allChecked = newList.every(item => item.isChecked);
  setIsSelectAll(allChecked);
  }

  function handleSelectAllChange() {
    const newList = list.map(item => ({...item, isChecked: !isSelectAll }));
    setList(newList);
    setIsSelectAll(!isSelectAll);
  }//checkbox END

 
}


export default SmsResult_mms_wait;