
import './BasicLogin.scss';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import axios from "axios";
import fnc from '../mymod/commFunction';
import Cookies from 'js-cookie';
import { useSelector, useDispatch } from 'react-redux';
import { setIsAdmin, setIsLoggedIn, setUserInfo, setAcaName, setChoJoongGo } from '../reduxToolkit/redux';
import { Checkbox } from '@mui/material';



function BasicLogin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [certCode, setCertCode] = useState('');
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const userIdRef = useRef(null);  // useRef 훅을 사용해 참조 생성
  const stCode = fnc.getCookie('stCode');//getCookie함수 수정 utf-8에러 남
  const version = useSelector(state => state.reduxStore.version);
  
  const [isAutoLoginChecked, setAutoLoginChecked] = useState(false);
  const [isIdChecked, setIdChecked] = useState(false);

  const [appVersion, setAppVersion] = useState(''); // 앱 버전 상태
  const currentVersion = localStorage.getItem('updateVersion');
  const acaName = useSelector(state => state.reduxStore.acaName);

  let acaDbName;

  useEffect(() => {
    // 컴포넌트가 마운트될 때 input 요소에 포커스를 자동으로 맞춤
    restoreCookie();
    userIdRef.current.focus();
    setAppVersion(version);//초기값 공백으로 해야하고, [version] 붙여야함 안드로이드에서 못 읽어와
  }, [version]);


  const handleSubmit = (event) => {
    event.preventDefault(); // 폼 전송에 의한 페이지 새로고침 방지
    // console.log('로그인 시도:', { userId, password });
    // dispatch(setIsLoggedIn(true));
    // navigate('/home'); 
    if(!certCode){
      alert('인증코드를 입력하세요!');
      return false;
    }

    if(!userId){
      alert('아이디를 입력하세요!');
      return false;
    }
    if(!password){
      alert('패쓰워드를 입력하세요!');
      return false;
    }
    // fncBasicLogin(userId, password)
    fncInfoAca();
  };


  function fncInfoAca() {

    fnc.sendAxios('/infoAca', { certCode }, (res) => {
      if (res && Array.isArray(res) && res[0] && res[0].acaDbName) {  
    
        let acaCode = res[0].acaCode;
        acaDbName = res[0].acaDbName;
        const acaName = res[0].acaName;
        const s3_acaCode = res[0].s3_acaCode;
        const choJoongGo = res[0].choJoongGo;

        fnc.setCookie('acaCode', acaCode, 1000);
        fnc.setCookie('acaDbName', acaDbName, 1000);
        fnc.setCookie('acaName', acaName, 1000);
        fnc.setCookie('s3_acaCode', s3_acaCode, 1000);
        // fnc.setCookie('choJoongGo', choJoongGo, 1000);
        
      
        dispatch(setAcaName(acaName));
        dispatch(setChoJoongGo(choJoongGo));

        // console.log(acaDbName, acaCode, '========== acaDbName');
        fncBasicLogin(userId, password);//로그인 실행

      } else {
        alert('인증코드 정보가 올바르지 않습니다.');
      }
    });
  }


function fncBasicLogin(id, pw) {
  
  fnc.sendAxios('/kuesbLogin', { id, pw }, (res) => {
    if (res.length) {

      dispatch(setUserInfo(res[0]));
      dispatch(setIsLoggedIn(true));

      fnc.setCookie('certCode', certCode);
      fnc.setCookie('id', res[0].id);
      fnc.setCookie('pw', pw);
      fnc.setCookie('stCode', res[0].stCode);
      fnc.setCookie('userName', res[0].name);
      fnc.setCookie('lastLoginTime', Date.now(), 1);

      // fnc.setCookie('acaCode', 2000);


      navigate('/main'); 

      // if(res.token) {
      //   if(basicid === 'admin') {
      //     fnc.setCookie('token', res.token, 1);
      //     // alert( fnc.getCookie('token'))
      //     dispatch(setIsAdmin(true));
      //     dispatch(setIsLoggedIn(true));
      //     navigate('/home'); 
      //   }else{
      //     // fnc.setCookie_pure('token','',1);
      //   }     
      // }

    } else {
      alert('정보가 올바르지 않습니다.')
      console.log(res,"속성이 obj 내에 없습니다.");
    }
  });
}


const handleIdClick = () => {
  if (isIdChecked) {
      setAutoLoginChecked(false);
      fnc.setCookie('isAutoLoginChecked', 'false', 1000);
  }
  setIdChecked(prev => !prev);
  fnc.setCookie('isIdChecked', String(!isIdChecked), 1000);
}

const handleAutoLoginClick = () => {
if (!isAutoLoginChecked) {
    setIdChecked(true);
    fnc.setCookie('isIdChecked', 'true', 1000);
}
setAutoLoginChecked(prev => !prev);
fnc.setCookie('isAutoLoginChecked', String(!isAutoLoginChecked), 1000);
}


function restoreCookie() {
  const certCode = fnc.getCookie('certCode');
  const id = fnc.getCookie('id');
  const pw = fnc.getCookie('pw');
  const isIdChecked = fnc.getCookie('isIdChecked') === 'true';
  const isAutoLoginChecked = fnc.getCookie('isAutoLoginChecked') === 'true';

  setIdChecked(isIdChecked);
  setAutoLoginChecked(isAutoLoginChecked);

  console.log(certCode, 'certCode');
  if (certCode) {
    setCertCode(certCode);
  }

  if (id && isIdChecked) {
    setUserId(id);
  }

  if (pw && isAutoLoginChecked) {
    setPassword(pw);
  }


}

    
  axios.interceptors.request.use(config => {
    //login보다 먼저 실행돼야 한다.
    let acaDbName = fnc.getCookie('acaDbName');
    // 모든 POST 요청에 데이터 추가
    if (config.method === 'post' && config.data) {
      config.data.db  = acaDbName;// 'Autogen0000';//acaDbName;
      // config.data.name3 = '홍길동33';
    }
    // 모든 GET 요청에 쿼리 파라미터 추가
    if (config.method === 'get') {
      config.params = config.params || {};
      config.params.name3 = '홍길동2';
    }

    return config;
  }, error => {
    // 요청 오류 처리
    console.error('Axios 요청 인터셉터에서 오류 발생:', error);
    return Promise.reject(error);
  });


  //===============================================
  return (
    <div className='BasicLogin' >
  

    <form onSubmit={handleSubmit}>
        <div className='title'>
          <p>{acaName}</p>
          <p>Learning Management System</p>
        </div>

      <div> 
        <label htmlFor="cerCode">* 인증코드</label> 
          <input
            type="text"
            id="cerCode"
            value={certCode}
            onChange={(e) => setCertCode(e.target.value)}
          /> 
      </div>

      <div> 
        <label htmlFor="userId">* 아이디</label>
          <input
            type="text"
            id="userId"
            ref={userIdRef}  // input 요소에 ref 속성을 설정
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            spellCheck='false'
          />
      </div>

      <div>
        <label htmlFor="password">* 비밀번호</label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>

      <div className='box_menu'>
        <Button type="submit">
            <i className="fa fa-user"></i> 로그인
        </Button>
      </div>

      <section className="section-chkbox">
            <label className="form-check-label lblChk">
                <Checkbox className="form-check-input" type="checkbox" 
                checked={isIdChecked} onChange={handleIdClick} /> &nbsp;아이디저장 
            </label>
            <label className="form-check-label lblChk">
                <Checkbox className="form-check-input" type="checkbox"
                checked={isAutoLoginChecked}  onChange={handleAutoLoginClick} /> &nbsp;자동로그인 
            </label>
      </section>

     {/* {!stCode && <div className='box_setingPw'>
        <Button type="submit" onClick={() => navigate('/creatpw')}>초기 비밀번호 설정</Button>
      </div> } */}

     
    </form>
      <section className='section-menu-login'>
      <Button className='btnSignUp' onClick={() => navigate('/signup')}>회원가입 </Button>
        {/* <Button onClick={() => navigate('/signup')}>회원가입 </Button><p>|</p>
        <Button>비밀번호찾기</Button><p>|</p>
        <Button>아이디찾기</Button><p>|</p>
        <Button>계정삭제</Button> */}
          {appVersion && <div>ver {appVersion} ( {currentVersion} )</div>} 
          {!appVersion && <div className='version'>Aplication ver 1.0.1 ( {currentVersion} )</div>}
      </section>

       
    </div>
  );


  
}


export default BasicLogin;
