import './Combobox2.scss';
import React, { useState, useRef, useEffect } from 'react';

const Combobox2 = ({ options, handleChange, initialComboValue, className='' }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(initialComboValue || (options.length ? options[0].childKey : ''));
  const comboboxRef = useRef(null); // 컴포넌트 전체를 위한 ref

  // 드롭다운 메뉴 외부 클릭 감지를 위한 이벤트 핸들러 설정
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (comboboxRef.current && !comboboxRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    // 문서 전체에 클릭 이벤트 리스너 추가
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // options가 변경되거나, initialComboValue가 변경될 때 selectedValue 설정
  useEffect(() => {
    if (options && options.length > 0 && initialComboValue) {
      setSelectedValue(initialComboValue);
    }
  }, [options, initialComboValue]);

  // 옵션 선택 핸들러
  const handleSelectChange = (value, label, index) => {
    if (value !== selectedValue) { // 선택된 값이 이전에 선택된 값과 같다면 함수 실행을 중단
      setSelectedValue(value);
      handleChange({ value, label, index });
    }
    setIsOpen(false); // 옵션 선택시 메뉴 닫기
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`Combobox2 ${className}`} ref={comboboxRef}>
      <div className="custom-select">
        <div className="custom-select-trigger" onClick={toggleDropdown}>
          {options.find(opt => opt.childKey === selectedValue)?.childName || ''}
        </div>
        {isOpen && (
          <ul className="custom-options">
            {options.map((opt, index) => (
              <li key={opt.childKey} 
                className={opt.childKey === selectedValue ? 'selected' : ''}
                onClick={() => handleSelectChange(opt.childKey, opt.childName, index)}>
                {opt.childName}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Combobox2;
