import './SmsResult_mms_complete.scss';
import React, { useEffect, useState } from 'react';
import HeaderTitle from '../comm/HeaderTitle';
import * as S from '../scss/theme';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Button } from '@mui/material';
import fnc from '../mymod/commFunction'
import fnc2 from '../mymod/commFunction2';
import CustomSpinner from '../comm/CustomSpinner';

const SmsResult_mms_complete = ({year, month}) => {

    const [list, setList] = useState([]);
    const [msg, setMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fncSelect_list();
    }, [year, month]);


    function fncSelect_list() {

        const acaCode = fnc.getCookie('acaCode');
        const bill_id = process.env.REACT_APP_BILL_ID;
        const tableName = `MMS_LOG_${year}${month}`
     
        setList([]);
        setMsg('');

        setIsLoading(true);

        fnc.sendAxios('/select/sms/mms_complete', { acaCode, bill_id, tableName }, (res) => {
          if (res && Array.isArray(res)) { 
       
            setList(getRecords(res));
            setIsLoading(false);
    
          } else {
            console.log(res,"kuesbSmsRoutes/select/sms/mms_complete");
            setIsLoading(false);
          }
        });
      }
    
      
      function getRecords(res) {
        let records = [];
        for (let i = 0; i < res.length; i++) {      
            const writeDate= res[i]["작성일"];
            const arrivalDate= res[i]["도착일"];
            const mode = res[i]["모드"];
            let stName = res[i]["성명"];
            const phone = res[i]["전화번호"];
            const category = res[i]["항목"];
            const msg = res[i]["내용"];
            let sendUserName = res[i]["발송인"];
            let sno = res[i]["연번"];
            const telCompay = res[i]["통신사"];
            const fileCnt = res[i]["FILE_CNT"];
            const resultCode = res[i]["결과코드"];
            const result = fnc2.getSmsErrMsg(resultCode);
            const statusCode = res[i]["상태"];
            const status = fnc2.getSmsStatus(statusCode);
            console.log(status, 'status');

            records.push({ writeDate, arrivalDate, mode, stName, phone, category,
                msg, sendUserName, sno, telCompay, fileCnt, resultCode, result, status
             });
        }
        return records;
      }
    
      
     


    //======================================
    return(

        <div className='SmsResult_mms_complete'>
            <div className='wrap-main'>
                <section className='section-main'>
                    <table className="table-hong table-main">
                        <thead>
                            <tr>
                            <th>{list.length}</th>
                            <th>작성일</th>
                            <th>도착일</th>
                            <th>모드</th>
                            <th>성명</th>
                            <th>전화번호</th>
                            <th>내용</th> 
                            <th>발송인</th> 
                            <th>코드</th>   
                            <th>결과</th> 
                            <th>전송상태</th>   
                            <th>통신사</th>   
                            </tr>
                        </thead>

                        <tbody>
                            {list && list.map((item, index) => (

                                <tr key={index} onClick={() => setMsg(item.msg)}>  
                                    <td>{index+1}</td>
                                    <td>{item.writeDate}</td>
                                    <td>{item.arrivalDate}</td>
                                    <td>{item.mode}</td>
                                
                                    <td>{item.stName}</td>
                                    <td>{item.phone}</td>

                                    <td>{item.msg}</td>
                                    <td>{item.sendUserName}</td>
                                    <td>{item.resultCode}</td>
                                    <td>{item.result}</td>
                                    <td>{item.status}</td>
                                    <td>{item.telCompay}</td>
                                    
                                </tr>
                            ))}
                        
                        </tbody>                       
                    </table>
                            
                </section>

                <section className='section-msg'>
                        {msg}
                </section>

            </div>

           {isLoading && <CustomSpinner /> }

        </div>

    );
}


export default SmsResult_mms_complete;