
import Dexie from 'dexie';

// const dbAutogen = new Dexie('autogen');
const dbAutogen = new Dexie('kuesb');//여기만 바꾸면 된다.

// 데이터베이스 스키마 정의
dbAutogen.version(1).stores({
  memo_que: `++id, 
             acaCode, 
             testCode, 
             stCode, 
             munCode, 
             kind,
             [acaCode+testCode+stCode+munCode+kind],
             [acaCode+testCode+stCode+munCode], 
             [testCode+kind]`
});

// 새로운 버전에서 복합 인덱스 추가
dbAutogen.version(4).stores({
  memo_que: `++id, 
  acaCode, 
  testCode, 
  stCode, 
  munCode, 
  kind,
  [acaCode+testCode+stCode+munCode+kind],
  [acaCode+testCode+stCode+munCode], 
  [testCode+kind],
  [testCode+stCode]`
}).upgrade(trans => {
  // 필요한 경우 데이터 마이그레이션 로직을 여기에 추가
});


// 데이터베이스 오픈 및 오류 처리
dbAutogen.open().catch((err) => {
  console.error(`Open failed: ${err.stack}`);
});

export default dbAutogen;

