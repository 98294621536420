import './DigitalFastSearch.scss';
import Combobox2 from '../comm/Combobox2';
import React, { useEffect, useState } from 'react';
import HeaderTitle from '../comm/HeaderTitle';
import fnc from '../mymod/commFunction';
import { Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { setHeaderInfo, setIsBookStudy, setSelectedUnit_pdf } from '../reduxToolkit/redux';

const DigitalFastSearch = ({setIsFastSearch, setSelectedUnitItem}) => {

  const choJoongGo = useSelector(state => state.reduxStore.choJoongGo);
  // fnc.sendAxios('/select/book/combo_digital', { parentKey, userId, choJoongGo } 잊지 말것

  const [teacherId, setTeacherId] = useState('');
  const [initialComboValue1, setInitialComboValue1] = useState();
  const [initialComboValue2, setInitialComboValue2] = useState();
  const [initialComboValue3, setInitialComboValue3] = useState();
  const [initialComboValue4, setInitialComboValue4] = useState('0');
  const [initialComboValue5, setInitialComboValue5] = useState('0');
  const [initialComboValue6, setInitialComboValue6] = useState();

  const [unit1Options, setUnit1Options] = useState([]);
  const [unit2Options, setUnit2Options] = useState([]);
  const [unit3Options, setUnit3Options] = useState([]);
  const [unit4Options, setUnit4Options] = useState([]);
  const [unit5Options, setUnit5Options] = useState([]);
  const [unit6Options, setUnit6Options] = useState([]);
  const [unit7Options, setUnit7Options] = useState([]);
  
  const [selectedNodeKey, setSelectedNodeKey] = useState(null);
  const [selectedNodeName, setSelectedNodeName] = useState('');
  const [selectedBookCode, setSelectedBookCode] = useState(null);//디지털은 추가
  const [testList, setTestList] = useState([]);
  const [activeButtons, setActiveButtons] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    // fetchOptions('ROOT', setUnit1Options);
    fncComboRestore();

  }, []);

  
  
  useEffect(() => {//리스트 복원
    const unitName='unit6_digital';
    const key = fnc.getCookie_pure(unitName);
    const activeItem = unit6Options.filter(pre => pre.childKey === key);
    if (activeItem.length > 0) {
      const nodeKeyValue = activeItem[0].childKey;
      const nodeName = activeItem[0].childName;
      const bookCode = activeItem[0].bookCode;

      fncSelectTestnmList(nodeKeyValue, nodeName, bookCode);

    }

  }, [unit6Options])


  function fncComboRestore() {
    let key, unitName;

    fetchOptions('ROOT', setUnit1Options);

    unitName='unit1';
    key = fnc.getCookie_pure(`${unitName}_digital`);

    if (key) {
      fetchOptions_restore(key, setUnit2Options, unitName);
      setInitialComboValue1(key);
    }

 
    unitName='unit2';
    key = fnc.getCookie_pure(`${unitName}_digital`);

    if (key) {
  
      fetchOptions_restore(key, setUnit3Options, unitName);
    }

    unitName='unit3';
    key = fnc.getCookie_pure(`${unitName}_digital`);
  
    if (key) {
      fetchOptions_restore(key, setUnit4Options, unitName, true);
    }

    
    unitName='unit4';
    key = fnc.getCookie_pure(`${unitName}_digital`);
    if (key) {
      fetchOptions_restore(key, setUnit5Options, unitName, true);
      setInitialComboValue4(key);
    }
    
    unitName='unit5';
    key = fnc.getCookie_pure(`${unitName}_digital`);
    if (key) {
      fetchOptions_restore(key, setUnit6Options, unitName);
      setInitialComboValue5(key);
    }

    unitName='unit6';
    key = fnc.getCookie_pure(`${unitName}_digital`);
    if (key) {
      fetchOptions_restore(key, setUnit7Options, unitName);
    }

  }

  //------------복구용
  const fetchOptions_restore = (parentKey, setOptions, unitName, isInsertTitle=false) => {//setOptions은 setUnit1Options의 주소 연결임
    fncSelect_exam_list_fastSearch_restore(parentKey, setOptions, unitName, isInsertTitle);
  };

  const fncSelect_exam_list_fastSearch_restore = (parentKey, setOptions, unitName, isInsertTitle) => {
    const userId = teacherId;

    fnc.sendAxios('/select/book/combo_digital', { parentKey, choJoongGo, userId }, (res) => {
      if (res && Array.isArray(res)) {

        if(isInsertTitle) {
          const updatedOptions = [{ childKey: '0', childName: '선택하세요' }, ...res];
          setOptions(updatedOptions); 
        } else {
          setOptions(res);
        }

        setActiveButtons(prevState => ({...prevState, [unitName]: parentKey}));
      } else {
        console.log(res, '/kuesbBookRoutes/select/book/combo_digital');
      }
    });
  };

 
 //------------클릭용
const fetchOptions = (parentKey, setOptions, isInsertTitle=false, unitName = '') => {//setOptions은 setUnit1Options의 주소 연결임
  fncSelect_exam_list_fastSearch(parentKey, setOptions, isInsertTitle, unitName);
};

const fncSelect_exam_list_fastSearch = (parentKey, setOptions, isInsertTitle, unitName) => {
  const userId = teacherId;
//choJoongGo 넣는거 잊지말 것
  fnc.sendAxios('/select/book/combo_digital', { parentKey, userId, choJoongGo }, (res) => {
    if (res && Array.isArray(res)) {
         // 선택하세요 옵션을 맨 앞에 추가
        if(isInsertTitle) {
            const updatedOptions = [{ childKey: '0', childName: '선택하세요' }, ...res];
            setOptions(updatedOptions); 

            if(unitName == 'unit3') {
              setInitialComboValue4('0');  
            }else if (unitName == 'unit4') {
              setInitialComboValue5('0');  
            }
    
        } else {
            setOptions(res);
        }
 
    } else {
      console.log(res, '/kuesbBookRoutes/select/book/combo_digital');
    }
  });
};



const handleComboboxChange1 = (option) => {
  handleButtonClick(option.value, option.label, option.bookCode, "unit1", setUnit2Options);
}

const handleComboboxChange4 = (option) => {
  handleButtonClick(option.value, option.label, option.bookCode, "unit4", setUnit5Options);
}
const handleComboboxChange5 = (option) => {

  handleButtonClick(option.value, option.label, option.bookCode, "unit5", setUnit6Options);
}



  const handleButtonClick = (key, name, bookCode = '', unitName, setOptions) => {
   
    setActiveButtons(prevState => ({
      ...prevState,
      [unitName]: key
    }));


    let isInsertTitle = false;

    switch (unitName) {
      case 'unit3':
      case 'unit4':
        isInsertTitle = true;//선택하세요 추가
        break;
    }
    


    fnc.setCookie_pure(`${unitName}_digital`, key);

    fetchOptions(key, setOptions, isInsertTitle, unitName);


    if(unitName =='unit5' || unitName =='unit6') {

      setSelectedNodeKey(key);
      setSelectedNodeName(name);
      setSelectedBookCode(bookCode);

      // fncSelectTestnmList(key, name, bookCode);//리스트 일단 죽임
    }

   


      switch (unitName) {
        case 'unit1':
          setUnit2Options([]);
          setUnit3Options([]);
          setUnit4Options([]);
          setUnit5Options([]);
          setUnit6Options([]);
          setUnit7Options([]);
          setActiveButtons(prevState => ({
            ...prevState,
            unit2: null,
            unit3: null,
            unit4: null,
            unit5: null,
            unit6: null,
            unit7: null
          }));
          fnc.setCookie_pure('unit2_digital', null);
          fnc.setCookie_pure('unit3_digital', null);
          fnc.setCookie_pure('unit4_digital', null);
          fnc.setCookie_pure('unit5_digital', null);
          fnc.setCookie_pure('unit6_digital', null);
          fnc.setCookie_pure('unit7_digital', null);
          break;
        case 'unit2':
          setUnit3Options([]);
          setUnit4Options([]);
          setUnit5Options([]);
          setUnit6Options([]);
          setUnit7Options([]);
          setActiveButtons(prevState => ({
            ...prevState,
            unit3: null,
            unit4: null,
            unit5: null,
            unit6: null,
            unit7: null
          }));
          fnc.setCookie_pure('unit3_digital', null);
          fnc.setCookie_pure('unit4_digital', null);
          fnc.setCookie_pure('unit5_digital', null);
          fnc.setCookie_pure('unit6_digital', null);
          fnc.setCookie_pure('unit7_digital', null);
          break;
        case 'unit3':
          setUnit4Options([]);
          setUnit5Options([]);
          setUnit6Options([]);
          setUnit7Options([]);
          setActiveButtons(prevState => ({
            ...prevState,
            unit4: null,
            unit5: null,
            unit6: null,
            unit7: null
          }));
          fnc.setCookie_pure('unit4_digital', null);
          fnc.setCookie_pure('unit5_digital', null);
          fnc.setCookie_pure('unit6_digital', null);
          fnc.setCookie_pure('unit7_digital', null);
          break;
        case 'unit4':

      
          setUnit5Options([]);
          setUnit6Options([]);
          setUnit7Options([]);
          setActiveButtons(prevState => ({
            ...prevState,
            unit5: null,
            unit6: null,
            unit7: null
          }));
          fnc.setCookie_pure('unit5_digital', null);
          fnc.setCookie_pure('unit6_digital', null);
          fnc.setCookie_pure('unit7_digital', null);
          break;
        case 'unit5':
          setUnit6Options([]);
          setUnit7Options([]);
          setActiveButtons(prevState => ({
            ...prevState,
            unit6: null,
            unit7: null
          }));
          fnc.setCookie_pure('unit6_digital', null);
          fnc.setCookie_pure('unit7_digital', null);
          break;
        case 'unit6':
          setUnit7Options([]);
          setActiveButtons(prevState => ({
            ...prevState,
            unit7: null
          }));
          fnc.setCookie_pure('unit7_digital', null);
          break;
        default:
          break;
      }

  };

  

  function fncSelectTestnmList(nodeKeyValue, nodeName, bookCode) {//digital은 bookCode가 더 있다.
    const userId = teacherId;
    const stCode = fnc.getCookie('stCode');
    setSelectedNodeKey(nodeKeyValue);
    setSelectedNodeName(nodeName);
    setSelectedBookCode(bookCode);

    // fnc.sendAxios('/select/digital/fastSearch/testName', { nodeKeyValue, userId, stCode }, (res) => {
    //   if (res && Array.isArray(res)) {
    //     setTestList(res);
    //   } else {
    //     console.log(res, 'select/exam/fastSearch/testName');
    //   }
    // });
  };

  function onConfirm() {

   if(!selectedNodeKey) {
    alert('대상이 없습니다.');
    return;
   }

    // alert(selectedBookCode)
    setSelectedUnitItem({value: selectedNodeKey, label: selectedNodeName, bookCode: selectedBookCode});
    dispatch(setHeaderInfo({ title: selectedNodeName, direction: -1 }));
    dispatch(setSelectedUnit_pdf(selectedNodeName));
    setIsFastSearch(false);
    dispatch(setIsBookStudy(false));

  }

  //=======================================================
  return (
    <div className="DigitalFastSearch">
      {/* <HeaderTitle onBackClick={() => {setIsFastSearch(false)}} title=" " direction={1} 
        style={{}}
      /> */}

      <Button className='btnClose-me' onClick={() => setIsFastSearch(false)} >
              <i class="fa-solid fa-xmark"></i>
      </Button> 

      <div className="main">
        <div className="main-inner">
            {false && <div className="boxRect">
              <div className="lblTecId">선생님ID</div>
              <div className="unit1">
                <input
                  type="text"
                  className="txtTeacherID"
                  value={teacherId}
                  onChange={(e) => setTeacherId(e.target.value)}
                />
              </div> 
          </div>}
          <div className='box-cmb1'>
            <Combobox2 className='cmb cmb1' options={unit1Options} handleChange={handleComboboxChange1} initialComboValue={initialComboValue1}/>
            <p className='lbl-cmb1'>이전(다음) → 개정 찾기  제목 클릭</p>
          </div>
          
          <UnitBox label="학년" options={unit2Options} activeKey={activeButtons.unit2} unitName="unit2" onOptionClick={handleButtonClick} setOptions={setUnit3Options} />
          <UnitBox label="과목" options={unit3Options} activeKey={activeButtons.unit3} unitName="unit3" onOptionClick={handleButtonClick} setOptions={setUnit4Options} />
          
          {/* <UnitBox label="개정" options={unit1Options} activeKey={activeButtons.unit1} unitName="unit1" onOptionClick={handleButtonClick} setOptions={setUnit2Options} /> */}
          {/* <UnitBox label="단원1" options={unit4Options} activeKey={activeButtons.unit4} unitName="unit4" onOptionClick={handleButtonClick} setOptions={setUnit5Options} /> */}
          
          <div className='box-cmb2'>
            <p>제작사</p>
            <Combobox2 className='cmb' options={unit4Options} handleChange={handleComboboxChange4} initialComboValue={initialComboValue4}/>
            <i class="fa-solid fa-arrow-right"></i>
            <p>교재 제목</p>
            <Combobox2 className='cmb' options={unit5Options} handleChange={handleComboboxChange5} initialComboValue={initialComboValue5}/> 
          </div>
          
          
          {/* <UnitBox label="단원2" options={unit5Options} activeKey={activeButtons.unit5} unitName="unit5" onOptionClick={handleButtonClick} setOptions={setUnit6Options} /> */}
          <div className='box-danwon'>
            <p className='lbl-danwon'>단원명</p>
            <UnitBox label="단원명" options={unit6Options} activeKey={activeButtons.unit6} unitName="unit6" onOptionClick={handleButtonClick} setOptions={setUnit7Options} />
          </div>
         

          {/* <section className="section-list">
            <div className="box-tb_pdf_list" >
              <table className="table-hong">
                <thead>
                  <tr>
                    <th style={{ width: '5rem' }}>no</th>
                    <th style={{ width: 'auto' }}>시험지명</th>
                    <th style={{ width: '5rem' }}>문항수</th>
                  </tr>
                </thead>
                <tbody>
                  {testList.map((test, index) => (
                    <tr key={index} className="row_testnm_list">
                      <td><span>{index + 1}</span></td>
                      <td style={{ textAlign: 'left', paddingLeft: '0.5rem' }}><span>{test.testNm}</span></td>
                      <td><span>{test.munCnt}</span></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </section> */}

          <section className="section-menu-btn">     
               <Button className="btnCancel"  onClick={() => {setIsFastSearch(false)}}>취소</Button>
               <Button className="btnConfirm"  onClick={onConfirm}>확인</Button>
          </section>
        </div>
      </div>
    </div>
  );
};


const UnitBox = ({ label, options, activeKey, unitName, onOptionClick, setOptions }) => {

  return (
    <div className="boxRect">
      <div className="lblUnit">{label}</div>
      <div className="unit">
        {options.map((option, index) => (
          <div className='box-btn'>
            <button
              key={index}
              className={`btnUnit ${activeKey === option.childKey ? 'active' : ''}`}
              onClick={() => onOptionClick(option.childKey, option.childName, option.bookCode, unitName, setOptions)}
            >
              <div className="childName">
                {option.childName} {option.testCnt > 0 ? <span className="testCnt">({option.testCnt})</span> : ""}
              </div>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DigitalFastSearch;
