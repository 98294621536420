import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateMarkingList } from '../reduxToolkit/redux';
import './Detail_omr.scss';
import Button from '@mui/material/Button';
import fnc from '../mymod/commFunction';

const Detail_omr = ({isRefresh, setIsRefresh}) => { 
    const dispatch = useDispatch();
    // const markingList = useSelector(state => state.reduxStore.markingList);
    // const list = useSelector(state => state.reduxStore.list_pdf_marking);
    const [selectedValues, setSelectedValues] = useState({});
    const [list, setList] = useState([]);
    const [markingList, setMarkingList] = useState({});
    const lmsListCode = useSelector(state => state.reduxStore.lmsListCode);

    useEffect(() => {
        fncSelect_list();
      }, []);

      
    useEffect(() => {
        if (markingList) {
            setSelectedValues(markingList);
        }
    }, [markingList]);

    const handleRadioChange = (idx, value) => {
        const updatedValues = {
            ...selectedValues,
            [idx + 1]: value
        };
        setSelectedValues(updatedValues);
    };

     
  function fncSelect_list() {
    const acaCode = fnc.getCookie('acaCode');
    const stCode = fnc.getCookie('stCode');
    
    fnc.sendAxios('/select/lms/course/omrGetDap', { acaCode, parentCode: lmsListCode }, (res) => {
      if (res && Array.isArray(res)) { 

        console.log(res, 'res');
        setList(getRecords(res));
      } else {
        console.log(res,"kuesbLmsRoutes/select/lms/course/omrGetDap");
      }
    });
  }



  
  function getRecords(res) {
    let records = [];
    let isMultipleChoice = null;
    for (let i = 0; i < res.length; i++) {      
        const sno= res[i]["sno"];
        const munNo= res[i]["munNo"];//list 코드 parent Code로 사용
        const dap = res[i]["dap"];
        const point = res[i]["point"];//배점

        if ([1, 2, 3, 4, 5].includes(Number(dap))) {
          isMultipleChoice = true;         
        }else {
          isMultipleChoice = false;
        }
      
        records.push({ sno, munNo, dap, point, isMultipleChoice });
    }
    return records;
  }



  function onsubmit() {


    for (let i = 0; i < list.length; i++) {
      if (!selectedValues[i + 1]) {
          alert('모든 문항에 마킹을해주세요.');
          return;
      }
    }

    const rtn = window.confirm('마킹을 제출하시겠습니까?');
    if(!rtn) return;

    const isExistPoints_null = list.some(item => item.point == '');//배점에 공백이 존재하면 
    if (isExistPoints_null) {
      fncChejum();
    }else {
      fncChejum_point();
    }
    // alert(isExistPoints)
    // fncChejum();
    // fncChejum_point();
  }

  
  function fncChejum() {
    let oxBar = null;
    let dapBar = null;
    let markingBar = null;
    let oCnt = 0;
    let xCnt = 0;
     
    let  markingValue, munNo, dap, OX;
    for (let i = 0; i < list.length; i++) {
        munNo = list[i].munNo;
        dap = list[i].dap;
        markingValue = selectedValues[i+1];// 이부분 i+1로 해야

        if (dap == markingValue) {//== 두 개로 해야 함 (나중에 띄어쓰기 처리 해야 함)
            OX = 'O';
            oCnt++;
        } else {
            OX = 'X';
            xCnt++;
        }

        dapBar = dapBar ? dapBar + '@' + dap : dap;
        oxBar = oxBar ? oxBar + '@' + OX : OX;
        markingBar = markingBar ? markingBar + '@' + markingValue : markingValue; 
    }

    const munCnt = oCnt + xCnt;
    const achievement_rate = (oCnt / munCnt) * 100;
    const score = Math.round(achievement_rate * 10) / 10; // 소수 둘째 자리에서 반올림

    fncInsert_usp_markingOmrSt(lmsListCode, markingBar, oxBar, score);

    // console.log(achievement_rate);
    // const truncatedTitle = headerInfo.title;
    // const msg = `\n${truncatedTitle} 학습\n맞음: ${oCnt}/${munCnt} 성취도: ${score}\n입니다`;
    // fncInsert_exam_te_marking_DB({testCode, score, munCnt, oCnt, xCnt, dapBar, markingBar, oxBar})
    // alert(msg);
  };

  function fncChejum_point() {
    let oxBar = null;
    let dapBar = null;
    let markingBar = null;
    let oCnt = 0; // 맞춘 문항 수
    let xCnt = 0; // 틀린 문항 수
    let totalPoints = 0; // 총 배점 합
    let obtainedPoints = 0; // 얻은 점수
    
    let markingValue, munNo, dap, point, OX;
    for (let i = 0; i < list.length; i++) {
        munNo = list[i].munNo;
        dap = list[i].dap;
        point = list[i].point; // 배점
        
        markingValue = selectedValues[i + 1]; // 이부분 i+1로 해야

        totalPoints += parseFloat(point); // 총 배점 합산

        if (dap == markingValue) { // == 두 개로 해야 함 (나중에 띄어쓰기 처리 해야 함)
            OX = 'O';
            oCnt++;
            obtainedPoints += parseFloat(point); // 맞춘 문항의 배점을 합산
        } else {
            OX = 'X';
            xCnt++;
        }

        dapBar = dapBar ? dapBar + '@' + dap : dap;
        oxBar = oxBar ? oxBar + '@' + OX : OX;
        markingBar = markingBar ? markingBar + '@' + markingValue : markingValue; 
    }

    const munCnt = oCnt + xCnt; // 총 문항 수
    const achievement_rate = (obtainedPoints / totalPoints) * 100; // 배점에 따른 성취율 계산
    const score = Math.round(achievement_rate * 10) / 10; // 소수 둘째 자리에서 반올림

    // 결과 저장 함수 호출
    fncInsert_usp_markingOmrSt(lmsListCode, markingBar, oxBar, score);
}



function fncInsert_usp_markingOmrSt(parentCode, dataString, oxString, score) {
    const acaCode = fnc.getCookie('acaCode');
    const stCode = fnc.getCookie('stCode');
    
    fnc.sendAxios('/insert/lms/omr/usp_markingOmrSt', { acaCode, parentCode, stCode, dataString, oxString }, (res) => {
      if (res) { 
        // console.log(res, 'fncInsert_usp_markingOmrSt');
        fncSaveMarkingScore(parentCode, score)
      } else {
        console.log(res,"insert/lms/omr/usp_markingOmrSt");
      }
    });
  }


  
  function fncSaveMarkingScore(parentCode, score) {
    const acaCode = fnc.getCookie('acaCode');
    const stCode = fnc.getCookie('stCode');
    
    fnc.sendAxios('/save/lms/omr/MarkingScore', { acaCode, stCode, parentCode, score }, (res) => {
      if (res) { 
        setIsRefresh(!isRefresh);

        console.log(res, 'save/lms/omr/MarkingScore');
      } else {
        console.log(res,"save/lms/omr/MarkingScore");
      }
    });
  }



  //============================================================================
  return (
    list.length > 0 && (
        <div className='Detail_omr'>
            <div className='wrap-main'>
                <div className="title">OMR 마킹</div>
                <table className="warp-marking-omr">
                    <tbody>
                        {list.map((item, index) => (
                            <tr className="tr_queMenu_omr" key={index}>
                              {/* <p>{item.dap}</p> */}
                                <td>
                                    <span className="NoOmr">{index + 1}</span>
                                    {item.isMultipleChoice &&  [1, 2, 3, 4, 5].map((value) => (
                                        <label
                                            key={value}
                                            className={`custom-radio ${selectedValues[index + 1] == value ? 'selected' : ''}`}
                                            data-number={value}
                                        >
                                            <input
                                                type="radio"
                                                name={`question${index + 1}`}
                                                value={value}
                                                checked={selectedValues[index + 1] == value}
                                                onChange={() => handleRadioChange(index, value)}
                                            />
                                        
                                        </label>
                                    ))}

                                   <input
                                        className={`markingValue_omr ${!item.isMultipleChoice ? 'subjective' : ''}`}
                                        type="text"
                                        spellCheck="false"
                                        autoComplete="off"
                                        value={selectedValues[index + 1] || ''}
                                        onChange={(e) => handleRadioChange(index, e.target.value)}
                                    />

                                    {item.point && <span className='point'>{item.point}점</span> }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Button className='btnSubmit' onClick={onsubmit}>제출</Button>
        </div>
    )
);
};

export default Detail_omr;
