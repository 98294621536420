import './CourseMain.scss';
import React, { useState, useEffect } from "react";
import fnc from '../mymod/commFunction';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';

import { useDispatch, useSelector } from 'react-redux';
import { setList_pdf, setHeaderInfo,setIsRefresh_studentCourseList } from '../reduxToolkit/redux';


const CourseMain = ({selectedItem, handleChange}) => {

    const imgUrl = process.env.REACT_APP_imgUrl;
    const dispatch = useDispatch();
    const isRefresh_studentCourseList = useSelector(state => state.reduxStore.isRefresh_studentCourseList);


    const [list, setList] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectAll, setSelectAll] = useState(false);


    useEffect(() => {
      fncSelect_studentCourseList(selectedItem);
    }, [selectedItem, isRefresh_studentCourseList]);

    
  function fncSelect_studentCourseList(selectedItem) {
    const acaCode = fnc.getCookie('acaCode');
    const stCode = fnc.getCookie('stCode');
    
    fnc.sendAxios('/select/class/studentCourseList', { acaCode, stCode }, (res) => {
      if (res && Array.isArray(res)) { //단원선택 클릭 시 결과 (오른쪽 페이지 list)

        // console.log(res, 'course res');
        setList(res);
        setSelectAll(false);//체크박스 해제
        setSelectedItems([]);//체크박스 해제
        const listPdf = getRecords_studentCourseList(res);
        dispatch(setList_pdf(listPdf));
      } else {
        console.log(res,"select/book/pdfList");
      }
    });
  }

  
  function getRecords_studentCourseList(res) {
    let records = [];
    for (let i = 0; i < res.length; i++) {      
        const pdfFileName= res[i]["pdfFileName"];
        const source = res[i]["source"];
        const page = res[i]["page"];//BookPdf에서는 page, source를 사용한다.

        const testCode = source;
        const munCode = pdfFileName;
        const no = page;
        let rnd = Math.floor(Math.random() * 900) + 100;
        let imagePath = `${imgUrl}/pdf/${testCode}/${pdfFileName}?${rnd}`;

        records.push({ imagePath, testCode, munCode, no, source, page, pdfFileName });
    }
    return records;
  }


  
  function onClickTr( item, index) {
   
  }

  function handleSelectAll() {
    if (selectAll) {
      setSelectedItems([]);
    } else {
      setSelectedItems(list.map((item, index) => index));
    }
    setSelectAll(!selectAll);
  }

  function handleSelectItem(index) {
    const isSelected = selectedItems.includes(index);
    if (isSelected) {
      setSelectedItems(selectedItems.filter(item => item !== index));
    } else {
      setSelectedItems([...selectedItems, index]);
    }
  }

  function handleDeleteSelected() {
    const selectedListItems = list.filter((item, index) => selectedItems.includes(index));

    if(selectedListItems.length === 0){
      alert('강좌를 선택하세요!');
      return null;
    }
    const rtn = window.confirm(selectedListItems.length +'개의 강좌를 취소하시겠습니까?');
    if(!rtn) return null;

    for (let i = 0; i < selectedListItems.length; i++) {
      const sno = selectedListItems[i].sno;
      let isLast = false;
      if(i === selectedListItems.length-1) {
        isLast = true;
      }
      fncDelete_student_course(sno, isLast);
    }
  }

  function fncDelete_student_course(sno, isLast) {
    const acaCode = fnc.getCookie('acaCode');
    const stCode = fnc.getCookie('stCode');
    
    fnc.sendAxios('/delete/class/student_course', { acaCode, sno }, (res) => {
      if (res) { 
        if (isLast) {
          dispatch(setIsRefresh_studentCourseList(!isRefresh_studentCourseList));
          
        }
        console.log(res, ' delete/class/student_course list');
      } else {
        console.log(res,"select/book/pdfList");
      }
    });
  }

    //=========================================
    return (
      //자식 컨포넌트
      <div className="CourseMain">
        <div className="wrap-course-main">
          <section className="section-book-main">
            <table className="table-hong table-course-main">
              <thead>
                <tr>
                  <th>No</th>
                  <th>강사</th>
                  {/* <th>과정</th> */}
                  <th>강좌</th>
                  <th>수강일</th>
                  <th>상태</th>
                   <th><Checkbox checked={selectAll} onChange={handleSelectAll} /></th>
              
                </tr>
              </thead>

              <tbody>
                  {list && list.map((item, index) => (
  
                      <tr key={index} onClick={() => onClickTr(item, index)}>
                       
                       <td>{index+1}</td>
                        <td>{item.gangSa}</td>
                        {/* <td>{item.groupName} </td> */}
                        <td>{item.className}</td>
                        <td>{item.sDate}</td>
                        <td>{item.status == 'Y' ? '수강' : '대기'}</td>
                        <td>
                          <Checkbox checked={selectedItems.includes(index)} onChange={() => handleSelectItem(index)} />
                        </td>
                      </tr>
                  ))}
            
                </tbody>

            </table>
          </section>
        </div>
        
        <div className='box-menu'>
          <Button className='btnDelete' onClick={handleDeleteSelected}>수강취소</Button>    
        </div>
                
      </div>
    );
}

export default CourseMain;