import './LmsMainDetail.scss';
import * as S from '../scss/theme';
import React, { useState, useEffect } from "react";
import fnc from '../mymod/commFunction';
import Button from '@mui/material/Button';
import Combobox from '../comm/Combobox_noBMK';
import Select from 'react-select';
import HeaderTitle from "../comm/HeaderTitle";
import Detail_homework from './Detail_homework';
import Detail_test from './Detail_test';
import Detail_download from './Detail_download';
import Detail_omr from './Detail_omr';
import Detail_omrResult from './Detail_omrResult';

import { useDispatch, useSelector } from 'react-redux';
import { setIsModal } from '../reduxToolkit/redux';



const LmsMainDetail = ({ sno, classCode, kind}) => {

    const dispatch = useDispatch();
  //  const isModal = useSelector((state) => state.reduxStore.isModal);
    const lmsListCode = useSelector(state => state.reduxStore.lmsListCode);//parentCode
    const [list, setList] = useState([]);
    const [selectedIdx, setSelectedIdx] = useState(0);
    const [isRefresh, setIsRefresh] = useState(false);
    const [selectedUnitItem, setSelectedUnitItem] = useState({});
    const [isModalTest, setIsModalTest] = useState(false);
    const [markingCnt, setMarkingCnt] = useState(0);
    const userName = fnc.getCookie('userName');

    useEffect(() => {
      fncSelect_list();
      fncSelect_isMarkingComplete(lmsListCode);
    }, [sno, isRefresh]);

    function fncSelect_list() {
      const acaCode = fnc.getCookie('acaCode');
      const stCode = fnc.getCookie('stCode');
      
      fnc.sendAxios('/select/lms/course/detailSt', { sno }, (res) => {
        if (res && Array.isArray(res)) { 
          setList(getRecords(res));
        } else {
          console.log(res,"select/book/pdfList");
        }
      });
    }

    function fncSelect_isMarkingComplete( parentCode) {
      const acaCode = fnc.getCookie('acaCode');
      const stCode = fnc.getCookie('stCode');

      // console.log({ acaCode, stCode, parentCode });
      fnc.sendAxios('/select/lms/omr/isMarkingComplete', { acaCode, stCode, parentCode }, (res) => {
        if (res && Array.isArray(res)) { 
          setMarkingCnt(res[0].cnt);
        //  console.log(res, '------------- lmsListCode');
        } else {
          console.log(res,"select/book/pdfList");
        }
      });
    }
  
    function getRecords(res) {
      let records = [];
      for (let i = 0; i < res.length; i++) {      
          const kind= res[i]["kind"];
          const sDate = res[i]["sDate"];
          const jindo = res[i]["진도"];
          const homework = res[i]["과제"];
          const content = res[i]["내용"];
          const title = res[i]["제목"];
          const fileName = res[i]["파일명"];
              
          records.push({ kind, sDate, content, jindo, homework, title, fileName });
      }
      return records;
    }

  
    function onClickTr( item, index) {
      setSelectedIdx(index);//값넣기는 일단 실패
    
    }

    function fncClose() {
      dispatch(setIsModal(false))
    }

    
    //=========================================
    return (

      <div className="LmsMainDetail">
        <HeaderTitle onBackClick={fncClose} title={`${userName}의 과제`} direction={1} />
        <div className="wrap-course-main">
          <section className="section-book-main">
            {list.length > 0 && (
              <div className='box-board'>
                <div className='box-title'>
                    <div className="txt_detail txt_title">{list[0].title}</div>
                    <div className="txt_detail txt_date">{list[0].sDate}</div>
                </div>   
                <div className="txt_detail txt_jindo">{list[0].jindo}</div>
                <div className="txt_detail txt_homework">{list[0].homework}</div>  
                <div className="txt_detail txt_content">{list[0].content}</div>

              </div>
            )}

          </section>

          <section className='section-homework'>
            <Detail_homework />
          </section>        

          <section className='section-test'>
            <Detail_test/>
          </section>     

          <section className='section-download'>
            <Detail_download/>
          </section>        

          {!markingCnt &&  <section className='section-omr'>
            <Detail_omr isRefresh={isRefresh} setIsRefresh={setIsRefresh}/>
          </section>  }     

          {!!markingCnt &&  <section className='section-omrResult'>
            <Detail_omrResult />
          </section> }     


        </div>


      </div>
    );
}

export default LmsMainDetail;