import './UpdateInfo.scss';
import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import TermsAgreement from './TermsAgreement'; // 이용약관 컴포넌트 임포트
import AddressDaum from './AddressDaum';
import fnc from '../mymod/commFunction';
import * as S from '../scss/theme';

function UpdateInfo() {
  const svrUrl = process.env.REACT_APP_SERVER_URL;
  const navigate = useNavigate();

  const [userInput, setUserInput] = useState({
    username: '',
    userid: '',
    email: '',
    phone: '',
    phone_mom: '',
    password: '',
    confirmPassword: '',
    address: '',
    detailAddress: '',
    zipcode: '',
    kind: 0,
  });

  const [errorMessages, setErrorMessages] = useState({});
  const [isCheckingUserid, setIsCheckingUserid] = useState(false);
  const [useridAvailable, setUseridAvailable] = useState(true);
  const [agreedTerms, setAgreedTerms] = useState(false);
  const [agreedPrivacy, setAgreedPrivacy] = useState(false);
  const [isModal, setIsModal] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInput({ ...userInput, [name]: value,  });
  };

  useEffect(() => {
    fncSelectUserInfo();
  }, []);


  function fncSelectUserInfo() {
    const id = fnc.getCookie('id');

    fnc.sendAxios('/select/userInfo', { id }, (res) => {
      if (res && Array.isArray(res)) {
        const obj = res[0];
        getRecords(obj)
      } else {
        console.log("kuesbLoginRoutes/속성이 obj 내에 없습니다.");
      }
    });
  }

  function getRecords(obj) {

    setUserInput(pre => ({...pre, 
      username: obj.name,
      email: obj.email,
      phone: obj.hp,
      phone_mom: obj.hp_parent,
      address: obj.adress1,
      detailAddress: obj.adress2,
      zipcode: obj.zipCode,
      kind: obj.kind,
      userid: obj.id,
    }));
  }
  //----------------------------------

  const validateForm = () => {

    if (userInput.kind == 0) {
      alert('회원유형을 선택해 주세요.');
      return false;
    }

    if (!userInput.userid.trim()) {
      alert('아이디를 입력해 주세요.');
      return false;
    }

    if (!userInput.username.trim()) {
      alert('이름을 입력해 주세요.');
      return false;
    }

    if (!userInput.email) {
      alert('이메일을 입력해 주세요.');
      return false;
    } else if (!/\S+@\S+\.\S+/.test(userInput.email)) {
      alert('유효한 이메일 주소를 입력해 주세요.');
      return false;
    }


    const phonePattern = /^[0-9]{10,11}$/;
    if (!userInput.phone) {
      alert( '휴대전화 번호를 입력해 주세요.');
      return false;
    } else if (!phonePattern.test(userInput.phone)) {
      alert('유효한 휴대전화 번호를 입력해 주세요.');
      return false;
    }

    if (!userInput.phone_mom && userInput.kind == 1) {
      alert( '부모님 휴대전화 번호를 입력해 주세요.');
      return false;
    } else if (!phonePattern.test(userInput.phone_mom)  && userInput.kind == 1) {
      alert('유효한 부모님 휴대전화 번호를 입력해 주세요.');
      return false;
    }

    if (!userInput.address) {
      alert('주소를 입력해 주세요.');
      return false;
    }

    if (!userInput.detailAddress) {
      alert('상세주소를 입력해 주세요.');
      return false;
    }

    
    if (!userInput.password) {
      alert('비밀번호를 입력해 주세요.');
      return false;
    }

    // if (userInput.password !== userInput.confirmPassword) {
    //   alert( '비밀번호가 일치하지 않습니다.');
    //   return false;
    // }

    
    // if (!agreedTerms) {
    //   alert('이용약관에 동의해야 합니다.');
    //   return false;
    // }

    // if (!agreedPrivacy) {
    //   alert('개인정보 수집에 동의해야 합니다.');
    //   return false;
    // }

    return true;
  };

 
  function onClose() {
    navigate(-1);
  }


  function fncCheckPwAndUpdate() {
    const id = userInput.userid;
    const pw = userInput.password;

    fnc.sendAxios('/kuesbLogin', { id, pw }, (res) => {
      if (res && Array.isArray(res) && res.length > 0) {
            fncUpdateUser();
      } else {
          alert('정보가 옳바르지 않습니다.');
      }
    });
  }

  function fncUpdateUser() {

    if (!validateForm()) return;

    const rtn = window.confirm('정보를 수정하시겠습니까?');
    if (!rtn) return;

    fnc.sendAxios('/update/userInfo', {   
      userId: userInput.userid,
      userName: userInput.username,
      email: userInput.email,
      hp: userInput.phone,
      hp_parent: userInput.phone_mom,
      zipCode: userInput.zipcode, 
      adress1: userInput.address,
      adress2: userInput.detailAddress,
    }, (res) => {
      if (res) {
        if(res.success) {
          alert('완료되었습니다.');
        }
      } else {
        alert('회원정보변경에 실패했습니다.');
      }
    });
  }

  const handleAddressChange = (address, zipcode) => {
    setUserInput({
      ...userInput,
      address: address,
      zipcode: zipcode,
    });
  };

  const onAgreeTermsChange = (isAgreed) => {
    setAgreedTerms(isAgreed);
  };

  const onAgreePrivacyChange = (isAgreed) => {
    setAgreedPrivacy(isAgreed);
  };

  return (
    <S.Section_n>
    <div className='UpdateInfo'>
     <div className='wrap-main'>
      <section className='section-singnup'>
      <p className='title'>개인 정보 변경</p>
          <div className='box_signUp'>
            <div className='inner_singUp'>
              <article className='article-kind'>
                <label className="main_lbl">*회원유형</label>
                <select
                  className="cmbKind"
                  name='kind'
                  value={userInput.kind}
                  onChange={handleChange}
                  disabled 
                >
                  <option value="0">유형을 선택하세요!</option>
                  <option value="1">학생</option>
                  <option value="2">선생님</option>
                  <option value="3">관리자</option>
                </select>
        
              </article>

              <div>
                <label htmlFor="userid">*아이디</label>
                <input
                  type="text"
                  id="userid"
                  name="userid"
                  value={userInput.userid}
                  onChange={handleChange}
                  required
                  placeholder='아이디를 입력해 주세요.'
                  disabled 
                />
    
              </div>

              <div>
                <label htmlFor="username">*이름</label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  value={userInput.username}
                  onChange={handleChange}
                  required
                  placeholder='이름을 입력해 주세요.'
                />
 
              </div>

              <div>
                <label htmlFor="email">*이메일</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={userInput.email}
                  onChange={handleChange}
                  required
                  placeholder='이메일을 입력해 주세요.'
                />
          
              </div>

              <div>
                <label htmlFor="phone">*휴대전화</label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  value={userInput.phone}
                  onChange={handleChange}
                  required
                  placeholder='- 없이 입력해주세요.'
                />
   
              </div>

             { userInput.kind == 1 && <div>
                <label htmlFor="phone_mom">*부모님 휴대전화</label>
                <input
                  type="text"
                  id="phone_mom"
                  name="phone_mom"
                  value={userInput.phone_mom}
                  onChange={handleChange}
                  required
                  placeholder='- 없이 입력해주세요.'
                />
  
              </div>}


              <div className='box-adress'>
              <label htmlFor="address">*주소 {userInput.zipcode ? `(${userInput.zipcode})` : ''}</label>
                <input
                  type="text"
                  id="address"
                  name="address"
                  value={userInput.address}
                  readOnly
                  placeholder='주소를 검색해 주세요.'
                />
                <Button className='btnAdressSearch'  color="error" onClick={() => setIsModal(true)} >
                  주소검색
                </Button>
 
                 <input
                  type="text"
                  id="detailAddress"
                  name="detailAddress"
                  value={userInput.detailAddress}
                  onChange={handleChange}
                  placeholder='상세주소를 입력해 주세요.'
                />
            </div>

            <div>
                <label htmlFor="password">*비밀번호</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={userInput.password}
                  onChange={handleChange}
                  required
                  placeholder='비밀번호를 입력해 주세요.'
                />
              </div>
            
            </div>

          </div>

       

          <div className='box_menu'>
            <Button className='btnClose h-btn-gray' onClick={onClose}>취소</Button>
            <Button  className='btnSave h-btn-red' onClick={fncCheckPwAndUpdate} >변경하기</Button>
          </div>
  
      </section>
    
      {isModal && <div className='box_modal'>
        <AddressDaum setIsModal={setIsModal} handleAddressChange={handleAddressChange} />
      </div> }   
      
    </div>
    </div>
    </S.Section_n>
  );
}

export default UpdateInfo;
