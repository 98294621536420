import "./Lms.scss";
import * as S from "../scss/theme";
import HeaderTitle from "../comm/HeaderTitle";
import React, { useState, useEffect } from "react";
import { setHeaderInfo, setIsModalTestLms, setSelectedUnit_pdf } from '../reduxToolkit/redux';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import fnc from "../mymod/commFunction";
import MyTreeView from "./MyTreeView";
import LmsMain from "./LmsMain";
import Exam2Main from '../page_exam/Exam2Main';
import BookMain from '../page_book/BookMain';
import DigitalMain from  '../page_digital/DigitalMain';


const Lms = () => {
    const dispatch = useDispatch();
    
    const [selectedNodeItem, setSelectedNodeItem] = useState({});
    const [kind, setKind] = useState('lesson');
    const [isClickGangsa, setIsClickGangsa] = useState(true);
    const [isClickClass, setIsClickClass] = useState(false);
    const [isClickGroup, setIsClickGroup] = useState(false);
    const [optionsUniTable, setOptionsUniTable] = useState([]);
    const isModalTest = useSelector((state) => state.reduxStore.isModalTest);
    const bookCode = useSelector((state) => state.reduxStore.bookCode);

    const isModalTestLms = useSelector((state) => state.reduxStore.isModalTestLms);
    const selectedBookCode = useSelector((state) => state.reduxStore.selectedBookCode);
    const bookTestKind = useSelector((state) => state.reduxStore.bookTestKind);
    const selectedBookName = useSelector((state) => state.reduxStore.selectedBookName);

// console.log(selectedBookCode, ' selectedBookCode');

    useEffect(() => {
        const userName = fnc.getCookie('userName');
        dispatch(setHeaderInfo({ title: `${userName} 학생의 LMS`, direction: -1 }));
        dispatch(setIsModalTestLms(false));//처음에는 무조건 false
    }, []);

    useEffect(() => {
        if (selectedNodeItem.node) {
            // console.log(selectedNodeItem.node.key, 'selectedNodeItem');
        }
    }, [selectedNodeItem]);

    function fncSelectGangsaCombo(acaCode) {
        fnc.sendAxios("/select/class/gangsaList", { acaCode }, (res) => {
            if (res) {
                // setOptionsByIndex1(res);
            } else {
                console.log(res, "속성이 obj 내에 없습니다.");
            }
        });
    }

    function fncSelectClassTable(acaCode, groupCode) {
        fnc.sendAxios("/select/class/classList", { acaCode, groupCode }, (res) => {
            if (res) {
                setOptionsUniTable(res);
            } else {
                console.log(res, "속성이 obj 내에 없습니다.");
            }
        });
    }


    const [selectedUnitItem, setSelectedUnitItem] = useState({});


    return (
        <>
            <S.Section>
                <HeaderTitle />
                <div className="Lms">
                    <div className="wrap-book">
                        <section className="section-btn">
                            <MyTreeView setSelectedNodeItem={setSelectedNodeItem} />
                        </section>
                    </div>

                    {isClickGangsa &&
                        <section className="section-bookMain">
                            {isClickGangsa && selectedNodeItem.node && <LmsMain classCode={selectedNodeItem.node.key} kind={kind} />}
                        </section>
                    }
                </div>

                
          {isModalTestLms && bookTestKind && (
                    <S.Modal>

                            <HeaderTitle onBackClick={()=>dispatch(setIsModalTestLms(false))} title={selectedBookName} direction={1}/>
                    
                            {bookTestKind === 'TEST' ? (
                                    <div className="section-bookMain">
                                        <Exam2Main selectedItem={{value: selectedBookCode}} />
                                    </div>
                            ) : bookTestKind === '출판물' ? (
                                    <div className="section-bookMain">
                                        <BookMain selectedItem={{bookCode: selectedBookCode}} />
                                    </div>
                            ) : bookTestKind === '디지털' ? (
                                    <div className="section-bookMain">
                                        <DigitalMain selectedItem={{bookCode: selectedBookCode}} />
                                    </div>

                            ) : (
                                <div className="section-bookMain">
                                   
                                </div>
                            )}    

                            {/* <div className="section-bookMain">
                                {bookTestKind === 'TEST' ? (
                                    <Exam2Main selectedItem={{ value: selectedBookCode }} />
                                ) : bookTestKind === 'book' ? (
                                    <BookMain selectedItem={{ bookCode: selectedBookCode }} />
                                ) : bookTestKind === 'digital' ? (
                                    <DigitalBookMain selectedItem={{ bookCode: selectedBookCode }} />
                                ) : (
                                    <DefaultComponent />
                                )}
                            </div> */}
            
                </S.Modal>

               )}

           
            </S.Section>

      
          
           

        </>
    );
};

export default Lms;
