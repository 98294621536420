import './BookPdf_marking.scss';
// import * as S from '../scss/theme';
import React, { useState, useEffect } from "react";
import HeaderTitle from '../comm/HeaderTitle';
import BookPdf_marking_menu from './BookPdf_marking_menu';
import BookPdf_marking_omr from './BookPdf_marking_omr';

import { useSelector, useDispatch } from 'react-redux';
import { setHeader_dev, initializeMarkingList, setList_que, setRowIdx_que, setIsRefresh_bookMain } from '../reduxToolkit/redux';
import { Button } from '@mui/material';
import fnc from '../mymod/commFunction';
import fncS3 from '../mymod/commFunction3';

const BookPdf_marking = ({ setIsModal }) => {
    const dispatch = useDispatch();

    const imgUrl = process.env.REACT_APP_imgUrl_S3;
    const headerInfo = useSelector(state => state.reduxStore.headerInfo);
    const list = useSelector(state => state.reduxStore.list_pdf_marking);
    // const list_pdf = useSelector(state => state.reduxStore.list_pdf);
    const rowIdx_pdf = useSelector(state => state.reduxStore.rowIdx_pdf);
    const markingList = useSelector(state => state.reduxStore.markingList);
    const isRefresh_bookMain = useSelector(state => state.reduxStore.isRefresh_bookMain);
    const userKind = useSelector(state => state.reduxStore.userInfo.kind);

    const [imagePath, setImagePath] = useState('');
    const [title, setTitle] = useState('');
    const [selectedListItem, setSelectedListItem] = useState({});
    const [record_que, setRecord_que] = useState([]);

    useEffect(() => {
      dispatch(setHeader_dev('BookPdf_marking'));
      // console.log(list, 'list_pdf_marking');
    }, []);

    useEffect(() => {
        const records = getRecords_pdf_detail_que(list);

        console.log(list, 'commque-');
        setRecord_que(records);
        dispatch(initializeMarkingList(records.length));
        setSelectedListItem(records[rowIdx_pdf]);
        dispatch(setList_que(records));       
     }, [list, dispatch]);


    function getRecords_pdf_detail_que(userJsonObj) {

      console.log(userJsonObj, ' userJsonObj');
        let records = [];
        const acaCode = fnc.getCookie('acaCode');

        for (let i = 0; i < userJsonObj.length; i++) {
            let source = userJsonObj[i]['source'];
            let folder = userJsonObj[i]['folder'].replaceAll('\\', '/');
            let commQue = userJsonObj[i]['commQue'];//공통지문
            let imgFileName = userJsonObj[i]['imgFileName'];
            let kind = userJsonObj[i]['kind'];
            let page = userJsonObj[i]['page'];
            let pdfFileName = userJsonObj[i]['pdfFileName'];
            let queNo = userJsonObj[i]['queNo'];
            let realNo = userJsonObj[i]['realNo'];
            let cntMarkingSt = userJsonObj[i]['cntMarkingSt'];
            let dap = userJsonObj[i]['dap'];
            let imgCol = userJsonObj[i]['imgCol'];
            let ImgSize = userJsonObj[i]['ImgSize'];
            let ImgSizeSol = userJsonObj[i]['ImgSizeSol'];
            let qrCode = userJsonObj[i]['qrCode'];

            // let rnd = Math.floor(Math.random() * 900) + 100;
            let imagePath = fncS3.getS3Path( `${imgUrl}/${acaCode}/${folder}/${imgFileName}`);
            let imagePath_sol = fncS3.getS3PathLong( `${imgUrl}/${acaCode}/${folder}/풀이_${imgFileName}`);

            let imagePath_comm = '';
            if (commQue) {
                imagePath_comm = fncS3.getS3PathLong( `${imgUrl}/${acaCode}/${folder}/${commQue}.gif`);
            } else {
                imagePath_comm ='';
            }

            records.push({
                source, imagePath, folder, imgFileName, kind, page, pdfFileName, queNo, realNo, dap, imgCol,
                ImgSize, ImgSizeSol, qrCode, imagePath_sol, imagePath_comm, commQue, cntMarkingSt
            });
        }
        return records;
    }

    function onSubmit() {

      // alert('제출')

      let isMarkingComplete = fncIsMarkingComplete();
      if (!isMarkingComplete) {
          alert('마킹을 완료하세요!');
          return false;
      }
      let rtn = window.confirm('제출하시겠습니까?');
      if (!rtn) return false;
      fncChejum();

    }
    const fncIsMarkingComplete = () => {
      for (let key in markingList) {
          if (markingList[key] === '') {
              return false;
          }
      }
      return true;
  };
  
  function fncChejum() {

    let Ocnt = 0;
    let Xcnt = 0;
    let source, dap, page, realNo, queNo, markingValue, OX, kind;
    for (let i = 0; i < record_que.length; i++) {
        source = record_que[i].source;
        dap = record_que[i].dap;
        page = record_que[i].page;
        realNo = record_que[i].realNo;
        queNo = record_que[i].queNo;
        kind = record_que[i].kind;
        markingValue = markingList[i + 1]; // Redux에서 마킹 값 가져오기

        if (dap === markingValue || (kind === '개념' && markingValue === '학습완료') ) {
            OX = 'O';
            Ocnt++;
        } else {
            OX = 'X';
            Xcnt++;
        }
        //비동기로 실행돼도 상관 없음
        fncInsert_te_marking_mun_st_pdf({source, page, realNo, queNo, OX, marking: markingValue});
    }

    const Tcnt = Ocnt + Xcnt;
    const achievement_rate = (Ocnt / Tcnt) * 100;
    const truncatedTitle = headerInfo.title;
    const roundedAchievementRate = Math.round(achievement_rate * 10) / 10; // 소수 둘째 자리에서 반올림
    const msg = `\n${truncatedTitle} 학습\n맞음: ${Ocnt}/${Tcnt} 성취도: ${roundedAchievementRate}\n입니다`;

    fncInsert_te_Marking_PDF(source, page , Tcnt, achievement_rate);//alert(msg); 앞에 있어야 적용됨

    alert(msg);

  

    dispatch(setIsRefresh_bookMain(!isRefresh_bookMain));    
    setIsModal(false);//창 닫기



     // 작업 완료될 때까지 주석 처리된 SMS 전송 함수
    // fncSendSMS_pdfHomework(msg, "");

};

      function fncInsert_te_marking_mun_st_pdf({source, page, realNo, queNo, OX, marking, munCnt, score}) {    
          const sDate = fnc.fncDate();
          const acaCode = fnc.getCookie('acaCode');
          const stCode = fnc.getCookie('stCode');

          fnc.sendAxios('/insert/book/te_marking_mun_st_pdf', { acaCode, stCode, source, page, 
              realNo, queNo, OX, marking, sDate }, (res) => {       
                if (res) {  
                 
                } else {
                    console.log('te_marking_mun_st_pdf');
                }
          });

      }

      function fncInsert_te_Marking_PDF(source, page ,munCnt, score) {    
        // const sDate = fnc.fncDate();
        const acaCode = fnc.getCookie('acaCode');
        const stCode = fnc.getCookie('stCode');

        fnc.sendAxios('/insert/book/te_Marking_PDF', { acaCode, stCode, source, page, 
           munCnt, score }, (res) => {       
              if (res) {  
                  
              } else {
                  console.log('te_marking_mun_st_pdf');
              }
        });

    }

    //========================================================================================
    return (
      <div className="BookPdf_marking">
        {/* <HeaderTitle title={title} direction={1} onBackClick={() => setIsModal(false)} /> */}
        
        <Button className='btnClose-me' onClick={() => setIsModal(false)} >
            <i class="fa-solid fa-xmark"></i>
        </Button> 

        <div className="wrap-que">
          <section className="section-que">
            {record_que.map((item, index) => (
              <article className="article-que" key={index}>
                <span className="No">{index + 1}</span>
                <div className="box-imgQue">
                  <img
                    className={`imgQue ${item.imgCol}`}
                    src={item.imagePath}
                    alt={`${item.pdfFileName}`}
                  />
                </div>
                <p className="menu">
                  <BookPdf_marking_menu
                    list_que={record_que}
                    item={item}
                    no={index + 1}
                    markingListValue={markingList[index + 1]}
                  />
                </p>
              </article>
            ))}

           {userKind == 1 || userKind == 2 && 
          <div className="box-submit">
              <Button className="btnSubmit" onClick={onSubmit}>제출</Button>
            </div> }
          </section>

          <section className="section-omr">
            <BookPdf_marking_omr />
          </section>

       

        </div>
      </div>
    );
}

export default BookPdf_marking;
