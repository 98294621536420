import React from 'react';
import { createRoot } from 'react-dom/client'; // 변경된 임포트
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import {store2} from './store';

import './scss/reset.scss';

const root = createRoot(document.getElementById('root')); // createRoot 사용
root.render(
  // <React.StrictMode>
     <Provider store={store2}>
    <Router>
      <App />
    </Router>
    </Provider>
  // </React.StrictMode>
);
