import './Exam4Marking_menu.scss';
import fnc from '../mymod/commFunction';
import fncS3 from '../mymod/commFunction3';

import * as S from '../scss/theme';

import MemoCanvas from '../drawSvg/MemoCanvas';
import VideoReact from '../video/VideoReact';
// import DrawMenu from '../drawSvg/DrawMenu';

import React, { useEffect, useState} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { initializeMarkingList, updateMarkingList, setKind, setRowIdx_que, setHeaderInfo } from '../reduxToolkit/redux';



const Exam4Marking_menu = ({list_que, item, no, markingListValue, isComplete}) => {

    // const videoUrl = process.env.REACT_APP_VIDEO_URL;
    const videoUrl = process.env.REACT_APP_VIDEO_URL_S3;

    const dispatch = useDispatch();
    // const markingList = useSelector(state => state.reduxStore.markingList); 
    // 이 컨포넌트에는 markingList 죽야함( 문항수만큼 컨포넌트가 있어서 버튼 클릭하면 부모컨포넌트에서 markingList가 바뀌므로 문항수만큼 실행됨 )
    const selectedTestNm = useSelector(state => state.reduxStore.selectedTestNm);
    const headerInfo = useSelector(state => state.reduxStore.headerInfo);
    // const selectedPage_pdf = useSelector(state => state.reduxStore.selectedPage_pdf);
    const userKind = useSelector(state => state.reduxStore.userInfo.kind);

    const [markingValue, setMarkingValue] = useState('');
    const [isModal_memo, setIsModal_memo] = useState(false);
    const [isModal_video, setIsModal_video] = useState(false);
    const [fullUrl, setFullUrl] = useState('');

    useEffect(() => {
      setMarkingValue(markingListValue);
    }, [markingListValue]);

   function onClickNo(value) { 
      dispatch(updateMarkingList({ no: no, value: value }));
      setMarkingValue(value);
    };

    const onInputChange = (e) => {
      const value = e.target.value;
      setMarkingValue(value);
      dispatch(updateMarkingList({ no: no, value: value }));
  };

  const handleChange = (e) => {
    setMarkingValue(e.target.value);
    
  };
  
  function onClickMemo_que() {
    setIsModal_memo(true);
    dispatch(setKind('que'));//pdf와 que 두개로 나눔
    dispatch(setRowIdx_que(no-1));

    const label =  `${selectedTestNm} ${no}번`;
    dispatch(setHeaderInfo({title: label, direction: -1}));
  }

  
  function onClickMemo_sol() {
    setIsModal_memo(true);
    dispatch(setKind('sol'));//pdf와 que 두개로 나눔
    dispatch(setRowIdx_que(no-1));

    const label =  `${selectedTestNm} ${no}번`;
    dispatch(setHeaderInfo({title: label, direction: -1}));
  }

  function onClickMemo_comm() {
    setIsModal_memo(true);
    dispatch(setKind('comm'));//pdf와 que 두개로 나눔
    dispatch(setRowIdx_que(no-1));

    const label =  `${selectedTestNm} ${no}번`;
    dispatch(setHeaderInfo({title: label, direction: -1}));
  }
  
  
  function showPlay_que(item) {

    if (!item.qrCode) {
      alert('영상이 없습니다.');
      return null;
    }
    const acaCode = fnc.getCookie('acaCode');

    const fileName=`${item.qrCode}`;
    const path =fncS3.getS3PathVideo(`${videoUrl}/${acaCode}/${fileName}`);

    setFullUrl(path);
    setIsModal_video(true);
  }
  
  //===============================================================
  return (
    <>
      {/* 이곳은 객관식만 사용함 주관식 필요하면 pdf에서 가져 오도록 */}
      {item.kind === "객관식" && (
        <div className="Exam4Marking_menu">
          <input className="OX" type="text" />
          <span className="realNo">{no}/{list_que.length}</span>
          <input
            className="markingValue"
            type="text"
            value={isComplete ? item.marking : markingValue}
            readOnly
            onChange={onInputChange} // onChange 이벤트 핸들러 추가
            spellCheck="false"
            autoComplete="off"
          />

          {!isComplete && [1, 2, 3, 4, 5].map((value) => (//1, 2, 3, 4, 5 버튼
            <input
              key={value}
              className={`markingBtn ${markingValue === value ? "active" : ""}`}
              type="button"
              value={value}
              onClick={() => onClickNo(value)}
            />
          ))}



          <input type="button" class={item.qrCode ? "existQrCode" : "notQrCode"}  value=""
            onClick={() => showPlay_que(item)}
          />

          <input type="button" class={item.commQue ? "existComm" : "notComm"}  value="지문"
            onClick={() => onClickMemo_comm(item)}
          />

          <input class="btnMemo" type="button" value="" onClick={onClickMemo_que}/>
          {(isComplete || userKind == 2) && <input class="btnSolPreview" type="button" value="" onClick={onClickMemo_sol}/> }
         
          {false && <input class="btnMemoBoardWrite" type="button" value="" /> }
        </div>
      )}


    {item.kind === "주관식" && (
        <div className="Exam4Marking_menu">
          <input className="OX" type="text" />
          <span className="realNo">{no}/{list_que.length}</span>
          <input
            className="markingValue markingValue_ju"
            type="text"
            value={isComplete ? item.marking : markingValue}
            // readOnly
            onChange={onInputChange} // onChange 이벤트 핸들러 추가
            spellCheck="false"
            autoComplete="off"
          />

       

          <input type="button" class={item.qrCode ? "existQrCode" : "notQrCode"}  value=""
            onClick={() => showPlay_que(item)}
          />
         
         <input type="button" class={item.commQue ? "existComm" : "notComm"}  value="지문"
            onClick={() => onClickMemo_comm(item)}
          />
          
          <input class="btnMemo" type="button" value="" onClick={onClickMemo_que}/>
          {(isComplete || userKind == 2) && <input class="btnSolPreview" type="button" value="" onClick={onClickMemo_sol}/> }
         
          {false && <input class="btnMemoBoardWrite" type="button" value="" /> }
        </div>
      )}

   
    
      {/*----------------------------------------------------------------메모 */}
      {isModal_memo && (
        <>
          <S.Modal>
            <section className="section-memo">
              <MemoCanvas setIsModal={setIsModal_memo} item={item} />
            </section>
          </S.Modal>
        </>
      )}

    {isModal_video && (
        <>
          <S.Modal>
            <section className="section-video">
              <VideoReact fullUrl={fullUrl} setIsModal={setIsModal_video} item={item} />
            </section>
          </S.Modal>
        </>
      )}


    </>
  );
};

export default Exam4Marking_menu;
