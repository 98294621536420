import './EvaluationTec.scss';
import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import fnc from '../mymod/commFunction';
import MyClass from './MyClass';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';

const EvaluationTec = () => {
  const stCode = fnc.getCookie('stCode');
  const selectedClassList = useSelector((state) => state.reduxStoreAca.selectedClassList);
  const classCode = selectedClassList.classCode;
  const gangsaCode = selectedClassList.gangsaCode;

  const [list, setList] = useState([]);
  const [selectedNumbers, setSelectedNumbers] = useState({});
  const [onTrigger, setOnTrigger] = useState(false);
  const [isDisable, setIsDisable] = useState(selectedClassList.checkCnt === 0);
  const [isLoading, setIsLoading] = useState(false);  // 로딩 상태 관리




  useLayoutEffect(() => {//useLayoutEffect 별 차이가 없네
    if(classCode) {
        setIsLoading(true);
        fncSelectEvaluation();
        setIsDisable(selectedClassList.checkCnt);
    }
  }, [selectedClassList]);

  function fncSelectEvaluation() {
    
    fnc.sendAxios("/aca/selectEvaluation", {stCode, classCode, gangsaCode}, (res) => {
      if (res) {
 
        setList(res);
       
        const initialSelectedNumbers = res.reduce((acc, item) => {
          //acc 누적(prev), item 현재 (curr)
          acc[item.sno] =  item.score || ""; // 해당 키가 객체에 존재하지 않으면, 자동으로 그 키와 함께 새로운 속성이 생성
          return acc;
        }, {});

        setSelectedNumbers(initialSelectedNumbers);
        setIsLoading(false);
      }
    });
  }

  function handleSelect(value, sno) {
    setSelectedNumbers((prev) => ({
      ...prev,
      [sno]: value,
    }));
  }

  function onClickSumbit() {
    const rtn = checkForEmptyValues(selectedNumbers);
    if (!selectedClassList.gangsaName) {
      alert("선생님을 선택하세요!");
      return;
    }
    if (rtn) {
      alert("모든 항목을 체크해주세요!");
      return;
    }

    const rtn2 = window.confirm('평가지를 제출하시겠습니까?');
    if (!rtn2) return;

    // list 배열에서 itemCode만 추출하여 콤마로 구분된 문자열로 만들기
    const itemCodes = list.map(item => item.itemCode).join(',');
    // selectedNumbers 객체에서 값만 추출하여 콤마로 구분된 문자열로 만들기
    const scores = Object.values(selectedNumbers).join(',');
    const classCode = selectedClassList.classCode;
    const gangsaCode = selectedClassList.gangsaCode;

    fncInsert_evaluation(stCode, classCode, gangsaCode, itemCodes, scores);

  }

  function checkForEmptyValues(selectedNumbers) {
    // Object.values()를 사용하여 객체의 모든 값을 배열로 반환받고,
    // some() 메소드를 사용하여 하나라도 공백이 있는지 확인
    return Object.values(selectedNumbers).some((value) => value === "");
  }

  const fncInsert_evaluation = (stCode, classCode, gangsaCode, itemCodes, scores) => {

    fnc.sendAxios('/aca/fncInsert_evaluation', {stCode, classCode, gangsaCode, itemCodes, scores}, (res) => {
        if(res.result === 'Y'){
            setOnTrigger(!onTrigger);
            setIsDisable(true);
            alert('완료되었습니다.');
        }else {
            alert('실패했습니다.');
        }
    });
  }
  //=======================================================
  return (
  <div className="EvaluationTec">
       <div className='inner_evaluationTec'>
      <MyClass onTrigger={onTrigger}/>
     
        <div className="box_list">

        <div className="box_title">
          <p>* {selectedClassList.className}</p>
          <p>{selectedClassList.gangsaName} 선생님</p>
        </div>

          <table className='table2'>
            <thead>
              <tr>
                <th>no</th>
                {/* <th>제목</th> */}
                <th>항목</th>
                <th>배점</th>
                <th>점수</th>
              </tr>
            </thead>
            <tbody>
              {list && list.map((item, index) => (
                <tr key={item.sno}>
                  <td>{index + 1}</td>
                  {/* <td>{item.title}</td> */}
                  <td>{item.item}</td>
                  <td>{item.points}</td>
                  <td>
                    <select
                      className="selectNumber"
                      onChange={(e) => handleSelect(e.target.value, item.sno)}
                      value={selectedNumbers[item.sno] || ""}
                      disabled={isDisable} // 조건에 따라 select를 비활성화
                    >
                      {/* <option value="" disabled hidden>Choose here</option> */}
                      <option value=""></option>
                      {Array.from({ length: 10 }, (_, i) => i + 1).map(
                        (number) => (
                          <option key={number} value={number}>
                            {number}
                          </option>
                        )
                      )}
                    </select>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* <div className="box_menu">
         { !isLoading && !isDisable &&  <Button className="btnSubmit" onClick={onClickSumbit} disabled={isDisable}>
            제출
          </Button>}
        </div> */}

        <div className="box_menu">
          { !isLoading && !isDisable && 
            <Button className="btnSubmit" onClick={onClickSumbit} disabled={!!isDisable}>
              <i className="fa fa-paper-plane"></i> 제출
            </Button>
          }
        </div>


      </div>
    </div>
  );
}

export default EvaluationTec;
