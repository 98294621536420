import './DigitalPdf.scss';
import React, { useState, useEffect } from "react";
import fnc from '../mymod/commFunction';
import fncS3 from '../mymod/commFunction3';
import HeaderTitle from '../comm/HeaderTitle';
import redux from '../reduxToolkit/redux';
import { useSelector, useDispatch } from 'react-redux';
import { setList_pdf, setRow_pdf } from '../reduxToolkit/redux';
import { Button } from '@mui/material';


const DigitalPdf = ({ setIsModal }) => {
    const dispatch = useDispatch();

    const imgUrl = process.env.REACT_APP_imgUrl;
    const headerInfo = useSelector(state => state.reduxStore.headerInfo);
    const list_pdf = useSelector(state => state.reduxStore.list_pdf);
    const rowIdx_pdf = useSelector(state => state.reduxStore.rowIdx_pdf);

    const [imagePath, setImagePath] = useState('');
    const [title, setTitle] = useState('');
    const [selectedListItem, setSelectedListItem] = useState({});

    useEffect(() => {
        setSelectedListItem(list_pdf[rowIdx_pdf]);
    }, []);

    useEffect(() => {
        if (selectedListItem) { //(acaCode=1000인듯)
            const newImagePath = selectedListItem.imagePath;
            console.log(newImagePath, ' === newImagePath');
            setImagePath(newImagePath);
        }
    }, [selectedListItem, headerInfo]); // 의존성 배열에 selectedListItem과 headerInfo 추가

    //========================================================================
    return (
        <div className='DigitalPdf'>    
            <Button className='btnClose-me' onClick={() => setIsModal(false)} >
                <i class="fa-solid fa-xmark"></i>
            </Button> 

            {/* <HeaderTitle title={title} direction={1} onBackClick={() => setIsModal(false)} /> */}
            
            <div className='box-imgPdf'>
                {imagePath && <img className='imgPdf' src={imagePath} alt={`Page ${selectedListItem.page} of ${selectedListItem.pdfFileName}`} />}
            </div>
           
        </div>
    );
}

export default DigitalPdf;