import './Download_newWindow.scss';
import * as S from '../scss/theme';
import React, { useState, useEffect } from "react";
import fnc from '../mymod/commFunction';
import Button from '@mui/material/Button';
import Combobox from '../comm/Combobox_noBMK';
import Select from 'react-select';
import HeaderTitle from "../comm/HeaderTitle";
import { useDispatch, useSelector } from 'react-redux';
import { setIsModalTest, bookCode, setBookCode } from '../reduxToolkit/redux';
import Exam2Main from '../page_exam/Exam2Main';
import BookMain from '../page_book/BookMain';


const Download_newWindow = ({}) => {

    const fileFolder = process.env.REACT_APP_fileFolder;
    const dispatch = useDispatch();
    // const classCode = useSelector(state => state.reduxStore.classCode);
    // const lessonDate = useSelector(state => state.reduxStore.lessonDate);
    const lmsListCode = useSelector(state => state.reduxStore.lmsListCode);


   
    useEffect(() => {
      onClickDownload();
    }, []);

    
  function onClickTr() {

  }
  
  function onClickDownload() {   
    const rtn = window.confirm('파일을 다운로드하시겠습니까?');
    if(!rtn) return null;

    // const fileFolder = item.fileFolder;
    //  const fileFolder = "/web/kuesbLmsFileUpload/1000"
    // const fileName = item.fileName;
    // const fileNameCode = item.fileCode;
    const fileFolder = '/web/kuesbLmsFileUpload/1000';
    const fileName = '[기본] 1-2-3.기체 사이의 반응과 화학 반응에서의 .pdf';
    const fileNameCode = 'c20240712163100223_[기본] 1-2-3.기체 사이의 반응과 화학 반응에서의 .pdf';

    const remoteFilePath = `${fileFolder}/${fileNameCode}`;

    fnc.sendAxiosBlob(`/downloadSftp`, {remoteFilePath, fileNameCode }, (res) => {
        if (res) {        
            const url = window.URL.createObjectURL(new Blob([res], { type: 'application/octet-stream' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);  // 다운로드할 파일 이름 설정
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);  // 생성된 URL 해제
        } else {
            console.log('Error during file download');
        }
    }); 
 }

    //=================================================
    return (
    <div className="Download_newWindow">
        <div className="title">파일 다운로드</div>
        <div className="wrap-main">
          <section className="section-main">
           
           다운로드
         
          </section>

        
          
        </div>
      </div>
      
    );
}

export default Download_newWindow;