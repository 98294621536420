import React, { useState, useEffect,useRef } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.scss';
import fnc from './mymod/commFunction';
import { useSelector, useDispatch } from 'react-redux';
import { setIsLoggedIn,  setIsModalTestLms } from "./reduxToolkit/redux";
import { useNavigate } from 'react-router-dom';
import NavbarSub from './NavbarSub';
import NavbarMO from './NavbarMO';
import hboiaLogo from './images/kuesb_logo.png'
import { Button } from '@mui/material';



function Navbar() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(state => state.reduxStore.isLoggedIn);
    const userKind = useSelector(state => state.reduxStore.userInfo.kind);
    const isLoginPage = useSelector(state => state.reduxStore.isLoginPage);
    const version = useSelector(state => state.reduxStore.version);
    const acaName = useSelector(state => state.reduxStore.acaName);

    const targetRef = useRef(null);  
    const [isOpen, setIsOpen] = useState(false);
    const [activeLink, setActiveLink] = useState('evaluationTec');
    const [isAndroid, setIsAndroid] = useState(null);

    const [showSubmenu, setShowSubmenu] = useState(false);
    const [showSubmenu2, setShowSubmenu2] = useState(false);
    const [showSubmenu3, setShowSubmenu3] = useState(false);
    const [activeSubLink, setActiveSubLink] = useState(null);
    const [selectedTitle3, setSelectedTitle3] = useState('LMS 생성');
    const [selectedTitle2, setSelectedTitle2] = useState('문제은행');
    const [selectedTitle1, setSelectedTitle1] = useState('수강신청(결과)');
    const menuRef = useRef(null);
    const menuRef2 = useRef(null);
    const menuRef3 = useRef(null);

    const [navbarClicked3, setNavbarClicked3] = useState('');
    const [navbarClicked2, setNavbarClicked2] = useState('');
    const [navbarClicked1, setNavbarClicked1] = useState('');
    const [selectedIndex, setSelectedIndex] = useState(3);
    const userName = fnc.getCookie('userName') || '';

  useEffect(() => {
    if (version && version.toLowerCase().includes('android')) {
      setIsAndroid(true);
    } else {
      setIsAndroid(false);
    }
  }, [version]);


    
    const toggleMenu = () => {
      if (isOpen) {
        setTimeout(() => setIsOpen(false), 100);
      } else {
        setIsOpen(true);
      }
    };

    const onToggleLogin = () => {
      if (isLoggedIn) {
        const rtn = window.confirm("로그아웃하시겠습니까?");
        if (!rtn) return;

        dispatch(setIsLoggedIn(false));
        navigate("/"); //evaluationTec
      } else {
        navigate("/login");
      }
    };

    const onHome = () => {
      navigate('/main');
    };

    const handleLinkClick = (linkName) => {
      setActiveLink(linkName);
      dispatch(setIsModalTestLms(false));//lms시험에서 열린 창 닫기

    }

    const checkSession = () => {
      // const lastLoginTime = fnc.getCookie("lastLoginTime");
      // const minutesPassed = (Date.now() - lastLoginTime) / 60000; 
      // if (!lastLoginTime || minutesPassed > 30) {//분
      //   alert('시간이 만료되었습니다. \n로그인을 다시 해주세요!');
      //   navigate("/");
      // }else{
      //   fnc.setCookie('lastLoginTime', Date.now(), 1);
      // }
    };

    function onCloseApp() {

      if (typeof window.myJSInterface !== 'undefined'){
          if (window.myJSInterface.CloseAppReact) {
            window.myJSInterface.CloseAppReact('');
          }
      }

      // if (window.myJSInterface.callWebSite) {
      //   window.myJSInterface.callWebSite('https://naver.com');
      // } else {
      //   console.error("callWebSite feature is not available on your platform.");
      // }


    }

    //====================================================================서브메뉴
    const toggleSubmenu = () => {
      setShowSubmenu(prev => !prev);
    };

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (showSubmenu && menuRef.current && !menuRef.current.contains(event.target)) {
          setShowSubmenu(false);
        }
      };
    
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [showSubmenu]);
    
      
      //=============================================================================서브메뉴2
      
      const toggleSubmenu2 = () => {
        setShowSubmenu2(prev => !prev);
      };
     
  
      useEffect(() => {
        const handleClickOutside = (event) => {
          if (showSubmenu2 && menuRef2.current && !menuRef2.current.contains(event.target)) {
            setShowSubmenu2(false);
          }
        };
      
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [showSubmenu2]);
      
      //------------------------------------------------------
      const toggleSubmenu3 = () => {
        setShowSubmenu3(prev => !prev);
      };

      useEffect(() => {
        const handleClickOutside = (event) => {
          if (showSubmenu3 && menuRef3.current && !menuRef3.current.contains(event.target)) {
            setShowSubmenu3(false);
          }
        };
      
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [showSubmenu3]);

//=====================================================================
    return (
      <>
        <nav className="navbar" onClick={checkSession} >
          <div className="navbar-logo" onClick={onHome}>
            <div className="txt-logo">
              {/* <p>강 원 고 등 학 교</p> */}   
              <p>{acaName}</p>
              <p>Learning Management System</p>       
            </div>
          </div>

          <span className='lbl-online'>
              AI Online Digital School
          </span>

          <div className={`navbar-menu`}>
          
      
          <div ref={menuRef3} className={`navbar-menu2 ${navbarClicked3}`}>

            {isLoggedIn  && (
                  <Link to="#" 
                    className={`link-hover-underline ${activeLink === "book4" ? "active" : ""}`}
                    onClick={(e) => {
                      e.preventDefault(); // Prevent navigating
                      handleLinkClick("book4");
                      toggleSubmenu3();
                      setSelectedIndex(3);
                  
                    }}>
                    <span className="link-content">
                    {`${selectedIndex == 3 && navbarClicked3 ? selectedTitle3 : 'LMS 생성'}`}                    
                        {/* <i className="fa-solid fa-caret-down"></i> */}
                    </span>
                </Link>
              )}

        

            {showSubmenu3 && (

              <div className="submenu2">
                {isLoggedIn && userKind == 2 && (
                  <Link to="/lmsTec"
                    className={`submenu-link ${activeSubLink === 'lmsTec' ? 'active' : ''}`}
                    onClick={() => {
                      setActiveSubLink('lmsTec');
                      setShowSubmenu3(false);
                      setSelectedTitle3('LMS 생성 (교사용)');
                    
                      setNavbarClicked2('');
                      setNavbarClicked1('');
                      setNavbarClicked3('navbar-clicked');
                    }}>
                    LMS 생성 (교사용)
                  </Link>
                )}          

                {isLoggedIn && userKind == 2 &&(
                  <Link to="/lms"
                    className={`submenu-link ${activeSubLink === 'lms' ? 'active' : ''}`}
                    onClick={() => {
                      setActiveSubLink('lms');
                      setShowSubmenu3(false);
                      setSelectedTitle3('LMS 검증 (학생용)');
                      setNavbarClicked3('navbar-clicked');
                      setNavbarClicked2('');
                      setNavbarClicked1('');
                    }}>
                    LMS 검증 (학생용)
                  </Link>
                )}  

                 {isLoggedIn && userKind == 1 && (
                  <Link to="/lms"
                    className={`submenu-link ${activeSubLink === 'lms' ? 'active' : ''}`}
                    onClick={() => {
                      setActiveSubLink('lms');
                      setShowSubmenu3(false);
                      setSelectedTitle3('LMS 생성');
                      setNavbarClicked3('navbar-clicked');
                      setNavbarClicked2('');
                      setNavbarClicked1('');
                    }}>
                    LMS 생성
                  </Link>
                )}                  
              </div>

            )}

          

        </div>
{/* ----------------------------------------------------------------------------------- */}
      <div ref={menuRef2} className={`navbar-menu2 ${navbarClicked2}`}>
          {isLoggedIn  && (
              <Link to="#" 
                className={`link-hover-underline ${activeLink === "book4" ? "active" : ""}`}
                onClick={(e) => {
                  e.preventDefault(); // Prevent navigating
                  handleLinkClick("book4");
                  toggleSubmenu2();
                  setSelectedIndex(2);
                }}>
                <span className="link-content">

                {`${selectedIndex == 2 && navbarClicked2 ? selectedTitle2 : '문제은행'}`} 
                {/* <i className="fa-solid fa-caret-down"></i> */}
                </span>
              </Link>
            )}

          {showSubmenu2 && (
            <div className="submenu2">
              {isLoggedIn && (
                <Link to="/digital"
                  className={`submenu-link ${activeSubLink === 'digital' ? 'active' : ''}`}
                  onClick={() => {
                    setActiveSubLink('digital');
                    setShowSubmenu2(false);
                    setNavbarClicked3('');
                    setNavbarClicked2('navbar-clicked');
                    setNavbarClicked1('');
                    setSelectedTitle2('디지털 1.0');
                  }}>
                  디지털 1.0
                </Link>
              )}

              {isLoggedIn && (
                <Link to="/book"
                  className={`submenu-link ${activeSubLink === 'book' ? 'active' : ''}`}
                  onClick={() => {
                    setActiveSubLink('book');
                    setShowSubmenu2(false);
                    setNavbarClicked3('');
                    setNavbarClicked2('navbar-clicked');
                    setNavbarClicked1('');
                    setSelectedTitle2('디지털 2.0');
                  }}>
                  디지털 2.0
                </Link>
              )}

              {isLoggedIn && (
                <Link to="/exam"
                  className={`submenu-link ${activeSubLink === 'exam' ? 'active' : ''}`}
                  onClick={() => {
                    setActiveSubLink('exam');
                    setShowSubmenu2(false);
                    setNavbarClicked3('');
                    setNavbarClicked2('navbar-clicked');
                    setNavbarClicked1('');
                    setSelectedTitle2('진단평가');
                  }}>
                  진단평가
                </Link>
              )}
            </div>
          )}

        </div>

        {/* ----------------------------------------------------------------- 수강신청결과 */}
        <div ref={menuRef} className={`navbar-menu2 ${navbarClicked1}`}>
          {isLoggedIn && (
              <Link to="#" 
                className={`link-hover-underline ${activeLink === "book4" ? "active" : ""}`}
                onClick={(e) => {
                  e.preventDefault(); // Prevent navigating
                  handleLinkClick("book4");
                  toggleSubmenu();
                  setSelectedIndex(1);
                }}>
                <span className="link-content">
                { `${selectedIndex == 1 && navbarClicked1 ? selectedTitle1 : '수강신청(결과)'}`} 
                {/* <i className="fa-solid fa-caret-down"></i> */}
                </span>
              </Link>
            )}

          {showSubmenu && (
            <div className={`submenu2 ${setNavbarClicked1}`}>

              {isLoggedIn && userKind == 2 && (
                <Link to="/basicSetting"
                  className={`submenu-link ${activeSubLink === 'basicSetting' ? 'active' : ''}`}
                  onClick={() => {
                    setActiveSubLink('basicSetting');
                    setShowSubmenu(false);
                    setNavbarClicked3('');
                    setNavbarClicked2('');
                    setNavbarClicked1('navbar-clicked');
                    setSelectedTitle1('기초설정');
                  }}>
                  기초설정
                </Link>
              )}

              {isLoggedIn && userKind == 2 && (
                <Link to="/courseRegTec"
                  className={`submenu-link ${activeSubLink === 'courseRegTec' ? 'active' : ''}`}
                  onClick={() => {
                    setActiveSubLink('courseRegTec');
                    setShowSubmenu(false);
                    setNavbarClicked3('');
                    setNavbarClicked2('');
                    setNavbarClicked1('navbar-clicked');
                    setSelectedTitle1('수강신청(T)');
                  }}>
                  수강신청(T)
                </Link>
              )}
              {isLoggedIn && (
                <Link to="/courseReg"
                  className={`submenu-link ${activeSubLink === 'courseReg' ? 'active' : ''}`}
                  onClick={() => {
                    setActiveSubLink('courseReg');
                    setShowSubmenu(false);
                    setNavbarClicked3('');
                    setNavbarClicked2('');
                    setNavbarClicked1('navbar-clicked');
                    setSelectedTitle1('수강신청');
                  }}>
                  수강신청
                </Link>
              )}
              
              {isLoggedIn && userKind == 2 && (
                <Link to="/LmsTecResultApp"
                  className={`submenu-link ${activeSubLink === 'LmsTecResultApp' ? 'active' : ''}`}
                  onClick={() => {
                    setActiveSubLink('LmsTecResultApp');
                    setShowSubmenu(false);
                    setNavbarClicked3('');
                    setNavbarClicked2('');
                    setNavbarClicked1('navbar-clicked');
                    setSelectedTitle1('학생결과');
                  }}>
                   학생결과
                </Link>
              )}

            </div>
          )}

        </div>



          </div>

          <div className="box_navar-login">
            <Button
              className="navbar-login"
              onClick={() => navigate("/updateapp")}
            >     
              {isLoggedIn ? <i className="fas fa-user-circle"></i> : ""}
            </Button>

            {<Button className="navbar-login" onClick={onToggleLogin}>
                {isLoggedIn ? (
                  <div className='box-user'>
                   
                    <p className='userName'>{userName}</p>
                    <p className='logout'>로그아웃</p>
                  </div>
                ) : "로그인"}

            </Button>}

           {isLoginPage && isAndroid && <Button className="closeApp" onClick={onCloseApp}>
              종료
            </Button>}

          </div>
        </nav>

        <nav className="navbar-mo">
          <div
            className={`navbar-menu-mo ${
              isOpen ? "navbar-menu-active-mo" : ""
            }`}
          >
            <NavbarMO isOpen={isOpen} setIsOpen={setIsOpen} />
          </div>

          <div className="navbar-toggler" onClick={toggleMenu}>
            <i className={`fas ${isOpen ? "fa-times" : "fa-bars"}`}></i>
          </div>
        </nav>
      </>
    );
}

export default Navbar;
