
import {configureStore} from "@reduxjs/toolkit";
//{configureStore} 괄호 없으면 안 돼 명명된 내보내기 (Named Exports):
//기본 내보내기 (Default Export): import configureStore from '@reduxjs/toolkit';
//모듈당 하나의 기본 내보내기만 있을 수 있으며
// import counterReducer_slide from './reduxToolkit/counter/counterSlice';
// import todosReducer_slide from './reduxToolkit/todo/todosSlice';
// import usersReducer_slide from './reduxToolkit/todo/usersSlice';
// import users2Reducer_slide from './reduxToolkit/users/userSlice';
import reduxReducer_slide from './reduxToolkit/reduxSlice';
import reduxReducer_aca from "./reduxToolkit/reduxAca";
import reduxReducer from "./reduxToolkit/redux";

export const store2 = configureStore({
    reducer : {
        // name_counter2: counterReducer_slide,
        // users: usersReducer_slide,
        // users2: users2Reducer_slide,
        // reduxStore: reduxReducer_slide,
        reduxStoreAca: reduxReducer_aca,
        reduxStore: reduxReducer
    }
});



