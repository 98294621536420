import './LmsTecResultAllimjang.scss';
import * as S from '../scss/theme';
import React, { useState, useEffect, useRef } from "react";
import fnc from '../mymod/commFunction';
import fnc3 from '../mymod/commFunction3';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { setLessonDate, setIsModal, setLmsListCode } from '../reduxToolkit/redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LmsTecResultAllimjang_report from './LmsTecResultAllimjang_report';
import { Checkbox } from '@mui/material';
import { toPng, toJpeg } from 'html-to-image';
import CircularProgressWithLabel from '../comm/CircularProgressWithLabel'; // Progress 컴포넌트 임포트
import SmsResult from '../page_smsResult/SmsResult';

// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
import LmsTecResultAllimjang_report_sendMenu from './LmsTecResultAllimjang_report_sendMenu';


const LmsTecResultAllimjang = ({kind}) => {
    const smsFolder = process.env.REACT_APP_smsFolder;
    const sendPhoneNumber = process.env.REACT_APP_sendPhone;

    const dispatch = useDispatch();
    const isModal = useSelector((state) => state.reduxStore.isModal);
    const classCode = useSelector((state) => state.reduxStore.classCode);
    const isRefresh_lmsTecList = useSelector((state) => state.reduxStore.isRefresh_lmsTecList);

    const [list, setList] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [monthInfo, setMonthInfo] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);

    // const [selectedItems, setSelectedItems] = useState([]);//체크박스
    // const [selectAll, setSelectAll] = useState(false);//체크박스
    const [isSelectAll, setIsSelectAll] = useState(false);//체크박스

    const [loading, setLoading] = useState(false);
    const [optValueTarget, setOptValueTarget] = React.useState('all'); 
    const [optValueSchedule, setOptValueSchedule] = React.useState('reserve'); 
    const [sendDate, setSendDate] = useState(new Date());
    const [sendTime, setSendTime] = useState(new Date());
    const [progress, setProgress] = useState(0); // 초기 진행률 0%
    
    const [isModal_smsResult, setIsModal_smsResult] = useState(false);

    const captureRef = useRef();
    
      
    const monthInterval = 1;

   
    useEffect(() => {
      fncSelect_list();
    }, [classCode]);

    useEffect(() => {
      fncSelect_list();
      const selectedDate = fnc.formatDate(startDate);
      const weekday = fnc.getWeekDay(selectedDate);
      setMonthInfo(`${selectedDate} (${weekday})`);
  
    }, [startDate]);
  
    
  function fncSelect_list() {
    const acaCode = fnc.getCookie('acaCode');
    const stCode = fnc.getCookie('stCode');
    const lessonDate = fnc.formatDate(startDate);
    fnc.sendAxios('/select/lmsTecResultAllimjang/attendByClass', 
          { acaCode, classCode, kind, startDate, lessonDate }, (res) => {
      if (res && Array.isArray(res)) { 

        setIsSelectAll(false);
        setList(getRecords(res));

      } else {
        console.log(res,"kuesbLmsTecResult_allimjangRoutes/select/lmsTecResultAllimjang/attendByClass");
      }
    });
  }

  function getRecords(res) {
    let records = [];
    res.forEach(item => {
        const snoSt = item["snoSt"];
        const lessonDate = item["lessonDate"]; // list 코드 parent Code로 사용
        const stCode = item["stCode"];
        const name = item["name"];
        let hp = item["hp"];
        hp = fnc.formatPhoneNumber(hp);
        let hpParent = item["hpParent"];
        hpParent = fnc.formatPhoneNumber(hpParent);
        const classCode = item["classCode"];
        const className = item["className"];
        const kind = item["kind"];
        let checkedynSt = item["checkedynSt"];
        checkedynSt = fncCheckedynSt(checkedynSt);
        let eDate = item["eDate"];
        const confirmed = item["confirmed"];
        const weekDay = fnc.getWeekDay(lessonDate);
        const gangsaCode = item["gangsaCode"];
        const gangsaName = item["gangsaName"];
  
        const id = snoSt;

        const isChecked = false;//getRecords에서

        records.push({ snoSt, lessonDate, weekDay, stCode, name, hp, hpParent, classCode, className, 
          kind, checkedynSt, eDate, confirmed, gangsaCode, gangsaName, isChecked, id });
    });
    return records;
}



  
  function onRefresh() {
    if (isButtonDisabled) return; // 버튼이 비활성화되어 있는 경우 아무것도 하지 않음
    fncSelect_list();
    // 버튼 비활성화 및 일정 시간 후 재활성화
    setIsButtonDisabled(true);
    setTimeout(() => setIsButtonDisabled(false), 2000); // 2초 후 버튼 재활성화
  }

//------------------------------------------

  
  function moveDay(currDate, days) {
    const newStartDate = fnc.moveDay(currDate, days)
    setStartDate(newStartDate);
  }

  function onClickRow(item) {

    // console.log(item, 'item');
    setSelectedItem(item);
  }

  function onSendSms() {

    simulateRowClicks();

    // onCreatImg_blob();//지우지 말 것
    // onCreatImg();//지우지 말 것
    // alert('준비 중입니다.');
  }

  
  //-------------------------rowClick
// row들을 차례대로 클릭하는 함수

const simulateRowClicks = async () => {
  const checkedCnt = list.filter(item => item.isChecked === true).length;
  // if (checkedCnt === 0){
  //   alert('대상을 체크하세요!');
  //   return null;
  // }
  
  if (!optValueTarget) return alert('학생 또는 부모를 선택하세요!'), null;
  if (!optValueSchedule) return alert('즉시 또는 예약을 선택하세요!'), null;
  if (!checkedCnt) return alert('대상을 체크하세요!'), null;


  const rtn = window.confirm(`${checkedCnt}명의 학생에게 문자를 전송하시겠습니까?`);
  if (!rtn) return;

  setProgress(0);
  setProgress(0.01);//처음에 show하기 위해
  for (let index = 0; index < list.length; index++) {

      if (list[index].isChecked) {

        const item = list[index];
        // 1초 대기 후 row 클릭
        await new Promise((resolve) => setTimeout(resolve, 1000));
        onClickRow(item); // 해당 row 클릭  
        // 0.5초 대기 후 이미지 생성
        await new Promise((resolve) => setTimeout(resolve, 500));
        await onCreatImg_blob(item, index); // 이미지 생성 및 서버 전송

        // 진행률 업데이트 (완료된 항목 / 총 항목 수 * 100)
        setProgress((prevProgress) => prevProgress + (1 / checkedCnt) * 100);
      }
   
  }

      setProgress(100);

      setTimeout(() => {
        setProgress(0);
      }, [1000]);
  
};


//--------------------------------------------------------------to image
const onCreatImg_blob = (item, index) => {
  return new Promise((resolve, reject) => {

  setLoading(true); // 로딩 상태 시작

  if (captureRef.current === null) {
    setLoading(false);
    return;
  }

  // 스크롤바를 숨기기 위한 스타일 변경
  const originalOverflow = captureRef.current.style.overflow;
  const originalHeight = captureRef.current.style.height; // 기존 높이 값 저장
  captureRef.current.style.overflow = 'hidden';
  captureRef.current.style.height = 'auto';

  const scale = 1; // 해상도 배율을 2배로 설정 (sk가 해상도 기준이 2000*2000이라 1로 수정)
  const width = captureRef.current.offsetWidth * scale;
  const height = captureRef.current.scrollHeight * scale; // 전체 높이 사용

  // // toPng로 이미지 생성 및 Blob 변환
  // toPng(captureRef.current, {
  //   skipFonts: true,
  //   filter: (node) => {
  //     // classList가 없는 요소와 custom-spinner-container 클래스를 포함하지 않은 모든 요소를 캡처에 포함
  //     return !node.classList || !node.classList.contains('custom-spinner-container');
  //   },
  //   canvasWidth: width,
  //   canvasHeight: height,
  //   pixelRatio: scale, // 해상도를 높이기 위해 픽셀 비율을 설정
  // })

   // toJpeg로 이미지 생성 및 Blob 변환
   toJpeg(captureRef.current, {
    skipFonts: true,
    filter: (node) => {
      // classList가 없는 요소와 custom-spinner-container 클래스를 포함하지 않은 모든 요소를 캡처에 포함
      return !node.classList || !node.classList.contains('custom-spinner-container');
    },
    canvasWidth: width,
    canvasHeight: height,
    pixelRatio: scale, // 해상도를 높이기 위해 픽셀 비율을 설정
    quality: 0.95 // JPEG 이미지 품질 설정 (0 ~ 1 사이 값)
  })
  
    .then((dataUrl) => {
      // dataUrl을 Blob으로 변환
      const byteString = atob(dataUrl.split(',')[1]); // Base64 문자열 분리
      const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0]; // MIME 타입 추출

      // Blob 데이터 생성
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ab], { type: mimeString });
    })
    .then((blob) => {
      // FormData 객체 생성
      const formData = new FormData();
      // const fileName = '2222222.png'; // 파일 이름 설정
      const currDate = fnc.fncSirialNum('S');
      // const currDate = '1111';
      const fileName = `${item.stCode}.jpg`;//SMS png 안 돼
     
      formData.append('classCode', currDate);  // 추가 데이터 (예: classCode)
      formData.append('file', blob, fileName);  // Blob 파일 추가

      // 파일 업로드 요청
      fnc.sendAxios('/uploadSftpSMS', formData, (res) => {
        if (res) {
          // console.log('File uploaded successfully:', res);
          setTimeout(() => {
            fncSendSMS(`${currDate}_${fileName}`, item, index);
            resolve();  // 성공 시 Promise 완료
          }, 1000);  // 1초 지연 (1000ms)
         
        } else {
          console.log('Error during file upload');
          reject('Error during file upload');
        }
      });
    })
    .catch((err) => {
      console.error('Error capturing image or sending to server', err); // 에러 처리
    })
    .finally(() => {
      setLoading(false); // 로딩 상태 종료
      captureRef.current.style.overflow = originalOverflow; // 원래 overflow 상태로 복원
      captureRef.current.style.height = originalHeight; // 원래 height로 복원
    });

  });
};



function fncSendSMS(fileName, item, index) {
  const acaCode = fnc.getCookie('acaCode');
  const stCode = fnc.getCookie('stCode');

  const MSG='고대학원';
  const SUBJECT = '일일리포트';
  const category = '일일리포트';
  const FILE_CNT = 1;
  // const FILE_PATH1 = `${smsFolder}/${fileName}`;//smsFolder;// `http://kuesb.i234.me/kuesbLmsFileUpload/sms/${fileName}`;//https(안 돼)
 
//  const FILE_PATH1 = fnc3.getS3Path( `${smsFolder}/${fileName}`);
  const FILE_PATH1 =  `${smsFolder}/${fileName}`;
 console.log(FILE_PATH1, '========= FILE_PATH1');
  const sendPhone = sendPhoneNumber;//'033-735-8805';
  let mode = '';
  const sendUserName = fnc.getCookie('userName');

  let sendDateTime = '';


  if (optValueSchedule == 'now') {//즉시 예약
      sendDateTime = fnc.getNow();
      mode='즉시';
  } else {
      sendDateTime =`${fnc.formatDate(sendDate)} ${fnc.formatTime(sendTime)}`;
      mode='예약';
  }

  // return;

    // 대상 정보를 담은 객체
  let targetList = [];

  // optValueTarget에 따라 처리
  if (optValueTarget === 'student' || optValueTarget === 'all') {
    // 학생 정보가 유효한 경우 추가

    if (item.hp && item.hp.trim() !== '') {
      targetList.push({ name: item.name, phone: item.hp });
    }
  }

  if (optValueTarget === 'parent' || optValueTarget === 'all') {
    // 부모 정보가 유효한 경우 추가
    if (item.hpParent && item.hpParent.trim() !== '') {
      targetList.push({ name: `${item.name} 학부모`, phone: item.hpParent });
    }
  }


  // 대상이 없으면 함수 종료
  if (targetList.length === 0) {
    console.log("전화번호가 모두 공백입니다. SMS를 보내지 않습니다.");
    return;  // 아무것도 하지 않고 종료
  }

  // 각각의 대상에게 SMS 전송
  targetList.forEach(target => {
    fnc.sendAxios('/insert/sms', { 
      SUBJECT, 
      MSG,  
      FILE_CNT,
      FILE_PATH1,
      PHONE: target.phone,  // 대상의 전화번호
      sendPhone,
      sendDateTime,
      acaCode,
      stCode,
      stName: target.name,  // 대상의 이름 (학생 혹은 '학생 이름 학부모')
      mode, //즉시/예약
      sendUserName,
      category//항목 
    }, (res) => {
      console.log(res, Array.isArray(res) ? 'sms' : "kuesbSms/insert/sms");
    });
  });

  
  // if (optValueTarget === '학생') {//즉시 예약
  //   PHONE = item.hp;
  // } else if(optValueTarget === '부모') {
  //   PHONE = item.hpParent;
  // } else {

  // }



  // fnc.sendAxios('/insert/sms', { 
  //   SUBJECT, MSG, FILE_CNT, FILE_PATH1, PHONE, sendPhone, sendDateTime, acaCode, stCode, stName, mode, sendUserName
  // }, (res) => {
  //   if (res && Array.isArray(res)) { 
  //     console.log(res, 'sms');
  //   } else {
  //     console.log(res,"kuesbSms/insert/sms");
  //   }
  // });


}

const onCreatImg_blob2 = () => {
  setLoading(true); // 로딩 상태 시작

  if (captureRef.current === null) {
    setLoading(false);
    return;
  }

  // 스크롤바를 숨기기 위한 스타일 변경
  const originalOverflow = captureRef.current.style.overflow;
  const originalHeight = captureRef.current.style.height; // 기존 높이 값 저장
  captureRef.current.style.overflow = 'hidden';
  captureRef.current.style.height = 'auto';

  const scale = 2; // 해상도 배율을 2배로 설정
  const width = captureRef.current.offsetWidth * scale;
  const height = captureRef.current.scrollHeight * scale; // 전체 높이 사용

  // toPng로 이미지 생성 및 Blob 변환
  toPng(captureRef.current, {
    skipFonts: true,
    filter: (node) => {
      // classList가 없는 요소와 custom-spinner-container 클래스를 포함하지 않은 모든 요소를 캡처에 포함
      return !node.classList || !node.classList.contains('custom-spinner-container');
    },
    canvasWidth: width,
    canvasHeight: height,
    pixelRatio: scale, // 해상도를 높이기 위해 픽셀 비율을 설정
  })
    .then((dataUrl) => {
      // Blob으로 변환
      return fetch(dataUrl)
        .then(res => res.blob()); // dataUrl을 Blob으로 변환
    })
    .then((blob) => {

      // FormData 객체 생성
      const formData = new FormData();
      const fileName = '2222222.png'
      formData.append('file', blob, fileName);  // Blob 파일 추가
      formData.append('classCode', classCode);  // 추가 데이터 (예: classCode)

      // 파일 업로드 요청
      fnc.sendAxios('/uploadSftpSMS', formData, (res) => {
        if (res) {
          console.log('File uploaded successfully:', res);
        } else {
          console.log('Error during file upload');
        }
      });


      // const formData = new FormData();
      // formData.append('file', blob, `${selectedItem.stName}_${fnc.formatDate(startDate)}.png`);

      // fetch('http://localhost:8000/uploadSftpSMS', {
      // method: 'POST',
      // body: formData,
      // })
      // .then((response) => {
      // if (response.ok) {
      // console.log('Image uploaded and sent via SFTP successfully');
      // } else {
      // console.error('Failed to upload image');
      // }
      // })
      // .catch((err) => {
      // console.error('Error uploading image', err);
      // });


      // Blob 데이터를 FormData로 감싸서 서버로 전송
      // const formData = new FormData();
      // formData.append('image', blob, `${selectedItem.stName}_${fnc.formatDate(startDate)}.png`);

      // // 서버로 Blob 전송
      // return fetch('https://your-server-url.com/upload', {
      //   method: 'POST',
      //   body: formData,
      // });
    })
    .then((response) => {
      if (response.ok) {
        console.log('Image uploaded successfully');
      } else {
        console.error('Image upload failed');
      }
    })
    .catch((err) => {
      console.error('Error capturing image or sending to server', err);
    })
    .finally(() => {
      setLoading(false); // 로딩 상태 종료
      captureRef.current.style.overflow = originalOverflow; // 원래 overflow 상태로 복원
      captureRef.current.style.height = originalHeight; // 원래 height로 복원
    });
};



const onCreatImg = () => {
  setLoading(true); // 로딩 상태 시작

  if (captureRef.current === null) {
    return;
  }

  // 스크롤바를 숨기기 위한 스타일 변경
  const originalOverflow = captureRef.current.style.overflow;
  const originalHeight = captureRef.current.style.height; // 기존 높이 값 저장
  captureRef.current.style.overflow = 'hidden';
  captureRef.current.style.height = 'auto';
  
  const scale = 2; // 해상도 배율을 2배로 설정 (원하는 해상도에 맞게 조정 가능)
  const width = captureRef.current.offsetWidth * scale;
  // const height = captureRef.current.offsetHeight * scale;//(이걸로 안 보이면 아래로: 감자경우)
  const height = captureRef.current.scrollHeight * scale;  // scrollHeight로 요소의 전체 높이를 가져옵니다

  toPng(captureRef.current, {
    skipFonts: true,
    filter: (node) => {
 
      // classList가 없는 요소와 custom-spinner-container 클래스를 포함하지 않은 모든 요소를 캡처에 포함
      return !node.classList || !node.classList.contains('custom-spinner-container');
    },
    canvasWidth: width,
    canvasHeight: height,
    pixelRatio: scale, // 해상도를 높이기 위해 픽셀 비율을 설정
  })
    .then((dataUrl) => {
      const link = document.createElement('a');
      link.href = dataUrl;
      link.download =  `${selectedItem.stName}_${fnc.formatDate(startDate)}.png`;
      link.click();
    })
    .catch((err) => {
      console.error('Could not capture image', err);
    })
    .finally(() => {
      setLoading(false); // 로딩 상태 종료
      captureRef.current.style.overflow = originalOverflow; // 원래 overflow 상태로 복원
      captureRef.current.style.height = originalHeight; // 원래 height로 복원
    });
};




  //=========================================
    return (

      <div className="LmsTecResultAllimjang">

       
        {!!progress && <div className='box-progress'>  {/* Progress 표시 */}
            <CircularProgressWithLabel value={progress} />
         </div> }
         
        <div className="wrap-main">
          <section className="section-main">
            <table className="table-hong table-main">
              <thead>
                <tr>
                  <th>No</th>
                  <th>
                    <Checkbox    
                        className="custom-checkbox"
                        checked={isSelectAll}
                        onChange={handleSelectAllChange} 
                    />
                  </th>
                  <th>성명</th>
                  <th>HP학생</th>
                  <th>HP부모</th>
                  {/* <th>수업일</th> */}
      
   
                </tr>
              </thead>

              <tbody>
                  {list && list.map((item, index) => (
  
                      <tr key={index} onClick={() => onClickRow(item)}>  
                        <td>{index+1}</td>

                        <td>
                            <Checkbox
                            className="custom-checkbox"
                            checked={item.isChecked}
                            onChange={(event) => handleCheckboxChange(event, index)} />
                        </td>

                        <td>
                            <p className='name'> {item.name}</p>
                        </td>
                
                        <td>
                          <span className='hp'> {item.hp}</span> 
                        </td>
                        <td>
                          <span className='hpParent'>{item.hpParent}</span>
                        </td>

                        {/* <td>
                            <p> {item.lessonDate} ({item.weekDay})</p>
                        </td> */}
         
                      </tr>
                  ))}
            
                </tbody>
            </table>
          </section>

          <section className='section-reportChart' >
           {<LmsTecResultAllimjang_report selectedItem={selectedItem}  ref={captureRef} /> }

           <article className='article-sendMenu'>
                <LmsTecResultAllimjang_report_sendMenu 
                  optValueTarget={optValueTarget} setOptValueTarget={setOptValueTarget} 
                  optValueSchedule={optValueSchedule} setOptValueSchedule={setOptValueSchedule}
                  sendDate={sendDate} setSendDate={setSendDate}
                  sendTime={sendTime} setSendTime={setSendTime}
                />
               
               
            </article>
          
          </section>

        
      
        </div>

        <section className='section-menu'>

          <p className='stName'>{selectedItem?.name}</p>
          {/* <p className='monthInfo'>{monthInfo}</p> */}


          <Button className='btnNextPrev' onClick={() => moveDay(startDate, - 1)}>
            <i className="fas fa-backward"></i> 
          </Button>

          <DatePicker
              selected={startDate}
              onChange={(date) => {setStartDate(date)}}
              dateFormat="yyyy-MM-dd"
              className="datePicker"
              enableTabLoop={false}//클릭시 오른쪽 밀림 방지
            />


          <Button className='btnNextPrev' onClick={() => moveDay(new Date(), 0)}>
            <i className="fas fa-pause"></i>
          </Button>

          <Button className='btnNextPrev' onClick={() => moveDay(startDate, 1)}>
            <i className="fas fa-forward"></i>
          </Button>

          

          <section className='section-smsMenu'>

            <Button className='btnRefresh h-btn-gray' onClick={onRefresh} disabled={isButtonDisabled}> 
              <i className="fas fas fa-sync"></i> 
              새로고침
            </Button>

              <Button className='btnSendSms h-btn-red' onClick={onSendSms}> 
                  <i class="fa-solid fa-mobile-retro"></i>문자전송
              </Button>

              <Button className='btnSmsHistory h-btn-green' onClick={() => setIsModal_smsResult(true)}> 
                  <i class="fa-solid fa-mobile-retro"></i>문자내역
              </Button>
          </section>

        </section>

        {isModal_smsResult && <S.Modal_fullScreen backgroundColor='white'>
          <SmsResult  setIsModal={setIsModal_smsResult} />
          </S.Modal_fullScreen>
          }

      </div>
    );

    //------------------------------------------------------


    
   //---------------------------checkbox
   function handleCheckboxChange(event, index) {
    const isChecked = event.target.checked;
    console.log(isChecked, 'isChecked');
    const newList = [...list];
    newList[index].isChecked = isChecked;
    setList(newList);
 
  // 모두 체크되었는지 확인하여 헤더 체크박스 상태 업데이트
  const allChecked = newList.every(item => item.isChecked);
  setIsSelectAll(allChecked);
  }

  function handleSelectAllChange() {

    const newList = list.map(item => ({...item, isChecked: !isSelectAll }));
    setList(newList);
    setIsSelectAll(!isSelectAll);
  }//checkbox END

    
  function fncCheckedynSt(checkedynSt) {
    switch(checkedynSt) {

      case 'Y':
        return '출석';
        break;  
    
      case 'L':
        return '지각';
        break;

      default:
        return '결석';
        break;
  
    }
  }
  
    
}

export default LmsTecResultAllimjang;