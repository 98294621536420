import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateMarkingList } from '../reduxToolkit/redux';
import './Detail_omrTec.scss';
import Button from '@mui/material/Button';
import fnc from '../mymod/commFunction';
import ComboboxMui from '../comm/ComboboxMui';

const Detail_omrTec = () => { 
    const dispatch = useDispatch();
    // const markingList = useSelector(state => state.reduxStore.markingList);
    // const list = useSelector(state => state.reduxStore.list_pdf_marking);
    const [selectedValues, setSelectedValues] = useState({});
    const [questionCount, setQuestionCount] = useState(null); // 문항 수를 상태로 관리
    const [list, setList] = useState([]);
    const [markingList, setMarkingList] = useState({});
    const lmsListCode = useSelector(state => state.reduxStore.lmsListCode);//prentCode
    const [isRefresh, setIsRefresh] = useState(false);
    const [pointSum, setPointSum] = useState(0);

    useEffect(() => {
        fncSelect_list();
      }, [isRefresh]);

      
    useEffect(() => {
        if (markingList) {
            setSelectedValues(markingList);
        }
    }, [markingList]);

    const handleRadioChange = (idx, value) => {
        const updatedValues = {
            ...selectedValues,
            [idx + 1]: value
        };
        setSelectedValues(updatedValues);
    };

     
  function fncSelect_list() {
    
    fnc.sendAxios('/select/lmsTec/course/testMakeOmr', { parentCode: lmsListCode }, (res) => {
      if (res && Array.isArray(res)) { 
        const fetchedList = getRecords(res);
        setList(fetchedList);
        setQuestionCount(fetchedList.length);
        const initialValues = {};
        fetchedList.forEach(item => {
            initialValues[item.munNo] = item.dap;
        });
        setSelectedValues(initialValues);

      } else {
        console.log(res,"kuesbLmsTecRoutes/select/lmsTec/course/testMakeOmr");
      }
    });
  }



  
  function getRecords(res) {
    let records = [];
    for (let i = 0; i < res.length; i++) {      
        const sno= res[i]["sno"];
        const munNo= res[i]["munNo"];//list 코드 parent Code로 사용
        const dap = res[i]["dap"];
        const point = res[i]["point"];
        records.push({ sno, munNo, dap, point });
    }
    return records;
  }


  
  function fncSaveRecord() {
 
    const rtn = window.confirm('OMR을 저장하시겠습니까?');
    if(!rtn) return;

    let markingBar = null;
    let pointsBar = null;
    let markingValue;
    let pointsValue;
    for (let i = 0; i < list.length; i++) {
        markingValue = selectedValues[i+1] ?? '';// 이부분 i+1로 해야
        pointsValue = list[i].point ?? ' ';//null undefinded일 때만 공백 || ''는 0 fase nan '' 모두 ' '이렇게 해야 공백 들어감
        markingBar = markingBar ? markingBar + '@' + markingValue : markingValue; 
        pointsBar = pointsBar ? pointsBar  + '@' + pointsValue : pointsValue; 
    }

// console.log(lmsListCode, markingBar, pointsBar);
    fncInsert_usp_markingOmrTec(lmsListCode, markingBar, pointsBar);

  };


function fncInsert_usp_markingOmrTec(parentCode, dataString, pointsBar) {
    const acaCode = fnc.getCookie('acaCode');
    const stCode = fnc.getCookie('stCode');

    console.log({ acaCode, parentCode, dataString, pointsBar });
    
    fnc.sendAxios('/insert/lmsTec/omr/usp_markingOmrTec', { acaCode, parentCode, dataString, pointsBar }, (res) => {
      if (res) { 
          setIsRefresh(!isRefresh);
          alert('완료되었습니다.');
      } else {
        console.log(res,"kuesbLmsTecRoutes/insert/lmsTec/omr/usp_markingOmrTec");
      }
    });
  }


  function onMakeOmr() {
    if (!questionCount) {
      alert('문항수를 입력하세요!');
      return;
    }
    const newList = Array.from({ length: questionCount }, (_, index) => ({ id: index + 1 }));
    setList(newList);

  }

  function handleMunCntChange(e) {
    const newCount = e.target.value;
    if (!isNaN(newCount) && newCount > 0) {
     setQuestionCount(newCount);
    }

  }

  function onSave() {
    fncSaveRecord();
  }

  function onDelete() {
    const acaCode = fnc.getCookie('acaCode');
    const parentCode = lmsListCode;

    const rtn = window.confirm('OMR을 삭제하시겠습니까?');
    if(!rtn) return;

    fnc.sendAxios('/delete/lmsTec/course/markingOmrTec', { acaCode, parentCode }, (res) => {
      if (res) { 
        setIsRefresh(!isRefresh);
        setQuestionCount(null);
      } else {
        console.log(res,"delete/lmsTec/course/markingOmrTec");
      }
    });

  }

  function onRefresh() {
    setIsRefresh(!isRefresh);
  }

  
  const optionData = [
    { value: '', label: '배점을 선택를 선택하세요' },
    { value: '2', label: '2점' },
    { value: '3', label: '3점' },
    { value: '4', label: '4점' },
  ];

  
  const handleOptionChange = (event, index) => {
    const newList = [...list];
    newList[index].point = event.target.value;
    setList(newList);

    // point의 합계 계산
    const totalPoints = newList.reduce((sum, item) => sum + (parseFloat(item.point) || 0), 0);

    // 합계를 setPointSum에 설정
    setPointSum(totalPoints);

  };

   // 새로 추가한 함수 - 전체 2점으로 설정
   const handleSetAllPointsToTwo = (point) => {
    const updatedList = list.map(item => ({ ...item, point }));
    setList(updatedList);
    const totalPoints = updatedList.reduce((sum, item) => sum + 2, 0);
    setPointSum(totalPoints);
};


  //============================================================================
  return (
    true && (
        <div className='Detail_omrTec'>
            <div className='wrap-main'>
                <div className="title">OMR 만들기 배점: {pointSum}</div>
                <span>점수합계가 100점이 아니어도 가중치 점수 환산으로 100점 처리 됩니다. </span>
                <table className="warp-marking-omr">
                    <tbody>
                        {list.map((item, index) => (
                            <tr className="tr_queMenu_omr" key={index}>
                                <td>
                                    <span className="NoOmr">{index + 1}</span>
                                    {[1, 2, 3, 4, 5].map((value) => (
                                        <label
                                            key={value}
                                            className={`custom-radio ${selectedValues[index + 1] == value ? 'selected' : ''}`}
                                            data-number={value}
                                        >
                                            <input
                                                type="radio"
                                                name={`question${index + 1}`}
                                                value={value}
                                                checked={selectedValues[index + 1] == value}
                                                onChange={() => handleRadioChange(index, value)}
                                            />
                                        </label>
                                    ))}

                                    
                                    <input
                                        className="markingValue_omr"
                                        type="text"
                                        spellCheck="false"
                                        autoComplete="off"
                                        value={selectedValues[index + 1] || ''}
                                        onChange={(e) => handleRadioChange(index, e.target.value)}
                                    />
                                    <div className='point'>
                                      <ComboboxMui
                                        label=""
                                        value={item.point || ''}
                                        onChange={(event) => handleOptionChange(event, index)}
                                        options={optionData}
                                      />
                                    </div>
                                  
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

                      
          <section className='section-menu-omr'>
            <input className='txtMunCnt' type='text' placeholder='문항수를 입력하세요' 
            value={questionCount || ''} 
            onChange={handleMunCntChange} />
            <Button className='btnMakeOmr' onClick={onMakeOmr}>OMR만들기</Button>
            <Button className='btnSave' onClick={onSave}>저장</Button>
            <Button className='btnDelete' onClick={onDelete}>삭제</Button>
            <Button className='btnRefresh' onClick={onRefresh}>새로고침</Button>
            <Button className='btnScore' onClick={() => handleSetAllPointsToTwo(2)}>전체 2점</Button>
            <Button className='btnScore' onClick={() => handleSetAllPointsToTwo(3)}>3점</Button>
            <Button className='btnScore' onClick={() => handleSetAllPointsToTwo(4)}>4점</Button>
          </section>
            

        </div>
    )
);
};

export default Detail_omrTec;
