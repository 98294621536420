import './MemoCanvas.scss';
import React, { useState, useEffect, useRef } from "react";
import fnc from '../mymod/commFunction';
import * as S from '../scss/theme';
import HeaderTitle from '../comm/HeaderTitle';
import redux from '../reduxToolkit/redux';
import { useSelector, useDispatch } from 'react-redux';

import { setHeader_dev, setList_pdf, setRowIdx_pdf, setPrev, setNext, setRowIdx_que, setHeaderInfo } from '../reduxToolkit/redux';
import DrawNormal from '../drawSvg/DrawNormal';
import DrawMenu from '../drawSvg/DrawMenu';
import { Button } from "@mui/material";

const MemoCanvas = ({ setIsModal, item }) => {

    const dispatch = useDispatch();

    const imgUrl = process.env.REACT_APP_imgUrl;
    const headerInfo = useSelector(state => state.reduxStore.headerInfo);
    const selectedUnit_pdf = useSelector(state => state.reduxStore.selectedUnit_pdf);//단원선택  unit table
    const selectedTestNm = useSelector(state => state.reduxStore.selectedTestNm);//단원선택  unit table
    
    const list_pdf = useSelector(state => state.reduxStore.list_pdf);
    const list_que = useSelector(state => state.reduxStore.list_que);
    const rowIdx_pdf = useSelector(state => state.reduxStore.rowIdx_pdf);
    const rowIdx_que = useSelector(state => state.reduxStore.rowIdx_que);
    const kind = useSelector(state => state.reduxStore.kind);

    const prev = useSelector(state => state.reduxStore.prev);
    const next = useSelector(state => state.reduxStore.next);

    const [imagePath, setImagePath] = useState('');
    const [testCode, setTestCode] = useState('');
    const [munCode, setMunCode] = useState('');
    const [no, setNo] = useState('');
    const [title, setTitle] = useState('');
    const [index, setIndex] = useState(0);
    const [height, setHeight] = useState(null);
    const [allowTouchMove, setAllowTouchMove] = useState(true);
    const drawSwipRefs = useRef([]);
    const [imgSize, setImgSize] = useState(null);
    const [imgWidth, setImgWidth] = useState('');


    const isCommBoxVisible = useSelector(state => state.reduxStore.isCommBoxVisible);

    
    useEffect(() => {


      dispatch(setHeader_dev('MemoCanvas'));


    }, []);

      
    useEffect(() => {     
        if(kind === 'pdf') {
            const idx = rowIdx_pdf;
            fncSetImgInfo_pdf(idx)

        }else if (kind === 'comm') {

            const idx = rowIdx_que;
            fncSetImgInfo_comm(idx)

        } else{//que || sol
            const idx = rowIdx_que;
            fncSetImgInfo_que(idx)
        }    
    }, [rowIdx_pdf, rowIdx_que]);


    
    function fncSetImgInfo_comm(idx) {
  
      let newImagePath = list_que[idx].imagePath_comm;

      console.log(newImagePath, ' newImagePath comm');

      setImagePath(newImagePath);
      setTestCode(list_que[idx].source);
      setMunCode(list_que[idx].queNo);
      setNo(list_que[idx].page);

      const newImgSize = list_que[idx].ImgSize;

      if (newImgSize) {
        setImgSize(newImgSize);
        const imgSizeX = newImgSize.split(",")[0];

        if (imgSizeX == 2379) {
          setImgWidth("75%");
        } else if (imgSizeX == 1428) {//exam 문제
          setImgWidth("80%");//75%는 흐리게 나온다.
        } else {
          setImgWidth("100%");
        }
      }else {//newImgSize가 undefind일 때
        setImgWidth("75%");//혹시 몰라서
      }

      console.log(list_que, 'list_que');
       //해더 추가 (이전 다음할 때 변경)
       const label =  `${selectedTestNm} ${idx+1}번`;
       dispatch(setHeaderInfo({title: label, direction: -1}));

    }



    function fncSetImgInfo_pdf(idx) {
      // console.log(list_pdf, 'lis pdf');
      const newImagePath = list_pdf[idx].imagePath;

      setImagePath(newImagePath);
      setTestCode(list_pdf[idx].testCode);
      setMunCode(list_pdf[idx].munCode);
      setNo(list_pdf[idx].no);
      setImgWidth("100%");
      //해더 추가 (이전 다음할 때 변경)
      const label =  `${selectedUnit_pdf} ${list_pdf[idx].page}쪽`;
      dispatch(setHeaderInfo({title: label, direction: -1}));
    }

    function fncSetImgInfo_que(idx) {
      let newImagePath = list_que[idx].imagePath;
  
      if(kind === 'sol') {
         newImagePath = list_que[idx].imagePath_sol;
      }

      setImagePath(newImagePath);
      setTestCode(list_que[idx].source);
      setMunCode(list_que[idx].queNo);
      setNo(list_que[idx].page);

      const newImgSize = list_que[idx].ImgSize;

      if (newImgSize) {
        setImgSize(newImgSize);
        const imgSizeX = newImgSize.split(",")[0];

        if (imgSizeX == 2379) {
          setImgWidth("75%");
        } else if (imgSizeX == 1428) {//exam 문제
          setImgWidth("80%");//75%는 흐리게 나온다.
        } else {
          setImgWidth("100%");
        }
      }else {//newImgSize가 undefind일 때
        setImgWidth("75%");//혹시 몰라서
      }

      // console.log(list_que, 'list_que');
       //해더 추가 (이전 다음할 때 변경)
       const label =  `${selectedTestNm} ${idx+1}번`;
       dispatch(setHeaderInfo({title: label, direction: -1}));

    }


  //---------------------------------------
  if (prev) {
    fncPrev();
    dispatch(setPrev(false));
  }

  if (next) {
    fncNext();
    dispatch(setNext(false));
  }



  function fncPrev() {
    if (kind === "pdf") {
      if (rowIdx_pdf == 0) {
        alert("처음입니다.");
        return null;
      }
      dispatch(setRowIdx_pdf(rowIdx_pdf - 1));
    } else {
      if (rowIdx_que == 0) {
        alert("처음입니다.");
        return null;
      }
      dispatch(setRowIdx_que(rowIdx_que - 1));
    }
  }

  function fncNext() {

    if (kind === "pdf") {
      if (rowIdx_pdf == list_pdf.length - 1) {
        alert("끝입니다.");
        return null;
      }
      dispatch(setRowIdx_pdf (rowIdx_pdf + 1));
    } else {
      if (rowIdx_que == list_que.length - 1) {
        alert("끝입니다.");
        return null;
      }
      dispatch(setRowIdx_que(rowIdx_que + 1));
    }
  }

    //=====================================================================================
    return(
        <div className="MemoCanvas">
            {/* <HeaderTitle title={title} direction={1} onBackClick={() => setIsModal(false)} /> */}
       
            <Button className='btnClose-me' onClick={() => setIsModal(false)} >
                <i class="fa-solid fa-xmark"></i>
            </Button>  

           {  
            <DrawNormal
                    key={imagePath}
                    imgUrlQue={{ imgPathQue: imagePath  }}
                    testCode={testCode}
                    munCode={munCode}
                    no={no}
                    setAllowTouchMove={setAllowTouchMove}
                    ref={(el) => (drawSwipRefs.current[index] = el)}
                    index={1} 
                    height={height}
                    imgSize={imgSize}
                    imgWidth={imgWidth} /> }

            <DrawMenu />
                           
        </div>
    );
}

export default MemoCanvas;