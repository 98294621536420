import './LmsTecResultOmrByStudent.scss';
import * as S from '../scss/theme';
import React, { useState, useEffect } from "react";
import fnc from '../mymod/commFunction';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { } from '../reduxToolkit/redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ClassStList from './ClassStList';


const LmsTecResultOmrByStudent = ({kind}) => {

    const dispatch = useDispatch();
    const isModal = useSelector((state) => state.reduxStore.isModal);
    const classCode = useSelector((state) => state.reduxStore.classCode);
    const selectedItemSt_result = useSelector((state) => state.reduxStore.selectedItemSt_result);

    const [list, setList] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const [isModal_classSt, setIsModal_classSt] = useState(false);
    const [selectedItemSt, setSelectedItemSt] = useState(null);
      
    // console.log(selectedItemSt_result, 'selectedItemSt_result');
    useEffect(() => {
      if (selectedItemSt_result) {
        fncSelect_list();
      }

    }, [selectedItemSt_result]);

  
    
    
  function fncSelect_list() {
    const acaCode = fnc.getCookie('acaCode');
    const stCode = selectedItemSt_result.stCode || '';
    const classCode = selectedItemSt_result.classCode;

    
    
    fnc.sendAxios('/select/lmsTecResult/omrByStudent', 
          { acaCode, stCode }, (res) => {
      if (res && Array.isArray(res)) { 
        setList(getRecords(res));


      } else {
        console.log(res,"select/book/pdfList");
      }
    });
  }

  
  function getRecords(res) {
    let records = [];
    for (let i = 0; i < res.length; i++) {      
      const classCode= res[i]["classCode"];
      let lessonDate= res[i]["lessonDate"];
      const weekDay = fnc.getWeekDay(lessonDate);
      const stName = res[i]["stName"];
      let hp = res[i]["hp"];
      hp=fnc.formatPhoneNumber(hp);
      let score = res[i]["score"];
      let avgScore = res[i]["avgScore"];
      let rank = res[i]["rank"];
      const markingDate = res[i]["markingDate"];

      if (score !== '') {
        score = parseFloat(score).toFixed(1);
      }

      if (avgScore !== '') {
        avgScore = parseFloat(avgScore).toFixed(1);
      }

    
      records.push({ classCode, lessonDate, weekDay, stName, hp,  score, avgScore, rank, markingDate });
    }
    return records;
  }
  
  
    //=========================================
    return (

      <div className="LmsTecResultOmrByStudent">
        <div className="wrap-main">
          <section className="section-main">
            <table className="table-hong table-main">
              <thead>
                <tr>
                  <th>No</th>
                  <th>수업일</th>
                  <th>성명</th>
                  <th>HP</th>
                  <th>점수</th>
                  <th>평균</th>
                  <th>석차</th>
                  <th>제출일</th>     
                </tr>
              </thead>

              <tbody>
                  {list && list.map((item, index) => (
  
                      <tr key={index}>  
                        <td>{index+1}</td>
                        <td>{item.lessonDate} ({item.weekDay})</td>
                        <td>{item.stName}</td>
                        <td>{item.hp}</td>
                        <td>{item.score}</td> 
                        <td>{item.avgScore}</td>    
                        <td>{item.rank}</td>              
                        <td>{item.markingDate}</td>
                      </tr>
                      
                  ))}
            
                </tbody>
            </table>
          </section>
      
        </div>


        <section className='section-menu'>
          <span className='lblStName'>{selectedItemSt_result.stName}</span>
          <Button className='btnSt' onClick={() => setIsModal_classSt(true)} disabled={isButtonDisabled}> 
            <i class="fa fa-user"></i> &thinsp; 학생검색
          </Button>
        </section>

         {isModal_classSt && <S.Modal_fullScreen>
          <ClassStList  setIsModal_classSt={setIsModal_classSt} setSelectedItemSt={setSelectedItemSt}/>
          </S.Modal_fullScreen>
          }

         
      </div>
    );
}

export default LmsTecResultOmrByStudent;