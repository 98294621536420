import React, { useState, useRef, useEffect } from 'react';
import './BoardUpdateDelete.scss';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import fnc from '../mymod/commFunction';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector  } from 'react-redux';
import { setIsRefresh_lmsTecList } from '../reduxToolkit/redux';

const BoardUpdateDelete = ({ fncClose_update }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [title, setTitle] = useState('');
  const [jindo, setJindo] = useState('');
  const [homework, setHomework] = useState('');
  const [content, setContent] = useState('');
  const [startDate, setStartDate] = useState(new Date());

  const classCode = useSelector((state) => state.reduxStore.classCode);
  const lmsListCode = useSelector(state => state.reduxStore.lmsListCode);//parentCode
  const isRefresh_lmsTecList = useSelector((state) => state.reduxStore.isRefresh_lmsTecList);

  const titleInputRef = useRef(null);


  useEffect(() => {

    fncSelectDetail(lmsListCode);

    if (titleInputRef.current) {
      titleInputRef.current.focus();
    }
  }, []);

  function onPrev() {

    dispatch(setIsRefresh_lmsTecList(!isRefresh_lmsTecList));//부모리스트 새로고침
    fncClose_update();
    
  }


  function fncSelectDetail(code) {

    fnc.sendAxios("/select/lmsTec/course/detail", 
      { code}, (res) => {
      if (res) {
        console.log(res);

        setTitle(res[0].제목);
        setContent(res[0].내용);
        setJindo(res[0].진도);
        setHomework(res[0].과제);
        setStartDate(new Date(res[0].sDate)); // Date 객체로 변환하여 설정
      } else {
          console.log(res, "속성이 obj 내에 없습니다.");
      }
  });
  }


  function fncUpdateBoard() {

    const rtn = window.confirm('내용을 수정하시겠습니까?');
    if(!rtn) return;

    const code = lmsListCode;
    const sDate =fnc.formatDate(startDate);

    fnc.sendAxios("/update/lmsTec/course/boardWrite", 
        { code, title, jindo, homework, content, sDate}, (res) => {
        if (res) {
          onPrev();
        } else {
            console.log(res, "속성이 obj 내에 없습니다.");
        }
    });
  }


  
  function fncDeleteBoard() {
    const code = lmsListCode;
    const sDate =fnc.formatDate(startDate);

    fnc.sendAxios("/delete/lmsTec/course/boardWrite", { code}, (res) => {
        if (res) {
    
        } else {
            console.log(res, "속성이 obj 내에 없습니다.");
        }
    });
  }




  //=========================================

  return (
    <div className="BoardUpdateDelete">
      <div className="box_jodit">
        <div className="formGroup">
          <input
            className="titleInput"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="제목을 입력하세요"
            ref={titleInputRef}
          />
          
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            dateFormat="yyyy-MM-dd"
            className="datePicker"
          />
        </div>

        <input
            className="jindoInput"
            spellCheck='false'
            autoComplete="1"  // 임의의 값 설정 포커스시 팝업 안뜨게
            type="text"
            value={jindo}
            onChange={(e) => setJindo(e.target.value)}
            placeholder="진도를 입력하세요"

          />

          <input
            className="homeworkInput"
            spellCheck='false'
            autoComplete="1"  // 임의의 값 설정 포커스시 팝업 안뜨게
            type="text"
            value={homework}
            onChange={(e) => setHomework(e.target.value)}
            placeholder="과제를 입력하세요"

          />

        <div className="box-textArea">
          <textarea
          className='txtAarea'
            name="postContent"
            id="postContent"
            spellCheck='false'
            rows="10"
            cols="50"
            placeholder="여기에 게시글을 작성하세요..."
            value={content}
            onChange={(event) => setContent(event.target.value)}
          ></textarea>
        </div>

        <div className="box_btnMenu">
          <Button className="btnPrev" variant="contained" onClick={onPrev}>
            이전으로
          </Button>
          <Button className="btnUpdate" variant="contained"  onClick={fncUpdateBoard} >
            수정하기
          </Button>
          <Button className="btnDelete" variant="contained"  onClick={fncDeleteBoard} >
            삭제하기
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BoardUpdateDelete;
