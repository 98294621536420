import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';
import axios from "axios";
const svrUrl = process.env.REACT_APP_SERVER_URL;//svrUrl = 'http://localhost:8000';

function setCookie(key, value, day) {
  let secretKey = process.env.REACT_APP_secretKey; // 이 키는 절대 노출되어서는 안됩니다!
  // const encryptedVaule = CryptoJS.AES.encrypt(value,secretKey).toString();
  // Cookies.set(key, encryptedVaule, {expires: day});

  const stringValue = value.toString(); // 숫자를 문자열로 변환 (새로 추가)
  const encryptedValue = CryptoJS.AES.encrypt(stringValue, secretKey).toString(); // 암호화
  Cookies.set(key, encryptedValue, { expires: day });


}



// function getCookie(key) {
//     let secretKey = process.env.REACT_APP_secretKey; 
//     const encryptedValue = Cookies.get(key);
//     if (!encryptedValue)  return '';
//     const bytesValue = CryptoJS.AES.decrypt(encryptedValue, secretKey);
//     const value = bytesValue.toString(CryptoJS.enc.Utf8);
//     return value;
// }

function getCookie(key) {
  let secretKey = process.env.REACT_APP_secretKey; 
  const encryptedValue = Cookies.get(key);
  if (!encryptedValue) return '';

  try {
      const bytesValue = CryptoJS.AES.decrypt(encryptedValue, secretKey);
      const value = bytesValue.toString(CryptoJS.enc.Utf8);
      // 복호화된 데이터가 빈 문자열이 아닌지 확인합니다.
      if (!value) {
          console.error("복호화된 데이터가 유효하지 않습니다.");
          return '';
      }
      return value;
  } catch (error) {
      // 오류 처리: 복호화 과정에서 발생한 예외를 콘솔에 출력
      console.error("복호화 과정에서 오류가 발생했습니다:", error);
      return '';
  }
}


// function setCookie_pure(key, value, day) {
//   Cookies.set(key, value, { expires: day });

// }

// function getCookie_pure(key) {
//   const value = Cookies.get(key);
//   return value;
// }

function setCookie_pure(key, value, day) {
  // 값을 URL 인코딩하여 쿠키에 저장
  const encodedValue = encodeURIComponent(value);
  Cookies.set(key, encodedValue, { expires: day });
}

function getCookie_pure(key) {
  // 쿠키에서 값을 가져온 후 URL 디코딩
  const value = Cookies.get(key);
  return value ? decodeURIComponent(value) : null;
}


// function fncDecrypt(encryptedValue) {
//   let secretKey = process.env.REACT_APP_secretKey; 
  
//   if (!encryptedValue)  return '';
//   const bytesValue = CryptoJS.AES.decrypt(encryptedValue, secretKey);
//   const value = bytesValue.toString(CryptoJS.enc.Utf8);
//   return value;
// }

function fncDecrypt(encryptedValue) {
  let secretKey = process.env.REACT_APP_secretKey; 
  
  if (!encryptedValue) return '';
  try {
    const bytesValue = CryptoJS.AES.decrypt(encryptedValue, secretKey);
    const value = bytesValue.toString(CryptoJS.enc.Utf8);
    if (!value) {
      console.error("복호화된 데이터가 유효하지 않습니다.");
      return '';
    }
    return value;
  } catch (error) {
    console.error("복호화 과정에서 오류가 발생했습니다:", error);
    return '';
  }
}



function fncRound(value, total, digit) {
  let percentage = (value / total) * 100;
  return Math.round(percentage * Math.pow(10, digit)) / Math.pow(10, digit);
}


function updateListItem(list, rowIndex, fieldName, newValue) {
  // 불변성을 유지하기 위해 배열을 복사합니다.
  const newList = [...list];
  // 주어진 인덱스가 배열 범위 내에 있는지 확인하고, 해당 요소를 업데이트합니다.
  if (rowIndex >= 0 && rowIndex < newList.length) {
      newList[rowIndex] = { ...newList[rowIndex], [fieldName]: newValue };
  }

  return newList;
}


const sendAxios = async (url, data, callback) => {
  try {// responseType을 json
    const response = await axios.post(`${svrUrl}${url}`, data);
    let jsonObj = response.data;
    callback(jsonObj);
  } catch (error) {
    console.error("Axios 요청 중 오류가 발생했습니다:", error);
  }
};

const sendAxiosBlob = async (url, data, callback) => {
  try {// responseType을 blob으로 설정
    const response = await axios.post(`${svrUrl}${url}`, data, { responseType: 'blob' });
    let jsonObj = response.data;
    callback(jsonObj);
  } catch (error) {
    console.error("Axios 요청 중 오류가 발생했습니다:", error);
  }
};


function fncDate() {
  const currentDate = new Date();
  // 날짜를 YYYY-MM-DD 형식으로 포매팅
  const formattedDate = currentDate.toISOString().substring(0, 10);
  return formattedDate;
}

function getNow() {
  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const day = currentDate.getDate().toString().padStart(2, '0');
  
  const hours = currentDate.getHours().toString().padStart(2, '0');
  const minutes = currentDate.getMinutes().toString().padStart(2, '0');
  const seconds = currentDate.getSeconds().toString().padStart(2, '0');

  // YYYY-MM-dd hh:mm:ss 형식으로 조합
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}



function fncSirialNum2(startValue = 'B') {//밀리초까지
 
  const uniqueTimestamp = Date.now();
  return startValue + uniqueTimestamp;
  //console.log(uniqueTimestamp); // 예: 1630567890123
  //uuid를 사용하는 방법도 있다. 
  //길이가 4개 짧아지지만 직관적이지 않다.
}

function fncSirialNum(startValue = 'B') {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더해줍니다.
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  const milliseconds = String(now.getMilliseconds()).padStart(3, '0');

  const uniqueTimestamp = `${year}${month}${day}${hours}${minutes}${seconds}${milliseconds}`;
  return startValue + uniqueTimestamp;
}

function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

function formatTime(dateTimeString) {
  const date = new Date(dateTimeString);
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  // hh:mm:ss 형식으로 조합
  return `${hours}:${minutes}:${seconds}`;
}

function formatPhoneNumber(phone) {
  if (!phone) return '';
  return phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
}


const getWeekDay = (date) => {
  const days = ['일', '월', '화', '수', '목', '금', '토'];
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  const dayIndex = date.getDay();
  return days[dayIndex];
};


const moveDay = (currDate, days) => {
  const newDate = new Date(currDate);
  newDate.setDate(currDate.getDate() + days);
  return newDate;
};

function getFirstDayOfMonth(date, intervalMonths) {
  // 입력 날짜에 intervalMonths 만큼 더하고, 해당 월의 첫 번째 날을 계산
  const newDate = new Date(date.getFullYear(), date.getMonth() + intervalMonths, 1);  
  return newDate;
}
  
function getLastDayOfMonth(date, intervalMonths) {
  // 입력 날짜에 intervalMonths 만큼 더하고, 해당 월의 마지막 날을 계산
  const newDate = new Date(date.getFullYear(), date.getMonth() + intervalMonths + 1, 0); 
  return newDate;
}

const getYear = () => {
  return new Date().getFullYear();
};

const getMonth = (digit = 2) => {//digit = 2는 01, 02
  const newMonth =  new Date().getMonth() + 1; // 0이 1월을 나타내므로 +1
  return toTwoDigits(newMonth, digit)
};

function toTwoDigits(num, digit) {
  return num.toString().padStart(digit, '0');
}


const fnc = { 
  setCookie, getCookie, fncDecrypt, 
  getCookie_pure, setCookie_pure, 
  fncRound, updateListItem, 
  fncDate, getNow, formatTime,
  fncSirialNum,
  sendAxios, sendAxiosBlob, formatDate, formatPhoneNumber,
  getWeekDay, moveDay, getFirstDayOfMonth, getLastDayOfMonth,
  getYear, getMonth
 }
export default fnc;
// module.exports = fnc;

