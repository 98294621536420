import './Exam.scss';
import React, { useState, useEffect } from "react";
import fnc from '../mymod/commFunction';
import * as S from '../scss/theme';
import Combobox from '../comm/Combobox';
import UnitTable from './Exam1UnitTable';
// import BookMain from './BookMain';
import HeaderTitle from '../comm/HeaderTitle';
import redux from '../reduxToolkit/redux';
import { useDispatch } from 'react-redux';
import { setHeaderInfo, setSelectedUnit_pdf } from '../reduxToolkit/redux';
import { Button } from '@mui/material';
import Exam2Main from './Exam2Main';
import Exam6FastSearch from './Exam6FastSearch';


// import BookPdf from './BookPdf';

const Exam = () => {
  const [options1, setOptions1] = useState([]);
  const [options2, setOptions2] = useState([]);
  const [options3, setOptions3] = useState([]);
  const [options4, setOptions4] = useState([]);
  const [options5, setOptions5] = useState([]);
  const [optionsUniTable, setOptionsUniTable] = useState([]);
  const [selectedUnitItem, setSelectedUnitItem] = useState({});
  const [selectedValues, setSelectedValues] = useState({}); // 각 콤보박스의 선택된 값을 저장하는 상태 배열
  const [initialComboValue, setInitialComboValue] = useState();
  const [initialComboValue2, setInitialComboValue2] = useState();
  const [initialComboValue3, setInitialComboValue3] = useState();
  const [initialComboValue4, setInitialComboValue4] = useState();
  const [initialComboValue5, setInitialComboValue5] = useState();
  const [initialComboValue6, setInitialComboValue6] = useState();


  const [isModal, setIsModal] = useState(true);
  const [isFastSearch, setIsFastSearch] = useState(true);
  const [isAnimated, setIsAnimated] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setHeaderInfo({ title: `진단평가`, direction: -1 }));
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsAnimated(false);
    }, 5000); 
    return () => clearTimeout(timer);
  }, []);

  //=================================================================
  return (
    < >
    <S.Section>
     <HeaderTitle />

      <div className="Exam">

        <Button className={`btnFastSearch ${isAnimated ? 'bounce' : ''}`} onClick={() => setIsFastSearch(!isFastSearch)}>
              <i className="fas fa-search"></i> &nbsp;빠른검색
        </Button>
    

        {!isFastSearch &&
        <section className="section-bookMain" >
          {selectedUnitItem && <Exam2Main selectedItem={selectedUnitItem} />}
        </section>}


        {isFastSearch &&
        <section className="section-bookMain">
           <Exam6FastSearch setIsFastSearch={setIsFastSearch} setSelectedUnitItem={setSelectedUnitItem}/>
        </section> }

      </div>


    </S.Section>

  
</>
  );
};

export default Exam;