
import './MyClass.scss';
import React, { useEffect, useState } from 'react';
import fnc from '../mymod/commFunction';

import {setSelectedClassList} from '../reduxToolkit/reduxAca';
import { useDispatch } from 'react-redux';

const MyClass = ({ onTrigger }) => {

const stCode = fnc.getCookie('stCode');
const dispatch = useDispatch();
const [list, setList] = useState([]);
const [selectedId, setSelectedId] = useState(null);

useEffect(() => {
    fncSelectMyClass();
}, [onTrigger]);

function fncSelectMyClass() {
    fnc.sendAxios('/aca/selectMyClass', { stCode }, (res) => {
        if(res) {
            // console.log(res, '------------');
            setList(res);
        }
    });
}

function onSelectedRow(item, index) {
    if (selectedId === index) {
        setSelectedId(null); // 같은 row를 클릭하면 선택 해제
      } else {
        setSelectedId(index); // 다른 row를 클릭하면 선택
        dispatch(setSelectedClassList(item));
      }
}

//------------------------------------
return (
  <div className="MyClass">
    <div className="inner_myClass">
      <div className="box_title">
        <p>* 강좌 선택</p>
      </div>
      <table className="table_myClass">
        <tbody className="tbody_myClass">
          {list &&
            list.map((item, index) => (
              <tr
                key={index}
                className={selectedId === index ? "active" : ""}
                onClick={() => {
                  onSelectedRow(item, index);
                }}
              >
                <td style={{ width: "50%"  }}>
                  <div className="box_name">
                    <p> {item.nameEng}</p>
                    <p> ({item.birthday.replaceAll("-", ".")})</p>
                  </div>
                </td>
                <td style={{ width: "50%" }}>
                  <div className="box_class">
                    <p>{item.className}</p>
                    <p>{item.gangsaName}</p>
                  </div>
                </td>

                <td>

                {/* 완료의 유무로 전체 폭을 맞추기 위해 이항연산 씀 */}
                  {item.checkCnt === 7 ? (
                    <p className="complete" style={{ width: "4rem", backgroundColor: 'var(--hanbat-gold)' }}>
                      완료
                    </p>
                  ) : (
                    <p className="complete" style={{ width: "4rem", backgroundColor: 'transparent' }}>
                 
                    </p>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  </div>
);

}

export default MyClass;