import './Digital.scss';
import React, { useState, useEffect } from "react";
import fnc from '../mymod/commFunction';
import * as S from '../scss/theme';
import Combobox from '../comm/Combobox';
import UnitTable from './UnitTable';
import BookMain from './DigitalMain';
import DigitalFastSearch from './DigitalFastSearch';
import HeaderTitle from '../comm/HeaderTitle';
import redux from '../reduxToolkit/redux';
import { useDispatch } from 'react-redux';
import { setHeaderInfo, setSelectedUnit_pdf } from '../reduxToolkit/redux';
import { Button } from '@mui/material';


const Digital = () => {
  const [options1, setOptions1] = useState([]);
  const [options2, setOptions2] = useState([]);
  const [options3, setOptions3] = useState([]);
  const [options4, setOptions4] = useState([]);
  const [options5, setOptions5] = useState([]);
  const [optionsUniTable, setOptionsUniTable] = useState([]);
  const [selectedUnitItem, setSelectedUnitItem] = useState({});
  const [selectedValues, setSelectedValues] = useState({}); // 각 콤보박스의 선택된 값을 저장하는 상태 배열
  const [initialComboValue, setInitialComboValue] = useState();
  const [initialComboValue2, setInitialComboValue2] = useState();
  const [initialComboValue3, setInitialComboValue3] = useState();
  const [initialComboValue4, setInitialComboValue4] = useState();
  const [initialComboValue5, setInitialComboValue5] = useState();
  const [initialComboValue6, setInitialComboValue6] = useState();

  const [isModal, setIsModal] = useState(true);
  const [isFastSearch, setIsFastSearch] = useState(true);
  const [isSideMenu, setIsSideMenu] = useState(false);
  const [isAnimated, setIsAnimated] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    // fncComboResore();
    dispatch(setHeaderInfo({ title: `디지털 교과서`, direction: -1 }));
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsAnimated(false);
    }, 5000); 
    return () => clearTimeout(timer);
  }, []);


//   function fncSelectBookCombo(parentkey, index) {

//     fnc.sendAxios("/select/book/combo_digital", { parentkey }, (res) => {
//       if (res) {     
//         setOptionsByIndex(index, res);
//       } else {
//         console.log(res, "속성이 obj 내에 없습니다.");
//       }
//     });
//   }

  

// function fncComboResore() {
//   let value;
//   fncSelectBookCombo("ROOT", 1);
//   value = fnc.getCookie_pure("combo_selected_key1");

//   if (value) {
//     fncSelectBookCombo(value, 2);
//     setInitialComboValue2(value)
//   }

//   value = fnc.getCookie_pure("combo_selected_key2");
//   if (value) {
//     fncSelectBookCombo(value, 3);
//     setInitialComboValue3(value)
//   }

//   value = fnc.getCookie_pure("combo_selected_key3");
//   if (value) {
//     fncSelectBookCombo(value, 4);
//     setInitialComboValue4(value)
//   }

  
//   value = fnc.getCookie_pure("combo_selected_key4");
//   if (value) {
//     fncSelectBookCombo(value, 5);
//     setInitialComboValue5(value)
//   }

  
//   value = fnc.getCookie_pure("combo_selected_key5");
//   if (value) {
//     fncSelectBookCombo(value, 6);
//     setInitialComboValue6(value)
//   }

//   }

//   const handleComboboxChange1 = (selectedOption) => {
//       fncResetBookmark(1, selectedOption.value);
//       fncSelectBookCombo(selectedOption.value, 2);
//       fnc.setCookie_pure('combo_selected_key1', selectedOption.value, 1000);
//   };



//   const handleComboboxChange2 = (selectedOption) => {
//     const value = selectedOption.value;
//     fncResetBookmark(2, selectedOption.value);
//     fncSelectBookCombo(value, 3);
//     fnc.setCookie_pure('combo_selected_key2', value, 1000);
//   };

//   const handleComboboxChange3 = (selectedOption) => {
//     const value = selectedOption.value; //key값
//     // console.log(value, ' ---------value3');

//     fncResetBookmark(3, selectedOption.value);
//     fncSelectBookCombo(value, 4);
//     fnc.setCookie_pure('combo_selected_key3', value, 1000);
//   };

//   const handleComboboxChange4 = (selectedOption) => {
//     const value = selectedOption.value;

//     // console.log(value, ' ---------value4');
//     fncResetBookmark(4, selectedOption.value);
//     fncSelectBookCombo(value, 5);
//     fnc.setCookie_pure('combo_selected_key4', value, 1000);
//   };

//   const handleComboboxChange5 = (selectedOption) => {
//     const value = selectedOption.value;
//     fncResetBookmark(5, selectedOption.value);

//     fncSelectBookCombo(value, 6);
//     setSelectedUnitItem(selectedOption);
//     fnc.setCookie_pure('combo_selected_key5', value, 1000);
//   };

//   const handleUnitTable = (selectedOption) => {//단원선택 rowclick

//     const value = selectedOption.value;
//     const label = selectedOption.label;

//     setSelectedUnitItem(selectedOption);
//     dispatch(setSelectedUnit_pdf(label));
//     dispatch(setHeaderInfo({title: label, direction: -1}));
//     setIsFastSearch(false);

//     // alert('fdfd')
//     setIsSideMenu(!isSideMenu);

//   };
  
//   function fncResetBookmark(index, value) {
//     // if (index == 1) {
//     //   fnc.setCookie_pure('combo_selected_key2', value, 1000);
//     //   setOptions2([]);
//     // }

//     const setters = {
//       setOptions1,
//       setOptions2,
//       setOptions3,
//       setOptions4,
//       setOptions5
//     };

//      // 인덱스 값에 따라 시작하는 쿠키 키와 옵션을 동적으로 결정
//   for (let i = index + 1; i <= 5; i++) {
//     fnc.setCookie_pure(`combo_selected_key${i}`, value, 1000); // 동적으로 쿠키 키 생성 및 설정
//     //setter 이해가 잘 안된다. 일단 사용하고 나중에 이해하도록(const setter = eval(`setOptions${i}`);이건 보안에 취약하대 eval: 문자를 함수로 만드는 것)
//     const setter = setters[`setOptions${i}`];
//     if (setter) {
//       setter([]);
//     }
//   }
//     setOptionsUniTable([]);
//   }

//   const setOptionsByIndex = (index, data) => {
//     const newOptions = [
//       { value: "-1", label: " ", isDisabled: false },
//     ].concat(
//       data.map((item) => ({
//         value: item.childKey,
//         label: item.childName,
//         bookCode: item.bookCode, //main pdfList에 필요함(북코드는 마지막 단계만 있다.)
    
//       }))
//     );

//     let selectedValue;
//     switch (index) {
//       case 1:
//         setOptions1(newOptions);
//         selectedValue = fnc.getCookie_pure("combo_selected_key1");
//         setInitialComboValue(selectedValue);
//         break;
//       case 2:
//         setOptions2(newOptions);
//         selectedValue = fnc.getCookie_pure("combo_selected_key2");
//         setInitialComboValue2(selectedValue);
//         break;
//       case 3:
//         setOptions3(newOptions);
//         selectedValue = fnc.getCookie_pure("combo_selected_key3");
//         setInitialComboValue3(selectedValue);
//         break;
//       case 4:
//         setOptions4(newOptions);
//         selectedValue = fnc.getCookie_pure("combo_selected_key4");
//         setInitialComboValue4(selectedValue);


//         // console.log(newOptions[0].bookCode, '-------------  newOptions bookCode');

//         break;

//       case 5:
//         // setOptions5(newOptions);
//         // selectedValue = fnc.getCookie_pure("combo_selected_key5");
//         // setInitialComboValue5(selectedValue);

//         let bookCode = null;
//         if (newOptions && newOptions.length > 1) {
//           bookCode = newOptions[1].bookCode;
//         }

//         if (bookCode) {

//           setOptionsUniTable(newOptions);
//         }else {
//           setOptions5(newOptions);
//           selectedValue = fnc.getCookie_pure("combo_selected_key5");
//           setInitialComboValue5(selectedValue);
//         }
      
//         break;

//       case 6:
   
//         setOptionsUniTable(newOptions);
//         break;
//       default:
//         console.error("잘못된 인덱스입니다.");
//     }
//   };

//   // const options = [
//   //     { value: '', label: '항목을 선택하세요.' },
//   //     { value: 'chocolate', label: 'Chocolate1' },
//   //   ];
 

 

  //=========================================
  return (
    //부모 컨포넌트
    //이걸 맨 밖으로 빼야지 flex가 원하는 대로 먹힌다.
    < >
    <S.Section>
     <HeaderTitle />

      <div className="Digital">
  
      <Button className={`btnFastSearch ${isAnimated ? 'bounce' : ''}`} onClick={() => setIsFastSearch(!isFastSearch)}>
          <i className="fas fa-search"></i> &nbsp;빠른검색
      </Button>
      
    
        {!isFastSearch &&
          <section className="section-bookMain">
            {selectedUnitItem && <BookMain selectedItem={selectedUnitItem} />}
          </section>
        }

        { isFastSearch && 
          <section className="section-bookMain">
              <DigitalFastSearch setIsFastSearch={setIsFastSearch} setSelectedUnitItem={setSelectedUnitItem}/>   
          </section> 
        }

      </div>

   


    </S.Section>

   

  
</>
  );
};

export default Digital;