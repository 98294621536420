import './LmsTecResultAllimjang_report.scss';
import * as S from '../scss/theme';
import React, { useState, useEffect, forwardRef } from "react";
import fnc from '../mymod/commFunction';

import { useDispatch, useSelector } from 'react-redux';


const LmsTecResultAllimjang_report = forwardRef(({ selectedItem }, ref) => {

    const dispatch = useDispatch();
    const isModal = useSelector((state) => state.reduxStore.isModal);
    const classCode = useSelector((state) => state.reduxStore.classCode);

    const [listAttend, setListAttend] = useState({});
    const [listBogang, setListBogang] = useState({});
    const [listJindo, setListJindo] = useState({});
    const [listHomework, setListHomework] = useState({});
    const [listExam, setListExam] = useState({});
    const [listOmr, setListOmr] = useState({});


    const acaCode = fnc.getCookie('acaCode');
    const stCode = selectedItem?.stCode;
    const lessonDate = selectedItem?.lessonDate;
    const gangsaName = selectedItem?.gangsaName;

    useEffect(() => {

      fncSelect_list_attend('출석');   
      fncSelect_list_bogang('보충');
      fncSelect_jindo('진도');
      fncSelect_homework('과제');
      fncSelect_exam('시험');
      fncSelect_omr();
    }, [selectedItem]);

    
  function fncSelect_list_attend(kind) {
    fnc.sendAxios('/select/lmsTecResultAllimjang/attendByStudent', 
          { acaCode, stCode, classCode, kind, lessonDate}, (res) => {
      if (res && Array.isArray(res)) { 
          setListAttend(getRecords(res));
      } else {
        console.log(res,"kuesbLmsTecResult_allimjang/Routes/select/lmsTecResultAllimjang/attendByStudent");
      }
    });
  }

  function fncSelect_list_bogang(kind) {
    fnc.sendAxios('/select/lmsTecResultAllimjang/attendByStudent', 
          { acaCode, stCode, classCode, kind, lessonDate}, (res) => {
      if (res && Array.isArray(res)) { 
          setListBogang(getRecords(res));
      } else {
        console.log(res,"kuesbLmsTecResult_allimjang/Routes/select/lmsTecResultAllimjang/attendByStudent");
      }
    });
  }

  

  function getRecords(res) {
    let records = {}; // 객체로 선언합니다.
    
    if (res.length > 0) {
        const snoSt = res[0]["snoSt"];
        const stCode = res[0]["stCode"];
        const name = res[0]["name"];
        let hp = res[0]["hp"];
        hp = fnc.formatPhoneNumber(hp);

        const lessonDate = res[0]["lessonDate"]; // list 코드 parent Code로 사용
        const classCode = res[0]["classCode"];
        const className = res[0]["className"];
        let checkedynSt = res[0]["checkedynSt"];
        const attend = fncCheckedynSt(checkedynSt);
        const kind = res[0]["kind"];
        let eDate = res[0]["eDate"];
        const confirmed = res[0]["confirmed"];
        const weekDay = fnc.getWeekDay(lessonDate);
;

        // 객체로 데이터를 추가합니다.
        records = {
            snoSt, lessonDate, weekDay, name, hp, classCode, className, kind, 
            attend, eDate, confirmed
        };
    }

    return records;
}



function fncSelect_jindo(kind) {//진도
  fnc.sendAxios('/select/lmsTecResultAllimjang/jindo_homework_exam_byStudent', 
        { acaCode, stCode, classCode, kind, lessonDate }, (res) => {
    if (res && Array.isArray(res)) { 
      setListJindo(getRecordsJindo(res));
    } else {
      console.log(res,"kuesbLmsTecResult_allimjangselect/jindo_homework_exam_byStudent");
    }
  });
}


function fncSelect_homework(kind) {//과제
  fnc.sendAxios('/select/lmsTecResultAllimjang/jindo_homework_exam_byStudent', 
        { acaCode, stCode, classCode, kind, lessonDate }, (res) => {
    if (res && Array.isArray(res)) { 
      setListHomework(getRecordsJindo(res));
    } else {
      console.log(res,"kuesbLmsTecResult_allimjangselect/jindo_homework_exam_byStudent");
    }
  });
}


function fncSelect_exam(kind) {//시험
  fnc.sendAxios('/select/lmsTecResultAllimjang/jindo_homework_exam_byStudent', 
        { acaCode, stCode, classCode, kind, lessonDate }, (res) => {
    if (res && Array.isArray(res)) { 
      setListExam(getRecordsJindo(res));
    } else {
      console.log(res,"kuesbLmsTecResult_allimjangselect/jindo_homework_exam_byStudent");
    }
  });
}

function getRecordsJindo(res) {
  let records = {}; // 객체로 선언합니다.

  if (res.length > 0) {
      const item = res[0]; // 첫 번째 레코드만 사용
      const snoSt = item["snoSt"];
      const lessonDate = item["lessonDate"]; // list 코드 parent Code로 사용
      const name = item["name"];
      let hp = item["hp"];
      hp = fnc.formatPhoneNumber(hp);
      const classCode = item["classCode"];
      const className = item["className"];
      const kind = item["kind"];
      let checkedynSt = item["checkedynSt"];
      checkedynSt = fncCheckedynSt_jindo(checkedynSt);
      let eDate = item["eDate"];
      const confirmed = item["confirmed"];
      const weekDay = fnc.getWeekDay(lessonDate);
      const jindo = item["jindo"];
      const homework = item["homework"];
      const bookCode = item["bookCode"];
      const bookName = item["bookName"];
      let score = item["score"];
      let avgScore = item["avgScore"];
      const rank = item["rank"];

      if (score !== '') {
          score = parseFloat(score).toFixed(1);
      }

      if (avgScore !== '') {
          avgScore = parseFloat(avgScore).toFixed(1);
      }

      records = {
          snoSt, lessonDate, weekDay, name, hp, classCode, className, kind, checkedynSt, 
          eDate, confirmed, jindo, homework, bookCode, bookName, score, avgScore, rank
      };
  }

  return records;
}

  
function fncSelect_omr() {
  fnc.sendAxios('/select/lmsTecResultAllimjang/omrByStudent', 
        { acaCode, stCode, lessonDate }, (res) => {
    if (res && Array.isArray(res)) { 
      setListOmr(getRecordsOmr(res));
    } else {
      console.log(res,"kuesbLmsTecResult_allimjangselect/select/lmsTecResultAllimjang/omrByStudent");
    }
  });
}

function getRecordsOmr(res) {
  let records = {}; // 객체로 선언합니다.

  if (res.length > 0) {
      const item = res[0]; // 첫 번째 레코드만 사용
      const classCode = item["classCode"];
      let lessonDate = item["lessonDate"];
      const weekDay = fnc.getWeekDay(lessonDate);
      const stName = item["stName"];
      let hp = item["hp"];
      hp = fnc.formatPhoneNumber(hp);
      let score = item["score"];
      let avgScore = item["avgScore"];
      let rank = item["rank"];
      const markingDate = item["markingDate"];

      if (score !== '') {
          score = parseFloat(score).toFixed(1);
      }

      if (avgScore !== '') {
          avgScore = parseFloat(avgScore).toFixed(1);
      }

      records = { classCode, lessonDate, weekDay, stName, hp, score, avgScore, rank, markingDate };
  }

  return records;
}

    //=========================================
    return (

      <div className="LmsTecResultAllimjang_report">
        <div className="wrap-main">
          <section className="section-main">

         { selectedItem && <section className="section-report"  ref={ref} >
               <h2>{listAttend.name}의 일일 리포트</h2>
               <p>수업일: {listAttend.lessonDate} ({listAttend.weekDay})</p>
               <p>반명: {listAttend.className}</p>
               <p>담임: {gangsaName}</p>
          
               <li>출결</li>
               <p>정규: {listAttend.attend}</p>
                {listBogang.attend && <p>보강: {listBogang.attend}</p>}
                <li>진도</li>
                <p>진도: {listJindo.jindo}</p>
                <p>진도완료: {listJindo.checkedynSt}</p>
           
               {listJindo.score && <div>
                  <p>점수: {listJindo.score}</p>
                  <p>평균: {listJindo.avgScore}</p>
                  <p>석차: {listJindo.rank}</p>          
                </div>}
                <li>과제</li>
                <p>과제: {listHomework.homework}</p>
                <p>과제완료: {listHomework.checkedynSt}</p>
           

               {listHomework.score && <div>
                  <span>점수: {listHomework.score}</span> &nbsp;
                  <span>평균: {listHomework.avgScore}</span> &nbsp;
                  <span>석차: {listHomework.rank}</span>          
                </div>}

               {listExam.bookName && 
                <div>
                  <li>시험</li>
                  <p>시험명: {listExam.bookName}</p>
                  <p>완료: {listExam.checkedynSt}</p> 
                </div>}

               {listExam.score && <div>
                  <span>점수: {listExam.score}</span>&nbsp;
                  <span>평균: {listExam.avgScore}</span>&nbsp;
                  <span>석차: {listExam.rank}</span>          
                </div>}

              
          
               {listOmr.score && <div>
                <li>임시시험</li>
                  <span>점수: {listOmr.score}</span>&nbsp;
                  <span>평균: {listOmr.avgScore}</span>&nbsp;
                  <span>석차: {listOmr.rank}</span>          
                </div>}

            </section>}
           
          </section>
      
        </div>

 
      </div>
    );

    
  function fncCheckedynSt(checkedynSt) {//출결
    switch(checkedynSt) {

      case 'Y':
        return '출석';
        break;  
    
      case 'L':
        return '지각';
        break;

      default:
        return '결석';
        break;
  
    }
  }

  
  function fncCheckedynSt_jindo(checkedynSt) {//진도
    switch(checkedynSt) {

      case 'Y':
        return 'O';
        break;  
    
      case 'N':
        return 'X';
        break;

      default:
        return '';
        break;
  
    }
  }
  
    
});

export default LmsTecResultAllimjang_report;