
import './GradeView.scss';
import React, { useEffect, useState, useMemo } from 'react';
import { useTable } from 'react-table';
import fnc from '../mymod/commFunction';

const GradeView = () => {
    const stCode = fnc.getCookie('stCode');
    const [list, setList] = useState([]);
    const [listHakjuk, setListHakjuk] = useState([]);


    useEffect(() => {
        // if(stCode) {
        //     alert('로그인이 필요합니다.');
        //     return;
        // }
        fncSelectHakjuk();
        fncSelectMyClass();
    }, []);

    
function fncSelectMyClass() {
    fnc.sendAxios('/aca/selectGrade', { stCode }, (res) => {
        if(res) {
            console.log(res, '------------');
            setList(res);
           
        }
    });
}

function fncSelectHakjuk() {
    fnc.sendAxios('/aca/selectHakjuk', { stCode }, (res) => {
        if(res) {
            console.log(res, '------------');
            setListHakjuk(res);
           
        }
    });
}
//==================================================
return (
  <div className="GradeView">
    <div className='GradeView_pc'>

        <div className='title'>* 성적조회</div>
        <table>
            {listHakjuk.length > 0  && listHakjuk[0] &&  <thead>
            <tr>
            <th>인적사항</th>
            <th colSpan={1}>성명</th>

            <th colSpan={4}>{listHakjuk[0].name}</th>
            <th>생년월일 <br/>국적</th>
            <th colSpan={3}>{listHakjuk[0].birthday} <br/>{listHakjuk[0].국적}</th>

            </tr>
            </thead>}
            <thead>
            <tr>
            <th>학기</th>
            <th>급수</th>
            <th>구분</th>
            <th>어휘<br/>문법</th>
            <th>쓰기</th>
            <th>듣기</th>
            <th>읽기</th>
            <th>말하기</th>
            <th>평균</th>
            <th>출석률</th>
            </tr>
            </thead>
            <tbody>
            {list && list.map((item, index) => (
            <tr key={item.sno}>
                <td>{item.학년도} {item.학기} <br/>{item.기간}</td>
                <td>{item.급수}</td>
                <td>중간 <br />기말</td>
                <td>{item.어휘문법1}<br />{item.어휘문법2}</td>
                <td>{item.쓰기1}<br />{item.쓰기2}</td>
                <td>{item.듣기1}<br />{item.듣기2}</td>
                <td>{item.읽기1}<br />{item.읽기2}</td>
                <td>{item.말하기1}<br />{item.말하기2}</td>
                <td>{item.평균1}<br />{item.평균2}</td>
                <td>{item.출석률}%</td>
                
            </tr>
            ))}
            </tbody>
        </table>
    </div>
    {/* ------------------------------------모바일 */}
    <div className="GradeView_mo">
    <div className='title'>* 성적확인 * </div>
   
    <div className='inner_gradeView_mo'>
      {list &&
        list.map((item, index) => (
          <div key={item.sno} className="card">
        <div className='box_title'>
            <p className='year'>{item.학년도} {item.학기} ({item.급수})</p> 
            <p className='term'>{item.기간}</p>
            <p className='name'>{listHakjuk[0].name}<br/>{listHakjuk[0].birthday} {listHakjuk[0].국적}</p>
        </div>
            <div className='box_lbl'>
                <p className='lbl'>어휘문법</p>
                <p>중간 {item.어휘문법1}  기말 {item.어휘문법2}</p>
            </div>

            <div className='box_lbl'>
                <p className='lbl'>쓰기</p>
                <p>중간 {item.쓰기1}  기말 {item.쓰기2}</p>
            </div>

            <div className='box_lbl'>
                <p className='lbl'>듣기</p>
                <p>중간 {item.듣기1}  기말 {item.듣기2}</p>
            </div>
   
            <div className='box_lbl'>
                <p className='lbl'>듣기</p>
                <p>중간 {item.듣기1}  기말 {item.듣기2}</p>
            </div>

            <div className='box_lbl'>
                <p className='lbl'>말하기</p>
                <p>중간 {item.말하기1}  기말 {item.말하기2}</p>
            </div>

            <div className='box_lbl'>
                <p className='lbl'>평균</p>
                <p>중간 {item.평균1} 기말 {item.평균2}</p>
            </div>

            <div className='box_lbl'>
                <p className='lbl'>출석률</p>
                <p>{item.출석률}%</p>
            </div>
          
            
          </div>
        ))}
    </div>
    </div>
  </div>
);
}

export default GradeView;