import './Detail_homeworkTec.scss';
import React, { useState, useEffect } from "react";
import fnc from '../mymod/commFunction';
import Button from '@mui/material/Button';
import Combobox from '../comm/Combobox_noBMK';
import Checkbox from '@mui/material/Checkbox';
import Select from 'react-select';
import HeaderTitle from "../comm/HeaderTitle";
import { useDispatch, useSelector } from 'react-redux';
import { setList_pdf, setHeaderInfo,setIsRefresh_studentCourseList } from '../reduxToolkit/redux';

const Detail_homeworkTec = () => {
  const dispatch = useDispatch();
  const classCode = useSelector(state => state.reduxStore.classCode);
  const className = useSelector(state => state.reduxStore.className);
  const lessonDate = useSelector(state => state.reduxStore.lessonDate);
  const lmsListCode = useSelector(state => state.reduxStore.lmsListCode);//parentCode

  const [list, setList] = useState([]);
  const [selectedIdx, setSelectedIdx] = useState(0);
  const [isRefresh, setIsRefresh] = useState(false);

  const [attendance, setAttendance] = useState(false);
  const [progress, setProgress] = useState(false);
  const [homework, setHomework] = useState(false);
  const [exam, setExam] = useState(false);
  const [extraLesson, setExtraLesson] = useState(false);


  useEffect(() => {
    fncSelect_list();
    fncSelect_homeworkRestore();
  }, [isRefresh]);

  function fncSelect_list() {
    const acaCode = fnc.getCookie('acaCode');
    const stCode = fnc.getCookie('stCode');
    
    fnc.sendAxios('/select/lms/course/studyStaus', { stCode, classCode, lessonDate }, (res) => {
      if (res && Array.isArray(res)) { 
        setList(getRecords(res));
      } else {
        console.log(res, "select/lms/course/studyStaus");
      }
    });
  }

  function getRecords(res) {
    let records = [];
    for (let i = 0; i < res.length; i++) {      
      const kind = res[i]["kind"];
      const lessonDate = res[i]["lessonDate"];
      const lessonDateSt = res[i]["lessonDateSt"];
      const classCode = res[i]["classCode"];
      const parentCode = res[i]["code"];
      const sno = res[i]["snoStu"];
             
      records.push({ kind, lessonDate, lessonDateSt, classCode, parentCode, sno });
    }
    return records;
  }

  function handleCheckboxClick(type) {
    let kind, checkedyn;
    switch (type) {
      case 'attendance':

        kind='출석';
        checkedyn = !attendance? 'Y':'N';
        setAttendance(!attendance);
        fncSave_homework(kind, checkedyn)

        break;
      case 'progress':
        kind='진도';
        checkedyn = !progress? 'Y':'N';
        setProgress(!progress);
        fncSave_homework(kind, checkedyn)

        break;
      case 'homework':
        kind='과제';
        checkedyn = !homework? 'Y':'N';
        setHomework(!homework);
        fncSave_homework(kind, checkedyn)
        break;

      case 'exam':
          kind='시험';
          checkedyn = !exam? 'Y':'N';
          setExam(!exam);
          fncSave_homework(kind, checkedyn)
          break;

      case 'extraLesson':
        kind='보충';
        checkedyn = !extraLesson? 'Y':'N';
        setExtraLesson(!extraLesson);
        fncSave_homework(kind, checkedyn)
        break;

      default:
        break;
    }
  }


  function fncSave_homework(kind, checkedyn) {
    const acaCode = fnc.getCookie('acaCode');
    const stCode = fnc.getCookie('stCode');
    const code = fnc.fncSirialNum('L');

    fnc.sendAxios('/save/lmsTec/course/homework', {acaCode, classCode, className, code, lessonDate , kind, checkedyn}, (res) => {
      if (res) { 
        setIsRefresh(!isRefresh);
      } else {
        console.log(res, "select/lms/course/studyStaus/delete");
      }
    });

  }

  
  function fncSelect_homeworkRestore() {

    fnc.sendAxios('/select/lmsTec/course/homeworkRestore', {classCode, lessonDate}, (res) => {
      if (res) { 
        res.forEach(item => {
          if (item.kind === "출석") {
            setAttendance(item.checkedyn === "Y");
          } else if (item.kind === "진도") {
            setProgress(item.checkedyn === "Y");
          } else if (item.kind === "과제") {
            setHomework(item.checkedyn === "Y");
          } else if (item.kind === "시험") {
            setExam(item.checkedyn === "Y");
          } else if (item.kind === "보충") {
            setExtraLesson(item.checkedyn === "Y");
          }
        });
      } else {
        console.log(res, "select/lms/course/studyStaus/delete");
      }
    });

  }
  //=================================================================

  return (
    <div className="Detail_homeworkTec">

      <div className='wrap-main'>

      <div className="lbl-help">
            정규 수업 : 출석 체크 (단, 보충 수업 :  보충 체크, 출석 미 체크) <br />
            수업 진도 있으면 :  진도 체크,
            시험 보면 : 시험 체크,  숙제 부여 : 과제 체크
      </div>


      <section className="section-checkbox">
        <article className="article-checkbox">
          <Checkbox
            id="attendance"
            checked={attendance}
            onChange={() => handleCheckboxClick('attendance')}
          />
          <label htmlFor="attendance">출석</label>
        </article>

        <article className="article-checkbox">
          <Checkbox
            id="progress"
            checked={progress}
            onChange={() => handleCheckboxClick('progress')}
          />
          <label htmlFor="progress">진도</label>
        </article>

        <article className="article-checkbox">
          <Checkbox
            id="homework"
            checked={homework}
            onChange={() => handleCheckboxClick('homework')}
          />
          <label htmlFor="homework">과제</label>
        </article>

        <article className="article-checkbox">
          <Checkbox
            id="exam"
            checked={exam}
            onChange={() => handleCheckboxClick('exam')}
          />
          <label htmlFor="exam">시험</label>
        </article>


        <article className="article-checkbox">
          <Checkbox
            id="extraLesson"
            checked={extraLesson}
            onChange={() => handleCheckboxClick('extraLesson')}
          />
          <label htmlFor="extraLesson">보충</label>
        </article>

      </section>
    </div>

    </div>
  );
}

export default Detail_homeworkTec;
