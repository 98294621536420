import './Detail_upload.scss';
import React, { useState, useEffect } from "react";
import fnc from '../mymod/commFunction';
import fncS3 from '../mymod/commFunction3';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
// import { setIsModalTest, bookCode, setBookCode } from '../reduxToolkit/redux';


const Detail_upload = () => {
  // const fileFolder = process.env.REACT_APP_fileFolder;
  const s3_acaCode = fnc.getCookie('s3_acaCode');
  const fileFolder = `${process.env.REACT_APP_uploadFileFolder}/${s3_acaCode}` ;
  const dispatch = useDispatch();
  const classCode = useSelector(state => state.reduxStore.classCode);
  const className = useSelector(state => state.reduxStore.className);
  const lessonDate = useSelector(state => state.reduxStore.lessonDate);
  const parentCode = useSelector(state => state.reduxStore.lmsListCode);//parentCode

  const [list, setList] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);
  const [fileName, setFileName] = useState('');

  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
   
  useEffect(() => {
    fncSelect_list();
  }, [isRefresh]);

  function fncSelect_list() {
    const acaCode = fnc.getCookie('acaCode');
    const stCode = fnc.getCookie('stCode');
    
    fnc.sendAxios('/select/lms/course/fileDownload', { acaCode, parentCode }, (res) => {

      console.log(res, ' fileName list');
      if (res && Array.isArray(res)) { 
        setList(getRecords(res));
      } else {
        console.log(res, "select/lms/course/studyTest");
      }
    });
  }

  function getRecords(res) {
    let records = [];
    for (let i = 0; i < res.length; i++) { 
        const sno = res[i]["sno"];     
        const fileCode = res[i]["fileCode"];
        const fileName = res[i]["fileName"];
        const kind = res[i]["kind"];
        const parentCode = res[i]["parentCode"];
        const code = res[i]["code"];
        records.push({ sno, fileCode, fileName, kind, parentCode, code });
    }
    return records;
  }

  function onClickTr() {
    // 클릭 이벤트 핸들러 내용 추가
  }

  // function onClickDownload(item) {   
  //   const rtn = window.confirm('파일을 다운로드하시겠습니까?3');
  //   if (!rtn) return null;

  //   // const fileFolder = "/web/kuesbLmsFileUpload/1000";
  //   const fileName = item.fileName;
  //   const fileNameCode = item.fileCode;
  //   const remoteFilePath = `${fileFolder}/${fileNameCode}`;

  //   fnc.sendAxiosBlob(`/downloadSftp`, { remoteFilePath, fileNameCode }, (res) => {
  //     if (res) {
  //       const url = window.URL.createObjectURL(new Blob([res], { type: 'application/octet-stream' }));
  //       const link = document.createElement('a');
  //       link.href = url;
  //       link.setAttribute('download', decodeURIComponent(fileName)); // 다운로드할 파일 이름 설정 및 디코딩
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //       window.URL.revokeObjectURL(url);  // 생성된 URL 해제
  //     } else {
  //       console.log('Error during file download');
  //     }
  //   }); 
  // }


  function onClickDownload(item) { //여기는 사용 안 하는 듯 upload에 다운로드 있음
        
    const rtn = window.confirm('파일을 다운로드하시겠습니까?');
    if(!rtn) return null;
  
    const fileName = item.fileName;
    const fileNameCode = item.fileCode;
    let s3FilePath = fncS3.getS3PathDownLoad( `${fileFolder}/${fileNameCode}`, fileName);
  
    if (s3FilePath) {

      const link = document.createElement('a');
      link.href = s3FilePath; // S3의 직접 다운로드 링크
      link.setAttribute('download', fileName); // 다운로드할 파일 이름 설정(직업 다운로드 시 안 먹혀) blob은 웹뷰에서 안먹혀
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.log('S3 파일 경로가 올바르지 않습니다.');
    }
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
      setFileName(file.name);
    } else {
      setFileName('');
    }
  };
const handleUpload = async () => {

  if (!file) {
    alert('파일을 선택하세요.');
    return;
  }


  const rtn = window.confirm(`${fileName}을 업로드하시겠습니까?`);
  if(!rtn) return;

  const formData = new FormData();
  formData.append('file', file);
  formData.append('classCode', classCode);
  const s3_acaCode = fnc.getCookie('s3_acaCode');
  formData.append('s3_acaCode', s3_acaCode);

  try {

    fnc.sendAxiosBlob('/uploadSftp', formData, (res, err) => {
      if (err) {
        alert('오류가 발생했습니다.');
        return;
      }

      fncInsertUploadRecord();//레코드 추가

      document.getElementById('file-upload-input').value = '';
      setFile(null);
      setFileName('');
    
    });
  } catch (err) {
    alert('오류가 발생했습니다.');
  }
};


function fncInsertUploadRecord() {

  const acaCode = fnc.getCookie('acaCode');
  const code = fnc.fncSirialNum('v');
  const fileCode = `${classCode}_${fileName}`;
  const extension = file.name.split('.').pop();
  const kind = extension;

  
fnc.sendAxiosBlob(`/insert/lmsTec/course/uploadRecord`, { acaCode, parentCode, classCode, fileCode, fileName, code, kind  }, (res) => {
  if (res) {
    setIsRefresh(!isRefresh);
    alert(' 완료되었습니다.')
  } else {
    console.log('Error during file download');
  }
}); 
}

function onClickDelete(item) {

const acaCode = fnc.getCookie('acaCode');
const sno = item.sno;

const rtn = window.confirm(`${item.fileName}을 \n삭제하시겠습니까?`);
if (!rtn) return null;


fnc.sendAxiosBlob(`/delete/lmsTec/course/upload`, { acaCode, sno }, (res) => {
  if (res) {
      setIsRefresh(!isRefresh);
  } else {
    console.log('Error during file download');
  }
}); 

}
//============================================================
  return (
    <div className="Detail_upload">
      <div className="title">파일 업로드</div>
      <div className="wrap-main">
        <section className="section-main">
          <table className="table-hong table-main">
            <thead>
              <tr>
                <th>종류</th>
                <th>파일명</th>
                <th>다운로드</th>
                <th>삭제</th>
              </tr>
            </thead>
            <tbody>
              {list &&
                list.map((item, index) => (
                  <tr key={index} onClick={() => onClickTr(item, index)}>
                    <td>{item.kind}</td>
                    <td>{item.fileName}</td>
                    <td>
                      <Button
                        className="btnDownload"
                        onClick={() => onClickDownload(item)}
                      >
                        {'다운'}
                      </Button>
                    </td>

                    <td>
                     <Button
                        className="btnDelete"
                        onClick={() => onClickDelete(item)}
                      >
                        {'삭제'}
                      </Button>

                    </td>

                  </tr>
                ))}
            </tbody>
          </table>
        </section>
        <section className="section-upload">
          <div className="file-upload-container">
            <input
              type="file"
              id="file-upload-input"
              className="file-upload-input"
              onChange={handleFileChange}
            />

          <Button className='btnUpload' onClick={handleUpload}>파일 업로드</Button>

          </div>

         
         
        </section>
      
      </div>
    </div>
  );
};

export default Detail_upload;
