import "./LmsTec.scss";
import * as S from "../scss/theme";
import HeaderTitle from "../comm/HeaderTitle";
import React, { useState, useEffect } from "react";
import { setHeaderInfo, setSelectedUnit_pdf } from '../reduxToolkit/redux';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import fnc from "../mymod/commFunction";
import MyTreeView from "./MyTreeViewTec";
import LmsTecMain from "./LmsTecMain";


const LmsTec = () => {
    const dispatch = useDispatch();
    
    const [selectedNodeItem, setSelectedNodeItem] = useState({});
    const [kind, setKind] = useState('lesson');
    const [isClickGangsa, setIsClickGangsa] = useState(true);
    const [isClickClass, setIsClickClass] = useState(false);
    const [isClickGroup, setIsClickGroup] = useState(false);
    const [optionsUniTable, setOptionsUniTable] = useState([]);
    const isModalTest = useSelector((state) => state.reduxStore.isModalTest);
    const bookCode = useSelector((state) => state.reduxStore.bookCode);



    useEffect(() => {
        const userName = fnc.getCookie('userName');
        dispatch(setHeaderInfo({ title: `${userName} 선생님의 LMS`, direction: -1 }));
    }, []);

    useEffect(() => {
        if (selectedNodeItem.node) {
            // console.log(selectedNodeItem.node.key, 'selectedNodeItem');
        }
    }, [selectedNodeItem]);

    // function fncSelectGangsaCombo(acaCode) {
    //     fnc.sendAxios("/select/class/gangsaList", { acaCode }, (res) => {
    //         if (res) {

    //         } else {
    //             console.log(res, "속성이 obj 내에 없습니다.");
    //         }
    //     });
    // }

    function fncSelectClassTable(acaCode, groupCode) {
        fnc.sendAxios("/select/class/classList", { acaCode, groupCode }, (res) => {
            if (res) {
                setOptionsUniTable(res);
            } else {
                console.log(res, "속성이 obj 내에 없습니다.");
            }
        });
    }


    return (
        <>
            <S.Section>
                <HeaderTitle />
                <div className="LmsTec">
                    <div className="wrap-book">
                        <section className="section-btn">
                            <MyTreeView setSelectedNodeItem={setSelectedNodeItem} />
                        </section>
                    </div>

                    {isClickGangsa &&
                        <section className="section-bookMain">
                            {isClickGangsa && selectedNodeItem.node && <LmsTecMain classCode={selectedNodeItem.node.key} kind={kind} />}
                        </section>
                    }
                </div>

           
            </S.Section>

      
          
           

        </>
    );
};

export default LmsTec;
