import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './NavbarMO.scss'; 
import hboiaLogo from './images/logo_transprent.png';
import { useSelector, useDispatch } from 'react-redux';
import { setIsLoggedIn,  setIsSubmenuVisible } from "./reduxToolkit/reduxAca";

function NavbarMO({ isOpen, setIsOpen }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(state => state.reduxStoreAca.isLoggedIn);

  const [activeSubMenu, setActiveSubMenu] = useState('');

  const toggleSubMenu = (menuName) => {
    setActiveSubMenu(activeSubMenu === menuName ? '' : menuName);
  };


  const onHome = () => {
    // navigate('/'); 
  }

  const closeMenuAndNavigate = (path) => {
    setActiveSubMenu(''); // 서브메뉴 닫기
    setIsOpen(false); // 전체 메뉴 닫기
    navigate(path); // 페이지 이동
  };

  
  const onToggleLogin = () => {
    if (isLoggedIn) {
      const rtn = window.confirm('로그아웃하시겠습니까?');
      if (!rtn) return;

      dispatch(setIsLoggedIn(false));
      closeMenuAndNavigate('/'); 
    }else {
      closeMenuAndNavigate('/login'); 
    }
  }

  //=======================================================
  return (
    <div className="mobileNav">

      <div className="navbar-logo" onClick={onHome}>
            {/* <img src={hboiaLogo} alt="HBOIA" /> */}
            <div className='txt-logo'>
                      <p>고대학원</p>     
                      <p>KUESB</p>      
            </div>
      </div>

      <div className={`menu ${isOpen ? 'open' : ''}`}>
      {/* {isLoggedIn && <div className="menuItem" onClick={() => {closeMenuAndNavigate('/evaluationTec')} }>
          강사평가
          <i className={`fas ${activeSubMenu === 'hussIntro' ? 'fa-chevron-up' : 'fa-angle-right'}`} style={{float: 'left'}}></i>
        </div> } */}
      {/* {isLoggedIn && <div className="menuItem" onClick={() => {closeMenuAndNavigate('/gradeView')}}>
          성적확인
          <i className={`fas ${activeSubMenu === 'hussIntro' ? 'fa-chevron-up' : 'fa-angle-right'}`} style={{float: 'left'}}></i>
        </div> } */}

        {/* {isLoggedIn && <div className="menuItem" onClick={() => {closeMenuAndNavigate('/updatepw')}}>
          정보수정
          <i className={`fas ${activeSubMenu === 'hussIntro' ? 'fa-chevron-up' : 'fa-angle-right'}`} style={{float: 'left'}}></i>
        </div>} */}

        <div className="menuItem" onClick={onToggleLogin}>
          {isLoggedIn ? '로그아웃' : '로그인'}
          <i className={`fas ${activeSubMenu === 'hussIntro' ? 'fa-chevron-up' : 'fa-angle-right'}`} style={{float: 'left'}}></i>
        </div>

       
      </div>
       
    </div>
  );
}

export default NavbarMO;
